import { getScenarioRequestParameters } from '@/js/helpers/scenario.mjs';
import { getFilters } from '@/js/helpers/data-filter.mjs';
import { isEditingCell } from '../helpers/context-menu';
import { getHydrocarbonHierarchy } from '../helpers/guideline-export.js';
import {
  AcceptanceMatrixRow,
  getDefaultAcceptanceMatrix,
} from '../types/acceptance-matrix';
import { convertMatrixStringToType } from '@component-library/business-logic/matrix';
import { ProjectScenarioGroupType } from '@component-library/enviro';

const get_paginated_chemicals_current_page = (state) =>
  state.load_paginated_chemicals_complete?.current_page || 0;

const get_chemical_groups = (state) => {
  if (
    !state.filters ||
    !state.filters.groups ||
    state.filters.groups.length == 0
  ) {
    return [];
  }

  let groups = state.filters.groups
    .map((group) => {
      const chemGroup = state.all_chemical_groups.find((g) => g.id == group.id);

      if (!chemGroup) {
        return;
      }

      return {
        ...chemGroup,
        units: group.units,
      };
    })
    .filter((g) => g);

  if (state.filters.show_other_group) {
    groups = [
      ...groups,
      {
        group_title: 'Other',
        is_other: true,
      },
    ];
  }

  return groups.filter((s) => !s.rendered);
};

const get_is_cell_highlighted = (state) => (id) => {
  return isEditingCell(id, state.editing_data);
};

const get_result_by_ids = (state) => (sample_id, chemical_id) => {
  return state.load_results_complete.find(
    (r) => r.sample_id == sample_id && r.chemical_id == chemical_id
  );
};

const get_filters = (state, getters, rootState, rootGetters) => {
  return getFilters(
    state.filters,
    rootState.project,
    rootGetters['dashboard/get_prerequisite_field']('has_enviro_data'),
    rootGetters['dashboard/get_scenario_group']?.type === 1
  );
};

const get_shared_properties = (state, getters, rootState, rootGetters) => {
  const scenarioGroup = rootGetters['dashboard/get_scenario_group'];
  return {
    matrix: rootState.matrix,
    filters: getters.get_filters,
    ...getScenarioRequestParameters(
      rootGetters['dashboard/get_processed_scenarios'],
      rootState.dashboard.processed_settings &&
        rootState.dashboard.processed_settings.by_documents
    ),
    verification_type: getters.get_verification_type,
    profile_id: scenarioGroup ? scenarioGroup.chemical_profile_id : null,

    has_gather_fields: state.sample_fields.some((f) => f.template_field_id),
    has_laboratory_field: state.sample_fields.some(
      (f) => f.key === 'laboratory'
    ),
    use_grouped_location_codes: false,
    type: scenarioGroup ? scenarioGroup.type : null,
  };
};

const get_export_settings = (state) => {
  const isExporting = state.is_exporting;

  const rawHydrocarbonHierarchy =
    state.hydrocarbon_export_type &&
    state.hydrocarbon_export_type !== 'everything'
      ? state.export_settings?.hydrocarbon_hierarchy || false
      : null;

  const hydrocarbonHierarchy =
    isExporting && rawHydrocarbonHierarchy
      ? getHydrocarbonHierarchy(rawHydrocarbonHierarchy)
      : null;

  const hydrocarbonHierarchyColSpan = hydrocarbonHierarchy
    ? Object.values(hydrocarbonHierarchy).reduce(
        (total, { colSpan }) => total + colSpan,
        0
      )
    : null;

  return {
    ...state.export_settings,
    isExporting,
    isExportPreview: state.is_export_preview,
    isHydrocarbonExport: state.is_hydrocarbon_export,
    hydrocarbonExportType: state.hydrocarbon_export_type,
    hydrocarbonHierarchy: hydrocarbonHierarchy,
    hydrocarbonHierarchyColSpan,
    compositeSampleCalculations: isExporting
      ? state.export_settings.composite_sample_calculations
      : [],
    hydrocarbon_hierarchy: rawHydrocarbonHierarchy,
  };
};

const get_table_settings = (state, getters, rootState) => {
  const project = rootState.project;
  const useAcceptanceMatrix = project?.use_rpd_acceptance_matrix || false;

  const getAcceptanceMatrix = (): AcceptanceMatrixRow[] => {
    const numberMatrix = convertMatrixStringToType(rootState.matrix);

    const acceptanceMatrix: AcceptanceMatrixRow[] =
      project?.rpd_acceptance_matrix
        ? project.rpd_acceptance_matrix.filter((s) => s.matrix == numberMatrix)
        : [];

    return acceptanceMatrix.length > 0
      ? acceptanceMatrix
      : getDefaultAcceptanceMatrix(numberMatrix);
  };

  return {
    hasEQLColumn: state.table_settings?.has_eql_column,
    editingData: state.editing_data,
    tableOnlyView: state.table_only_view,
    useAcceptanceMatrix,
    acceptanceMatrix: getAcceptanceMatrix(),
  };
};

const get_verification_type = (state, getters, rootState, rootGetters) => {
  const scenarioGroupType = rootGetters['dashboard/get_scenario_group']?.type;
  return scenarioGroupType === ProjectScenarioGroupType.RPD
    ? 'duplicate'
    : scenarioGroupType == ProjectScenarioGroupType.Blanks
    ? 'blanks'
    : null;
};

const table_filter_statistics = (state) => {
  return {
    total_samples: state.total_number_of_samples,
    visible_number_samples:
      state.load_paginated_samples_complete?.number_of_items || 0,
    total_chemicals: state.total_number_of_chemicals,
    visible_number_chemicals:
      state.load_paginated_chemicals_complete?.number_of_items || 0,
  };
};

export default {
  get_paginated_chemicals_current_page,

  get_chemical_groups,

  get_is_cell_highlighted,

  get_result_by_ids,

  get_filters,

  get_shared_properties,

  get_export_settings,
  get_table_settings,

  table_filter_statistics,
  get_verification_type,
};
