import { MatrixType } from '@component-library/business-logic/matrix';

export interface AcceptanceMatrixRow {
  from: null | number;
  operator: null | string;
  to: null | number;
  rpd: null | number;
  matrix: MatrixType;
}

export const getDefaultAcceptanceMatrix = (
  matrix: MatrixType
): AcceptanceMatrixRow[] => {
  return [
    { from: 1, operator: null, to: 10, rpd: 80, matrix },
    { from: 10, operator: null, to: 30, rpd: 50, matrix },
    { from: null, operator: '>', to: 30, rpd: 30, matrix },
  ];
};

export const getRPDFromMatrix = (
  acceptanceMatrix: AcceptanceMatrixRow[],
  eqlResult: number,
  resultValue: number
) => {
  for (const range of acceptanceMatrix) {
    const { from, to, operator } = range;

    const fromEql = from !== null ? eqlResult * from : null;
    const toEql = to !== null ? eqlResult * to : null;

    if (fromEql !== null && toEql != null && !operator) {
      if (resultValue >= fromEql && resultValue <= toEql) {
        return range.rpd;
      }
    }

    if (fromEql === null && toEql !== null && operator == '>') {
      if (resultValue > toEql) {
        return range.rpd;
      }
    }
  }

  return null;
};

export const exceedsRPD = (
  result: number | null,
  rpdAcceptance: number | null
) => {
  if (result === null || rpdAcceptance === null) {
    return false;
  }

  return result > rpdAcceptance;
};
