import { computed, Ref } from 'vue';
import _get from 'lodash/get';
import _set from 'lodash/set';
import {
  mapGetters,
  mapState,
  mapActions,
  createNamespacedHelpers,
} from 'vuex';

export type NamespaceHelpers = {
  mapState: typeof mapState;
  mapGetters: typeof mapGetters;
  mapActions: typeof mapActions;
  getState: (store: any, stateName: string) => any;
  useState: (store: any, stateName: string[]) => Ref<any>[];
  useGetters: (store: any, getterNames: string[]) => ((args) => any)[];
  useActions: (
    store: any,
    getterNames: string[]
  ) => ((payload: any) => Promise<any>)[];
  getGetter: (store: any, getterName: string) => any;
  dispatch: (store: any, actionName: string, payload?: any) => Promise<any>;
};

function empowerNamespace(namespace): any & NamespaceHelpers {
  const subNamespaceKeys = Object.keys(namespace).filter(
    (key) => !['name', 'namespaced'].includes(key)
  );

  namespace = {
    ...namespace,
    ...createNamespacedHelpers(namespace.name),
    useState(store, stateNames) {
      return stateNames.map((name) => {
        return computed({
          get() {
            return _get(
              store.state,
              `${namespace.name.replaceAll('/', '.')}.${name}`
            );
          },
          set(value) {
            return _set(
              store.state,
              `${namespace.name.replaceAll('/', '.')}.${name}`,
              value
            );
          },
        });
      });
    },
    useActions(store, actionNames) {
      return actionNames.map((name) => {
        return (payload) =>
          store.dispatch(`${namespace.name}/${name}`, payload);
      });
    },
    getState(store, stateName) {
      return _get(
        store.state,
        `${namespace.name.replaceAll('/', '.')}.${stateName}`
      );
    },
    getGetter(store, getterName) {
      return store.getters[`${namespace.name}/${getterName}`];
    },
    useGetters(store, getterNames) {
      return getterNames.map((name) => {
        return computed(() => store.getters[`${namespace.name}/${name}`]);
      });
    },
    dispatch(store, actionName, payload): Promise<any> {
      return store.dispatch(`${namespace.name}/${actionName}`, payload);
    },
  };

  if (subNamespaceKeys.length) {
    for (let item of subNamespaceKeys) {
      namespace = {
        ...namespace,
        [item]: empowerNamespace(namespace[item]),
      };
    }
  }

  return namespace;
}

const namespace = {
  namespaced: true,
  name: `maps`,
  ANALYSIS: {
    namespaced: true,
    name: 'maps/analysis',
    HEATMAP: {
      namespaced: true,
      name: 'maps/analysis/heatmap',
    },
    BUFFER: {
      namespaced: true,
      name: 'maps/analysis/buffer',
    },
  },
};

export type MapsNamespace = {
  name: string;
  namespaced: true;
  ANALYSIS: {
    name: string;
    namespaced: true;
    HEATMAP: {
      name: string;
      namespaced: true;
    } & NamespaceHelpers;
    BUFFER: {
      name: string;
      namespaced: true;
    } & NamespaceHelpers;
  } & NamespaceHelpers;
} & NamespaceHelpers;

export const NAMESPACE = empowerNamespace(namespace) as MapsNamespace;
