import { createTextShadow } from '../../../view-utils';
import * as bl_color from '../../../business-logic/color';

export default function createTextStyle(
  {
    color = bl_color.BLACK,
    textSize = 16,
    textWidth = 140,
    textAngle = 0,
    showTextBackground = false,
    textAlignment = 0,
    textItalicStyle = false,
    textBoldStyle = false,
    textUnderlineStyle = false,
    textBorderColor = bl_color.WHITE,
  },
  zoom = 1
) {
  const style = {
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color,
    fontSize: `${textSize}px`,
    width: `${textWidth}px`,
    transformOrigin: 'left top',
    transform: `rotate(${-textAngle}deg) scale(${zoom})`,
  };

  switch (textAlignment) {
    case 0:
      style.textAlign = 'left';
      break;
    case 1:
      style.textAlign = 'center';
      break;
  }

  style.fontStyle = textItalicStyle ? 'italic' : 'normal';
  style.fontWeight = textBoldStyle ? 'bold' : 'normal';
  style.textDecoration = textUnderlineStyle ? 'underline' : 'none';
  style.textUnderlinePosition = 'under';

  // use the backslash to remove linebreaks
  style.textShadow = createTextShadow(textBorderColor);

  if (showTextBackground) {
    style.backgroundColor = bl_color.WHITE;
    style.border = `0.1em solid ${bl_color.BLACK}`;
    style.padding = '0.1em';
  } else {
    style.backgroundColor = 'transparent';
    style.border = 'none';
    style.padding = 0;
  }

  return style;
}
