export function getOptions(placeholder) {
  return {
    theme: 'bubble',
    modules: {
      toolbar: [
        ['bold', 'italic', 'blockquote', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [2, 3, false] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }],
        ['clean'],
      ],
    },
    placeholder,
  };
}
