<template>
  <section ref="editor"></section>
</template>

<script>
import { getOptions } from './quill-handler';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import Quill from 'quill';
import {
  onMounted,
  ref,
  watch,
  onUnmounted,
  onBeforeUnmount,
  nextTick,
} from 'vue';

const defaultOptions = {
  boundary: document.body,

  ...getOptions('Insert content here ...'),
  readOnly: false,
};

export default {
  name: 'quill-editor',
  props: {
    content: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ['ready', 'change', 'input', 'blur', 'focus', 'update:value'],
  setup(props, context) {
    const state = {
      editorOption: {},
      quill: null,
    };

    let _content = '';

    // Update content when `value` prop changes
    watch(
      () => props.value,
      (val) => {
        if (state.quill) {
          if (val && val !== _content) {
            _content = val;
            state.quill.clipboard.dangerouslyPasteHTML(val);
          } else if (!val) {
            state.quill.setText('');
          }
        }
      }
    );

    // Update content when `content` prop changes
    watch(
      () => props.content,
      (val) => {
        if (state.quill) {
          if (val && val !== _content) {
            _content = val;
            state.quill.clipboard.dangerouslyPasteHTML(val);
          } else if (!val) {
            state.quill.setText('');
          }
        }
      }
    );

    // Toggle read-only based on disabled prop
    watch(
      () => props.disabled,
      (val) => {
        if (state.quill) {
          state.quill.enable(!val);
        }
      }
    );

    const editor = ref(null);

    const mergeOptions = (def, custom) => {
      for (const key in custom) {
        if (!def[key] || key !== 'modules') {
          def[key] = custom[key];
        } else {
          mergeOptions(def[key], custom[key]);
        }
      }
      return def;
    };

    const initialize = () => {
      if (editor.value) {
        // Merge default and custom options
        state.editorOption = mergeOptions(defaultOptions, props.options);
        state.editorOption.readOnly = props.disabled ? true : false;
        // Initialize Quill instance
        state.quill = new Quill(editor.value, state.editorOption);

        // Set initial content if provided
        if (props.value) {
          state.quill.clipboard.dangerouslyPasteHTML(props.value);
        }

        // Handle focus and blur events
        state.quill.on('selection-change', (range) => {
          if (!range) {
            context.emit('blur', state.quill);
          } else {
            context.emit('focus', state.quill);
          }
        });

        // Update model on text-change
        state.quill.on('text-change', () => {
          if (props.disabled) {
            state.quill.enable(false);
          }
          let html = editor.value.children[0].innerHTML;
          const quill = state.quill;
          const text = state.quill.getText();
          if (html === '<p><br></p>') html = '';
          _content = html;
          context.emit('update:value', _content);
          context.emit('change', { html, text, quill });
        });

        // Emit ready event and set cursor to end of content when visible
        context.emit('ready', state.quill);
        nextTick(() => {
          // Delay to ensure the editor is visible and content is rendered
          const length = state.quill.getLength();
          state.quill.focus();
          state.quill.setSelection(length, 0);
        });
      }
    };

    onBeforeUnmount(() => {
      const editorToolbar = editor.value.previousSibling;
      if (
        editorToolbar &&
        editorToolbar.nodeType === 1 &&
        editorToolbar.className.indexOf('ql-toolbar') > -1
      ) {
        editorToolbar.parentNode.removeChild(editorToolbar);
      }
    });

    onMounted(() => {
      initialize();
    });

    onUnmounted(() => {
      state.quill = null;
    });

    return { editor };
  },
};
</script>
