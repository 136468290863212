import * as coordinate from 'ol/coordinate';

export function getPixelDistance(map, position1, position2) {
  const pixel1 = map.getPixelFromCoordinate(position1);
  const pixel2 = map.getPixelFromCoordinate(position2);
  return coordinate.distance(pixel1, pixel2);
}

/**
 * Check whether a geographic position is in the area of a HTML element.
 * @param {ol.Coordinate} position
 * @returns
 */
export function CheckIsPositionInHtmlElement(map, position, htmlElement) {
  const mapTargetBCR = map.getTargetElement().getBoundingClientRect();
  const htmlElementBCR = htmlElement.getBoundingClientRect();
  // Use the top-left of the map target element as the origin
  const htmlElementArea = [
    [
      htmlElementBCR.left - mapTargetBCR.left,
      htmlElementBCR.top - mapTargetBCR.top,
    ],
    [
      htmlElementBCR.right - mapTargetBCR.left,
      htmlElementBCR.bottom - mapTargetBCR.top,
    ],
  ];

  function checkIsInArea(pixel, area) {
    const [x, y] = pixel;
    const [topLeft, bottomRight] = area;
    return (
      x >= topLeft[0] &&
      x <= bottomRight[0] &&
      y >= topLeft[1] &&
      y <= bottomRight[1]
    );
  }

  const pixel = map.getPixelFromCoordinate(position);
  return checkIsInArea(pixel, htmlElementArea);
}

// The position is in map view's projection.
export function getNeighbouringPosition(map, position) {
  // 0: Top-center
  // 1: Center-right
  // 2: Bottom-center
  // 3: Center-left
  const randomIndex = Math.floor(Math.random() * 4);
  const offset = 200; // pixels
  let pixel = map.getPixelFromCoordinate(position);
  if (randomIndex === 0) {
    pixel = [pixel[0], pixel[1] - offset];
  } else if (randomIndex === 1) {
    pixel = [pixel[0] + offset, pixel[1]];
  } else if (randomIndex === 2) {
    pixel = [pixel[0], pixel[1] + offset];
  } else if (randomIndex === 3) {
    pixel = [pixel[0] - offset, pixel[1]];
  }
  return map.getCoordinateFromPixel(pixel);
}

export function calculateOverlayPositioning(map, startPosition, endPosition) {
  const startPixel = map.getPixelFromCoordinate(startPosition);
  const endPixel = map.getPixelFromCoordinate(endPosition);
  const x = startPixel[0] - endPixel[0];
  const y = -(startPixel[1] - endPixel[1]);
  let angle = (Math.atan2(y, x) / Math.PI) * 180;
  if (angle < 0) {
    angle += 360;
  }
  if (angle >= 0 && angle < 45) {
    return 'center-left';
  } else if (angle >= 45 && angle < 135) {
    return 'bottom-center';
  } else if (angle >= 135 && angle < 225) {
    return 'center-right';
  } else if (angle >= 225 && angle < 315) {
    return 'top-center';
  } else if (angle >= 315 && angle < 360) {
    return 'center-left';
  }

  return null;
}
