export default function useIsGather() {
  // Refactor once we migrate to Vue3, as excel-exporter is SSR
  const viteAppUrl =
    import.meta.env?.VITE_APP_URL ||
    (typeof process !== 'undefined' ? process.env.VITE_APP_URL : null) ||
    '';

  return viteAppUrl.toLowerCase().includes('gather');
}

export function useIsPublicForm() {
  return window.location.pathname.startsWith('/form/');
}
