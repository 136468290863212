<template>
  <div
    v-click-outside="hideDropdownMenu"
    class="interactive-relationship-dropdown"
  >
    <div
      class="d-flex align-items-center clickable"
      @click.prevent.stop="toggleDropdown"
    >
      <span class="fw-medium me-2">Interactive Relationships</span>
      <i
        class="fas me-2"
        :class="{
          'fa-chevron-up': show,
          'fa-chevron-down': !show,
        }"
      />
    </div>

    <div class="dropdown-menu autocomplete-menu mt-2" :class="{ show: show }">
      <div v-if="eventSources.length > 0">
        <div
          v-for="eventSource in eventSources"
          :key="`eventSource-${eventSource.id}`"
          class="dropdown-item"
          @click.stop="
            $emit('toggleInteractiveRelationship', {
              eventSourceId: eventSource.id,
            })
          "
        >
          <i
            :class="{
              'far fa-square': !eventSource.checked,
              'fas fa-check-square': eventSource.checked,
            }"
          ></i>
          {{ eventSource.title }}
        </div>
      </div>
      <div v-else class="dropdown-item">No event sources found.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InteractiveRelationshipDropdown',
  props: {
    eventSources: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    hideDropdownMenu() {
      this.show = false;
    },
    toggleDropdown() {
      this.show = !this.show;
    },
  },
};
</script>
