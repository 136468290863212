import buffer from '@turf/buffer';
import difference from '@turf/difference';
import union from '@turf/union';
import { Feature, Map, getUid } from 'ol';
import * as olCoordinate from 'ol/coordinate';
import { LineString, MultiPolygon, Polygon } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { extentToBounds } from '../../common/extent';
import { fromGeoJSON, toGeoJSON } from '../../common/geojson';
import createShapeStyle from '../../style/createShapeStyle';
import LayerManager from '../LayerManager';
import { LayerType } from '../types';
import { createLayerProperties } from '../utils';
import type { BufferLayerModel, ShapeLayer } from './types';
import { BufferType } from './types';

export default function createBufferLayer(
  map: Map,
  model: BufferLayerModel,
  layerManager: LayerManager
): ShapeLayer {
  const [feature] = fromGeoJSON(map, model.geojson) as Feature<Polygon>[];
  const { type, usage } = model.geojson.properties;
  const options = {
    source: new VectorSource<Feature<Polygon>>({
      features: [feature],
    }),
    properties: createLayerProperties(model.id, type, usage),
  };
  const layer = new VectorLayer(options) as ShapeLayer;
  feature.set('layerUid', getUid(layer));
  layer.setStyle(
    createShapeStyle(map, () => {
      return model.geojson.properties;
    })
  );

  layer.getFirstFeature = function () {
    return this.getSource()!.getFeatures()[0];
  };

  layer.checkHasFeature = function (feature) {
    if (feature.getGeometry()!.getType() !== 'Polygon') {
      return false;
    }

    return this.getSource()!.hasFeature(feature as Feature<Polygon>);
  };

  layer.toGeoJSON = function () {
    const feature = this.getFirstFeature()!.clone() as Feature<Polygon>;
    feature.setProperties({
      ...this.getProperties(),
    });
    return toGeoJSON(map, feature);
  };

  layer.getBounds = function (padding = 0) {
    const extent = this.getSource()!.getExtent();
    return extentToBounds(extent, map.getView().getProjection(), padding);
  };

  layer.refresh = function () {
    const {
      boundLayerIds: boundLayerModelIds,
      distance,
      bufferType,
      type,
    } = model.geojson.properties;
    const boundLayers = boundLayerModelIds
      .map((boundLayerModelId) =>
        layerManager.findLayerByModelId(boundLayerModelId)
      )
      .filter((boundLayer) => {
        if (!boundLayer) {
          return false;
        }

        if ([BufferType.Unfilled, BufferType.Inverse].includes(bufferType)) {
          return ![LayerType.POLYLINE, LayerType.ARROW].includes(type);
        }

        return true;
      });
    const boundFeatures = boundLayers
      .map((boundLayer) => {
        const [feature] = boundLayer.getSource().getFeatures();
        const geom = feature.getGeometry();

        if (geom.getType() !== 'Polygon') {
          return feature;
        }

        // The start and end coordinates of a polygon could be different during dragging the start point
        // on a curve.
        const [coords] = geom.getCoordinates();
        return olCoordinate.equals(coords[0], coords[coords.length - 1])
          ? feature
          : undefined;
      })
      .filter((boundFeature) => !!boundFeature);
    const bufferGeometry = createBufferGeometry(
      map,
      boundFeatures,
      distance,
      bufferType
    );

    if (!bufferGeometry) {
      return;
    }

    const source = this.getSource()!;
    let [feature] = source.getFeatures() as BufferFeature[];
    if (!feature) {
      feature = new Feature(bufferGeometry);
      source.addFeature(feature);
    } else {
      feature.setGeometry(bufferGeometry);
    }

    const z = layerManager.calculateZIndexOfBufferLayer(
      boundLayerModelIds,
      bufferType
    );
    this.setZIndex(z);
  };

  // layer.refresh();

  return layer;
}

type BoundFeature = Feature<Polygon | LineString>;
type BoundGeometryGeoJSON = GeoJSON.Polygon | GeoJSON.LineString;
type BoundPolygonFeatureGeoJSON = GeoJSON.Feature<GeoJSON.Polygon>;
type BoundFeaturesGeoJSON = GeoJSON.FeatureCollection<BoundGeometryGeoJSON>;
type BufferGeometry = Polygon | MultiPolygon;
type BufferGeometryGeoJSON = GeoJSON.Polygon | GeoJSON.MultiPolygon;
type BufferFeature = Feature<BufferGeometry>;
type BufferFeatureGeoJSON = GeoJSON.Feature<BufferGeometryGeoJSON>;

function createFilledBufferGeometry(
  map: Map,
  boundFeatures: BoundFeature[],
  distance: number
): BufferGeometry | undefined {
  const boundFeaturesGeoJSON = toGeoJSON(
    map,
    boundFeatures
  ) as BoundFeaturesGeoJSON;
  const { features: bufferFeatureGeoJSONs } = buffer(
    boundFeaturesGeoJSON,
    distance / 1000,
    {
      units: 'kilometers',
    }
  ) as { features: BufferFeatureGeoJSON[] };

  const finalBufferFeatureGeoJSON =
    bufferFeatureGeoJSONs.length > 1
      ? bufferFeatureGeoJSONs.reduce((accu, bufferFeatureGeoJSON) => {
        return union(accu, bufferFeatureGeoJSON) as BufferFeatureGeoJSON;
      })
      : bufferFeatureGeoJSONs[0];
  const [bufferFeature] = finalBufferFeatureGeoJSON
    ? (fromGeoJSON(map, finalBufferFeatureGeoJSON) as BufferFeature[])
    : [];

  return bufferFeature?.getGeometry();
}

function createUnfilledBufferGeometry(
  map: Map,
  boundFeatures: BoundFeature[],
  distance: number
): BufferGeometry | undefined {
  const filledBufferGeometry = createFilledBufferGeometry(
    map,
    boundFeatures,
    distance
  );

  if (!filledBufferGeometry) {
    return undefined;
  }

  const bufferFeatureGeoJSON = boundFeatures.reduce((accu, boundFeature) => {
    if (boundFeature.getGeometry()?.getType() === 'LineString') {
      return accu;
    }

    const boundFeatureGeoJSON = toGeoJSON(
      map,
      boundFeature
    ) as BoundPolygonFeatureGeoJSON;
    accu = difference(accu, boundFeatureGeoJSON) as BufferFeatureGeoJSON;
    return accu;
  }, toGeoJSON(map, new Feature(filledBufferGeometry)) as BufferFeatureGeoJSON);

  const [bufferFeature] = bufferFeatureGeoJSON
    ? (fromGeoJSON(map, bufferFeatureGeoJSON) as BufferFeature[])
    : [];

  return bufferFeature?.getGeometry();
}

function createInverseBufferGeometry(
  map: Map,
  boundFeatures: BoundFeature[],
  distance: number
): BufferGeometry | undefined {
  const filledBufferGeometry = createFilledBufferGeometry(
    map,
    boundFeatures,
    -distance
  );

  if (!filledBufferGeometry) {
    return undefined;
  }

  const combinedBoundFeatureGeoJSON = boundFeatures
    .filter(
      (boundFeature) => boundFeature.getGeometry()?.getType() === 'Polygon'
    )
    .map(
      (boundFeature) =>
        toGeoJSON(map, boundFeature) as BoundPolygonFeatureGeoJSON
    )
    .reduce((accu, item) => {
      return union(accu, item) as BoundPolygonFeatureGeoJSON;
    });
  const finalBufferFeatureGeoJSON = difference(
    combinedBoundFeatureGeoJSON,
    toGeoJSON(map, new Feature(filledBufferGeometry)) as BufferFeatureGeoJSON
  );

  const [bufferFeature] = finalBufferFeatureGeoJSON
    ? (fromGeoJSON(map, finalBufferFeatureGeoJSON) as BufferFeature[])
    : [];

  return bufferFeature?.getGeometry();
}

function createBufferGeometry(
  map: Map,
  boundFeatures: BoundFeature[],
  distance: number,
  bufferType: BufferType
): BufferGeometry | undefined {
  switch (bufferType) {
    case BufferType.Filled:
      return createFilledBufferGeometry(map, boundFeatures, distance);
    case BufferType.Unfilled:
      return createUnfilledBufferGeometry(map, boundFeatures, distance);
    case BufferType.Inverse:
      return createInverseBufferGeometry(map, boundFeatures, distance);
  }
}
