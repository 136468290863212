import { useRoute } from "vue-router";

export default function useProjectId() {
  const route = useRoute();
  let projectId: string | null | undefined;
  if (route) {
    projectId = route.params.project_id as string | undefined;
    if (projectId) {
      return parseInt(projectId, 10);
    }
  }

  projectId = localStorage.getItem('project_id');
  if (projectId) {
    const id = parseInt(projectId, 10);
    if (isNaN(id)) {
      throw 'The project id is invalid!';
    }
    return id;
  }
  throw 'The project is not active';
}
