<template>
  <div class="d-flex">
    <div>{{ displayValue }}</div>
    <div v-if="field.options.unit" class="fw-bold ms-1">
      {{ field.options.unit }}
    </div>
  </div>
</template>

<script>
import { getResultDisplayText } from '../../business-logic/expression';

export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    displayValue() {
      return getResultDisplayText(this.field, this.value.value);
    },
  },
};
</script>
