<script lang="ts" setup>
import { computed, ref } from 'vue';
import Button from '../../components/Button.vue';
import InputTextarea from '../../components/InputTextarea.vue';
import {
  GatherAIFieldOptions,
  GatherAIFieldValue,
  GatherField,
  InputValue,
  Item,
  RepeatModeOrAll,
} from '../../gather';
import useApi from '../../api';
import { InputValueValue } from '../../gather';
import useProjectId from '../../composables/useProjectId';
import EventBus from '../../EventBus';
import { FEATURES, hasAccess } from '../../feature-manager';
import Upsell from '../../components/Upsell.vue';

const props = defineProps<{
  field: GatherField<GatherAIFieldOptions>;
  item?: Item;
  inputValue?: InputValue<GatherAIFieldValue>;
  inputValues?: InputValue[];
  isDisabled?: boolean;
}>();
const api = useApi();
const pendingValue = ref<GatherAIFieldValue>(null);
const isGenerating = ref(false);
const isDefaultInputValue = ref(true);
const projectId = useProjectId();
if (!projectId) {
  throw new Error('Project ID not found');
}
const abortController = ref<AbortController>();
const hasFeature = computed(() => {
  return hasAccess(FEATURES.AI_GATHER_PROMPTS);
});

function accept() {
  if (pendingValue.value) {
    setValue(pendingValue.value);
    pendingValue.value = null;
  }
}

function reject() {
  pendingValue.value = null;
}

function abort() {
  if (abortController.value) {
    abortController.value.abort();
    abortController.value = undefined;
  }
  pendingValue.value = null;
}

function edit() {
  isDefaultInputValue.value = false;
}

function setValue(value) {
  if (!props.inputValue) {
    throw new Error('Input value not found');
  }
  EventBus.$emit('updateInputValue', {
    inputValue: { ...props.inputValue, value },
    field: props.field.id,
    sectionIndex: props.inputValue.template_section_index ?? 0,
    templateTabId: props.inputValue.template_tab_id,
    isDefaultInputValue: isDefaultInputValue.value,
  });
}

let updateIndex = 0;
const setValueDebounced = (value: string) => {
  let index = updateIndex++;
  setTimeout(() => {
    if (index === updateIndex - 1) {
      setValue(value);
    }
  }, 500);
};

async function generate() {
  if (isGenerating.value) {
    return;
  }
  isGenerating.value = true;
  isDefaultInputValue.value = true;
  pendingValue.value = null;
  try {
    const inputs = props.field.options?.inputs ?? [];
    const inputValues: [number, RepeatModeOrAll, InputValueValue][] = [];
    for (const input of inputs) {
      if (input[0]) {
        // Get the same section index field or the first value of that field.
        const field =
          props.inputValues?.find(
            (inputValue) =>
              inputValue.template_field_id === input[0] &&
              inputValue.template_section_id ===
                props.inputValue?.template_section_id &&
              inputValue.template_section_index ===
                props.inputValue?.template_section_index
          ) ??
          props.inputValues?.find(
            (field) => field.template_field_id === input[0]
          );
        if (field) {
          inputValues.push([input[0], input[1], field.value]);
        }
      }
    }
    abortController.value = new AbortController();
    const response = await api.post(
      'gather/ai-prompts/' + props.field.id + '/generate',
      {
        project_id: projectId,
        inputs: inputValues,
      },
      {
        responseType: 'stream',
        signal: abortController.value.signal,
      }
    );
    pendingValue.value = '';
    let buffer = '';

    for await (const chunk of response.data) {
      // Append the new chunk to the buffer.
      buffer += chunk;

      // Split the buffer by newline to process complete lines.
      const lines = buffer.split('\n');

      // The last element might be an incomplete line; keep it in the buffer.
      buffer = lines.pop() || '';

      // Process each complete line.
      for (const line of lines) {
        // Skip empty lines.
        if (!line.trim()) continue;

        // Typically, each line from the stream starts with "data:".
        if (line.startsWith('data:')) {
          // Remove the "data:" prefix.
          const jsonStr = line.replace(/^data:\s*/, '');

          // Check if the stream signals that it's done.
          if (jsonStr === '[DONE]') {
            continue;
          }

          try {
            // Parse the JSON.
            const parsed = JSON.parse(jsonStr);
            // Extract the content; adjust the path as necessary depending on your API's response structure.
            const content = parsed.choices?.[0]?.delta?.content;
            if (content) {
              pendingValue.value += content;
            }
          } catch {}
        }
      }
    }
  } finally {
    isGenerating.value = false;
  }
}
</script>

<template>
  <div>
    <InputTextarea
      class="mb-2"
      :label="field.label"
      :disabled="!!hasFeature && (isDefaultInputValue || isGenerating)"
      :inputClass="pendingValue ? 'bg-warning' : ''"
      :modelValue="pendingValue || props.inputValue?.value"
      @update:modelValue="setValueDebounced"
    />
    <Upsell :featureKey="FEATURES.AI_GATHER_PROMPTS" isDisplayContents>
      <div class="d-flex gap-2 mb-3">
        <Button v-if="isGenerating" color="secondary" outline @click="abort">
          Cancel
          <i class="far fa-times"></i>
        </Button>
        <template v-else-if="pendingValue">
          <Button color="success" outline @click="accept">
            Accept
            <i class="far fa-check"></i>
          </Button>
          <Button @click="generate">
            Generate again
            <i class="far fa-magic"></i>
          </Button>
          <Button color="danger" outline @click="reject">
            Reject
            <i class="far fa-times"></i>
          </Button>
        </template>
        <template v-else>
          <Button @click="generate">
            Generate
            <i class="far fa-magic"></i>
          </Button>
          <Button
            v-if="isDefaultInputValue"
            color="secondary"
            outline
            @click="edit"
          >
            Edit
            <i class="far fa-pencil-edit"></i>
          </Button>
        </template>
      </div>
    </Upsell>
  </div>
</template>
