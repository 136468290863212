<template>
  <div>
    <quill-editor
      v-if="editing"
      ref="editor"
      v-model:value="getValue"
      :options="options"
      @click.stop
    />
    <template v-else>
      <div class="ql-bubble" @click="openEditor">
        <p v-if="!getValue && editable" class="placeholder">
          {{ placeholder }}
        </p>
        <div v-if="getValue" class="ql-editor">
          <div v-html="getValue" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getOptions } from '../../helpers/quill-handler.js';
import fixLinks from '../../helpers/fix-links';
import { goNumbering2 } from '../../helpers/heading-numbering';
import QuillEditor from '../../helpers/QuillEditor.vue';

export default {
  props: {
    block: Object,
    placeholder: {
      type: String,
      default: 'Continue your story...',
    },
  },
  data: () => ({
    editing: false,
    options: getOptions('Continue your story...'),
    inputValue: null,
  }),
  watch: {
    getValue() {
      this.$nextTick(() => {
        this.setPageHeadings();
      });
    },
  },
  components: {
    QuillEditor,
  },
  computed: {
    ...mapState({
      editable: (state) => state['reporter'].editable,
    }),
    ...mapGetters('reporter', ['getThemePropertyByKey', 'headingTree']),
    getValue: {
      get() {
        let value = this.inputValue ?? fixLinks(this.block.content);
        if (!this.editing && this.numberHeadings) {
          value = goNumbering2(this.headingTree, value, this.block.id);
        }

        return value;
      },
      set(value) {
        this.inputValue = value;

        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.$emit('update', {
            content: value,
          });

          this.setPageHeadings();
        }, 700);
      },
    },
    numberHeadings() {
      return this.getThemePropertyByKey('number_headings');
    },
  },
  methods: {
    ...mapActions('reporter', ['setPageHeadings']),
    openEditor() {
      if (!this.editable) {
        return;
      }

      this.editing = true;
    },
  },
  mounted() {
    this.options.placeholder = this.placeholder;
    this.inputValue = this.getValue;
  },
};
</script>
