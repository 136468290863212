export const ensureNumericDigit = (evt, allowDecimal = true) => {
  if (evt.ctrlKey || evt.metaKey || evt.altKey) {
    return;
  }

  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  if (allowDecimal) {
    allowedKeys.push('.');
  }

  const specialKeys = [
    'Backspace',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    'Home',
    'End',
  ];

  if (specialKeys.includes(evt.key)) {
    return;
  }

  if (evt.key === '-') {
    const input = evt.target;
    if (input.selectionStart !== 0 || input.value.includes('-')) {
      evt.preventDefault();
    }
    return;
  }

  if (!allowedKeys.includes(evt.key)) {
    evt.preventDefault();
  }
};
