<template>
  <div
    class="word-cloud w-100 h-100 px-2 py-2 d-flex flex-column align-items-stretch flex-grow-1"
  >
    <div
      class="title"
      :style="{
        color: options.titleColor,
        'font-size': `${options.titleSize}px`,
        'font-weight': 'bold',
      }"
    >
      {{ data.title }}
    </div>
    <div class="flex-grow-1 d-flex flex-column">
      <vue-word-cloud
        class="h-100 w-100 flex-grow-1"
        :style="{ overflow: 'visible' }"
        :words="data.words"
        :color="getColor"
        font-family="Roboto"
      />
    </div>
  </div>
</template>

<script>
import VueWordCloud from 'vuewordcloud';
import _isEqual from 'lodash/isEqual';

export default {
  name: 'WordCloudChart',
  components: {
    VueWordCloud,
  },
  props: {
    data: Object,
    options: Object,
  },
  data: () => ({
    heavyWeightThreshold: 10,
    mediumWeightThreshold: 5,
    weights: [],
  }),
  methods: {
    getColor(arg) {
      const { heavyWeightColor, mediumWeightColor, lightWeightColor } =
        this.options;
      const [, weight] = arg;
      if (!this.weights.includes(weight)) {
        this.weights = [...this.weights, weight].sort();
      }

      return weight >= this.heavyWeightThreshold
        ? heavyWeightColor
        : weight >= this.mediumWeightThreshold
        ? mediumWeightColor
        : lightWeightColor;
    },
  },
  watch: {
    weights(newValue, oldValue) {
      if (!_isEqual(newValue, oldValue)) {
        const index1 = Math.floor(newValue.length * 0.33);
        const index2 = Math.floor(newValue.length * 0.66);
        this.mediumWeightThreshold = newValue[index1] + 1;
        this.heavyWeightThreshold = newValue[index2] + 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.word-cloud {
  .title {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 16px;
  }
}
</style>
