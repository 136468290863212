import axios, { AxiosRequestConfig, AxiosResponse, type Axios } from 'axios';
import { client, initializeAuth } from './auth';
import useIsGather from './composables/useIsGather';

/** For public access to Hub's API */
export const clientPublic: Axios = axios.create({
  baseURL: useIsGather() ? '/api/hub' : '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

/** For external requests, e.g. service layers */
export const externalClient = axios.create();
if (externalClient?.defaults?.headers?.common) {
  delete externalClient.defaults.headers.common['Authorization'];
}

type Interceptors = {
  doBeforeRequestIsSent:
  | ((
    value: AxiosRequestConfig<any>
  ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>)
  | undefined;
  handleRequestError: ((error: any) => any) | undefined;
  doAfterResponseIsReady:
  | ((
    value: AxiosResponse<any, any>
  ) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>)
  | undefined;
  handleResponseError: ((error: any) => any) | undefined;
};

export function setInterceptors(interceptors: Interceptors) {
  const {
    doBeforeRequestIsSent,
    handleRequestError,
    doAfterResponseIsReady,
    handleResponseError,
  } = interceptors;

  const c = client || initializeAuth().client;
  c.interceptors.request.use(doBeforeRequestIsSent, handleRequestError);
  c.interceptors.response.use(doAfterResponseIsReady, handleResponseError);

  axios.interceptors.request.use(doBeforeRequestIsSent, handleRequestError);
  axios.interceptors.response.use(doAfterResponseIsReady, handleResponseError);
}

export default function useApi(): Axios {
  return client;
}

export function usePublicApi(): Axios {
  return clientPublic;
}

