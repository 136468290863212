<template>
  <div v-click-outside="() => (opened = false)" class="position-relative">
    <div class="clickable" @click="togglePopup">
      <i class="fas fa-ellipsis-h" />
    </div>

    <transition name="dropdown">
      <div v-show="opened" class="dropdown-menu animated__dropdown">
        <div class="dropdown-item" @click="duplicateBlock(block.id)">
          <i class="fal fa-fw fa-copy" />
          <small>Duplicate</small>
        </div>

        <div
          class="dropdown-item text-danger"
          @click="
            () =>
              setConfirmation({
                header: 'Are you sure you want to delete this chapter?',
                submit: () => deleteBlock(block.id),
                isDelete: true,
              })
          "
        >
          <i class="fal fa-fw fa-trash-alt" />
          <small>Delete</small>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions } from 'vuex';



export default {
  props: {
    block: Object,
  },
  data: () => ({
    opened: false,
  }),
  
  methods: {
    ...mapActions('reporter', [
      'deleteBlock',
      'duplicateBlock',
      'setConfirmation',
    ]),
    togglePopup() {
      this.opened = !this.opened;
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-menu {
  min-width: auto;
  top: -100px;
  left: 0px;
  min-width: 125px;
}
</style>
