<template>
  <div :title="getDepth">
    {{ getDepth }}
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    unit() {
      return this.field.options.unit || 'm';
    },
    getDepth() {
      const minValue =
        this.value.value !== null && !isNaN(this.value.value)
          ? parseFloat(this.value.value)
          : null;

      const maxValue =
        this.value.value2 !== null && !isNaN(this.value.value2)
          ? parseFloat(this.value.value2)
          : null;

      if (this.field.options.is_range && maxValue) {
        return `${minValue} - ${maxValue} ${this.unit}`;
      }

      return `${minValue} ${this.unit}`;
    },
  },
};
</script>
