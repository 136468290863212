<script lang="ts" setup>
import UpgradeIndicator from './UpgradeIndicator.vue';
import {
  FeatureKey,
  hasAccess,
  prettifyScopedFeatureSlug,
} from '../feature-manager';
import { computed, onUnmounted, watch } from 'vue';
import { useSubscriptionStore } from '../store/subscription';

const props = defineProps<{
  featureKey: FeatureKey;
  featureName?: string;
  disableCheck?: boolean;
  opacity?: number;
  hideUpgradeIndicator?: boolean;
  isDisplayContents?: boolean;
}>();

const subscriptionStore = useSubscriptionStore();
const hasFeatureAccess = computed(() => {
  return props.disableCheck === true || hasAccess(props.featureKey);
});

watch(
  () => hasFeatureAccess.value,
  () => {
    if (!hasFeatureAccess.value) {
      subscriptionStore.addToFeatureInPreview(props.featureKey);
    } else {
      subscriptionStore.removeFromFeatureInPreview(props.featureKey);
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  subscriptionStore.removeFromFeatureInPreview(props.featureKey);
});
</script>
<template>
  <UpgradeIndicator
    v-if="!hasFeatureAccess && !hideUpgradeIndicator"
    :featureName="featureName ?? prettifyScopedFeatureSlug(featureKey)"
  />
  <div
    :class="{
      disabled: !hasFeatureAccess,
      'd-flex flex-column flex-fill': !isDisplayContents,
    }"
    :style="{
      opacity: hasFeatureAccess ? 1 : opacity ?? 0.5,
      pointerEvents: hasFeatureAccess ? 'auto' : 'none',
      display: isDisplayContents ? 'contents' : undefined,
    }"
  >
    <slot />
  </div>
</template>
