<script lang="ts" setup>
import { ref } from 'vue';

const DEFAULT_BACKGROUND_COLOR = '#212121';
const ERROR_BACKGROUND_COLOR = 'rgb(220, 53, 69)';
const props = withDefaults(
  defineProps<{
    color?: string;
    backgroundColor?: string;
    title?: string;
    info: string;
    danger?: boolean;
    container?: string;
    trigger?: string;
    customStyle?: object;
  }>(),
  {
    title: '',
    color: '#ffffff',
    container: 'body',
    trigger: 'focus',
  }
);

const actualBackgroundColor = ref(
  props.backgroundColor || DEFAULT_BACKGROUND_COLOR
);
</script>

<template>
  <a
    v-tooltip="{ content: info }"
    class="text-center info-button"
    :style="{ 'background-color': actualBackgroundColor, ...props.customStyle }"
    style="
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid v-bind('actualBackgroundColor');
      border-radius: 50%;
      aspect-ratio: 1;
      color: v-bind('props.color');
      cursor: pointer;
    "
    tabindex="0"
  >
    <div
      class="d-flex align-items-center justify-content-center w-100 h-100"
      style="font-size: 0.5em"
    >
      <i class="fas fa-info text-white me-0"></i>
    </div>
  </a>
</template>
