import { AxiosError } from 'axios';
import makeId from '../local-id.mjs';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ErrorBag } from '../business-model/form';

export type ToastType =
  | 'success'
  | 'error'
  | 'danger'
  | 'warning'
  | 'info'
  | 'primary'
  | 'secondary';

export interface Toast {
  id: string;
  type: ToastType;
  message: string;
}

export const useToastStore = defineStore('toast', () => {
  const toasts = ref<Toast[]>([]);

  function addToast(message: string, type: ToastType = 'success') {
    const id = makeId();

    if (
      toasts.value.length > 0 &&
      toasts.value[toasts.value.length - 1].message === message &&
      toasts.value[toasts.value.length - 1].type === type
    ) {
      dismissToast(toasts.value[toasts.value.length - 1].id);
    }

    toasts.value.push({ id, type, message });

    setTimeout(() => {
      dismissToast(id);
    }, 5000);
  }

  function dismissToast(id: string) {
    toasts.value = toasts.value.filter((toast: Toast) => toast.id !== id);
  }

  function dismissAll() {
    toasts.value = [];
  }

  function getBackgroundColor(toast: Toast) {
    return toastColors[toast.type] ? toastColors[toast.type] : 'bg-primary';
  }

  const toastColors = {
    success: 'bg-success',
    error: 'bg-danger',
    danger: 'bg-danger',
    warning: 'bg-warning',
    info: 'bg-info',
    primary: 'bg-primary',
    secondary: 'bg-secondary',
  };

  return {
    toasts,
    addToast,
    dismissToast,
    dismissAll,
    getBackgroundColor,

    danger: (message: string) => addToast(message, 'danger'),
    error: (message: string) => addToast(message, 'error'),
    info: (message: string) => addToast(message, 'info'),
    primary: (message: string) => addToast(message, 'primary'),
    secondary: (message: string) => addToast(message, 'secondary'),
    success: (message: string) => addToast(message, 'success'),
    warning: (message: string) => addToast(message, 'warning'),

    unexpected: (e?: AxiosError | Error | any, defaultMessage?: string) => {
      console.error(e || defaultMessage);
      reportError(e || defaultMessage);
      defaultMessage ||=
        'An unexpected error occurred, please try again later.';
      if (!e) {
        addToast(defaultMessage, 'danger');
        return;
      }

      if ('response' in e) {
        if (e.response?.status === 422 && e.response.data?.errors) {
          const errorBag = e.response.data.errors as ErrorBag;
          const firstError = Object.entries(errorBag)[0][1][0];
          addToast(firstError, 'danger');
          return;
        }

        if (e.response?.status === 403 &&
          e.response.data?.message === 'MFA verification required.' &&
          !window.location.pathname.includes('/onboarding') &&
          !window.location.hostname.includes('gather')
        ) {
          window.location.href = '/onboarding';
        }

        if (
          e.response?.status &&
          e.response.status > 399 &&
          e.response.status < 500 &&
          e.response.data?.message
        ) {
          addToast(e.response.data.message || defaultMessage, 'danger');
          return;
        }
      }
      addToast(defaultMessage, 'danger');
    },
  };
});

export type ToastStore = ReturnType<typeof useToastStore>;
