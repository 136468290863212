<template>
  <div class="quill-editor">
    <div ref="editor"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import Quill from 'quill';

const props = withDefaults(
  defineProps<{
    modelValue: string | null;
    editorToolbar: any[];
    isDisabled?: boolean;
    useCustomImageHandler?: boolean;
  }>(),
  {
    modelValue: '',
    editorToolbar: () => [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
    isDisabled: false,
  }
);

const emit = defineEmits(['update:modelValue', 'imageAdded']);

const editor = ref<HTMLDivElement | null>(null);
let quillInstance: Quill | null = null;
let quillExposedInstance = ref<Quill | null>(null);

function customImageHandler() {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.click();

  input.onchange = () => {
    if (!quillInstance || !input.files || !input.files[0]) return;

    const file = input.files[0];
    const Editor = quillInstance;
    const range = Editor.getSelection(true);
    const cursorLocation = range ? range.index : 0;

    const resetUploader = () => {
      input.value = '';
    };

    emit('imageAdded', {
      file,
      Editor,
      cursorLocation,
      resetUploader,
    });
  };
}

onMounted(() => {
  quillInstance = new Quill(editor.value!, {
    modules: {
      toolbar: {
        container: props.editorToolbar,
        handlers: {
          image: customImageHandler,
        },
      },
    },
    theme: 'snow',
    readOnly: props.isDisabled,
  });

  quillExposedInstance.value = quillInstance;

  quillInstance.on('text-change', () => {
    const html = (editor.value as any).querySelector('.ql-editor').innerHTML;
    emit('update:modelValue', html);
  });

  quillInstance.root.innerHTML = props.modelValue || '';
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (quillInstance && quillInstance.root.innerHTML !== newValue) {
      quillInstance.root.innerHTML = newValue || '';
    }
  }
);

watch(
  () => props.isDisabled,
  (newVal) => {
    if (quillInstance) {
      quillInstance.enable(!newVal);
    }
  }
);

defineExpose({
  quillExposedInstance,
});
</script>
