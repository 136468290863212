<template>
  <div :title="value.value" v-text="getValue" />
</template>
<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    getValue() {
      let value = this.value.value || '';
      if (value.length > 100) {
        value = value.substr(0, 100) + '...';
      }
      return value;
    },
  },
};
</script>
