<script setup lang="ts">
import _debounce from 'lodash/debounce';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import {
  checkIsInputValueEmpty,
  checkIsInputValueValueEmpty,
} from '../../business-logic/input-value';
import EventBus from '../../EventBus';
import type { GatherField, InputValue } from '../../gather';
import { fieldHasTolerance, inputValuePasses } from '../../utils/tolerance';
import { waitFor } from '../../utils/wait-for';
import Hazard from '../classes/Hazard.js';
import { ensureNumericDigit } from '../../utils/validation';
import { isIOS } from '../../utils';

const props = withDefaults(
  defineProps<{
    field: GatherField;
    inputValue: InputValue;
    sectionIndex: number;
    isSafety?: boolean;
    lastValue?: object;
    inputValues: InputValue[];
  }>(),
  {
    isSafety: false,
  }
);

const formContext = inject('formContext') as any;
const isDefaultInputValue = ref(false);
const isWaiting = ref(false);
const numberInput = ref<HTMLInputElement | null>(null);

const disabled = computed(() => {
  return (
    props.field.options?.is_readonly || props.inputValue?.options?.disabled
  );
});

const value = computed({
  get() {
    if (checkIsInputValueEmpty(props.inputValue, props.field)) {
      return null;
    }

    return props.inputValue.value;
  },
  set(value: string) {
    setValue(value);
  },
});

const hasTolerance = computed(() => {
  return fieldHasTolerance(props.field);
});

const passesTolerance = computed(() => {
  if (!hasTolerance.value) {
    return false;
  }
  return inputValuePasses(props.field, props.inputValue, props.inputValues);
});

function getDefaultValue() {
  const { default_value: startingValue, increment } = {
    default_value: null,
    increment: null,
    ...props.field.options,
  };
  if (startingValue == null && increment == null) {
    return null;
  }

  const { value: _lastValue } = { value: null, ...props.lastValue };
  if (_lastValue != null && increment != null) {
    return (
      Math.round((parseFloat(_lastValue) + parseFloat(increment)) * 1000000) /
      1000000
    );
  }

  if (startingValue != null) {
    return Math.round(parseFloat(startingValue) * 1000000) / 1000000;
  }

  return null;
}

function setValue(value) {
  const _isDefaultInputValue = isDefaultInputValue.value;
  if (_isDefaultInputValue) {
    isDefaultInputValue.value = false;
  }
  EventBus.$emit('updateInputValue', {
    inputValue: { ...props.inputValue, value },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
    isDefaultInputValue: _isDefaultInputValue,
  });
}

function getStyle(value) {
  if (value === null || !props.inputValue?.options?.is_health_safety) {
    return null;
  }
  const hazard = new Hazard({});
  return hazard.calculateStyling(value);
}

async function handleBlur() {
  if (isWaiting.value) {
    await waitFor(() => !isWaiting.value);
    await nextTick();
  }
  if (checkIsInputValueEmpty(props.inputValue, props.field)) {
    isDefaultInputValue.value = true;
    const defaultValue = getDefaultValue();
    setValue(defaultValue);
  }
}

watch(isWaiting, (newValue) => {
  formContext.setIsBusy(newValue);
});

onMounted(() => {
  if (props.field.options?.increment) {
    numberInput.value!.step = props.field.options.increment;
  }

  if (checkIsInputValueEmpty(props.inputValue, props.field)) {
    isDefaultInputValue.value = true;
    const defaultValue = getDefaultValue();
    setValue(defaultValue);
  }
});
</script>

<template>
  <div class="form-group" :class="getStyle(value)">
    <label class="form-label" for="number">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
    </label>
    <div class="input-group">
      <span v-if="field.options?.prefix" class="input-group-text">
        {{ field.options.prefix }}
      </span>
      <input
        ref="numberInput"
        v-model="value"
        :inputmode="
          isIOS
            ? 'text'
            : field.options?.enforce_integer
            ? 'numeric'
            : 'decimal'
        "
        type="text"
        step="any"
        :disabled="disabled"
        :class="[
          'form-control',
          {
            'border-danger':
              field.is_required && checkIsInputValueValueEmpty(value),
          },
        ]"
        name="number"
        @blur="handleBlur"
        @keydown="ensureNumericDigit($event, !field.options?.enforce_integer)"
        @wheel.prevent
      />
      <span v-if="field.options?.unit" class="input-group-text">
        {{ field.options.unit }}
      </span>
      <span
        v-if="hasTolerance"
        :class="[
          'input-group-text',
          {
            'bg-success': passesTolerance,
            'bg-warning text-light': !passesTolerance,
          },
        ]"
      >
        <i
          :class="[
            'fas fa-fw',
            {
              'fa-check-circle': passesTolerance,
              'fa-times-circle': !passesTolerance,
            },
          ]"
        ></i>
      </span>
    </div>
  </div>
</template>
