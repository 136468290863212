import {
  Circle,
  LineString,
  MultiLineString,
  MultiPolygon,
  Point,
  Polygon,
} from 'ol/geom';
import {
  VectorImage as VectorImageLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Color } from '../../style/color';
import type { StylingPriority } from '../../style/types';
import type { LayerMixin, LayerModel, Properties } from '../types';
import { Feature } from 'ol';

export type Shape =
  | Point
  | Circle
  | Polygon
  | MultiPolygon
  | LineString
  | MultiLineString;

export type ShapeProperties = Properties & {
  color?: Color | string;
  weight?: number; // Stroke thickness
  opacity?: number; // Stroke opacity
  fillStyle?: number;
  fillOpacity?: number;
  outlineStyle?: number;
  areaMeasurement?: boolean;
  outlineMeasurement?: boolean;
  multiDirectional?: boolean;
  icon?: number;
  iconSize?: number;
  shouldShowLabel?: boolean;
  isConnectorShownAsArrow?: boolean;
  isConnectorHidden?: boolean;
  interval?: number;
  shouldReverseHashMarks?: boolean;
  arrowHeads?: any[];
  // curveSpecsData has been renamed to curveSpecs.
  curveSpecsData?: any[];
  curveSpecs?: any[];
  stylingPriority?: StylingPriority;
};

export type ShapeStyle = ShapeProperties;

export type SingleShapeLayerModel = LayerModel<
  ShapeProperties,
  GeoJSON.Feature<GeoJSON.Geometry, ShapeProperties>
>;

export type MultipleShapeLayerModel = LayerModel<
  ShapeProperties,
  GeoJSON.FeatureCollection<GeoJSON.Geometry, ShapeProperties | null> & {
    properties: ShapeProperties;
  }
>;

export type ShapeLayer = (
  | VectorLayer<VectorSource<Feature<Shape>>>
  | VectorImageLayer<VectorSource<Feature<Shape>>>
) &
  LayerMixin;

export enum BufferType {
  Filled = 1,
  Unfilled = 2,
  Inverse = 3,
}

export type BufferProperties = ShapeProperties & {
  boundLayerIds: number[];
  folderId?: number;
  distance: number;
  bufferType: BufferType;
};

export type BufferLayerModel = LayerModel<
  BufferProperties,
  GeoJSON.Feature<GeoJSON.Polygon, BufferProperties>
>;
