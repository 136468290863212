<script setup lang="ts">
import type { FillPatternSize } from '../business-model/common';
import {
  FILL_PATTERN_DATA,
  FILL_PATTERN_DOT,
  FILL_PATTERN_HORIZONTAL_STRIPE,
  FILL_PATTERN_SOLID,
  FILL_PATTERN_TRANSPARENT,
  FILL_PATTERN_VERTICAL_STRIPE,
  FILL_PATTERN_CIRCLE,
  FILL_PATTERN_CROSS_45_DEG,
  FILL_PATTERN_STRIPE_45_DEG,
} from '../business-model/common';

withDefaults(
  defineProps<{
    value?: number;
    title?: string;
    size?: FillPatternSize;
    color?: string;
  }>(),
  {
    value: FILL_PATTERN_SOLID,
    ...FILL_PATTERN_DATA[FILL_PATTERN_SOLID],
    size: () => FILL_PATTERN_DATA[FILL_PATTERN_SOLID].size,
  }
);

const emit = defineEmits(['click']);
</script>

<template>
  <div
    :class="[
      'd-flex justify-content-center align-items-center fill-pattern',
      {
        solid: value === FILL_PATTERN_SOLID,
        transparent: value === FILL_PATTERN_TRANSPARENT,
        'h-stripe': value === FILL_PATTERN_HORIZONTAL_STRIPE,
        'v-stripe': value === FILL_PATTERN_VERTICAL_STRIPE,
        dot: value === FILL_PATTERN_DOT,
        circle: value === FILL_PATTERN_CIRCLE,
        'cross-45-deg': value === FILL_PATTERN_CROSS_45_DEG,
        'stripe-45-deg': value === FILL_PATTERN_STRIPE_45_DEG,
      },
    ]"
    :style="{
      width: `${size[0]}px`,
      height: `${size[1]}px`,
    }"
    :title="title"
    @click="emit('click', value)"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.fill-pattern {
  cursor: pointer;
  border-radius: 6px;

  &.solid {
    background-color: v-bind(color);
  }

  &.transparent {
    background-color: transparent;
    border: 2px solid v-bind(color);
  }

  &.h-stripe {
    background-color: v-bind(color);
    background-image: url('../assets/images/fill-patterns/horizontal-stripe.svg');
  }

  &.v-stripe {
    background-color: v-bind(color);
    background-image: url('../assets/images/fill-patterns/vertical-stripe.svg');
  }

  &.dot {
    background-color: v-bind(color);
    background-image: url('../assets/images/fill-patterns/dot.svg');
  }

  &.circle {
    background-image: url('../assets/images/fill-patterns/circle.png');
    background-size: 8px;
  }

  &.cross-45-deg {
    background-image: url('../assets/images/fill-patterns/cross-45-deg.png');
    background-size: 8px;
  }

  &.stripe-45-deg {
    background-image: url('../assets/images/fill-patterns/stripe-45-deg.png');
    background-size: 8px;
  }
}
</style>
