<template>
  <div :id="id" class="flex-column">
    <div v-if="editingByValue && !isVisible" class="alert alert-warning mb-0">
      Condition was not met, not editable.
    </div>
    <div
      :id="fieldAnchorId"
      style="display: block; visibility: hidden; height: 0; width: 0"
      :style="{ 'scroll-margin-top': normalizedCssFieldScrollMarginTop }"
    ></div>
    <component
      :is="fieldComponents[tagName]"
      v-if="fieldComponents[tagName]"
      v-show="isVisible"
      :app="app"
      :section="section"
      :field="field"
      :item="sample"
      :inputValue="inputValue"
      :inputValues="inputValues"
      :lastValue="lastValue"
      :sectionIndex="sectionIndex"
      :defaultValue="defaultValue || dValue"
      :groupKey="groupKey"
      :fields="fields"
      :allFields="allFields"
      :allSections="allSections"
      :repeatable="!!repeatable"
      :isAutoAssignEnabled="isAutoAssignEnabled"
      :isAutoAssignActive="isAutoAssignActive"
      :isDisabled="isDisabled"
      :sampleIdentifier="sampleIdentifier"
      :isVisible="isVisible"
      @isLoading="isLoading"
      @input="emit('input')"
      @clickCamera="clickCamera"
      @clickVideo="clickVideo"
      @clickSetPreview="clickSetPreview"
      @clickStartDrawing="clickStartDrawing"
    />

    <div
      v-if="isVisible && isAutoAssignEnabled && !field.options?.is_readonly"
      class="d-flex mt-2"
    >
      <label :for="checkboxId" class="form-label me-2 mb-0"> Edit </label>
      <div class="form-switch">
        <input
          :id="checkboxId"
          v-model="isAutoAssignActive"
          type="checkbox"
          class="form-check-input me-2"
        />
        <label :for="checkboxId">Read-only</label>
        <InfoButton
          class="ms-2"
          :backgroundColor="formContext?.theme.primaryColor"
          info="Switch this option off to edit the value. Leave it on to use the automatic value."
          :container="`#${id}`"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import InfoButton from '../components/InfoButton.vue';
import EventBus from '../EventBus';
import {
  FieldTypeIds,
  checkIsConditionMet,
  getDefaultValue,
  getFieldAnchorId,
  getGroupKey,
} from '../fields';
import makeId from '../local-id.mjs';
import {
  components as fieldComponents,
  getComponentNameFromId,
} from './fields';
import { FormContext } from '../utils';
import {
  App,
  GatherField,
  InputValue,
  InputValuePlaceholder,
  Section,
} from '../gather';

const props = defineProps<{
  id: string;
  app: App;
  section: Section;
  field: GatherField;
  inputValue: InputValue | InputValuePlaceholder;
  inputValues: InputValue[];
  editingByValue;
  lastValue;
  sectionIndex;
  sample;
  fields;
  allFields;
  allSections;
  templateTabId;
  repeatable;
  search;
  sectionCount;
  sampleIdentifier;
  cssFieldScrollMarginTop;
}>();

const emit = defineEmits<{
  (event: 'clickCamera', data: any): void;
  (event: 'clickVideo', data: any): void;
  (event: 'clickSetPreview', data: any): void;
  (event: 'clickStartDrawing', data: any): void;
  (event: 'isLoading', data: boolean): void;
  (event: 'input'): void;
}>();

const formContext = inject<FormContext>('formContext');
const dValue = ref(null),
  checkboxId = ref(makeId());

const tagName = computed(() => {
  return getComponentNameFromId(props.field.field_type_id);
});
const conditionIsMet = computed(() => {
  return checkIsConditionMet(
    props.field,
    props.inputValues,
    props.sectionIndex
  );
});

const matchesSearch = computed(() => {
  if (!props.search) {
    return true;
  }

  const search = props.search.toLowerCase();
  const value = props.inputValue.value
    ? String(props.inputValue.value).toLowerCase()
    : '';
  const value2 = props.inputValue.value2
    ? String(props.inputValue.value2).toLowerCase()
    : '';

  return (
    value.includes(search) ||
    value2.includes(search) ||
    props.field.label?.toLowerCase().includes(search)
  );
});

const isVisible = computed(() => {
  return conditionIsMet.value && matchesSearch.value;
});

const defaultValue = computed(() => {
  return getDefaultValue(
    props.field,
    props.inputValues,
    props.sectionIndex,
    (newValue) => {
      dValue.value = newValue;
    },
    (id) => props.allFields.find((f) => f.id === id)
  );
});

const groupKey = computed(() => {
  return getGroupKey(props.field, props.inputValues, props.sectionIndex);
});

const isAutoAssignEnabled = computed(() => {
  return (props.field.options?.defaults?.length || 0) > 0;
});

const isAutoAssignActive = computed({
  get() {
    return props.inputValue?.options?.isAutoAssignActive ?? true;
  },
  set(value) {
    EventBus.$emit('updateInputValue', {
      inputValue: {
        ...props.inputValue,
        options: { ...props.inputValue.options, isAutoAssignActive: value },
      },
      field: props.inputValue.template_field_id,
      sectionIndex: props.inputValue.template_section_index,
      templateTabId:
        'template_tab_id' in props.inputValue
          ? props.inputValue.template_tab_id
          : props.app.id,
    });
  },
});

const isDisabled = computed(() => {
  return (
    props.field.options?.is_readonly ||
    props.inputValue?.options?.disabled ||
    (isAutoAssignEnabled.value && isAutoAssignActive.value)
  );
});

const fieldAnchorId = computed(() => {
  return getFieldAnchorId(props.field.label);
});

const normalizedCssFieldScrollMarginTop = computed(() => {
  return props.cssFieldScrollMarginTop ?? '0px';
});

function clickCamera(data) {
  emit('clickCamera', data);
}
function clickVideo(data) {
  emit('clickVideo', data);
}

function clickSetPreview(data) {
  emit('clickSetPreview', data);
}

function clickStartDrawing(data) {
  emit('clickStartDrawing', data);
}

function isLoading(value: boolean) {
  emit('isLoading', value);
}

function checkShouldBeVisible() {
  if (
    isVisible.value ||
    ![
      FieldTypeIds.DROPDOWN,
      FieldTypeIds.DUPLICATE,
      FieldTypeIds.TRIPLICATE,
    ].includes(props.field.field_type_id as any)
  ) {
    return;
  }

  if (
    props.field.options?.should_clear_selection_on_invisible ||
    [FieldTypeIds.DUPLICATE, FieldTypeIds.TRIPLICATE].includes(
      props.field.field_type_id as any
    )
  ) {
    EventBus.$emit('updateInputValue', {
      inputValue: { ...props.inputValue, value: null, value2: null },
      field: props.field.id,
      sectionIndex: props.sectionIndex,
      templateTabId: props.templateTabId,
    });
  }
}

watch(
  () => props.inputValue.value,
  () => {
    emit('input');
  }
);

watch(
  () => props.inputValue.options,
  () => {
    emit('input');
  }
);

watch(
  () => props.inputValue.value2,
  () => {
    emit('input');
  }
);

watch(isVisible, checkShouldBeVisible);
onBeforeMount(checkShouldBeVisible);
</script>
