import { captureException } from '@component-library/sentry';
import { useCollectionStore } from '@component-library/store/collection';
import _debounce from 'lodash/debounce';
import { getUid } from 'ol';
import BaseObject from 'ol/Object';

export default class LoadManager extends BaseObject {
  counter: number = 0;
  serviceLayerLoadErrorHandlers: Record<string, () => void> = {};
  loadStartCallback: () => void;
  loadEndCallback: () => void;
  timeout: NodeJS.Timeout | null = null;

  constructor() {
    super();
    const collectionStore = useCollectionStore();
    this.loadStartCallback = () => (collectionStore.startBusy());
    this.loadEndCallback = () => (collectionStore.stopBusy());
  }

  static instance: LoadManager | undefined;
  static getInstance(): LoadManager {
    if (!LoadManager.instance) {
      LoadManager.instance = new LoadManager();
    }

    return LoadManager.instance;
  }

  start() {
    if (this.counter === 0) {
      this.loadStartCallback();
    }

    this.counter++;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      if (this.counter > 0) {
        this.counter = 0;
        this.loadEndCallback();
        const err = new Error('LoadManager: Timed out!');
        captureException(err);
        throw err;
      }
    }, 30000);
  }
  end() {
    this.counter--;
    if (this.counter <= 0) {
      this.counter = 0;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.loadEndCallback();
    }
  }
  addLoadListenersToServiceLayer(layer) {
    layer.on('loadstart', () => {
      this.start();
    });

    layer.on('loadend', () => {
      this.end();
    });

    layer.on('loaderror', () => {
      this.end();
      let handler = this.serviceLayerLoadErrorHandlers[getUid(layer)];
      if (!handler) {
        handler = _debounce(() => {
          console.error(
            `An error occurred during loading the service layer at z index: ${layer.getZIndex()}.`
          );
        }, 5000);
        this.serviceLayerLoadErrorHandlers[getUid(layer)] = handler;
      }
      handler();
    });
  }
  static destroy() {
    if (LoadManager.instance) {
      LoadManager.instance.counter = 0;
      if (LoadManager.instance.timeout) {
        clearTimeout(LoadManager.instance.timeout);
      }
      LoadManager.instance = undefined;
    }
  }
}

export function withLoadManager<T, A extends any[]>(fn: (...args: A) => Promise<T>) {
  return async (...args: A) => {
    const loadManager = LoadManager.getInstance();
    loadManager.start();

    try {
      return await fn(...args);
    } finally {
      loadManager.end();
    }
  };
}
