<template>
  <div v-click-outside="onClickOutside">
    <div
      class="dropdown__toggle"
      :class="{
        active: opened,
      }"
      @click="togglePopup"
    >
      <slot />
    </div>

    <transition name="dropdown">
      <div
        v-show="opened"
        class="dropdown-menu animated__dropdown"
        :class="position"
      >
        <div
          v-if="isTypeSupported('text')"
          class="dropdown-item"
          @click="createBlock('text')"
        >
          <i class="fal fa-fw fa-text" />
          <small class="text-muted">Text</small>
        </div>

        <div
          v-if="isTypeSupported('image')"
          class="dropdown-item"
          @click="createBlock('image')"
        >
          <i class="fal fa-fw fa-image" />
          <small class="text-muted">Image</small>
        </div>

        <div
          v-if="isTypeSupported('audio')"
          class="dropdown-item"
          @click="createBlock('audio')"
        >
          <i class="fal fa-fw fa-volume-up" />
          <small class="text-muted">Audio</small>
        </div>

        <div
          v-if="isTypeSupported('video')"
          class="dropdown-item"
          @click="createBlock('video')"
        >
          <i class="fal fa-fw fa-video" />
          <small class="text-muted">Video</small>
        </div>

        <div
          v-if="isTypeSupported('attachment')"
          class="dropdown-item"
          @click="createBlock('attachment')"
        >
          <i class="fal fa-fw fa-file" />
          <small class="text-muted">Attachments</small>
        </div>

        <div
          v-if="isTypeSupported('iframe')"
          class="dropdown-item"
          @click="createBlock('iframe')"
        >
          <i class="fal fa-fw fa-window" />
          <small class="text-muted">iFrame</small>
        </div>

        <div
          v-if="isTypeSupported('table')"
          class="dropdown-item"
          @click="createBlock('table')"
        >
          <i class="fal fa-fw fa-table" />
          <small class="text-muted"><b>Datanest / Custom</b> Table</small>
        </div>

        <div
          v-if="isTypeSupported('insight')"
          class="dropdown-item"
          @click="createBlock('insight')"
        >
          <i class="fal fa-fw fa-analytics" />
          <small class="text-muted"><b>Datanest</b> Insights</small>
        </div>

        <div
          v-if="isTypeSupported('map')"
          class="dropdown-item"
          @click="createBlock('map')"
        >
          <i class="fal fa-fw fa-map" />
          <small class="text-muted"> <b>Datanest</b> Maps </small>
        </div>

        <div
          v-if="isTypeSupported('seperator')"
          class="dropdown-item"
          @click="createBlock('seperator')"
        >
          <i class="fal fa-fw fa-horizontal-rule" />
          <small class="text-muted">Separator</small>
        </div>

        <div
          v-if="isTypeSupported('button')"
          class="dropdown-item"
          @click="createBlock('button')"
        >
          <i class="fal fa-fw fa-mouse-alt" />
          <small class="text-muted">Button</small>
        </div>

        <div
          v-if="isTypeSupported('slideshow')"
          class="dropdown-item"
          @click="createBlock('slideshow')"
        >
          <i class="fal fa-fw fa-presentation" />
          <small class="text-muted">Chapter</small>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    position: String,
    block: Object,
  },
  data: () => ({
    opened: false,
  }),

  computed: {
    supportedTypes() {
      if (this.block.group_block_id) {
        return ['text', 'audio', 'seperator', 'button'];
      }

      if (this.block.slide_id) {
        return [
          'text',
          'image',
          'seperator',
          'button',
          'audio',
          'video',
          'table',
          'attachment',
          'iframe',
        ];
      }

      return [
        'text',
        'image',
        'audio',
        'video',
        'attachment',
        'iframe',
        'table',
        'seperator',
        'button',
        'slideshow',
        'insight',
        'map',
      ];
    },
  },
  methods: {
    onClickOutside() {
      this.opened = false;
    },
    togglePopup() {
      this.opened = !this.opened;
    },
    createBlock(type) {
      this.opened = false;

      this.$emit('createBlock', type);
    },
    isTypeSupported(type) {
      if (!this.supportedTypes) {
        return true;
      }

      return this.supportedTypes.includes(type);
    },
  },
};
</script>
<style lang="scss" scoped>
.animated__dropdown {
  display: grid;
  grid-template-columns: 13em 15em;
  grid-template-rows: 1fr;
}

.top__dropdown .dropdown-menu {
  bottom: unset;
  top: -250px;
}

.dropdown-menu {
  top: 100%;
  left: 25px;
}

.text-center .dropdown-menu {
  top: calc(100% - 515px);
}

.block__empty .dropdown-menu {
  left: 55px;
}

.block--slideshow .dropdown-menu {
  top: 100%;
}

.slideshow__slide.last__slide .block__layout:last-child .dropdown-menu {
  top: calc(100% - 188px);
}

.block--slideshow .top__dropdown .dropdown-menu {
  top: 0px;
}

.add__media-btn .dropdown-menu {
  left: 0;
  top: calc(100% + 10px);
}

.block__lower-levels .top__dropdown .dropdown-menu {
  top: -94px;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item small {
  white-space: normal;
}
</style>
