<script setup lang="ts">
import Spinner from './Spinner.vue';

withDefaults(
  defineProps<{
    hasError: Boolean;
    isUploading?: Boolean;
    icon?: string;
  }>(),
  {
    icon: 'fa-upload',
  }
);

const emit = defineEmits(['triggerUpload']);
</script>

<template>
  <button
    type="button"
    class="d-flex align-items-center justify-content-center clickable p-2 btn file-upload-btn p-2 w-100"
    @click="emit('triggerUpload')"
  >
    <template v-if="isUploading">
      <Spinner small />
      <span class="fw-medium"> Uploading file... </span>
    </template>
    <template v-else>
      <i class="fal me-2" :class="icon" />
      <span class="fw-medium">
        <slot />
      </span>
    </template>
  </button>
</template>

<style scoped>
.file-upload-btn {
  border: 1px dashed #4e0695;
  color: #4e0695;
  background: #4e069508;
}
</style>
