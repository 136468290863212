import { findFieldByIdFromApps } from '@component-library/business-logic/app';
import { hasCompanyRole } from '@component-library/company';
import { App, GatherField } from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import { produce, setAutoFreeze } from 'immer';
import Map from 'ol/Map';
import { containsCoordinate } from 'ol/extent';
import TileGrid from 'ol/tilegrid/TileGrid';
import { normalizeJsonData } from '../../../api/layer/utils';
import { Dpi } from '../../../business-logic/measure';
import { getScopedSamples as _getScopedSamples } from '../../../business-logic/sample';
import {
  checkIsFigureStylingRuleOnEnviroSamples,
  findFigureStylingRulesByFigureId,
} from '../../../business-logic/styling/figure-styling-rule';
import { getStoreApi, setStoreApi } from '../../../lib/olbm/common/store-api';
import type { Extent, Id, Pid } from '../../../lib/olbm/common/types';
import { MapType } from '../../../lib/olbm/common/types';
import type {
  FigureStylingRuleOnApp,
  FigureStylingRuleOnEnvrioSamples,
} from '../../../lib/olbm/figure/styling-rule';
import { default as OlbmLayerManager } from '../../../lib/olbm/layer/LayerManager';
import getDefaultSampleStyle from '../../../lib/olbm/layer/sample/getDefaultSampleStyle';
import {
  checkIsSampleGroup,
  getSampleLocation,
} from '../../../lib/olbm/layer/sample/utils';
import { findLayerModels } from '../../../lib/olbm/layer/utils';
import { setLayoutZoom } from '../../../lib/olbm/measurement/layout';
import { Color } from '../../../lib/olbm/style/color';
import type {
  CriteriaType,
  Exceedance,
  Figure,
  LayerModel,
  Sample,
  SampleGroup,
  SampleScope,
  Scenario,
} from '../../../lib/olbm/types';
import { LayerType } from '../../../lib/olbm/types';
import { dispatch, getGetter, getState } from '../../../view-utils';
import TreeView from '../../TreeView';
import { ControlManager } from '../controls';
import { InteractionManager } from '../interactions';
import { LayerManager } from '../layers';
import {
  boundsToExtent,
  extentToBounds,
  resolutionToScale,
  scaleToResolution,
} from '../utils';
import { transformCoordinate } from '../utils/coordinate';

export default class DatanestMap extends Map {
  private viewer;
  private type;
  public layerManager;
  public olbmLayerManager;
  public interactionManager;
  public controlManager;
  private tempSampleGroups: any[] = [];

  constructor(options, viewer, type: MapType) {
    super(options);

    this.viewer = viewer;
    this.type = type;

    this.layerManager = new LayerManager(this);
    this.olbmLayerManager = new OlbmLayerManager(this);
    this.controlManager = new ControlManager(this);
    this.interactionManager = new InteractionManager(this);

    if (this.viewer) {
      this.set('hedgeImage', this.viewer.hedgeImage);
      setLayoutZoom(this, this.viewer.figureLayout.zoom);
    }

    // The store api only support sample layers.
    setStoreApi(this, {
      getProject: () => {
        return this.viewer.project;
      },
      findLayerModelById: (id: Id): LayerModel => {
        const layerModels = getStoreApi(this).getLayerModels();
        const [result] = findLayerModels(
          layerModels,
          (layerModel) => layerModel.id === id
        );
        return result;
      },
      findLopSampleByLayerModelId: (id: Id) => {
        const layer = this.viewer.getLayerById(id);
        return layer.data.lopSample;
      },
      getSelectedFigure: () => {
        return this.viewer.selectedFigure;
      },
      getLayerModels: () => {
        setAutoFreeze(false);
        const result = [
          ...this.viewer.sampleGroups,
          ...this.tempSampleGroups,
        ].map((sampleGroup) => {
          return produce<SampleGroup>(sampleGroup, (draft) => {
            draft.geojson = normalizeJsonData(draft.geojson);

            draft.hidden_sub_folders =
              normalizeJsonData(draft.hidden_sub_folders) ?? [];
            draft.visible = draft.visible ?? false;
            draft.parent_id = draft.parent_id ?? undefined;
          });
        });
        setAutoFreeze(true);
        return result;
      },
      findFigureStylingRulesByFigureId: (id: Id) => {
        if (typeof id === 'string') {
          return [];
        }

        const allFigureStylingRules = getState('figureStylingRules');
        const figureStylingRules = findFigureStylingRulesByFigureId(
          allFigureStylingRules,
          id
        );
        return figureStylingRules.map((fsr) => {
          if (checkIsFigureStylingRuleOnEnviroSamples(fsr)) {
            return fsr as unknown as FigureStylingRuleOnEnvrioSamples;
          } else {
            return fsr as unknown as FigureStylingRuleOnApp;
          }
        });
      },
      findAppById: (id: Pid): App | undefined => {
        const apps = getState('gatherApps') as App[];
        return apps.find((app) => app.id === id) as App | undefined;
      },
      findFieldById: (id: Id): GatherField | undefined => {
        if (typeof id === 'string') {
          return undefined;
        }

        const apps = getState('gatherApps') as App[];
        return findFieldByIdFromApps(apps, id);
      },
      findLayerModelsByType: (type: LayerType): LayerModel[] => {
        const storeApi = getStoreApi(this);
        const allLayerModels = storeApi.getLayerModels();
        return findLayerModels(
          allLayerModels,
          // Sub folders non't have geojson
          (layerModel: any) => layerModel.geojson?.properties.type === type
        );
      },
      getAllSampleGroups: (): SampleGroup[] => {
        const storeApi = getStoreApi(this);
        return storeApi.findLayerModelsByType(
          LayerType.SAMPLE_GROUP
        ) as SampleGroup[];
      },
      getDefaultSampleGroup: (): SampleGroup => {
        const storeApi = getStoreApi(this);
        return storeApi.getAllSampleGroups().find((sampleGroup) => {
          return sampleGroup.geojson.properties.default;
        })!;
      },
      getSampleLayerModel: (sample: Sample): LayerModel | undefined => {
        const storeApi = getStoreApi(this);
        // A sample in import preview layer doesn't have a sample group.
        return sample.sample_group
          ? storeApi.findLayerModelById(sample.sample_group.id)
          : undefined;
      },
      loadSamples: async (
        sampleGroupId: Id,
        extentInWgs84: Extent
      ): Promise<Sample[]> => {
        this.viewer.startLoadingLayer(sampleGroupId);

        try {
          const allSamples = getState('allSamples');
          const samples = allSamples.filter((sample) => {
            const { longitude, latitude } = getSampleLocation(this, sample);
            return (
              longitude &&
              latitude &&
              containsCoordinate(extentInWgs84, [longitude, latitude]) &&
              sample.sample_group.id === sampleGroupId
            );
          });
          return Promise.resolve(samples);
        } finally {
          this.viewer.endLoadingLayer(sampleGroupId);
        }
      },
      findSampleById: function (id: Id): Sample | undefined {
        return getGetter('getSampleById')(id);
      },
      findSampleByIdAsync: function (id: Pid): Promise<Sample | undefined> {
        return Promise.resolve(getGetter('getSampleById')(id));
      },
      getScopedSamples: (sampleScope: SampleScope): Sample[] => {
        const allSamples = getState('allSamples');
        return _getScopedSamples(sampleScope, allSamples);
      },
      addSample: (sample: Sample) => {
        const storeApi = getStoreApi(this);
        const isExisting = !!storeApi.findSampleById(sample.id);
        if (!isExisting) {
          dispatch('addNewSample', sample);
        }
      },
      removeSample: function (id: Id): void {
        dispatch('deleteSampleById', { id });
      },
      getExceedance: (criteriaType: CriteriaType): Exceedance => {
        const allExceedances = getState('allExceedances');
        return allExceedances.find((e) => e.criteria_type == criteriaType)!;
      },
      findScenarioById: (id: Id): Scenario | undefined => {
        const allScenarios = getState('allScenarios');
        return allScenarios.find((scenario) => scenario.id === id);
      },
      getExceededCriteriaTypes: (
        figure: Figure,
        sample: Sample,
        scenarios: Scenario[],
        chemicalIds: Id[]
      ): CriteriaType[] => {
        return this.viewer.getExceededCriteriaTypes(
          figure,
          sample,
          scenarios,
          chemicalIds
        );
      },
      getSampleOnlyExport: (): boolean => {
        return this.viewer.sampleOnlyExport;
      },
      getStylableLayerModels: (): LayerModel[] => {
        const storeApi = getStoreApi(this);
        const rectangles = storeApi.findLayerModelsByType(LayerType.RECTANGLE);
        const circles = storeApi.findLayerModelsByType(LayerType.CIRCLE);
        const polygons = storeApi.findLayerModelsByType(LayerType.POLYGON);
        const polylines = storeApi.findLayerModelsByType(LayerType.POLYLINE);
        const arrows = storeApi.findLayerModelsByType(LayerType.ARROW);
        const hedges = storeApi.findLayerModelsByType(LayerType.HEDGE);
        return [
          ...rectangles,
          ...circles,
          ...polygons,
          ...polylines,
          ...arrows,
          ...hedges,
        ];
      },
      getIntegrations: () => {
        return getState('integrations');
      },
      showError: (error) => {
        this.viewer.$toastStore.error(error);
      },
      showWarning: (warning) => {
        this.viewer.$toastStore.warning(warning);
      },
      checkHasPermission: (p) => {
        const user = this.viewer.getUser();
        return hasCompanyRole(user, p);
      },
      checkIsRenderableNonSpatialSampleGroup: (layerModelId) => {
        const path = `isRenderableNonSpatialSampleGroup[${layerModelId}]`;
        let { value } = this.viewer.getCachedValue(path);
        if (value === undefined) {
          const storeApi = getStoreApi(this);
          const model = storeApi.findLayerModelById(layerModelId);
          value =
            !!model &&
            checkIsSampleGroup(model) &&
            !!model.geojson.properties.renderableAppLinkConfig;
          this.viewer.setCachedValue({ path, value });
        }
        return value;
      },
    });
  }

  addTempSampleGroup(marker_identifier) {
    const tempSampleGroup = this.tempSampleGroups.find(
      (tsg) => tsg.marker_identifier === marker_identifier
    );
    if (tempSampleGroup) {
      return tempSampleGroup;
    }

    const defaultSampleStyle = getDefaultSampleStyle();
    const [result] = TreeView.prepareDBLayersForTree([
      {
        id: makeId(),
        geojson: JSON.stringify({
          //this is used for storing in DB as it's a new folder
          properties: {
            type: LayerType.SAMPLE_GROUP,
            title: 'Untitled Group',
            iconSize: defaultSampleStyle.iconSize,
            iconRotation: defaultSampleStyle.iconRotation,
            iconOpacity: defaultSampleStyle.iconOpacity,
            labelSize: defaultSampleStyle.labelSize,
            hideMarkerLabel: false,
            hideDuplicateLabel: false,
            markerLabelColor: Color.Black,
            markerLabelShadowColor: Color.White,
            markerLabelUnderlined: false,
            markerLabelAsteriskAppended: false,
          },
        }),
        marker_identifier,
        children: [],
      },
    ]);
    this.tempSampleGroups.push(result);
    return result;
  }

  clearTempSampleGroups() {
    this.tempSampleGroups = [];
  }

  getViewer() {
    return this.viewer;
  }

  getType() {
    return this.type;
  }

  scaleToResolution(scale) {
    const view = this.getView();
    const projection = view.getProjection();
    const center = view.getCenter();
    return scaleToResolution(projection, scale, center, this.viewer.dpi);
  }

  resolutionToScale(resolution) {
    const view = this.getView();
    const projection = view.getProjection();
    const center = view.getCenter();
    return resolutionToScale(projection, resolution, center, this.viewer.dpi);
  }

  getScale() {
    const view = this.getView();
    const resolution = view.getResolution();
    return this.resolutionToScale(resolution);
  }

  setScale(value, animate = false) {
    const view = this.getView();
    const resolution = this.scaleToResolution(value);

    view.animate({
      resolution: resolution,
      duration: animate ? 650 : undefined,
    });
  }

  patchTileGrid(tileGrid: TileGrid, projection, targetDpi = Dpi.Screen) {
    const view = this.getView();
    const viewProjection = view.getProjection();
    projection = projection ?? viewProjection;
    const getZForResolutionNative = tileGrid.getZForResolution;
    tileGrid.getZForResolution = (resolution, opt_direction) => {
      const viewer = this.getViewer();
      // Some maps, e.g. AddressChecker, they don't have a MapViewer instance.
      if (viewer) {
        const { zoom } = viewer.figureLayout;
        const center = transformCoordinate(
          view.getCenter(),
          viewProjection,
          projection
        );
        resolution = scaleToResolution(
          projection,
          this.getScale() * zoom,
          center,
          targetDpi
        );
      }
      return getZForResolutionNative.call(tileGrid, resolution, opt_direction);
    };
  }

  getBounds() {
    const view = this.getView();
    const extent = view.calculateExtent();

    return extentToBounds(extent, view.getProjection());
  }

  fitBounds(bounds, { animate, maxZoom }) {
    const view = this.getView();
    const extent = boundsToExtent(bounds, view.getProjection());

    let options: any = {
      duration: animate ? 650 : 0,
    };
    if (typeof maxZoom === 'number') {
      options = {
        ...options,
        maxZoom,
      };
    }
    view.fit(extent, options);
  }
}
