import axios from 'axios';
import { useNavigationStore } from '../store/navigation';
import { useProjectStore } from '../store/project';
import { Router } from 'vue-router';
import useIsGather from '../composables/useIsGather';

function openUrl(url: string, isMiddleClick: boolean = false) {
  if (isMiddleClick) {
    window.open(url);
  } else {
    window.location.href = url;
  }
}

function appendQueryParam(url: string, param: string, value: string) {
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}${param}=${value}`;
}

let router: Router;
export function setRouter(newRouter: Router) {
  router = newRouter;
}

export async function goToApp({
  isGather = undefined,
  route,
  app,
  isMiddleClick = false,
  project,
}: {
  isGather?: never;
  route: string;
  app: string;
  isMiddleClick?: boolean;
  $router?: never;
  $store?: never;
  project?: {
    template_id?: number;
    project_id?: number | null;
    uuid?: string;
  } | null;
}) {
  if (!router) {
    throw new Error('Router not found, setRouter missing from app.ts');
  }
  let project_id: number | null = null;
  if (project) {
    project_id = project.project_id ?? null;
  }

  if (isGather === undefined) {
    isGather = useIsGather();
  }

  const projectStore = useProjectStore();
  projectStore.showSwitcherModal = false;

  if (app == 'gather') {
    if (!project) {
      return;
    }
    const { template_id } = project;
    if (!isGather) {
      axios.get('/handover/gather').then((response) => {
        let url = response.data.redirect_url + (route ? `/${route}` : '');
        const navigationStore = useNavigationStore();
        if (navigationStore.demoModeEnabled) {
          url = appendQueryParam(url, 'demo', '1');
        }
        openUrl(url, isMiddleClick);
      });
      return;
    }

    if (isMiddleClick) {
      let props = router.resolve({
        name: route,
        params: { project_id, template_id },
      });
      window.open(props.href);
      return;
    }

    router.push({
      name: route,
      params: { project_id, template_id },
    });
    return;
  }

  if (app == 'hub') {
    if (isGather) {
      axios.get('/api/handover/datanest').then((response) => {
        let url = response.data.redirect_url + (route ? `/${route}` : '');
        const navigationStore = useNavigationStore();
        if (navigationStore.demoModeEnabled) {
          url = appendQueryParam(url, 'demo', '1');
        }
        openUrl(url, isMiddleClick);
      });
      return;
    }

    if (route == 'project_create') {
      project_id = null;
      if (projectStore.currentProject) {
        projectStore.updateProject(null);
      }
    } else if (isMiddleClick) {
      let props = router.resolve({
        name: route,
        params: { project_id, projectUuid: project?.uuid },
      });

      window.open(props.href);
      return;
    }

    const currentRoute = router?.currentRoute?.value;
    if (currentRoute?.name === route) {
      return;
    }

    if (route.startsWith('/')) {
      router.push({
        path: route,
      });
      return;
    }

    router.push({
      name: route,
      params: { project_id, projectUuid: project?.uuid },
    });
  }
}
