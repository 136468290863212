<template>
  <div
    class="h-100"
    :class="{
      'slideshow__container--right': block.sidebar_is_right,
    }"
  >
    <div
      ref="slideshow"
      class="slideshow__container h-100"
      :class="{
        'slideshow__container--full-screen': !editable,
      }"
      :style="getSidebarFractionStyling"
    >
      <div v-if="block.slides.length == 0" class="slideshow__slide"></div>
      <template
        v-for="(slide, slideIndex) in block.slides"
        :key="'slide-' + slideIndex"
      >
        <div
          class="slideshow__slide"
          :data-slide-id="slide.id"
          :class="{
            last__slide: slideIndex == block.slides.length - 1,
            'slideshow__slide--hidden':
              slide.figure &&
              slide.figure.settings &&
              slide.figure.settings.display_full_screen,
          }"
          :data-title="getDataTitle(slide)"
        >
          <block-editor
            :slideshow="block"
            :blocks="getSlideBlocks(block.id, slide.id)"
            :currentActionBlock="currentActionBlock"
            :progressHeight="progressHeight"
            @create="
              (data) =>
                createBlock({
                  slideshow_block_id: block.id,
                  slide_id: slide.id,
                  ...data,
                })
            "
            @delete="
              (id) =>
                setConfirmation({
                  header: 'Are you sure you want to delete this block?',
                  submit: () => deleteBlock(id),
                  isDelete: true,
                })
            "
          />

          <div
            v-if="editable && slideIndex != block.slides.length - 1"
            class="slideshow__slide_end text-muted"
          >
            <span>END OF SECTION</span>
          </div>
        </div>

        <slideshow-figure
          :block="block"
          :slide="slide"
          :currentSlide="currentSlide"
          :style="{
            background:
              slide.figure && slide.figure.settings
                ? slide.figure.settings.background
                : null || '#f8f9fa',
          }"
        />
      </template>

      <size-toggle-container
        v-if="
          editable &&
          getCurrentSlide &&
          (!getCurrentSlide.figure ||
            !(
              getCurrentSlide.figure.settings &&
              getCurrentSlide.figure.settings.display_full_screen
            ))
        "
        :block="block"
        @updateSlideshow="
          (data) => updateBlock({ id: block.id, updated_fields: { ...data } })
        "
      />
    </div>

    <div v-if="editable" class="slideshow__manager">
      <slide-manager
        :block="block"
        :slides="block.slides"
        :currentSlide="currentSlide"
        @create="
          createAndGotoSlide({
            order: block.slides.length + 1,
            slideshow_block_id: block.id,
          })
        "
        @delete="
          (slide_id) =>
            setConfirmation({
              header: 'Are you sure you want to delete this section?',
              submit: () =>
                deleteAndGotoLastSlide({
                  slideshow_block_id: block.id,
                  slide_id,
                }),
              isDelete: true,
            })
        "
        @duplicate="
          (slide_id) =>
            duplicateSlide({
              slideshow_block_id: block.id,
              slide_id,
            })
        "
        @updateOrdering="
          (data) =>
            updateSlideOrdering({
              ...data,
              slideshow_block_id: block.id,
            })
        "
        @goToSlide="goToSlide"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SlideManager from './SlideManager.vue';
import SlideshowFigure from './Figure.vue';
import SizeToggleContainer from './SizeToggleContainer.vue';
import { goNumbering2 } from '../../../helpers/heading-numbering.js';
import { elementInScreenBounds } from '../../../helpers/general.js';
import { auth } from '@component-library/auth';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'Slideshow',
  props: {
    block: Object,
  },
  data: () => ({
    currentSlide: 1,
    currentActionBlock: null,
    progressHeight: null,
  }),
  watch: {
    currentActionBlock(block_id) {
      this.setBlockActionProperties(block_id);
    },
  },
  components: {
    BlockEditor: defineAsyncComponent({
      loader: () => import('../..//BlockEditor.vue'),
    }),
    SlideManager,
    SlideshowFigure,
    SizeToggleContainer,
  },
  computed: {
    ...mapState({
      editable: (state) => state['reporter'].editable,
      pageHeadings: (state) => state['reporter'].pageHeadings,
    }),
    ...mapGetters('reporter', [
      'getSlideBlocks',
      'getThemePropertyByKey',
      'headingTree',
    ]),
    getSidebarFractionStyling() {
      const sidebarWidth = this.block.sidebar_width;
      const figureWidth = 100 - this.block.sidebar_width;

      if (this.block.sidebar_is_right) {
        return {
          'grid-template-columns': `${figureWidth}% ${sidebarWidth}% `,
        };
      }

      return {
        'grid-template-columns': `${sidebarWidth}% ${figureWidth}%`,
      };
    },
    getCurrentSlide() {
      return this.block.slides.find((s) => s.id == this.currentSlide);
    },
    numberHeadings() {
      return this.getThemePropertyByKey('number_headings');
    },
  },
  methods: {
    ...mapActions('reporter', [
      'createBlock',
      'updateBlock',
      'deleteBlock',

      'createSlide',
      'duplicateSlide',
      'updateSlideOrdering',
      'deleteSlide',
      'deleteSlideFigure',

      'setConfirmation',

      'setBlockActionProperties',
      'scrollToElement',
    ]),
    handleSlideScrolling() {
      const windowHeight =
        window.innerHeight -
        (auth.check() ? 112 : 42) -
        (this.editable ? 135 : 0) -
        50;

      const slideshow = this.$refs.slideshow;

      if (!slideshow) {
        return;
      }

      const slides = Array.from(
        slideshow.querySelectorAll('.slideshow__slide')
      );

      for (let slide of slides) {
        if (
          windowHeight -
            slide.getBoundingClientRect().bottom +
            (this.editable ? 135 : 0) <
          0
        ) {
          this.currentSlide = parseInt(slide.getAttribute('data-slide-id'));
          break;
        }
      }

      const currentSlide = slideshow.querySelector(
        `[data-slide-id="${this.currentSlide}"]`
      );

      if (!currentSlide) {
        return;
      }

      const blocks = Array.from(
        currentSlide.querySelectorAll('.block--with-actions')
      );

      if (blocks.length > 0) {
        const middlePosition = windowHeight / 2;
        const closestBlock = blocks.reduce((prev, curr) => {
          const prevTop = prev.getBoundingClientRect().top;
          const currentTop = curr.getBoundingClientRect().top;

          return Math.abs(currentTop - middlePosition) <
            Math.abs(prevTop - middlePosition)
            ? curr
            : prev;
        });

        if (elementInScreenBounds(closestBlock)) {
          this.currentActionBlock = parseInt(
            closestBlock.getAttribute('data-block-id')
          );

          const blockRect = closestBlock.getBoundingClientRect();
          const progress =
            ((middlePosition - blockRect.top + blockRect.height / 2) /
              blockRect.height) *
            100;

          this.progressHeight = Math.min(Math.max(progress, 0), 100);
        }
      }
    },
    goToSlide(id) {
      this.scrollToElement(
        this.$refs.slideshow.querySelector(`[data-slide-id="${id}"]`)
      );
    },
    async createAndGotoSlide(data) {
      await this.createSlide(data);

      this.$nextTick(() => {
        this.goToSlide(this.block.slides[this.block.slides.length - 1].id);
      });
    },
    async deleteAndGotoLastSlide(data) {
      await this.deleteSlide(data);

      this.$nextTick(() => {
        const lastIndex = this.block.slides.length - 1;

        if (lastIndex > this.currentSlide) {
          return;
        }

        this.goToSlide(this.block.slides[lastIndex].id);
      });
    },
    getDataTitle(slide) {
      let title =
        (slide.figure &&
          slide.figure.type == 'coverpage' &&
          slide.figure.settings.include_in_heading &&
          slide.figure.settings.title) ||
        '';

      if (this.numberHeadings && title) {
        title = goNumbering2(
          this.headingTree,
          `<h2>${title}</h2>`,
          slide.figure.id
        );
        title = title.substring('<h2>'.length, title.length - '</h2>'.length);
      }

      return title;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleSlideScrolling);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleSlideScrolling);
  },
  mounted() {
    this.handleSlideScrolling();
  },
};
</script>
<style lang="scss" scoped>
.slideshow__figure {
  height: calc(100vh - 133px - 58px - 70px);
}

.slideshow__slide {
  min-height: calc(100vh - 70px - 56px);
  padding-bottom: 60vh !important;
  position: relative;
}

.slideshow__slide.last__slide {
  padding-bottom: inherit !important;
}

.slideshow__manager {
  position: sticky;
  bottom: 0px;
  height: 135px;
  z-index: 10;
}

.slideshow__navigation {
  position: absolute;
  top: calc(100vh - 70px - 56px - 80px);
  bottom: 0px;
  left: 1em;
}

.slideshow__container--right .slideshow__navigation {
  right: 1em;
  left: unset;
}

.slideshow__container {
  display: grid;
  grid-template-rows: auto;

  .slideshow__slide {
    grid-column: 1;
  }

  .slideshow__figure {
    grid-column: 2;
  }
}

.slideshow__container--right {
  .slideshow__slide {
    grid-column: 2;
  }

  .slideshow__figure {
    grid-column: 1;
    border-left: none;
  }
}

.slideshow__figure--full-screen {
  grid-column: 1 / span 2 !important;
  background: var(--background-color);
}

.slideshow__figure--full-screen.active {
  z-index: 6 !important;
}

.slideshow__slide--hidden {
  visibility: hidden;
}

.slideshow__slide--hidden:first-child {
  padding-bottom: 0vh !important;
  grid-row: 1;
}

.slideshow__slide--hidden.last__slide {
  grid-row: unset;
}

.slideshow__container--full-screen {
  .slideshow__figure {
    height: calc(100vh - 70px - 56px);
  }
}

.viewer__container--headings {
  .slideshow__figure {
    height: calc(100vh - 175px - 70px - 50px);
    top: calc(56px + 70px + 50px);
  }

  .slideshow__slide {
    min-height: calc(100vh - 70px - 56px - 50px);
  }

  .slideshow__navigation {
    top: calc(100vh - 70px - 56px - 80px - 50px);
  }

  .slideshow__container--full-screen {
    .slideshow__figure {
      height: calc(100vh - 70px - 56px - 50px);
    }
  }
}

.slideshow__slide_end {
  width: calc(100% - 40px);
  text-align: center;
  border-bottom: 1px solid #f4f4f4;
  line-height: 0.1em;
  margin: 10px 20px 20px 20px;
  position: absolute;
  bottom: 27vh;
  left: 0px;
  font-weight: bold;

  span {
    background: #ffffff;
    padding: 0 10px;
  }
}
</style>
