<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    type?: 'danger' | 'warning' | 'info' | 'primary' | 'success' | 'light';
    icon?: string | null;
  }>(),
  {
    type: 'danger',
    icon: null,
  }
);

function getDefaultIcon() {
  const icon =
    {
      danger: 'fa-exclamation-triangle',
      warning: 'fa-exclamation-triangle',
      info: 'fa-info-circle',
      primary: 'fa-info-circle',
      light: 'fa-info-circle',
      success: 'fa-check-circle',
    }[props.type] ?? 'fa-exclamation-triangle';
  return 'fal fa-lg ' + icon;
}
</script>

<template>
  <div
    :class="`alert alert-${type} ${
      type === 'danger' ? 'text-danger' : ''
    } d-flex align-items-center`"
  >
    <i
      :class="`${icon || getDefaultIcon()} opacity-75 text-${
        type === 'light' ? 'dark' : type
      } me-2`"
    />
    <span class="flex-grow-1">
      <slot />
    </span>
  </div>
</template>
