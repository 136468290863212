import along from '@turf/along';
import { Feature, Map } from 'ol';
import { LineString, Point, Polygon } from 'ol/geom';
import { Fill, Icon, Style } from 'ol/style';
import { getCoordinateFromGeoJSONPointFeature } from '../common/coordinate';
import { toGeoJSON } from '../common/geojson';
import { getMapProjection } from '../common/view';
import { getSampleIcon } from '../layer/sample/utils';
import { getLength } from '../measurement/length';

const markerCache = {};

export default function createPolylineArrowHeadStyle(
  map: Map,
  polylineFeature: Feature<LineString>,
  resolution: number,
  layoutZoom: number,
  arrowHeads: any[]
): Style[] {
  const proj = getMapProjection(map);
  const mpu = proj.getMetersPerUnit()!;
  const polylineGeom = polylineFeature.getGeometry()!;
  const polylineCoords = polylineGeom.getCoordinates();
  const gPolylineFeature = toGeoJSON(
    map,
    polylineFeature
  ) as GeoJSON.Feature<GeoJSON.LineString>;
  const length = getLength(map, polylineGeom);

  const styles: Style[] = [];
  for (let i = 0; i < arrowHeads.length; i++) {
    const { size, isBackward, color, position, iconId } = arrowHeads[i];
    const zoomedSize = size * layoutZoom;
    const sizeInMeter = zoomedSize * resolution * mpu;
    const lengthUntilBasePoint = length * position;

    let firstPointDelta = 2 * sizeInMeter;
    if (isBackward) {
      firstPointDelta = -firstPointDelta;
    }

    let firstPointCoord;
    try {
      const gFirstPointFeature = along(
        gPolylineFeature,
        (lengthUntilBasePoint + firstPointDelta) / 1000
      );
      firstPointCoord = getCoordinateFromGeoJSONPointFeature(
        gFirstPointFeature,
        proj
      );
    } catch (e) {
      console.error(e);
      // The first point is not on the line.
      firstPointCoord = !isBackward
        ? polylineCoords[polylineCoords.length - 1]
        : polylineCoords[0];
    }

    const createAndCacheIconStyle = () => {
      const geomteryMethod = function (feature) {
        if (position === 0 || position === 1) {
          const coords = feature.getGeometry().getCoordinates();
          return new Point(
            position === 0 ? coords[0] : coords[coords.length - 1]
          );
        }

        return new Point(firstPointCoord);
      };

      const { src, size: siSize } = getSampleIcon(
        iconId - 1,
        color,
        size,
        size,
        1
      );
      const img = new Image();
      const key = `${src}&polylineIcon=1&position=${position}`;
      let style = markerCache[key];
      if (!style) {
        img.onload = function () {
          const canvas = document.createElement('canvas');

          canvas.width = siSize[0];
          canvas.height = siSize[1];

          (canvas as any)
            .getContext('2d')
            .drawImage(img, 0, 0, siSize[0], siSize[1]);

          style = markerCache[key] = new Style({
            image: new Icon({
              src: canvas.toDataURL(),
            }),
            geometry: geomteryMethod,
            zIndex: 1,
          });

          styles.push(style);

          polylineFeature.changed();
        };

        img.src = src;
      } else {
        style.geometry = geomteryMethod;
        styles.push(style);
      }
    };

    if (iconId) {
      createAndCacheIconStyle();
      continue;
    }

    // Get points on the perpendicular line
    // Salute to the guy who offered the algorithm here:
    // https://stackoverflow.com/questions/17989148/javascript-find-point-on-perpendicular-line-always-the-same-distance-away
    const gStartPointFeature = along(
      gPolylineFeature,
      lengthUntilBasePoint / 1000
    );
    const startPointCoord = getCoordinateFromGeoJSONPointFeature(
      gStartPointFeature,
      proj
    );
    const startPointPx = map.getPixelFromCoordinate(startPointCoord);

    const gEndPointFeature = along(
      gPolylineFeature,
      (lengthUntilBasePoint + 0.001) / 1000
    );
    const endPointCoord = getCoordinateFromGeoJSONPointFeature(
      gEndPointFeature,
      proj
    );
    const endPointPx = map.getPixelFromCoordinate(endPointCoord);
    const centerPointPx = [
      (startPointPx[0] + endPointPx[0]) / 2,
      (startPointPx[1] + endPointPx[1]) / 2,
    ];
    const angle = Math.atan2(
      endPointPx[1] - startPointPx[1],
      endPointPx[0] - startPointPx[0]
    );
    const secondPointPx = [
      Math.sin(angle) * zoomedSize + centerPointPx[0],
      -Math.cos(angle) * zoomedSize + centerPointPx[1],
    ];
    const secondPointCoord = map.getCoordinateFromPixel(secondPointPx);
    const thirdPointPx = [
      -Math.sin(angle) * zoomedSize + centerPointPx[0],
      Math.cos(angle) * zoomedSize + centerPointPx[1],
    ];
    const thirdPointCoord = map.getCoordinateFromPixel(thirdPointPx);

    styles.push(
      new Style({
        geometry: new Polygon([
          [
            firstPointCoord,
            secondPointCoord,
            startPointCoord,
            thirdPointCoord,
            firstPointCoord,
          ],
        ]),
        fill: new Fill({
          color,
        }),
        zIndex: 1,
      })
    );
  }

  return styles;
}
