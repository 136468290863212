import { DrawingType } from '../../gather';
import { produce } from 'immer';

// The string id means that the styling rule is a new one created in the browser and has not been saved.
export type Id = number | string;

export enum TargetType {
  GatherApp = 1,
  EnviroSamples = 2,
}

export type CollectionType =
  | DrawingType.Point
  | DrawingType.Polygon
  | DrawingType.Polyline;

export type BaseTarget = {
  id: number; // Gather App id or sample group id.
};

export type GatherAppTarget = BaseTarget & {
  type: TargetType.GatherApp;
  collectionType: CollectionType;
  sectionId: number;
};

export type ConditionOnGatherApp = {
  fieldId: number;
  operator: string;
  operand: { value: string | null; value2: string | null };
};

export type SampleStyle = {
  icon: number;
  color: string;
  iconSize: number;
  iconRotation: number;
  iconOpacity: number;
  isLabelHidden: boolean;
  isDuplicateLabelHidden: boolean;
  labelColor: string;
  labelShadowColor: string;
  labelSize: number;
  isLabelUnderlined: boolean;
  isLabelAsteriskAppended: boolean;
};

export type PolylineStyle = {
  color: string;
  outlineStyle: number;
  weight: number;
  opacity: number;
  arrowHeadSize: number;
  isArrowHeadBackward: boolean;
  arrowHeads: [];
  shouldShowLabel: boolean;
  outlineMeasurement: boolean;
};

export type PolygonStyle = {
  color: string;
  fillStyle;
  fillOpacity;
  outlineStyle: number;
  weight: number;
  opacity: number;
  shouldShowLabel: boolean;
  outlineMeasurement: boolean;
  areaMeasurement: boolean;
};

export type StylingRuleOnGatherAppStyle =
  | SampleStyle
  | PolygonStyle
  | PolylineStyle
  | object;

export interface BaseStylingRule {
  id: Id;
  name: string;
}

export interface StylingRuleOnGatherApp extends BaseStylingRule {
  target: GatherAppTarget;
  condition: ConditionOnGatherApp;
  style: StylingRuleOnGatherAppStyle;
}

export type StylingRuleDataOnGatherApp = Omit<StylingRuleOnGatherApp, 'id'>;

export type ConditionDescription = {
  isValid: boolean;
  content: string;
};

export const INVALID_CONDITION: ConditionDescription = {
  isValid: false,
  content: 'Invalid condition',
};

export enum Operator {
  EqualTo = '==',
  NotEqualTo = '!=',
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
  LessThan = '<',
  LessThanOrEqualTo = '<=',
}

export const OperatorLabelMap: Record<Operator, string> = {
  [Operator.EqualTo]: 'Equal to',
  [Operator.NotEqualTo]: 'Not equal to',
  [Operator.GreaterThan]: 'Greater than',
  [Operator.GreaterThanOrEqualTo]: 'Greater than or equal to',
  [Operator.LessThan]: 'Less than',
  [Operator.LessThanOrEqualTo]: 'Less than or equal to',
};

export function findStylingRuleById<T extends BaseStylingRule>(
  stylingRules: T[],
  id: Id
): T | undefined {
  return stylingRules.find((sr) => sr.id === id);
}

export function getDefaultCollectionType(
  drawingType: DrawingType
): CollectionType {
  if (
    [DrawingType.Point, DrawingType.Polygon, DrawingType.Polyline].includes(
      drawingType
    )
  ) {
    return drawingType as CollectionType;
  } else if (drawingType === DrawingType.Any) {
    return DrawingType.Point;
  } else if (drawingType === DrawingType.Arrow) {
    return DrawingType.Polyline;
  } else if (
    [DrawingType.Rectangle, DrawingType.Circle, DrawingType.Hedge].includes(
      drawingType
    )
  ) {
    return DrawingType.Polygon;
  } else {
    throw `Invalid drawing type: ${drawingType}`;
  }
}

export function getShapeProperty(
  shapeProperties: Record<string, any>,
  name: string,
  defaultValue: any = null
): any {
  const value = shapeProperties[name] ?? defaultValue;

  if (
    !isNaN(value) &&
    !['boolean', 'object'].includes(typeof value) &&
    !['title', 'text', 'unit'].includes(name)
  ) {
    return Number(value);
  }

  return value;
}

export function checkIsNewStylingRule<T extends BaseStylingRule>(
  stylingRule: T
): boolean {
  return typeof stylingRule.id === 'string';
}

export type UpdateOneOfStylingRulesPayload<T extends BaseStylingRule> = {
  id: Id;
  producer: (draft: T) => void;
};

export function updateOneOfStylingRules<T extends BaseStylingRule>(
  stylingRules: T[],
  { id, producer }: UpdateOneOfStylingRulesPayload<T>
) {
  const stylingRule = findStylingRuleById(stylingRules, id);

  if (!stylingRule) {
    return;
  }

  const newStylingRule = produce(stylingRule, producer);
  const index = stylingRules.indexOf(stylingRule);
  stylingRules.splice(index, 1, newStylingRule);
}

export function deleteOneOfStylingRules<T extends BaseStylingRule>(
  stylingRules: T[],
  id: Id
) {
  const stylingRule = findStylingRuleById(stylingRules, id);

  if (!stylingRule) {
    return;
  }

  const index = stylingRules.indexOf(stylingRule);
  stylingRules.splice(index, 1);
}
