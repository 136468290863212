import store from '@/js/store';
import {
  STROKE_PATTERN_DASH,
  STROKE_PATTERN_DOT,
} from '@component-library/business-model/common';
import { getImageFallbackSrc } from '@component-library/utils/image';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import _isEqualWith from 'lodash/isEqualWith';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import { Feature } from 'ol';
import Popup from 'ol-ext/overlay/Popup';
import { equals } from 'ol/coordinate';
import { LineString } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import { getUid } from 'ol/util';
import {
  getGatherImageSrc,
  getImageSrc,
} from '../../../../business-logic/common';
import {
  ALIGNMENT_CENTER,
  DEFAULT_OPTIONS,
  getUsage,
  Usage,
} from '../../../../business-logic/tool/call-out';
import { setLayoutZoom } from '../../../../lib/olbm/measurement/layout';
import { NAMESPACE } from '../../../../store';
import { createTextShadow, dispatch } from '../../../../view-utils';
import * as maps from '../../maps';
import * as overlays from '../../overlays';
import * as styles from '../../styles';
import addOpacity from '../../styles/addOpacity';
import * as utils__ from '../../utils';
import { coordinateToLatLng } from '../../utils';
import { resolutionToScale } from '../../utils/scale';
import createDrawingLayer from '../createDrawingLayer';
import * as constants from './constants';
import * as utils from './utils';

/**
 * A call-out layer consists of a popup and a connector.
 * @param {ol.Map} map
 * @param {ol.Coordinate | ol.Feature} positionOrConnector A coordinate or a connector.
 * @returns
 */
export default function createLayer(map, positionOrConnector) {
  const viewer = map.getViewer();

  let position;
  let connector;
  if (Array.isArray(positionOrConnector)) {
    position = positionOrConnector;
    const targetPosition = calculateInitialTargetPosition(map, position);
    connector = new Feature(new LineString([position, targetPosition]));
  } else {
    position = positionOrConnector.getGeometry().getCoordinates()[0];
    connector = positionOrConnector;
  }
  const handleConnectorChange = _debounce(() => {
    const isAutoPositioningEnabled = viewer.getShapeProperty(
      'isAutoPositioningEnabled',
      true
    );
    if (!isAutoPositioningEnabled) {
      return;
    }

    // Update the popup positioning.
    const popup = layer.getPopup();
    const ends = layer.getConnectorEnds();
    popup.setPosition(ends[0]);
    const positioning = utils__.position.calculateOverlayPositioning(
      map,
      popup.getPosition(),
      ends[1]
    );
    popup.setPositioning(positioning);
    if (map.interactionManager.hasSession()) {
      NAMESPACE.dispatch(store, 'setShapeProperty', {
        positioning,
      });
    }
    layer.refresh({ positioning });
  }, 100);
  connector.on('change', handleConnectorChange);

  const layer = createDrawingLayer(
    map,
    new VectorSource({ features: [connector] })
  );

  const layers = map.getLayers();
  const handleAdd = ({ element }) => {
    if (element === layer) {
      const { options } = layer;
      const layerUid = getUid(layer);
      const popupId = `ol-popup-${layerUid}`;
      const popup = new Popup({
        id: popupId,
        closeBox: false,
        stopEvent: false,
        positioning: options.positioning,
        anchor: false,
      });
      popup.set(constants.CUSTOM_LAYER_ID, layerUid);
      map.addOverlay(popup);
      popup.show(position);
      updatePopup(map, layer, popup, options);

      layers.un('add', handleAdd);
    }
  };
  layers.on('add', handleAdd);
  const handleRemove = ({ element }) => {
    if (element === layer) {
      const popup = layer.getPopup();
      map.removeOverlay(popup);

      layers.un('remove', handleRemove);
    }
  };
  layers.on('remove', handleRemove);

  layer.adaptOptions = function (options) {
    const {
      connectorColor: color,
      connectorPattern: outlineStyle,
      connectorWeight: weight,
      connectorOpacity: opacity,
    } = options;
    return viewer.getScaledLayerProperties({
      ...options,
      color,
      outlineStyle,
      weight,
      opacity,
    });
  };

  const superApplyOptions = layer.applyOptions;
  layer.applyOptions = function (options, forceToRecreateContent = false) {
    options = {
      image_data: DEFAULT_OPTIONS.image_data,
      mapData: DEFAULT_OPTIONS.mapData,
      isTextUsedAsCaption: DEFAULT_OPTIONS.isTextUsedAsCaption,
      textColor: options.color ?? DEFAULT_OPTIONS.textColor,
      textOutlineColor:
        options.backgroundColor ?? DEFAULT_OPTIONS.textOutlineColor,
      fontSize: DEFAULT_OPTIONS.fontSize,
      alignment: DEFAULT_OPTIONS.alignment,
      isItalic: DEFAULT_OPTIONS.isItalic,
      isBold: DEFAULT_OPTIONS.isBold,
      isUnderlined: DEFAULT_OPTIONS.isUnderlined,
      width: DEFAULT_OPTIONS.width,
      height: DEFAULT_OPTIONS.height,
      color: DEFAULT_OPTIONS.color,
      outlineStyle: DEFAULT_OPTIONS.outlineStyle,
      weight: DEFAULT_OPTIONS.weight,
      opacity: DEFAULT_OPTIONS.opacity,
      backgroundColor: DEFAULT_OPTIONS.backgroundColor,
      backgroundOpacity: DEFAULT_OPTIONS.backgroundOpacity,
      connectorColor: DEFAULT_OPTIONS.connectorColor,
      connectorPattern: DEFAULT_OPTIONS.connectorPattern,
      connectorWeight: DEFAULT_OPTIONS.connectorWeight,
      connectorOpacity: DEFAULT_OPTIONS.connectorOpacity,
      ...options,
    };

    layer.setVisible(!options.isConnectorHidden);

    // Set whether the content needs to be re-created;
    let shouldRecreateContent = forceToRecreateContent;
    if (!shouldRecreateContent) {
      const newUsage = getUsage(options);
      const oldUsage = this.options ? getUsage(this.options) : Usage.ShowText;
      if (newUsage !== oldUsage) {
        shouldRecreateContent = true;
      } else if (newUsage === Usage.ShowText) {
        const paths = ['image_data', 'mapData', 'isTextUsedAsCaption'];
        const newTextOptions = _omit(options, paths);
        const oldTextOptions = _omit(this.options, paths);
        shouldRecreateContent = !_isEqual(newTextOptions, oldTextOptions);
      } else if (newUsage === Usage.ShowImage) {
        const paths = [
          'text',
          'image_data',
          'isTextUsedAsCaption',
          'opacity',
          'width',
          'weight',
        ];
        if (options.isTextUsedAsCaption) {
          paths.push(
            'textColor',
            'textOutlineColor',
            'fontSize',
            'alignment',
            'isItalic',
            'isBold',
            'isUnderlined'
          );
        }
        const newImageOptions = _pick(options, paths);
        const oldImageOptions = _pick(this.options, paths);
        shouldRecreateContent = !_isEqual(newImageOptions, oldImageOptions);
      } else if (newUsage === Usage.ShowMap) {
        const paths = ['text', 'width', 'height', 'isTextUsedAsCaption'];
        if (options.isTextUsedAsCaption) {
          paths.push(
            'textColor',
            'textOutlineColor',
            'fontSize',
            'alignment',
            'isItalic',
            'isBold',
            'isUnderlined'
          );
        }
        let newMapOptions = _pick(options, paths);
        let oldMapOptions = _pick(this.options, paths);

        // The centerLatLng and scale should be excluded in the comparison
        const mapDataPaths = ['basemapApiIndex', 'layerIds'];
        const newMapData = _pick(options.mapData, mapDataPaths);
        const oldMapData = _pick(this.options.mapData, mapDataPaths);
        newMapOptions = { ...newMapOptions, mapData: newMapData };
        oldMapOptions = { ...oldMapOptions, mapData: oldMapData };
        shouldRecreateContent = !_isEqualWith(
          newMapOptions,
          oldMapOptions,
          (objValue, othValue, index, object, other, stack) => {
            if (index === 'layerIds') {
              // The layer id could be either a string or a number.
              return _isEqual(
                objValue.map((v) => String(v)),
                othValue.map((v) => String(v))
              );
            }
            return undefined;
          }
        );
      }
    }

    superApplyOptions.call(this, options);
    const popup = this.getPopup();
    if (popup) {
      updatePopup(map, this, popup, this.options, shouldRecreateContent);
    }
  };

  layer.refresh = function (
    optionsUpdate = {},
    forceToRecreateContent = false
  ) {
    this.applyOptions(
      { ...this.options, ...optionsUpdate },
      forceToRecreateContent
    );
  };

  layer.duplicate = function () {
    const connector = this.getFirstFeature().clone();
    const duplicate = createLayer(map, connector);
    duplicate.refresh(_cloneDeep(this.options));
    return duplicate;
  };

  layer.getPopup = function () {
    return map
      .getOverlays()
      .getArray()
      .find((item) => item.get(constants.CUSTOM_LAYER_ID) === getUid(this));
  };

  layer.getConnectorEnds = function () {
    const coordinates = connector.getGeometry().getCoordinates();
    return [coordinates[0], coordinates[coordinates.length - 1]];
  };

  layer.updateConnectorCoordinates = function (coordinates) {
    connector.getGeometry().setCoordinates(coordinates);
  };

  layer.move = function (deltaX, deltaY) {
    connector.getGeometry().translate(deltaX, deltaY);
  };

  layer.updateConnectorFirstCoordinate = function (firstCoordinate) {
    const coordinates = connector.getGeometry().getCoordinates();
    const nextCoordinates = [...coordinates];
    nextCoordinates.splice(0, 1, firstCoordinate);
    this.updateConnectorCoordinates(nextCoordinates);
  };

  layer.updateConnectorLastCoordinate = function (lastCoordinate) {
    const coordinates = connector.getGeometry().getCoordinates();
    const nextCoordinates = [...coordinates];
    nextCoordinates.splice(coordinates.length - 1, 1, lastCoordinate);
    this.updateConnectorCoordinates(nextCoordinates);
  };

  layer.finishConnectorChange = function (startPositioning) {
    const { connectedTarget } = this.options;

    if (connectedTarget) {
      const ends = this.getConnectorEnds();

      let lastCoordinate;
      if (connectedTarget.type === constants.CONNECTED_TARGET_TYPES.SAMPLE) {
        const { id } = connectedTarget;
        const sample = NAMESPACE.getGetter(store, 'getSampleByIdEx')(id);
        lastCoordinate = utils__.getSampleCoordinate(map, sample);
      } else if (
        connectedTarget.type === constants.CONNECTED_TARGET_TYPES.COORDINATE
      ) {
        lastCoordinate = utils__.latLngToCoordinate(
          connectedTarget.latLng,
          map.getView().getProjection()
        );
      } else {
        throw `Invalid connected target type: ${connectedTarget.type}`;
      }

      if (!equals(ends[1], lastCoordinate)) {
        this.updateConnectorLastCoordinate(lastCoordinate);
      }
    }

    const popup = this.getPopup();
    if (startPositioning !== popup.getPositioning()) {
      const ends = this.getConnectorEnds();
      this.updateConnectorCoordinates(ends);
    }
  };

  layer.intersectsCoordinate = function (coordinate) {
    return utils__.position.CheckIsPositionInHtmlElement(
      map,
      coordinate,
      this.getPopup().getElement()
    );
  };

  layer.setLayoutZoom = function (value) {
    setLayoutZoom(this.calloutMap, value);
  };

  layer.getBox = function () {
    const {
      connectedTarget: { id: sampleId },
    } = this.options;
    const popup = this.getPopup();
    const element = popup.getElement().parentElement;
    const cs = window.getComputedStyle(element);
    const marginTop = parseFloat(cs.marginTop);
    const marginLeft = parseFloat(cs.marginLeft);
    const { transform } = cs;
    const tRegExp =
      /matrix\(1,\s0,\s0,\s1,\s(-?(?:\d+\.?\d*|\.\d+)),\s(-?(?:\d+\.?\d*|\.\d+))\)/;
    const matchResult = transform.match(tRegExp);
    const x = parseFloat(matchResult[1]) + marginLeft;
    const y = parseFloat(matchResult[2]) - marginTop;
    return {
      id: sampleId,
      x,
      y,
      width: element.offsetWidth,
      height: element.offsetHeight,
    };
  };

  layer.updatePopupPosition = function (position, positioning) {
    connector.un('change', handleConnectorChange);
    this.updateConnectorFirstCoordinate(position);
    const popup = this.getPopup();
    const ends = this.getConnectorEnds();
    popup.setPosition(ends[0]);
    popup.setPositioning(positioning);
    this.refresh({ positioning });
    connector.on('change', handleConnectorChange);
  };

  return layer;
}

function calculateInitialTargetPosition(map, position) {
  const positionPx = map.getPixelFromCoordinate(position);
  return map.getCoordinateFromPixel([
    positionPx[0],
    positionPx[1] + constants.DEFAULT_CONNECTOR_LENGTH,
  ]);
}

function getTextStyle(options) {
  const {
    textColor,
    textOutlineColor,
    alignment,
    isItalic,
    isBold,
    isUnderlined,
    isBuiltin,
  } = options;

  return {
    color: textColor,
    textShadow: !isBuiltin ? createTextShadow(textOutlineColor) : 'none',
    textAlign: alignment === ALIGNMENT_CENTER ? 'center' : 'left',
    fontStyle: isItalic ? 'italic' : 'normal',
    fontWeight: isBold ? 'bold' : 'normal',
    textDecoration: isUnderlined ? 'underline' : 'none',
    textUnderlinePosition: 'under',
    padding: '0.2em',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  };
}

function applyStyleToTextNode(style, textNode) {
  Object.keys(style).forEach((item) => {
    textNode.style[item] = style[item];
  });
}

function createContent(map, layer, options) {
  const viewer = map.getViewer();
  const usage = getUsage(options);
  const {
    text,
    textColor,
    connectedTarget,
    isBuiltin,
    contentType,
    image_data,
    mapData,
    isTextUsedAsCaption,
  } = options;
  const container = document.createElement('div');
  const textStyle = getTextStyle(options);

  if (usage === Usage.ShowImage && image_data.url) {
    const imageNode = document.createElement('img');
    const { url, isFromGather } = image_data;
    const { project_id } = viewer.project;
    imageNode.src = !isFromGather
      ? getImageSrc(project_id, url)
      : getGatherImageSrc(project_id, url);
    imageNode.style.width = '100%';
    imageNode.draggable = false;
    imageNode.onerror = () => {
      imageNode.src = getImageFallbackSrc();
    };
    container.appendChild(imageNode);

    if (isTextUsedAsCaption) {
      imageNode.style.display = 'block';
      const captionNode = document.createElement('div');
      captionNode.innerHTML = text;
      applyStyleToTextNode(textStyle, captionNode);
      container.appendChild(captionNode);
    }
  } else if (usage === Usage.ShowText) {
    const { textShadow, fontWeight } = textStyle;

    if (!isBuiltin) {
      container.innerHTML = text ?? '';
    } else {
      const { id: sampleId } = connectedTarget;
      if (contentType === constants.BUILTIN_CALLOUT_CONTENT_TYPES.ENVIRO) {
        const sample = NAMESPACE.getGetter(store, 'getSampleById')(sampleId);
        container.innerHTML = utils.getEnviroTable(map, sample, {
          textColor,
          textShadow,
          fontWeight,
          backgroundColor: 'transparent',
        });
      } else if (
        contentType === constants.BUILTIN_CALLOUT_CONTENT_TYPES.GATHER
      ) {
        const sample = viewer.allVisibleGatherSamples.find(
          (item) => item.id === sampleId
        );
        container.innerHTML = utils.getGatherTable(map, sample, {
          textColor,
          textShadow,
          fontWeight,
        });
      }
    }

    container.style.width = '100%';
    container.style.height = '100%';
    applyStyleToTextNode(textStyle, container);
  } else if (usage === Usage.ShowMap && mapData) {
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.display = 'flex';
    container.style.flexDirection = 'column';

    const mapTarget = document.createElement('div');
    mapTarget.style.flexGrow = 1;
    container.appendChild(mapTarget);

    if (isTextUsedAsCaption) {
      const captionNode = document.createElement('div');
      captionNode.innerHTML = text;
      applyStyleToTextNode(textStyle, captionNode);
      container.appendChild(captionNode);
    }

    // The map won't render until its target is added to the DOM.
    setTimeout(() => {
      const calloutMap = maps.createCalloutMap(mapTarget, viewer, mapData);
      const view = calloutMap.getView();
      const handleZoomPanRotate = () => {
        const projection = view.getProjection();
        const center = view.getCenter();
        const centerLatLng = coordinateToLatLng(center, projection);
        const resolution = view.getResolution();
        const scale =
          resolutionToScale(projection, resolution, center) *
          viewer.figureLayout.zoom;
        const newMapData = {
          ...mapData,
          centerLatLng,
          scale,
        };
        dispatch('setShapeProperty', { mapData: newMapData });
      };
      view.on('change:center', handleZoomPanRotate);
      view.on('change:resolution', handleZoomPanRotate);
      view.on('change:rotation', handleZoomPanRotate);
      // This check is for stopping polluting the shape property.
      if (viewer.currentDrawingLayer === layer) {
        handleZoomPanRotate();
      }
      layer.calloutMap = calloutMap;
    });
  }

  return container;
}

function updatePopup(map, layer, popup, options, shouldRecreateContent = true) {
  const { zoom } = map.getViewer().figureLayout;
  const usage = getUsage(options);
  const {
    isBuiltin,
    text,
    image_data,
    mapData,
    fontSize,
    width,
    height,
    color,
    outlineStyle,
    weight,
    opacity,
    backgroundColor,
    backgroundOpacity,
  } = options;

  overlays.utils.offsetNoAnchor(popup);

  const popupElement = popup.getElement();
  popupElement.style.width = `${width * zoom}px`;
  if (usage === Usage.ShowMap) {
    popupElement.style.height = `${height * zoom}px`;
  } else {
    popupElement.style.height = 'auto';
  }
  popupElement.style.minHeight =
    !isBuiltin &&
    ((usage === Usage.ShowText && !text) ||
      (usage === Usage.ShowImage && !image_data.url) ||
      (usage === Usage.ShowMap && !mapData))
      ? '70px'
      : 0;
  const borderRadius = Math.round(5 * zoom);
  popupElement.style.borderColor = addOpacity(color, opacity);
  popupElement.style.borderWidth = `${weight}px`;
  popupElement.style.borderRadius = `${Math.round(5 * zoom)}px`;
  popupElement.style.borderStyle =
    outlineStyle + 1 === STROKE_PATTERN_DASH
      ? 'dashed'
      : outlineStyle + 1 === STROKE_PATTERN_DOT
      ? 'dotted'
      : 'solid';
  popupElement.style.fontSize = `${fontSize * zoom}px`;
  popupElement.style.overflow = 'hidden';
  popupElement.style.backgroundColor = styles.addOpacity(
    backgroundColor,
    backgroundOpacity
  );

  const { content } = popup;
  const child = content.children[0];
  if (child) {
    if (shouldRecreateContent) {
      content.removeChild(child);
      layer.calloutMap = undefined;
    } else {
      return;
    }
  }

  content.style.width = '100%';
  content.style.height = '100%';
  content.appendChild(createContent(map, layer, options));
}
