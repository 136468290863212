import axios from 'axios';
import { ReferenceDefault } from '../business-model/auto-assign';
import { InputValue, Item } from '../gather';
import { defineStore } from 'pinia';
import { GatherField } from '../gather';
import useIsGather from '../composables/useIsGather';
import useProjectId from '../composables/useProjectId';
import { usePublicFormToken } from '../composables/usePublicFormToken';

export const useLinkedFieldsStore = defineStore('linked-fields', () => {
  const isGather = useIsGather();
  const cachedValues = new Map<
    string,
    {
      input_values: InputValue[];
      item: Item;
      field: GatherField;
      timestamp: number;
    }
  >();

  type CallbackValueFunction = (value: any) => void;
  const activeRequests = new Map<string, CallbackValueFunction[]>();

  function clearCache() {
    cachedValues.clear();
  }

  function getLinkedFieldValue(
    referenceDefault: ReferenceDefault,
    inputValues: InputValue[],
    callback: CallbackValueFunction,
    sectionIndex: number = 0
  ): null | any {
    // find linked app item
    const { linkedFieldId, referenceAppFieldId } = referenceDefault;

    const inputValue = inputValues.find(
      (iv) =>
        iv.template_field_id === referenceAppFieldId &&
        iv.template_section_index === sectionIndex
    );

    if (!inputValue?.value) {
      callback(null);
      return null;
    }

    const cacheKey = linkedFieldId + '-' + inputValue?.value;

    // Check results map cache, must be no older than 10 minutes
    const cache = cachedValues.get(cacheKey);
    if (cache && cache.timestamp > Date.now() - 1000 * 60 * 10) {
      if (linkedFieldId === 'custom_title') {
        return callback(cache.item?.custom_title ?? null);
      }
      return callback(cache.input_values[0]?.value ?? null);
    }

    // De-duplicate requests, check active requests map
    if (activeRequests.has(cacheKey)) {
      activeRequests.get(cacheKey)?.push((data) => {
        parseDefaultsFromResponse(data, referenceDefault, callback);
      });
      return null;
    }

    const pendingRequest = axios.get(
      (isGather ? '/api' : '') +
        '/project/sample/values/by-field/' +
        linkedFieldId,
      {
        params: {
          item_id: inputValue?.value,
          project_id: useProjectId(),
          token: usePublicFormToken(),
        },
      }
    );

    pendingRequest.then((response) => {
      let callbacks = activeRequests.get(cacheKey);
      if (callbacks) {
        callbacks.forEach((cb) => cb(response.data));
      }
      cachedValues.set(cacheKey, {
        ...response.data,
        timestamp: Date.now(),
      });
      activeRequests.delete(cacheKey);
    });

    activeRequests.set(cacheKey, [
      (data) => {
        parseDefaultsFromResponse(data, referenceDefault, callback);
      },
    ]);

    return null;
  }

  function getLinkedAppTitle(itemId: number, callback?: CallbackValueFunction) {
    const cacheKey = 'custom_title' + '-' + itemId;

    const match = cachedValues.get(cacheKey);
    if (match) {
      if (callback) callback(match.item?.custom_title ?? null);
      return match.item?.custom_title ?? null;
    }

    if (activeRequests.has(cacheKey)) {
      if (callback) {
        activeRequests.get(cacheKey)?.push((data) => {
          callback(data.item?.custom_title ?? null);
        });
      }
      return null;
    }

    const pendingRequest = axios.get(
      (isGather ? '/api' : '') + '/project/sample/values/by-field/custom_title',
      {
        params: {
          item_id: itemId,
        },
      }
    );

    pendingRequest.then((response) => {
      let callbacks = activeRequests.get(cacheKey);
      if (callbacks) {
        callbacks.forEach((cb) => cb(response.data));
      }
      cachedValues.set(cacheKey, {
        ...response.data,
        timestamp: Date.now(),
      });
      activeRequests.delete(cacheKey);
    });

    activeRequests.set(
      cacheKey,
      callback
        ? [
            (data) => {
              callback(data.item?.custom_title ?? null);
            },
          ]
        : []
    );

    return null;
  }

  function parseDefaultsFromResponse(
    values: {
      input_values: InputValue[];
      item: Item;
      field: GatherField;
    },
    referenceDefault: ReferenceDefault,
    callback: CallbackValueFunction
  ) {
    if (typeof values === 'string') {
      throw new Error('Invalid api response');
    }

    if (referenceDefault.linkedFieldId === 'custom_title') {
      callback(values.item?.custom_title ?? null);
      return;
    }

    if (values.input_values.length) {
      callback(values.input_values[0]?.value ?? null);
      return;
    }
  }

  return {
    getLinkedFieldValue,
    getLinkedAppTitle,
    clearCache,
  };
});
