function onMounted(el, binding) {
  const clickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    binding.value(event);
  };

  const contextMenuHandler = (event) => {
    event.preventDefault();
    binding.value(event);
  };

  el.addEventListener('click', clickHandler);
  el.addEventListener('contextmenu', contextMenuHandler);

  el._clickHandler = clickHandler;
  el._contextMenuHandler = contextMenuHandler;
}

function beforeUnmount(el) {
  el.removeEventListener('click', el._clickHandler);
  el.removeEventListener('contextmenu', el._contextMenuHandler);

  delete el._clickHandler;
  delete el._contextMenuHandler;
}

const directive = {
  // Vue 3
  mounted: onMounted,
  beforeUnmount: beforeUnmount,
};

export default directive;
