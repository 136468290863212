import axios from 'axios';
import type { Ref } from 'vue';
import { ref, watchEffect } from 'vue';
import {
  FORM_CONTEXT_TYPE_DATA_MANAGER,
  FORM_CONTEXT_TYPE_GATHER,
  FORM_CONTEXT_TYPE_APP_PREVIEW,
  FORM_CONTEXT_TYPE_PUBLIC_FORM,
  type FormContextType,
} from '../business-model/common';
import type { Item } from '../gather';
import { captureException } from '../sentry';
import useProjectId from './useProjectId';
import { usePublicFormToken } from './usePublicFormToken';

export enum Context {
  Gather = 1,
  DataManager = 2,
  PublicForm = 3,
}

export function mapFormContextType(value: FormContextType): Context {
  switch (value) {
    case FORM_CONTEXT_TYPE_GATHER:
    case FORM_CONTEXT_TYPE_APP_PREVIEW:
      return Context.Gather;
    case FORM_CONTEXT_TYPE_DATA_MANAGER:
      return Context.DataManager;
    case FORM_CONTEXT_TYPE_PUBLIC_FORM:
      return Context.PublicForm;
    default:
      throw new Error(`Unhandled form context type: ${value}`);
  }
}

function getApiUrl(context: Context, projectId: number): string {
  switch (context) {
    case Context.Gather:
    case Context.PublicForm:
      return '/api/project/items-from-app-title?project_id=' + projectId;
    case Context.DataManager:
      return '/project/items-from-app-title?project_id=' + projectId;
  }
}

export default function useItemsFromAppTitle(
  projectId: number,
  context: Context,
  appTitle?: () => string | null,
  options?: () => {
    linkedItemId: number | null;
  }
): {
  isLoading: Ref<boolean>;
  items: Ref<Item[]>;
} {
  const isLoading = ref(false);
  const items = ref<Item[]>([]);

  watchEffect(async () => {
    if (!appTitle || (options && options().linkedItemId === null)) {
      isLoading.value = false;
      items.value = [];
      return;
    }

    const apiUrl = getApiUrl(context, projectId);
    isLoading.value = true;
    try {
      const {
        data: { items: _items },
      } = await axios.post(apiUrl, {
        appTitle: appTitle(),
        linked_item_id: options ? options().linkedItemId : null,
        token: usePublicFormToken(),
      });
      items.value = _items;
    } catch (e) {
      captureException(e);
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  });

  return {
    isLoading,
    items,
  };
}

export function useItemsCountFromAppTitle(
  context: Context,
  appTitle?: () => string | null,
  options?: () => {
    linkedItemId: number | string | null;
  }
): {
  isLoading: Ref<boolean>;
  count: Ref<number>;
} {
  const isLoading = ref(false);
  const count = ref(0);
  const projectId = useProjectId();

  watchEffect(async () => {
    if (!appTitle || (options && options().linkedItemId === null)) {
      isLoading.value = false;
      count.value = 0;
      return;
    }

    const apiUrl = getApiUrl(context, projectId);
    isLoading.value = true;
    try {
      const {
        data: { count: _count },
      } = await axios.post(apiUrl, {
        appTitle: appTitle(),
        linked_item_id: options ? options().linkedItemId : null,
        is_for_count: true,
      });
      count.value = _count;
    } catch (e) {
      captureException(e);
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  });

  return {
    isLoading,
    count,
  };
}
