import heatmap from './heatmap';
import buffer from './buffer';
import { MODULE_NAMES } from './helpers';
import { ACTION_TYPES as HEATMAP_ACTION_TYPES } from '../../business-logic/analysis/buffer';
import { ACTION_TYPES as BUFFER_ACTION_TYPES } from '../../business-logic/analysis/buffer';

export default {
  state: () => ({
    loaders: {
      [MODULE_NAMES.HEATMAP]: {
        [HEATMAP_ACTION_TYPES.GENERIC]: false,
        [HEATMAP_ACTION_TYPES.ANALYZE_SAMPLES]: false,
        [HEATMAP_ACTION_TYPES.CREATE]: false,
      },
      [MODULE_NAMES.BUFFER]: {
        [BUFFER_ACTION_TYPES.GENERIC]: false,
      },
    },
  }),
  mutations: {
    updateLoaders(state, { moduleName, actionType, isLoading }) {
      state.loaders = {
        ...state.loaders,
        [moduleName]: {
          ...state.loaders[moduleName],
          [actionType]: isLoading,
        },
      };
    },
  },
  actions: {
    updateLoaders(context, { moduleName, actionType, isLoading }) {
      context.commit('updateLoaders', { moduleName, actionType, isLoading });
    },
  },
  modules: {
    [MODULE_NAMES.HEATMAP]: {
      namespaced: true,
      ...heatmap,
    },
    [MODULE_NAMES.BUFFER]: {
      namespaced: true,
      ...buffer,
    },
  },
};
