<template>
  <div class="w-100 h-100">
    <iframe
      width="100%"
      :height="getBlockHeight"
      :src="block.file_url"
      frameborder="0"
    />

    <div v-if="!isSlide" class="caption mt-3">
      <caption-text
        :block="block"
        placeholder="Add a caption for this iframe (optional)"
        @update="(data) => $emit('update', data)"
      />
    </div>
  </div>
</template>
<script>
import CaptionText from './Text.vue';

export default {
  props: {
    block: Object,
    isSlide: Boolean,
  },
  components: { CaptionText },
  computed: {
    getBlockHeight() {
      return (this.block?.settings?.height || 525) + 'px';
    },
  },
};
</script>
<style lang="scss" scoped>
.slideshow__figure {
  iframe {
    height: 100%;
  }
}
</style>
