import { inject, provide, ref } from 'vue';

import _isEqual from 'lodash/isEqual';

import store from '@/js/store';
import { useToastStore } from '@component-library/store/toasts';
import { isProd } from '@component-library/utils/is-prod';

import { NAMESPACE } from './store';
import * as actions from './store/actions';

type GetViewer = () => any;
const getViewerSymbol = Symbol('getViewer');
// A viewer container is a component that renders the map viewer inside.
// The MapEditor, IFrameMapViewer, ActionMapViewer are viewer containers.
// $refs is not reative (https://stackoverflow.com/questions/54927707/why-does-the-vue-documentation-say-refs-are-not-reactive-when-indeed-they-are)
// Accessing the _isReady variable makes getViewer method reactive.
export function provideGetViewer(viewerContainer) {
  const _isReady = ref(false);
  provide(getViewerSymbol, () => {
    _isReady.value = _isReady.value;
    return viewerContainer.$refs.viewer;
  });
  return () => {
    _isReady.value = true;
  };
}

export function injectGetViewer(): GetViewer | undefined {
  return inject(getViewerSymbol, undefined);
}

export enum AttributesTabMode {
  VIEWING = 1,
  EDITING,
}

export function createTextShadow(color) {
  return `
    2px 0 0 ${color},
    -2px 0 0 ${color},
    0 2px 0 ${color},
    0 -2px 0 ${color},
    1px 1px ${color},
    -1px -1px 0 ${color},
    1px -1px 0 ${color},
    -1px 1px 0 ${color}
  `;
}

export function getState(name: string, namespace = NAMESPACE) {
  return namespace.getState(store, name);
}

export function getGetter(name: string, namespace = NAMESPACE) {
  return namespace.getGetter(store, name);
}

export async function dispatch<P>(
  actionName: string,
  payload: P,
  namespace = NAMESPACE
) {
  return await namespace.dispatch(store, actionName, payload);
}

export function checkIsProduction() {
  return isProd;
}

export function alertSomethingWentWrong() {
  const toastStore = useToastStore();
  toastStore.error('Something went wrong, please try it again later.');
}

// A list of store actions which should not cause an update to the layers tree
const DEFAULT_LAYERS_IRRELEVANT_MUTATIONS = Object.keys(actions).reduce(
  (accu, ak) => {
    accu[`maps/${ak}`] = true;
    return accu;
  },
  {}
);
const LAYERS_IRRELEVANT_MUTATIONS = {
  ...DEFAULT_LAYERS_IRRELEVANT_MUTATIONS,
  'maps/modifyFigure': (action) => {
    const { payload } = action;
    const keys = Object.keys(payload);
    // The figure modification is caused by zooming/panning/roation on the map.
    return !keys.some(
      (k) =>
        ![
          'id',
          'view_size',
          'view_orientation',
          'view_layout_id',
          'view_lat',
          'view_lng',
          'view_zoom',
          'view_scale',
          'view_rotation',
        ].includes(k)
    );
  },
  'maps/setHasTooManySamplesInViewportBySampleGroupId': (action, state) => {
    return _isEqual(
      action.payload,
      state.maps.hasTooManySamplesInViewportBySampleGroupId
    );
  },
  'maps/addNewSample': (action, state) => {
    return state.maps.isLoadingSamples;
  },
  'maps/replaceSample': (action, state) => {
    return state.maps.isLoadingSamples;
  },
  'maps/addNewLayer': false,
  'maps/removeLayer': false,
  'maps/updateIndividualLayer': (action, state) => {
    return state.maps.isSearchingLayers;
  },
  'maps/updateLayerTextById': false,
  'maps/setLayerSearchQuery': false,
  SET_FULL_SCREEN_LOADER: true,
  'gather/SET_FILTERS': true,
};

const LAYERS_IRRELEVANT_MUTATIONS_KEYS = Object.keys(
  LAYERS_IRRELEVANT_MUTATIONS
);

export function checkIsLayersIrrelevantMutation(action, state) {
  if (!LAYERS_IRRELEVANT_MUTATIONS_KEYS.includes(action.type)) {
    return false;
  }

  const config = LAYERS_IRRELEVANT_MUTATIONS[action.type];
  if (typeof config === 'boolean') {
    return config;
  } else if (typeof config === 'function') {
    return config(action, state);
  }

  return false;
}
