<template>
  <div v-click-outside="() => (opened = false)" class="position-relative">
    <div class="clickable setting__toggle" @click.stop="togglePopup">
      <i class="fas fa-ellipsis-h" />
    </div>

    <transition name="dropdown">
      <div v-show="opened" class="dropdown-menu animated__dropdown">
        <div
          class="dropdown-item"
          @click.stop="
            closePopup();
            $emit('duplicate');
          "
        >
          <i class="fal fa-fw fa-copy" />
          <small>Duplicate</small>
        </div>

        <div
          class="dropdown-item text-danger"
          @click.stop="
            closePopup();
            $emit('delete');
          "
        >
          <i class="fal fa-fw fa-trash-alt" />
          <small>Delete</small>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions } from 'vuex';



export default {
  props: {
    block: Object,
  },
  data: () => ({
    opened: false,
  }),
  
  methods: {
    togglePopup() {
      this.opened = !this.opened;
    },
    closePopup() {
      this.opened = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-menu {
  min-width: auto;
  top: -100px;
  left: 0px;
  min-width: 125px;
}

.animated__dropdown {
  left: unset !important;
}

.setting__toggle {
  opacity: 0.8;
}

.setting__toggle:hover {
  opacity: 1;
}
</style>
