import EventBus from '@component-library/EventBus';
import { Control } from 'ol/control';

export default class NorthArrow extends Control {
  constructor(options = {}) {
    const {
      northArrowType = 0,
      width = 32,
      rotation = 0,
      clickable = true,
      nonClickableTitle = '',
      addClass = true,
    } = options;

    const element = document.createElement('div');
    if (addClass) {
      element.className = 'north-arrow';
    }
    options.element = element;

    const contentEl = document.createElement('div');
    contentEl.innerHTML = `<img src="/images/map_icons/north_arrows/${northArrowType}.svg" width="${width}px">`;
    contentEl.className = clickable ? ' clickable' : '';
    contentEl.style.transformOrigin = 'center';
    contentEl.style.transform = `rotate(${rotation}deg)`;
    if (clickable) {
      contentEl.setAttribute('title', 'Click to reset the map rotation.');
      contentEl.addEventListener('click', () => {
        EventBus.$emit('setMapRotation', 0);
      });
    } else {
      contentEl.setAttribute('title', nonClickableTitle);
    }
    element.appendChild(contentEl);

    super({ ...options, element });
  }
}
