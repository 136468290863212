import { defineStore } from 'pinia';
import axios from 'axios';
import { computed, onMounted, ref, watch } from 'vue';
import { Company } from '@component-library/company';
import useAuth from '@component-library/composables/useAuth';
import {
  CriteriaType,
  ProjectScenario,
  ScenarioDocumentStyle,
  ScenarioSetWithStyles,
  ScenarioStyle,
} from '@component-library/enviro';
import { useProjectStore } from '@component-library/store/project';
import {
  getDocumentTitle,
  getFormattedTitle,
  getStylingPropertiesByMethod,
} from '@/js/helpers/scenario.js';
import { checkHydrocarbonSettings } from './useEnviroScenarioManager';
import { EditableBlockType } from '@component-library/enviro-table-footer-blocks';
import { useStore } from '@/js/store';

export type ScenarioStylingRequest = object;

const useScenarioStyling = defineStore('scenario-styling', () => {
  const scenarioSet = ref<ScenarioSetWithStyles | null>(null);
  const projectStore = useProjectStore();
  const store = useStore();
  const auth = useAuth();
  const isBlockedLoadingSet = ref(false);

  const projectCountry = computed(() => {
    return projectStore.project?.location?.country as any;
  });

  const company = computed(() => {
    return auth.user().company as Company;
  });

  const projectScenarioGroupId = computed(() => {
    return (store.state as any).dashboard?.processed_settings?.group_id;
  });

  watch(
    () => projectScenarioGroupId.value,
    async () => {
      loadScenarioSet();
    }
  );

  const getScenarioStyle = (
    scenario: ProjectScenario
  ): ScenarioStyle | ScenarioDocumentStyle | null => {
    if (!scenarioSet.value) {
      return null;
    }

    const styles = scenarioSet.value.styles as ScenarioStyle[];

    let style: ScenarioStyle | null = null;
    if (scenario.criteria_type === CriteriaType.Landuse) {
      style =
        styles.find(
          (s) =>
            s.scenario_id == scenario.scenario_id && s.factor == scenario.factor
        ) || null;
    }

    if (scenario.criteria_type === CriteriaType.Criteria) {
      style =
        styles.find((s) => s.criteria_set_id == scenario.criteria_set_id) ||
        null;
    }

    // if styling by standard rather than landuse
    if (style && scenario.document) {
      const { short_url } = scenario.document;
      if (!short_url) {
        return null;
      }

      const documentStyle = style.documents.find(
        (d) =>
          d.short_url == short_url &&
          checkHydrocarbonSettings(
            scenario.hydrocarbon_settings,
            d.hydrocarbon_settings
          )
      );

      if (!documentStyle) {
        return null;
      }
      return {
        ...(documentStyle || {}),
        title: documentStyle?.title || null,
      };
    }

    return style;
  };

  const getScenarioStylingProperties = (
    scenario: ProjectScenario
  ): Record<string, string> => {
    const scenarioStyle = getScenarioStyle(scenario);
    return getStylingPropertiesByMethod(scenarioStyle) as Record<
      string,
      string
    >;
  };

  const getScenarioTitle = (
    scenario: ProjectScenario,
    showDocument = true,
    showHydrocarbonSettings = true,
    showFactor = true
  ) => {
    const isLanduseScenario = scenario.criteria_type === CriteriaType.Landuse;

    showDocument =
      showDocument &&
      isLanduseScenario &&
      scenario?.document?.country !== 'CA' &&
      !scenario?.document?.company_id;

    let scenarioTitle = '';

    if (showDocument && scenario.document) {
      scenarioTitle = getDocumentTitle(scenario.document, true) + ' ';
    }

    scenarioTitle +=
      getScenarioStyle(scenario)?.title ||
      getFormattedTitle(
        scenario.scenario || scenario.criteria,
        scenario.document,
        company.value.is_display_enviro_basis_on,
        scenario.criteria_type,
        scenario.factor,
        projectCountry.value,
        showFactor,
        scenario.options as any,
        showHydrocarbonSettings ? (scenario.hydrocarbon_settings as any) : null
      );

    return scenarioTitle;
  };

  const createScenarioSet = async () => {
    const { data } = await axios.post('/dashboard/scenario', {
      set: {
        title: 'Untitled Set',
      },
    });

    return data.set_id;
  };

  const updateScenarioSet = async (
    updatedFields: Partial<ScenarioSetWithStyles>
  ) => {
    let setId = scenarioSet.value?.id;
    if (!setId) {
      setId = await createScenarioSet();
    }

    await axios.put(`/dashboard/scenario/${setId}`, {
      set: updatedFields,
    });

    scenarioSet.value = {
      ...scenarioSet.value,
      ...updatedFields,
    } as ScenarioSetWithStyles;
  };

  const updateScenarioStyling = async (
    scenario: ProjectScenario,
    styling: ScenarioStylingRequest
  ) => {
    let setId = scenarioSet.value?.id;
    if (!setId) {
      setId = await createScenarioSet();
    }

    const { data } = await axios.put(
      `/dashboard/scenario/${setId}/scenario-styling`,
      {
        scenario: {
          ...styling,
          ...scenario,
        },
      }
    );

    scenarioSet.value = {
      ...scenarioSet.value,
      ...data.set,
    };
  };

  const hasVisibleFooterBlock = (blockType: EditableBlockType) => {
    const footerSettings = scenarioSet.value?.footer_settings;
    return footerSettings ? footerSettings[blockType].is_visible : true;
  };

  const loadScenarioSet = async () => {
    if (
      isBlockedLoadingSet.value ||
      projectScenarioGroupId.value === undefined
    ) {
      return;
    }

    try {
      const { data } = await axios.get('/dashboard/scenario/get-set', {
        params: {
          group_id: projectScenarioGroupId.value,
        },
      });

      scenarioSet.value = data.scenario_set;
    } catch (e) {
      console.warn('Suppressing error', e);
      scenarioSet.value = null;
    }
  };

  onMounted(loadScenarioSet);

  return {
    scenarioSet: computed<ScenarioSetWithStyles | null>(
      () => scenarioSet.value
    ),
    setScenarioSet: (set: ScenarioSetWithStyles) => (scenarioSet.value = set),
    loadScenarioSet,
    templateClass: computed(
      () => scenarioSet.value?.table_style_key || 'default-styling'
    ),
    orientation: computed(() =>
      scenarioSet.value?.is_horizontal ? 'horizontal' : 'vertical'
    ),
    getScenarioStyle,
    getScenarioStylingProperties,
    getScenarioTitle,
    updateScenarioSet,
    updateScenarioStyling,
    hasVisibleFooterBlock,
    isBlockedLoadingSet,
  };
});

export default useScenarioStyling;
