import _cloneDeep from 'lodash/cloneDeep';
import { Map } from 'ol';
import { stylefunction as _styleVectorTileLayer } from 'ol-mapbox-style';
import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { transformExtent } from 'ol/proj';
import { VectorTile as VectorTileSource } from 'ol/source';
import TileGrid from 'ol/tilegrid/TileGrid';
import { Code, throwError } from '../../../common/error';
import { proxify } from '../../../common/network';
import { getStoreApi } from '../../../common/store-api';
import { getLayoutZoom } from '../../../measurement/layout';
import { patchTileGrid } from '../../../measurement/scale';
import type { LayerUsage } from '../../constants';
import { LayerType } from '../../types';
import { createLayerProperties, getModel, getVisibleExtent } from '../../utils';
import enableLoadingEvents from '../enableLoadingEvents';
import type { GetVectorTileServerUrls } from './types';
import {
  VectorTileServerFolderLayerModel,
  VectorTileServerItemLayerModel,
  VectorTileServerLayer,
} from './types';
import { getVectorTileServerUrlsDefault } from './utils';

const glStyleByUrl = {};

function styleVectorTileLayer(map: Map, layer, glStyle) {
  const storeApi = getStoreApi(map);
  const layerModel = getModel(
    storeApi,
    layer
  ) as VectorTileServerFolderLayerModel;
  const glStyleCopy = _cloneDeep(glStyle);
  const { sources } = glStyleCopy;
  const source = Object.keys(sources).find(
    (key) => sources[key].type === 'vector'
  )!;
  const layoutZoom = getLayoutZoom(map);
  try {
    glStyleCopy.layers = glStyleCopy.layers.map((glStyleLayer) => {
      let result = glStyleLayer;

      if (result.type === 'line') {
        let lineDasharray = result.paint['line-dasharray'];
        if (Array.isArray(lineDasharray)) {
          lineDasharray = lineDasharray.map((value) => value * layoutZoom);
        }

        let lineWidth = result.paint['line-width'];
        if (typeof lineWidth === 'number') {
          lineWidth *= layoutZoom;
        } else if (
          lineWidth &&
          typeof lineWidth === 'object' &&
          'stops' in lineWidth
        ) {
          lineWidth = {
            ...lineWidth,
            stops: lineWidth.stops.map((stop) => [
              stop[0],
              stop[1] * layoutZoom,
            ]),
          };
        }

        result = {
          ...result,
          paint: {
            ...result.paint,
            'line-dasharray': lineDasharray,
            'line-width': lineWidth,
          },
        };
      } else if (result.type === 'symbol') {
        let textSize = result.layout['text-size'];
        if (typeof textSize === 'number') {
          textSize *= layoutZoom;
        }

        let textLetterSpacing = result.layout['text-letter-spacing'];
        if (typeof textLetterSpacing === 'number') {
          textLetterSpacing *= layoutZoom;
        }

        let textHaloWidth = result.paint['text-halo-width'];
        if (typeof textHaloWidth === 'number') {
          textHaloWidth *= layoutZoom;
        }

        result = {
          ...result,
          layout: {
            ...result.layout,
            'text-size': textSize,
            'text-letter-spacing': textLetterSpacing,
          },
          paint: {
            ...result.paint,
            'text-halo-width': textHaloWidth,
          },
        };
      }

      if (
        layerModel?.geojson.properties.shouldIgnoreLayerStyleRestrictions ??
        true
      ) {
        delete result.maxzoom;
        delete result.minzoom;
      }

      return result;
    });
    _styleVectorTileLayer(layer, glStyleCopy, source);
  } catch (e) {
    console.error(e);
    throwError(
      Code.StyleVectorTileServerLayerFailed,
      layerModel?.geojson.properties.url
    );
  }
}

export default function createVectorTileServerLayer(
  map: Map,
  folderModel: VectorTileServerFolderLayerModel,
  itemModel: VectorTileServerItemLayerModel,
  getVectorTileServerUrls: GetVectorTileServerUrls = getVectorTileServerUrlsDefault,
  layerType: LayerType = LayerType.BASEMAP_SERVICE,
  layerUsage?: LayerUsage
): VectorTileServerLayer {
  const { attributions, shouldUseCorsProxy } = folderModel.geojson.properties;
  const { origin, tileSize, maxLOD, projection } = itemModel.geojson.properties;

  let { tileUrl, styleUrl } = getVectorTileServerUrls(folderModel, itemModel);
  if (shouldUseCorsProxy) {
    tileUrl = proxify(tileUrl);
    styleUrl = proxify(styleUrl);
  }

  const viewProjection = map.getView().getProjection();
  const visibleExtent = getVisibleExtent(folderModel, viewProjection);

  let { resolutions } = itemModel.geojson.properties;
  if (typeof maxLOD === 'number') {
    resolutions = resolutions.filter((r, index) => index <= maxLOD);
  }

  const tileGrid = new TileGrid({
    extent: transformExtent(visibleExtent, viewProjection, projection),
    origin,
    tileSize,
    resolutions,
  });
  patchTileGrid(map, tileGrid, projection);

  const layerSource = new VectorTileSource({
    attributions,
    format: new MVT(),
    url: tileUrl,
    projection,
    extent: visibleExtent,
    tileGrid,
  });
  const layer = new VectorTileLayer({
    source: layerSource,
    extent: visibleExtent,
    properties: createLayerProperties(itemModel.id, layerType, layerUsage),
  }) as VectorTileServerLayer;

  layer.getFirstFeature = function () {
    throwError(Code.MethodNotImplemented, 'getFirstFeature');
  };

  layer.checkHasFeature = function (feature) {
    const extent = map.getView().calculateExtent();
    return this.getFeaturesInExtent(extent).indexOf(feature) !== -1;
  };

  layer.toGeoJSON = function () {
    throwError(Code.MethodNotImplemented, 'toGeoJSON');
  };

  layer.getBounds = function () {
    throwError(Code.MethodNotImplemented, 'getBounds');
  };

  layer.refresh = function () {
    styleVectorTileLayer(map, layer, glStyleByUrl[styleUrl]);
  };

  enableLoadingEvents(layer);

  const glStyle = glStyleByUrl[styleUrl];
  if (glStyle) {
    styleVectorTileLayer(map, layer, glStyle);
  } else {
    fetch(styleUrl)
      .then(function (response) {
        return response.json();
      })
      .then(function (glStyle) {
        glStyleByUrl[styleUrl] = glStyle;
        styleVectorTileLayer(map, layer, glStyle);
      });
  }

  return layer;
}
