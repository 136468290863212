import { Map } from 'ol';
import { WMTSCapabilities } from 'ol/format';
import { Tile as TileLayer } from 'ol/layer';
import { get as getProjection, transformExtent } from 'ol/proj';
import { WMTS } from 'ol/source';
import { default as WMTSTileGrid } from 'ol/tilegrid/WMTS';
import { Code, throwError } from '../../../common/error';
import { WGS84_WORLD_EXTENT, mergeExtents } from '../../../common/extent';
import { createRequestOptions, proxify } from '../../../common/network';
import { patchTileGrid } from '../../../measurement/scale';
import { Dpi } from '../../../measurement/types';
import type { LayerUsage } from '../../constants';
import { LayerType } from '../../types';
import { createLayerProperties, getVisibleExtent } from '../../utils';
import enableLoadingEvents from '../enableLoadingEvents';
import {
  AxisOrientation,
  WmtsFolderLayerModel,
  WmtsItemLayerModel,
  WmtsLayer,
} from './types';

declare const axios: any;

function handleCoord(coord, axisOrientation: AxisOrientation) {
  return axisOrientation === AxisOrientation.NEU ? [coord[1], coord[0]] : coord;
}

function getWgs84BoundingBox(layer) {
  const { WGS84BoundingBox } = layer;
  return WGS84BoundingBox ?? WGS84_WORLD_EXTENT;
}

export async function getServiceData(
  serviceUrl,
  shouldUseCorsProxy,
  axisOrientation
) {
  const url = !shouldUseCorsProxy ? serviceUrl : proxify(serviceUrl);
  const { data } = await axios.get(url, createRequestOptions());
  const parser = new WMTSCapabilities();
  const wmtsCapabilities = parser.read(data);

  const {
    version,
    ServiceIdentification: { Title: title },
    Contents: { Layer: wmtsLayers, TileMatrixSet: wmtsTileMatrixSets },
  } = wmtsCapabilities;

  const layers: any[] = [];
  wmtsLayers.forEach((item) => {
    let format;
    let url;

    const { ResourceURL } = item;
    if (ResourceURL) {
      const resourceUrl = item.ResourceURL.find(
        (item2) => item2.resourceType === 'tile'
      );
      format = resourceUrl.format;
      url = resourceUrl.template;
    } else if (
      wmtsCapabilities.OperationsMetadata?.GetTile?.DCP?.HTTP?.Get[0]?.href
    ) {
      format = item.Format[0];
      url = wmtsCapabilities.OperationsMetadata.GetTile.DCP.HTTP.Get[0].href;
    } else {
      format = item.Format[0];
      url = serviceUrl.split('?')[0].split('#')[0];
    }

    const requestEncoding = /\{(\w+?)\}/g.test(url) ? 'REST' : 'KVP';

    const { TileMatrixSet: matrixSet } = item.TileMatrixSetLink[0];
    const wmtsTileMatrixSet = wmtsTileMatrixSets.find(
      (item2) => item2.Identifier === matrixSet
    );

    let projection;
    const { SupportedCRS } = wmtsTileMatrixSet;
    try {
      projection = getProjection(wmtsTileMatrixSet.SupportedCRS);
      if (!projection) {
        throw `The layer ${item.Title} is dropped because it uses the unsupported projection ${SupportedCRS}.`;
      }
    } catch (e) {
      // The projection is not supported.
      console.warn(e);
      return;
    }

    const tileMatrixSetData = wmtsTileMatrixSet.TileMatrix.map((item2) => {
      return {
        id: item2.Identifier,
        // How to get the resolution from the ScaleDenominator:
        // https://github.com/tschaub/tilet/blob/main/readme.md
        resolution:
          (item2.ScaleDenominator * 0.00028) / projection.getMetersPerUnit(),
        origin: handleCoord(item2.TopLeftCorner, axisOrientation),
        matrixWidth: item2.MatrixWidth,
        matrixHeight: item2.MatrixHeight,
        tileWidth: item2.TileWidth,
        tileHeight: item2.TileHeight,
      };
    }).sort((item1, item2) => parseInt(item1.id, 10) - parseInt(item2.id, 10));

    const { Identifier: style } =
      item.Style.find((item) => item.isDefault) ?? item.Style[0];

    const matrixIds: any[] = [];
    const resolutions: any[] = [];
    const origins: any[] = [];
    const sizes: any[] = [];
    const tileSizes: any[] = [];
    tileMatrixSetData.forEach((item2) => {
      const {
        id,
        resolution,
        origin,
        matrixWidth,
        matrixHeight,
        tileWidth,
        tileHeight,
      } = item2;
      matrixIds.push(id);
      resolutions.push(resolution);
      origins.push(origin);
      sizes.push([matrixWidth, matrixHeight]);
      tileSizes.push([tileWidth, tileHeight]);
    });
    layers.push({
      id: item.Identifier,
      name: item.Title,
      extent: getWgs84BoundingBox(item),
      format,
      url,
      matrixSet,
      matrixIds,
      resolutions,
      origins,
      sizes,
      tileSizes,
      parentLayerId: -1,
      subLayerIds: null,
      style,
      requestEncoding,
      projection: projection.getCode(),
    });
  });

  const extents = layers.map((item) => item.extent);
  const fullExtent = mergeExtents(...extents);

  return {
    // TODO Research whether we can get attributions from the capabilities data.
    attributions: '',
    version,
    title,
    description: title,
    layers,
    fullExtent,
  };
}

export default function createLayer(
  map: Map,
  folderModel: WmtsFolderLayerModel,
  itemModel: WmtsItemLayerModel,
  layerType: LayerType = LayerType.BASEMAP_SERVICE,
  layerUsage?: LayerUsage
): WmtsLayer {
  const { attributions, version, shouldUseCorsProxy } =
    folderModel.geojson.properties.service;
  const {
    id,
    format,
    matrixSet,
    matrixIds,
    resolutions,
    origins,
    sizes,
    tileSizes,
    style,
    requestEncoding = 'REST',
    projection = 'EPSG:3857',
  } = itemModel.geojson.properties;
  let { url } = itemModel.geojson.properties;
  if (shouldUseCorsProxy) {
    url = proxify(url);
  }

  const viewProjection = map.getView().getProjection();
  const visibleExtent = getVisibleExtent(folderModel, viewProjection);

  const tileGrid = new WMTSTileGrid({
    extent: transformExtent(visibleExtent, viewProjection, projection),
    origins,
    resolutions,
    matrixIds,
    sizes,
    tileSizes,
  });

  if (map !== null) {
    patchTileGrid(map, tileGrid, projection, Dpi.WMTS);
  }

  const layerSource = new WMTS({
    attributions,
    crossOrigin: 'anonymous',
    version,
    layer: id,
    url,
    requestEncoding,
    style,
    matrixSet,
    format,
    tileGrid,
    wrapX: true,
    projection,
  });
  const layer = new TileLayer({
    source: layerSource,
    extent: visibleExtent,
    properties: createLayerProperties(itemModel.id, layerType, layerUsage),
  }) as WmtsLayer;

  layer.getFirstFeature = function () {
    throwError(Code.MethodNotImplemented, 'getFirstFeature');
  };

  layer.checkHasFeature = function () {
    throwError(Code.MethodNotImplemented, 'checkHasFeature');
  };

  layer.toGeoJSON = function () {
    throwError(Code.MethodNotImplemented, 'toGeoJSON');
  };

  layer.getBounds = function () {
    throwError(Code.MethodNotImplemented, 'getBounds');
  };

  layer.refresh = function () { };

  enableLoadingEvents(layer);

  return layer;
}
