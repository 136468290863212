import { createApp, defineComponent, h } from 'vue';
import Spinner from '@component-library/components/Spinner.vue';
import { Control } from 'ol/control';
import { MapType } from '../../../lib/olbm/common/types';

export default class LoadingSpinner extends Control {
  constructor(options) {
    const element = document.createElement('div');
    element.setAttribute('class', 'ol-control p-1 map-spinner');

    super({ ...options, element });

    this._renderSpinner();
  }
  _renderSpinner() {
    let size = 1.2;
    const map = this.getMap();
    // @ts-expect-error - @Matt why is there no type for this?
    if (map && map.getType() !== MapType.PARCEL_EDITOR) {
      // @ts-expect-error - @Matt why is there no type for this?
      size *= map.getViewer().figureLayout.zoom;
    }

    this.element.innerHTML = '';

    const SpinnerApp = defineComponent({
      render() {
        return h(Spinner, { size: `${size}rem` });
      },
    });

    const app = createApp(SpinnerApp);
    const container = document.createElement('div');
    this.element.appendChild(container);
    app.mount(container);
  }
  refresh() {
    this._renderSpinner();
  }
}
