import { Map } from 'ol';
import { permissions } from '../../adapter/uac';
import { Country } from '../../common/address';
import { Code, throwError } from '../../common/error';
import { getStoreApi } from '../../common/store-api';
import {
  INTEGRATION_NAMES,
  IntegrationId,
  findIntegrationById,
} from '../service/integration';
import type { Layer } from '../types';
import { LayerType } from '../types';
import { getType } from '../utils';
import * as basemapConfig from './config';
import type { BasemapApi } from './types';
import { BasemapId, BasemapStatus } from './types';

export function getBasemapLayerTypes(): LayerType[] {
  return [LayerType.BASEMAP_SERVICE, LayerType.BASEMAP_IMAGE];
}

export function checkIsBasemapLayer(layer: Layer): boolean {
  const type = getType(layer);
  return getBasemapLayerTypes().includes(type);
}

/** @deprecated always false! */
export function checkIsBasemapLayerSplitAvailable(): boolean {
  return false;
}

export function findBasemapApiById(id: BasemapId): BasemapApi | undefined {
  return Object.values(basemapConfig).find(
    (basemapApi) => basemapApi.id === id
  );
}

export function getAvailableBasemapApis(
  country: Country,
  state?: string
): BasemapApi[] {
  return Object.values(basemapConfig)
    .filter((basemapApi) => {
      const isCountryMatched =
        !basemapApi.countries || basemapApi.countries.includes(country);

      if (!isCountryMatched) {
        return false;
      }

      let isStateMatched = false;
      if (!basemapApi.states || !state) {
        isStateMatched = true;
      } else {
        const lowercasedState = state.toLowerCase();
        isStateMatched = !!basemapApi.states.find(
          ([fullName, acronym]) =>
            fullName.toLowerCase() === lowercasedState ||
            acronym.toLowerCase() === lowercasedState
        );
      }

      return isStateMatched;
    })
    .map((basemapApi) => ({
      ...basemapApi,
      subtitle: basemapApi.subtitle.replace('{COUNTRY}', country),
    }))
    .sort((basemapApi1, basemapApi2) => basemapApi1.id - basemapApi2.id);
}

export function getBasemapStatus(
  map: Map,
  basemapId: BasemapId
): BasemapStatus {
  const { NEARMAP, METROMAP } = BasemapId;
  const storeApi = getStoreApi(map);
  const integrations = storeApi.getIntegrations();
  const configs = {
    [NEARMAP]: {
      apiKey: findIntegrationById(integrations, IntegrationId.NEARMAP)?.apiKey,
      permission: permissions.FORBIDDEN_ACCESS_MAPS_NEARMAP,
    },
    [METROMAP]: {
      apiKey: findIntegrationById(integrations, IntegrationId.METROMAP)?.apiKey,
      permission: permissions.FORBIDDEN_ACCESS_MAPS_METROMAP,
    },
  };
  const config = configs[basemapId];
  if (config && config.apiKey === undefined) {
    return BasemapStatus.IntegrationNotReady;
  }
  if (config && config.apiKey === null) {
    return BasemapStatus.IntegrationDisabled;
  }
  if (config && storeApi.checkHasPermission(config.permission)) {
    return BasemapStatus.AccessForbidden;
  }

  return BasemapStatus.Available;
}

export function inspectBasemap(map: Map, basemapId: BasemapId) {
  const status = getBasemapStatus(map, basemapId);
  if (
    [
      BasemapStatus.IntegrationNotReady,
      BasemapStatus.IntegrationDisabled,
    ].includes(status)
  ) {
    let integrationId;
    if (basemapId === BasemapId.NEARMAP) {
      integrationId = IntegrationId.NEARMAP;
    } else if (basemapId === BasemapId.METROMAP) {
      integrationId = IntegrationId.METROMAP;
    }
    const integrationName = INTEGRATION_NAMES[integrationId];
    if (status === BasemapStatus.IntegrationNotReady) {
      throwError(Code.IntegrationNotReady, integrationName);
    } else if (status === BasemapStatus.IntegrationDisabled) {
      throwError(Code.IntegrationDisabled, integrationName);
    }
  } else if (status === BasemapStatus.AccessForbidden) {
    const basemapApi = findBasemapApiById(basemapId);
    throwError(Code.AccessForbidden, basemapApi!.title);
  }
}

export function checkIsBasemapWarningVisible(basemapId: BasemapId): boolean {
  return [BasemapId.NEARMAP, BasemapId.METROMAP].includes(basemapId);
}
