<template>
  <button v-if="editable" class="btn btn-custom btn-lg" :title="href">
    <input
      type="text"
      placeholder="text here"
      :value="inputValue"
      :style="{
        width: inputValue.length + 'ch',
      }"
      @keyup="(e) => onChange(e.target.value)"
    />
  </button>
  <a
    v-else
    class="btn btn-custom btn-lg"
    :title="href"
    :href="href"
    target="_blank"
    :style="{
      width: inputValue.length + 'ch',
    }"
  >
    {{ block.content }}
  </a>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    block: Object,
  },
  data: () => ({
    inputValue: '',
  }),
  computed: {
    ...mapState({
      editable: (state) => state['reporter'].editable,
    }),
    href() {
      return this.block.settings ? this.block.settings.href : '';
    },
  },
  methods: {
    onChange(value) {
      this.inputValue = value;

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.$emit('update', {
          content: value,
        });
      }, 700);
    },
  },
  mounted() {
    this.inputValue = this.block.content ?? '';
  },
};
</script>
<style lang="scss" scoped>
input,
a {
  border: none;
  background: transparent;
  outline: none;
  line-height: 1.42;
  font-weight: 400;
  text-align: center;
  color: inherit;
  font-size: var(--paragraph-font-size);
  min-width: 200px;
}

input::placeholder {
  color: rgb(255 255 255 / 60%);
}

.btn-custom {
  width: auto;
}
</style>
