<template>
  <div class="w-100 d-flex align-items-center flex-column">
    <audio controls>
      <source :src="block.file_url" />
      Your browser does not support the audio element.
    </audio>

    <div v-if="!isSlide" class="caption mt-3">
      <caption-text
        :block="block"
        placeholder="Add a caption for this audio (optional)"
        @update="(data) => $emit('update', data)"
      />
    </div>
  </div>
</template>
<script>
import CaptionText from './Text.vue';

export default {
  props: {
    block: Object,
    isSlide: Boolean,
  },
  components: { CaptionText },
};
</script>
<style lang="scss" scoped>
audio {
  width: 100%;
  max-width: 650px;
  min-width: 275px;
  height: 56px;
}
</style>
