<script lang="ts" setup>
import useIsGather from '../../composables/useIsGather';
import EventBus from '../../EventBus';
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { useProjectStore } from '../../store/project';
import { InputValue, GatherField } from '../../gather';
import type ISignaturePad from 'signature_pad';

const props = defineProps<{
  field: GatherField;
  inputValue: InputValue;
}>();
const projectStore = useProjectStore();
const signaturePadLib = ref<typeof ISignaturePad>();
const signaturePad = ref<ISignaturePad>();

const signatureCanvas = ref<HTMLCanvasElement>();

const project = computed(() => {
  if (!projectStore.project) {
    throw new Error('Project is not defined');
  }
  return projectStore.project!;
});
const isGather = useIsGather();
const placeholderImage = computed(() => {
  const value = props.inputValue.value as string;

  if (!value) return;
  if (typeof value !== 'string') {
    console.warn('Signature value is not a string');
  }

  if (value.includes('base64')) {
    return value;
  }

  return '/api/images/value/' + project.value.project_id + '/' + value;
});

function clearSignature() {
  signaturePad.value?.clear();

  EventBus.$emit('updateInputValue', {
    inputValue: {
      ...props.inputValue,
      value: null,
    },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
  });
}

async function initializeSignature() {
  if (!signaturePadLib.value) {
    signaturePadLib.value = (await import('signature_pad')).default;
  }

  if (!signatureCanvas.value) {
    // this can happen if user navigates or closes while library is loading.
    console.error('Signature canvas is not defined');
    return;
  }
  signaturePad.value = new signaturePadLib.value(signatureCanvas.value, {
    penColor: 'rgb(0, 0, 0)',
  });
  signaturePad.value.addEventListener('endStroke', finishSignatureStroke);
  signaturePad.value.on();
  resizeCanvas();
}
function resizeCanvas() {
  if (!signatureCanvas.value || !signaturePad.value) {
    throw new Error('Signature canvas is not defined');
  }
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  signatureCanvas.value.width = signatureCanvas.value.clientWidth * ratio;
  signatureCanvas.value.height = signatureCanvas.value.clientHeight * ratio;
  signatureCanvas.value.getContext('2d')!.scale(ratio, ratio);
  signaturePad.value.clear();

  nextTick(() => {
    if (!signaturePad.value || !signatureCanvas.value) {
      throw new Error('Signature Pad is not initialized');
    }
    if (props.inputValue.value && typeof props.inputValue.value === 'string') {
      if (props.inputValue.value.startsWith('data:')) {
        signaturePad.value.fromDataURL(props.inputValue.value, {
          ratio,
          width: signatureCanvas.value.clientWidth,
          height: signatureCanvas.value.clientHeight,
        });
      } else {
        signaturePad.value.fromDataURL(
          '/api/images/value/' +
            project.value.project_id +
            '/' +
            props.inputValue.value,
          {
            ratio,
            width: signatureCanvas.value.clientWidth,
            height: signatureCanvas.value.clientHeight,
          }
        );
      }
    }
  });
}

onMounted(() => {
  if (isGather) {
    initializeSignature();
  }
});
onBeforeUnmount(() => {
  signaturePad.value?.off();
});

function finishSignatureStroke() {
  if (!signaturePad.value) {
    throw new Error('Signature Pad is not initialized');
  }
  const dataUrl = signaturePad.value.toDataURL();
  EventBus.$emit('updateInputValue', {
    inputValue: {
      ...props.inputValue,
      value: dataUrl,
    },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
  });
}
</script>

<template>
  <div>
    <label class="form-label" for="dropdown">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
      <i class="fas fa-eraser clickable" @click="clearSignature" />
    </label>
    <div class="position-relative">
      <div
        v-if="isGather"
        :class="[
          'd-flex  align-items-stretch',
          { 'border-danger': field.is_required && !inputValue.value },
        ]"
        :style="{
          width: '280px',
          height: '100px',
          border: '1px solid #ced4da',
          'border-radius': '0.25rem',
        }"
      >
        <canvas ref="signatureCanvas" class="flex-grow-1" />
      </div>
      <template v-else>
        <img v-if="placeholderImage" class="w-100" :src="placeholderImage" />
        <div class="alert alert-warning mb-0">
          <i class="fas fa-exclamation-triangle"></i>
          <span class="ms-2"> Please edit signatures on Gather.</span>
        </div>
      </template>
    </div>
  </div>
</template>
