import { formatSampleDepth } from '@/js/helpers/sample';
import { getStyleByScenario as _getStyleByScenario } from '@/js/helpers/scenario.js';
import { findFieldByIdFromApps } from '@component-library/business-logic/app';
import { TargetType } from '@component-library/business-logic/mapping/styling-rule';
import { DrawingType } from '@component-library/gather';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import { getBasemapApis } from '../business-logic/basemap';
import { checkIsValidLatLng } from '../business-logic/coord';
import {
  ScenarioUtil,
  getAllSampleIdsFromSample,
} from '../business-logic/evalu8';
import { checkIsSpecificChemicalPlan } from '../business-logic/figure';
import { LAYER_TYPES, flattenLayerTree } from '../business-logic/layer';
import {
  checkHasResultsFully,
  getScopedSamples,
} from '../business-logic/sample';
import { SampleExceedanceColor } from '../business-logic/styling';
import type { FigureStylingRule } from '../business-logic/styling/figure-styling-rule';
import { findFigureStylingRulesByFigureId } from '../business-logic/styling/figure-styling-rule';
import { checkIsContours } from '../lib/olbm/layer/shape/utils';
import { EDIT_METHOD_CODES } from '../modules/openlayers/interactions/image/constants';
import TreeView from '../modules/TreeView';

export const getCountryName = (state) => (country) => {
  return state.countries[country]?.name ?? '';
};

export const getFeatureTypeNames = (state) => (featureTypeCode) => {
  return (
    state.featureTypes[featureTypeCode]?.names ?? {
      singular: '',
      plural: '',
    }
  );
};

export const getRegions = (state) => (country) => {
  return state.countries[country]?.regions ?? [];
};

export const hasSidebarLegend = (state) => {
  return state.selectedFigure && state.selectedFigure.legend_type == 0;
};

export const getScenario = (state) => (scenario) => {
  return state.allScenarios.find((_scenario) =>
    ScenarioUtil.checkIsEqual(_scenario, scenario)
  );
};

export const allLayers = (state) => {
  return state.allLayers.filter((layer) => {
    return (
      layer.data.properties.type !== LAYER_TYPES.FOLDER ||
      layer.children.length > 0
    );
  });
};

export const checkIsCalloutDelegate = () => (layer) => {
  const { type, isBuiltin } = layer.data.properties;
  return type === LAYER_TYPES.CALL_OUT && isBuiltin;
};

export const visibleLayers = (_, getters) => {
  return getters.allLayers.filter(
    (item) =>
      !getters.checkIsCalloutDelegate(item) &&
      !getters.checkIsLayerHiddenById(item.id)
  );
};

export const getAllOrderedClientLayers = (_, getters) => {
  return getters.allLayers
    .filter((layer) => layer.visible && !layer.hidden_from_story)
    .sort((a, b) => a.order - b.order);
};

// WARNING: This function is only applicable to layers at level 1 or level 2, e.g.
// squares, circles, sample groups etc. It's not applicable to sample layers
// because they could be at level 3 (the sample group is in a folder or the sample
// is in a sub folder) or level 4(the sample is in a sub folder and its sample group
// is in a folder).
export const getParentLayerById = (state) => (id) => {
  return state.allLayers.find(
    (pL) =>
      pL.id == id ||
      (pL.children && pL.children.findIndex((cL) => cL.id == id) != -1)
  );
};

export const layerById = (state) => {
  return flattenLayerTree(state.allLayers).reduce((accu, layer) => {
    accu[layer.id] = layer;
    return accu;
  }, {});
};

export const getLayerById = (state, getters) => (id) => {
  return getters.layerById[id];
};

export const getLayerBySampleId = (state, getters) => (sampleId) => {
  return (Object.values(getters.layerById) as any[]).find(
    (layer) => layer.data.lopSample?.id === sampleId
  );
};

export const cleanShapeProperties = (state) => {
  const properties = state.shapeProperties;
  ['dashArray'].forEach((property) => {
    delete properties[property];
  });

  return properties;
};

export const getAllSiteBoundaryLayers = (state, getters) => {
  return (Object.values(getters.layerById) as any[]).filter(
    (layer) => layer.data.properties.type === LAYER_TYPES.SITE_BOUNDARY
  );
};

export const getSiteBoundaryLayers = (state, getters) => {
  return getters.getAllSiteBoundaryLayers.filter((layer) => layer.visible);
};

export const checkIsLayerHiddenById = (state, getters) => (layer_id) => {
  let layer = getters.getLayerById(layer_id);

  if (!layer) {
    return false;
  }

  const { type } = layer.data.properties;
  if (type === 'sub_folder') {
    const { sampleGroupId } = layer.data.properties;
    const sampleGroup = getters.getLayerById(sampleGroupId);
    const hiddenSubFolders = JSON.parse(sampleGroup.hidden_sub_folders) ?? [];
    return !!hiddenSubFolders?.includes(layer.text);
  }

  return !layer.visible;
};

export const getSampleById = (state) => (id) => {
  return state.allSamples.find((item) => item.id == id);
};

export const getSampleByIdEx = (state) => (id) => {
  const { allSamples, lopSampleByLayerId } = state;
  return [...allSamples, ...Object.values(lopSampleByLayerId)].find(
    (item) => item.id === id
  );
};

export const sampleGroups = (state, getters) => {
  return (Object.values(getters.layerById) as any[]).filter(
    (layer) => layer.data.properties.type === LAYER_TYPES.SAMPLE_GROUP
  );
};

export const defaultSampleGroup = (state, getters) => {
  return getters.sampleGroups.find((sg) => sg.data.properties.default);
};

export const getSampleGroupById = (state, getters) => (id) => {
  return getters.sampleGroups.find((sg) => sg.id === id);
};

export const getSampleGroupByIdentifier = (state, getters) => (identifier) => {
  return identifier
    ? getters.sampleGroups.find(
        (sg) => sg.data.marker_identifier === identifier
      )
    : getters.defaultSampleGroup;
};

export const checkIsSamplesLoaded =
  (state) =>
  (sampleGroupId: number): boolean => {
    return state.allSamples.some(
      (sample) => sample.sample_group.id === sampleGroupId
    );
  };

// Builtin call-outs need a delegate to do editing. The delegate is a call-out and
// a project can have only one delegate.
export const builtinCalloutDelegate = (state, getters) => {
  return (Object.values(getters.layerById) as any[]).filter((layer) => {
    const { type, isBuiltin } = layer.data.properties;
    return type === LAYER_TYPES.CALL_OUT && isBuiltin;
  });
};

export const getResultExceedanceBySample = (state) => (sample, type) => {
  const selectedFigure = state.selectedFigure;
  const currentScenarios = selectedFigure.scenarios;
  const isChemicalFigure = !!selectedFigure.chemicals;
  const hiddenFormattedDepths =
    selectedFigure.enviro_callout_filter?.hiddenFormattedDepths ?? [];

  const allSampleIds = getAllSampleIdsFromSample(sample, hiddenFormattedDepths);

  // Filter results by samples that exceed
  let itemIdsExceeding: any[] = [];
  if (isChemicalFigure && type === 'sample') {
    const results = selectedFigure.results || [];
    results.forEach((chemical) => {
      itemIdsExceeding = [
        ...itemIdsExceeding,
        ...chemical.sample_items
          .filter((r) => allSampleIds.includes(r.sample_id))
          .map((r) => r.id),
      ];
    });
  }

  const exceedanceTypes: any[] = [];
  currentScenarios.forEach((scenario) => {
    const scenarioType = state.allExceedances.find(
      (e) => e.criteria_type == scenario.criteria_type
    );

    const exceedanceData =
      scenarioType?.exceedances.filter(
        (e) =>
          ((scenario.criteria_type == 'landuse' &&
            e.scenario_id == scenario.scenario_id) ||
            (scenario.criteria_type == 'criteria' &&
              e.criteria_set_id == scenario.criteria_set_id)) &&
          e.exceedances.findIndex((ee) =>
            allSampleIds.includes(ee.sample_id)
          ) != -1 &&
          (e.document_id ? scenario.document_id == e.document_id : true)
      ) ?? [];

    if (exceedanceData.length == 0) {
      return false;
    }

    // chemical based figures should only show an exceedance if any of the results in popup exceed
    exceedanceData.forEach((exceedance) => {
      if (type === 'sample') {
        if (
          isChemicalFigure &&
          exceedance.exceedances.filter((r) =>
            itemIdsExceeding.includes(r.item_id)
          ).length > 0
        ) {
          exceedanceTypes.push(scenario.criteria_type);
        }

        if (!isChemicalFigure) {
          exceedanceTypes.push(scenario.criteria_type);
        }
      } else {
        exceedanceTypes.push({
          criteria_type: scenario.criteria_type,
          ...exceedance,
        });
      }
    });
  });

  return exceedanceTypes;
};

export const isLabelHiddenGlobal = (state) => {
  return state.selectedFigure.hide_sample_labels;
};

export const getStyleByScenario = (state, getters) => (scenario) => {
  const styles = state.allScenarioStyles;
  const foundScenario = getters.getScenario(scenario);
  if (!foundScenario) {
    return {};
  }

  return _getStyleByScenario(
    {
      styles,
    },
    foundScenario
  );
};

export const getLayersBySection =
  (state) =>
  (section_id, hide_story_layers = true) => {
    let layers = state.allLayers.filter(
      (layer) => layer.section_id == section_id
    );

    return layers
      .filter(
        (layer) =>
          layer.visible && (hide_story_layers ? !layer.hidden_from_story : true)
      )
      .sort((a, b) => a.order - b.order);
  };

export const canSampleBeDeleted = (state, getters) => (sampleId) => {
  const sample = getters.getSampleById(sampleId);
  return (
    sample &&
    checkIsValidLatLng({
      lat: sample.latitude,
      lng: sample.longitude,
    })
  );
};

export const isSampleHidden = (state, getters) => (sampleId) => {
  const sample = getters.getSampleById(sampleId);
  if (!sample) {
    // The sample could represent polyline or polygon layers with data collected from Gather.
    const layer = getters.getLayerBySampleId(sampleId);
    return layer ? getters.checkIsLayerHiddenById(layer.id) : true;
  }
  const { sample_group: sampleGroup, sub_folder: subFolder } = sample;
  // The getParentLayerById is used instead of getLayerById because
  // the sampleGroup's parent_id is obsolete after layers are reordered.
  const sampleGroupParent = getters.getParentLayerById(sampleGroup.id);
  const subFolderLayer = TreeView.findSubFolderLayer(sampleGroup, subFolder);
  return (
    // sampleGroup.id is null when the sample group is a newly created one.
    // See SampleEvents.prepareSampleGroup.
    // In this situation is the sampleGroupParent null.
    getters.checkIsLayerHiddenById(sampleGroupParent?.id) ||
    getters.checkIsLayerHiddenById(sampleGroup.id) ||
    getters.checkIsLayerHiddenById(subFolderLayer?.id)
  );
};

export const getSampleExceedanceColor = (state, getters) => (sample) => {
  const exceedances = getters.getResultExceedanceBySample(sample, 'sample');

  if (exceedances.includes('landuse')) {
    return SampleExceedanceColor.Landuse;
  }

  if (exceedances.includes('criteria')) {
    return SampleExceedanceColor.Criteria;
  }

  return SampleExceedanceColor.NoExceedances;
};

export const checkHasResults = () => (figure, sample) => {
  return !!figure.results?.find(
    (result) =>
      !!result.sample_items.find((si) => {
        return si.sample_id === sample.id && si.prefix === null;
      })
  );
};

export const checkHasExceedances = (state) => (figure, sample) => {
  return (
    figure.results?.some((r /*SampleChemical*/) => {
      return r.sample_items.some((si) => {
        return (
          si.sample_id === sample.id &&
          state.allExceedances.some((e) => {
            return e.exceedances.some((ee) => {
              return ee.exceedances.some((eee) => eee.item_id === si.id);
            });
          })
        );
      });
    }) ?? false
  );
};

export const getFormattedDepths = (state, getters) => (figure, sample) => {
  const result: (string | null)[] = [];

  if (getters.checkHasResults(figure, sample)) {
    result.push(formatSampleDepth(sample));
  }

  if (sample.duplicate && getters.checkHasResults(figure, sample.duplicate)) {
    result.push(formatSampleDepth(sample.duplicate));
  }

  (sample.child_samples ?? []).forEach((sample) => {
    if (getters.checkHasResults(figure, sample)) {
      result.push(formatSampleDepth(sample));
    }

    if (sample.duplicate && getters.checkHasResults(figure, sample.duplicate)) {
      result.push(formatSampleDepth(sample.duplicate));
    }
  });

  return _uniq(result).sort();
};

export const getAllFormattedDepths = (state, getters) => (figure) => {
  const result: any[] = [];
  state.allSamples.forEach((sample) => {
    result.push(...getters.getFormattedDepths(figure, sample));
  });
  return _uniq(result).sort();
};

export const checkIsCcAlwaysHidden = (state, getters) => (sample) => {
  const { latitude: lat, longitude: lng } = sample;
  const { hide_ecs_without_exceedances, enviro_callout_filter } =
    state.selectedFigure;
  const { hiddenFormattedDepths = [] } = enviro_callout_filter ?? {};
  return (
    !checkIsValidLatLng({
      lat,
      lng,
    }) ||
    getters.isSampleHidden(sample.id) ||
    (hide_ecs_without_exceedances &&
      getters.getSampleExceedanceColor(sample) ===
        SampleExceedanceColor.NoExceedances) ||
    !getters
      .getFormattedDepths(state.selectedFigure, sample)
      .some((fd) => !hiddenFormattedDepths.includes(fd))
  );
};

export const checkIsCcHidden = (state, getters) => (sample) => {
  const popups_hidden = state.selectedFigure.popups_hidden ?? [];
  return (
    getters.checkIsCcAlwaysHidden(sample) || popups_hidden.includes(sample.id)
  );
};

export const checkIsGcAlwaysHidden = (state, getters) => (sample) => {
  const { latitude: lat, longitude: lng } = sample;
  return (
    !checkIsValidLatLng({
      lat,
      lng,
    }) || getters.isSampleHidden(sample.id)
  );
};

export const checkIsGcHidden = (state, getters) => (sample) => {
  const popups_hidden = state.selectedFigure.popups_hidden ?? [];
  return (
    getters.checkIsGcAlwaysHidden(sample) || popups_hidden.includes(sample.id)
  );
};

export const checkIsExceedanceStylingUsed = (state) => (sample) => {
  const { selectedFigure } = state;
  if (!selectedFigure) {
    return false;
  }

  const { hide_sample_exceedance_styling: hideSampleExceedanceStyling } =
    selectedFigure;

  return (
    !hideSampleExceedanceStyling &&
    checkIsSpecificChemicalPlan(selectedFigure) &&
    checkHasResultsFully(sample, selectedFigure)
  );
};

export const isServiceLayerFolderLayer = () => (properties) => {
  if (!properties) {
    // When this method is called to check an Image layer, the properties is null.
    return false;
  }

  const {
    type,
    // For ESRI service layers.
    esri_type,
    // For OGC OpenGIS service layers.
    service,
  } = properties;

  return type === 'folder' && (esri_type || service);
};

export const serviceLayerFolders = (state) => {
  const { allLayers } = state;
  return allLayers.filter((layer) => {
    const { type, service, esri_type } = layer.data.properties;
    return type === 'folder' && (!!service || !!esri_type);
  });
};

export const visibleServiceLayerFolders = (state, getters) => {
  return getters.serviceLayerFolders.filter(
    (slf) => slf.visible && slf.children.some((child) => child.visible)
  );
};

export const getServiceAttributions = (state, getters) => {
  const _visibleServiceLayerFolders = [...getters.visibleServiceLayerFolders];
  _visibleServiceLayerFolders.reverse();

  return _visibleServiceLayerFolders
    .map((vslf) => {
      const { service } = vslf.data.properties;
      return !!service
        ? service.attributions
        : vslf.data.properties.attributions;
    })
    .filter((attribution, index, self) => {
      // Remove the duplicates.
      return self.indexOf(attribution) === index;
    });
};

export const getAllBufferLayers = (state) => {
  return state.allLayers.filter(
    (item) => item.data.properties.type === LAYER_TYPES.BUFFER
  );
};

export const getBufferLayer = (state, getters) => (sourceLayer) => {
  if (!sourceLayer) {
    return null;
  }

  const {
    id: layerId,
    properties: { type: layerType },
  } = sourceLayer.data;

  const allBufferLayers = getters.getAllBufferLayers;
  if (layerType === 'folder') {
    return allBufferLayers.find(
      (item) => item.data.properties.folderId === layerId
    );
  } else if (state.bufferableLayerTypes.includes(layerType)) {
    return allBufferLayers.find((item) => {
      const { folderId, boundLayerIds } = item.data.properties;
      return !folderId && boundLayerIds.includes(layerId);
    });
  }

  return null;
};

export const findBufferLayersByBoundLayerId =
  (state, getters) => (boundLayerId) => {
    return (Object.values(getters.layerById) as any[]).filter((layer) => {
      const { type, boundLayerIds = [] } = layer.data.properties;
      return (
        type === LAYER_TYPES.BUFFER && boundLayerIds.includes(boundLayerId)
      );
    });
  };

export const getBufferLayerDefaultTitle = (state, getters) => {
  const { shapeProperties } = state;

  if (shapeProperties.type !== LAYER_TYPES.BUFFER) {
    throw `The current layer(${shapeProperties.type}) must be a buffer layer.`;
  }

  let { title } = shapeProperties;
  const { distance, folderId, boundLayerIds } = shapeProperties;
  let sourceLayer;
  if (folderId) {
    sourceLayer = getters.getLayerById(folderId);
  } else {
    sourceLayer = getters.getLayerById(boundLayerIds[0]);
  }
  const { text: sourceTitle } = sourceLayer;

  if (
    !title ||
    !title.trim() ||
    title.match(new RegExp(`^\\d+m buffer around ${sourceTitle}$`))
  ) {
    title = `${distance}m buffer around ${sourceTitle}`;
  }

  return title;
};

export const getAllCallouts = (state, getters) => {
  return (Object.values(getters.layerById) as any[]).filter(
    (layer) => layer.data.properties.type === LAYER_TYPES.CALL_OUT
  );
};

export const checkIsGeoreferencing = (state) => {
  const {
    shapeProperties: { type },
    imageEditMethodCode,
  } = state;
  return (
    type === LAYER_TYPES.IMAGE &&
    imageEditMethodCode === EDIT_METHOD_CODES.GEOREFERENCE
  );
};

// A gather sample represents a point, or a line, or a ploygon with
// data collected from Gather.
export const allGatherSamples = (state) => {
  const { allSamples } = state;
  const pointSamples = allSamples.filter((item) => !!item.template_tab_id);
  const lopSamples = Object.values(state.lopSampleByLayerId);
  return [...pointSamples, ...lopSamples];
};

export const checkGatherAppExists = (state) => (gatherAppId) => {
  return state.gatherApps.some((ga) => ga.id === gatherAppId);
};

export const getLockableLayerIds = (state) => (figureId) => {
  const otherFigures = state.allFigures.filter((f) => f.id !== figureId);
  const lockedLayerIds = otherFigures.reduce((accu, f) => {
    accu.push(...(f.locked_layer_ids ?? []));
    return accu;
  }, []);
  return state.allLayers
    .filter((layer) => !lockedLayerIds.includes(layer.id))
    .map((layer) => layer.id);
};

export const getLockedByFigure = (state, getters) => (layerId) => {
  let layer = getters.getLayerById(layerId);
  while (layer?.parent_id) {
    layer = getters.getLayerById(layer.parent_id);
  }

  if (!layer) {
    return undefined;
  }

  const finalizer = state.allFigures.find((f) =>
    f.locked_layer_ids?.includes(layer.id)
  );
  const basemapFigure = state.allFigures.find((f) => f.is_basemap);
  // Layers locked by the Basemap figure are still editable in the Basemap figure.
  const isLockedByBasemapFigure =
    !state.selectedFigure?.is_basemap && layer.data.isVisibleInBasemapFigure;
  return finalizer ?? (isLockedByBasemapFigure ? basemapFigure : undefined);
};

export const hasSearchMatchedLayers = (state, getters) => {
  return (Object.values(getters.layerById) as any[]).some(
    (layer) => layer.data.isSearchMatched ?? true
  );
};

export const selectedFigureStylingRules = (state): FigureStylingRule[] => {
  return state.selectedFigure?.id
    ? findFigureStylingRulesByFigureId(
        state.figureStylingRules,
        state.selectedFigure.id
      )
    : [];
};

export const createTempFigureStylingRules = (state, getters) => () => {
  return _cloneDeep(getters.selectedFigureStylingRules);
};

export const getLopSample = (state) => (layerId) => {
  return state.lopSampleByLayerId[layerId] ?? null;
};

export const getFigureStylingRulesOnLayer =
  (state, getters) =>
  (layerId): FigureStylingRule[] => {
    const { selectedFigureStylingRules } = getters;
    if (!selectedFigureStylingRules.length) {
      return [];
    }

    let layer = getters.getLayerById(layerId);
    if (!layer) {
      return [];
    }

    if (layer.data.properties.type === LAYER_TYPES.SAMPLE_GROUP_SUB_FOLDER) {
      layer = getters.getLayerById(layer.data.properties.sampleGroupId);
    }

    let result: any[] = [];
    const { type } = layer.data.properties;
    if (type === LAYER_TYPES.SAMPLE_GROUP) {
      const sfsrsOnEnviroSamples = selectedFigureStylingRules.filter(
        (sfsr) =>
          sfsr.target.type === TargetType.EnviroSamples &&
          sfsr.target.id === layer.id
      );
      result.push(...sfsrsOnEnviroSamples);

      const samples = getScopedSamples(layer, state.allSamples);
      const appIds = _uniq(
        samples
          .map((sample) => {
            const { template_tab_id: appId } = sample;
            return appId;
          })
          .filter((appId) => !!appId)
      );
      const sfsrsOnGatherApp = selectedFigureStylingRules.filter(
        (sfsr) =>
          sfsr.target.type === TargetType.GatherApp &&
          sfsr.target.collectionType === DrawingType.Point &&
          appIds.includes(sfsr.target.id)
      );
      result.push(...sfsrsOnGatherApp);
    } else if (
      [
        LAYER_TYPES.POLYGON,
        LAYER_TYPES.POLYLINE,
        LAYER_TYPES.ARROW,
        LAYER_TYPES.RECTANGLE,
        LAYER_TYPES.CIRCLE,
        LAYER_TYPES.HEDGE,
      ].includes(type)
    ) {
      const { lopSample } = layer.data;
      if (lopSample) {
        const sfsrsOnGatherApp = selectedFigureStylingRules.filter(
          (sfsr) =>
            sfsr.target.type === TargetType.GatherApp &&
            [DrawingType.Polygon, DrawingType.Polyline].includes(
              sfsr.target.collectionType
            ) &&
            sfsr.target.id === lopSample.template_tab_id
        );
        result.push(...sfsrsOnGatherApp);
      }
    }
    return result;
  };

export const findGatherAppById = (state) => (id) => {
  return state.gatherApps.find((gatherApp) => gatherApp.id === id);
};

export const findGatherFieldById = (state) => (id) => {
  return findFieldByIdFromApps(state.gatherApps, id);
};

export const basemapApis = (state, getters, rootState) => {
  const { project } = rootState;
  const country = project.location?.country ?? 'NZ';
  const address = project.address_state;
  return getBasemapApis(country, address);
};

export const layerCount = (state, getters) => {
  return Object.keys(getters.layerById).length;
};

export const sampleCount = (state) => {
  return state.allSamples.length;
};

export const isContours = (state) => {
  const { type, usage } = state.shapeProperties;
  return checkIsContours(type, usage);
};

export const selectedContourId = (state, getters) => {
  const { ID } = state.shapeProperties;
  return getters.isContours ? ID : undefined;
};

export const hasNoData = (state) => {
  if (state.allLayers.length > 1) {
    return false;
  }

  const [layer] = state.allLayers[0];
  const isDefaultSampleGroup =
    layer.data?.properties.type === LAYER_TYPES.SAMPLE_GROUP &&
    layer.data?.properties.default;

  return isDefaultSampleGroup && state.allSamples.length === 0;
};

export const getCachedValue = (state) => (path: string) => {
  return { path, value: _get(state.cache, path) };
};

export const variableVersionByName = (state) => {
  return state.variableVersionByName;
};

export const getVariableVersion = (state, getters) => (name: string) => {
  return getters.variableVersionByName[name];
};

export const checkIsLayerAddingScheduled =
  (state) =>
  (layerId: number): boolean => {
    return state.layerAddingStateByLayerId[layerId] !== undefined;
  };
