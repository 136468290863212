import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
  VectorTile as VectorTileLayer,
} from 'ol/layer';
import { ProjectionLike } from 'ol/proj';
import {
  ImageArcGISRest,
  TileArcGISRest,
  Vector as VectorSource,
  XYZ,
} from 'ol/source';
import { OlbmError } from '../../../common/error';
import type { Id } from '../../../common/types';
import { Extent } from '../../../common/types';
import { LegacySize } from '../../../measurement/types';
import type { LayerMixin, LayerModel, Properties } from '../../types';

export enum EsriType {
  FeatureServer = 'featureLayer',
  MapServer = 'dynamicMapLayer',
  ImageServer = 'imageMapLayer',
  VectorTileServer = 'vectorTileLayer',
}

export type RequestExtentResult = {
  id: Id;
  data?: Extent | OlbmError;
};

// FeatureServer
export type FeatureServerFolderProperties = Properties & {
  url: string;
  token?: string;
  esri_type: EsriType.FeatureServer;
  extent?: Extent;
  visibleExtent: Extent;
  maxImageSize?: LegacySize;
  mapTileConfig: any;
  attributions: string;
  projection: ProjectionLike;
  shouldUseCorsProxy: boolean | 1 | 0;
};

export type FeatureServerItemProperties = Properties & {
  index: number;
  hidden_sub_layers: any[];
  minScale: number;
  maxScale: number;
  pbfSupported: boolean;
  renderer: any;
};

export type FeatureServerFolderLayerModel =
  LayerModel<FeatureServerFolderProperties> & {
    children: FeatureServerItemLayerModel[];
  };

export type FeatureServerItemLayerModel =
  LayerModel<FeatureServerItemProperties>;

export type FeatureServerLayer = VectorLayer<VectorSource<Feature<Geometry>>> &
  LayerMixin;

// MapServer
export type MapServerFolderProperties = Properties & {
  url: string;
  token?: string;
  esri_type: EsriType.MapServer;
  extent: Extent;
  visibleExtent: Extent;
  maxImageSize: LegacySize;
  mapTileConfig: any;
  attributions: string;
  projection: ProjectionLike;
  shouldUseCorsProxy: boolean | 1 | 0;
};

export type MapServerItemProperties = Properties & {
  index: number;
  hidden_sub_layers: any[];
  minScale: number;
  maxScale: number;
  hasLabels: boolean;
  renderer: any;
};

export type MapServerFolderLayerModel =
  LayerModel<MapServerFolderProperties> & {
    children: MapServerItemLayerModel[];
  };

export type MapServerItemLayerModel = LayerModel<MapServerItemProperties>;

// ImageServer
export type ImageServerFolderProperties = Properties & {
  url: string;
  token?: string;
  esri_type: EsriType.ImageServer;
  extent: Extent;
  visibleExtent: Extent;
  maxImageSize: LegacySize;
  mapTileConfig: any;
  attributions: string;
  projection: ProjectionLike;
  shouldUseCorsProxy: boolean | 1 | 0;
};

export type ImageServerItemProperties = Properties & {
  index: string;
  hidden_sub_layers: any[];
  renderer: any;
};

export type ImageServerFolderLayerModel =
  LayerModel<ImageServerFolderProperties> & {
    children: ImageServerItemLayerModel[];
  };

export type ImageServerItemLayerModel = LayerModel<ImageServerItemProperties>;

export type MapImageServerLayer = (
  | TileLayer<XYZ>
  | ImageLayer<ImageArcGISRest>
  | TileLayer<TileArcGISRest>
) &
  LayerMixin;

// VectorTileServer
export type VectorTileServerFolderProperties = Properties & {
  url: string;
  token?: string;
  esri_type: EsriType.VectorTileServer;
  extent: Extent;
  visibleExtent: Extent;
  maxImageSize?: LegacySize;
  mapTileConfig: any;
  attributions: string;
  projection: ProjectionLike;
  shouldUseCorsProxy: boolean | 1 | 0;
  shouldIgnoreLayerStyleRestrictions?: boolean;
};

export type VectorTileServerItemProperties = Properties & {
  index: string;
  hidden_sub_layers: any[];
  defaultStyles: string;
  projection: ProjectionLike;
  resolutions: number[];
  origin: number[];
  tileSize: number[];
  maxLOD: number;
};

export type VectorTileServerFolderLayerModel =
  LayerModel<VectorTileServerFolderProperties> & {
    children: VectorTileServerItemLayerModel[];
  };

export type VectorTileServerItemLayerModel =
  LayerModel<VectorTileServerItemProperties>;

export type GetVectorTileServerUrls = (
  folderModel: VectorTileServerFolderLayerModel,
  itemModel: VectorTileServerItemLayerModel
) => { tileUrl: string; styleUrl: string };

export type VectorTileServerLayer = VectorTileLayer & LayerMixin;

export type EsriLayer =
  | FeatureServerLayer
  | MapImageServerLayer
  | VectorTileServerLayer;
