<script lang="ts" setup>
import moment from 'moment';
import { computed, onMounted, ref } from 'vue';
import { DateType, checkHasTime } from '../../business-model/date';
import { GatherDateOptions, GatherField, InputValue } from '../../gather';
import makeId from '../../local-id.mjs';
import { getDateType } from '../../utils/date';
import EventBus from '../../EventBus';

const props = defineProps<{
  field: GatherField<GatherDateOptions>;
  inputValue: InputValue;
}>();
const id = ref(makeId());
const currentMoment = ref(moment());

const yearRange = computed(() => {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];
  for (let i = currentYear - 20; i < currentYear + 19; i++) {
    years.push(i);
  }
  return years;
});

const year = computed({
  get() {
    return value.value ? String(value.value).split('-')[0] : null;
  },
  set(updated) {
    if (!updated) {
      return;
    }
    let date = String(value.value).split('-');
    date[0] = updated;
    value.value = date.join('-');
  },
});

const month = computed({
  get() {
    return value.value ? String(value.value).split('-')[1] : null;
  },
  set(updated) {
    if (!updated) {
      return;
    }
    let date = String(value.value).split('-');
    date[1] = updated;
    value.value = date.join('-');
  },
});

const useDateFormat = computed(() => {
  let inputType = props.field?.options?.type || 'date';
  return inputType == 'date' || inputType == 'datetime';
});

const inputType = computed(() => {
  return props.field?.options?.type || 'date';
});

const hasYearFormat = computed(() => {
  return (
    props.field?.options?.format == 'YYYY' ||
    props.field?.options?.format == 'MM-YYYY'
  );
});

const hasMonthFormat = computed(() => {
  return props.field?.options?.format == 'MM-YYYY';
});

const current = computed(() => {
  return props.field?.options?.current ?? true;
});

const valueLabel = computed(() => {
  if (useDateFormat.value && !hasYearFormat.value) {
    return 'date';
  } else if (useDateFormat.value && hasMonthFormat.value) {
    return 'month';
  } else if (useDateFormat.value && hasYearFormat.value) {
    return 'year';
  }
});

const value = computed({
  get() {
    if (props.inputValue.value || !current.value) {
      return props.inputValue.value;
    }

    const defaultValue = formatDate(new Date());
    EventBus.$emit('updateInputValue', {
      inputValue: { ...props.inputValue, value: defaultValue },
      field: props.inputValue.template_field_id,
      sectionIndex: props.inputValue.template_section_index,
      templateTabId: props.inputValue.template_tab_id,
      isDefaultInputValue: true,
    });
    return defaultValue;
  },
  set(updated) {
    EventBus.$emit('updateInputValue', {
      inputValue: { ...props.inputValue, value: updated },
      field: props.inputValue.template_field_id,
      sectionIndex: props.inputValue.template_section_index,
      templateTabId: props.inputValue.template_tab_id,
    });
  },
});

const value2 = computed({
  get() {
    let value2 = props.inputValue.value2;
    if (value2 || !current.value) {
      return value2;
    }

    const date = new Date();
    const defaultValue =
      (date.getHours() < 10 ? '0' : '') +
      date.getHours() +
      ':' +
      (date.getMinutes() < 10 ? '0' : '') +
      date.getMinutes();

    EventBus.$emit('updateInputValue', {
      inputValue: { ...props.inputValue, value2: defaultValue },
      field: props.inputValue.template_field_id,
      sectionIndex: props.inputValue.template_section_index,
      templateTabId: props.inputValue.template_tab_id,
      isDefaultInputValue: true,
    });

    return defaultValue;
  },
  set(newValue) {
    EventBus.$emit('updateInputValue', {
      inputValue: { ...props.inputValue, value2: newValue },
      field: props.inputValue.template_field_id,
      sectionIndex: props.inputValue.template_section_index,
      templateTabId: props.inputValue.template_tab_id,
    });
  },
});

const isClearDateVisible = computed(() => {
  const type = getDateType(props.field);
  return type !== DateType.Time && !!value.value;
});

const isClearTimeVisible = computed(() => {
  const type = getDateType(props.field);
  return checkHasTime(type) && !!value2.value;
});

function padValue(value) {
  return String(value).padStart(2, '0');
}

function formatDate(date) {
  const d = new Date(date);
  let month = String(d.getMonth() + 1),
    day = String(d.getDate());
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

onMounted(() => {
  if (
    current.value &&
    inputType.value != 'time' &&
    (!props.inputValue ||
      !props.inputValue.value ||
      props.inputValue.value == null)
  ) {
    // The value.value's getter accessor includes logic to get the default value.
    // So instead of calling the setter accessor we should use the getter accessor.
    value.value;
  }
});
</script>

<template>
  <div class="form-group mb-2">
    <label class="form-label" :for="id">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
      <a
        v-if="isClearDateVisible"
        href="#"
        class="ms-2"
        @click.prevent="value = null"
      >
        Clear {{ valueLabel }}
      </a>
      <a
        v-if="isClearTimeVisible"
        href="#"
        class="ms-2"
        @click.prevent="value2 = null"
      >
        Clear time
      </a>
    </label>
    <div class="row">
      <!-- Native date input -->
      <div
        v-if="useDateFormat && !hasYearFormat"
        class="form-group mb-2 col-12"
        :class="{
          'col-md-6': inputType == 'datetime',
        }"
      >
        <input
          :id="id"
          v-model="value"
          type="date"
          :class="{
            'border-danger': field.is_required && (!value || value == ''),
          }"
          :required="!!field.is_required"
          class="form-control"
        />
      </div>
      <!-- Year month / Year select -->
      <div v-else-if="useDateFormat" class="form-group mb-2 d-flex flex-fill">
        <!-- Year month select -->
        <select
          v-if="hasMonthFormat"
          :id="id"
          v-model="month"
          class="form-control flex-fill"
          :class="{
            'border-danger': field.is_required && !month,
          }"
        >
          <option v-for="n in 12" :key="`month-${n}`" :value="padValue(n)">
            {{ currentMoment.month(n - 1).format('MMMM') }}
          </option>
        </select>
        <!-- Year select-->
        <select
          v-if="hasYearFormat"
          :id="!hasMonthFormat ? id : undefined"
          v-model="year"
          class="form-control flex-fill"
          :class="{
            'border-danger': field.is_required && !year,
          }"
        >
          <option v-for="aYear of yearRange" :key="aYear" :value="aYear">
            {{ aYear }}
          </option>
        </select>
      </div>
      <div
        v-if="inputType == 'time' || inputType == 'datetime'"
        class="form-group mb-2 col-12"
        :class="{
          'col-md-6': inputType == 'datetime',
        }"
      >
        <input
          v-model="value2"
          type="time"
          :class="{
            'border-danger': field.is_required && (!value2 || value2 == ''),
          }"
          :required="!!field.is_required"
          class="form-control"
          name="time"
        />
      </div>
    </div>
  </div>
</template>
