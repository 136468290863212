export const CUSTOM_LAYER_ID = 'customLayerId';

export const DEFAULT_POSITIONING = 'bottom-center';
export const DEFAULT_WIDTH = 300; // in pixels
export const DEFAULT_WEIGHT = 2; // in pixels
export const DEFAULT_FONT_SIZE = 16; // in pixels
export const DEFAULT_CONNECTOR_LENGTH = 50; // in pixels
// The connected target of a call-out is either a sample or a coordinate
export const CONNECTED_TARGET_TYPES = {
  SAMPLE: 'marker', // Use marker for the compatibility with the LAYER_TYPES.SAMPLE
  COORDINATE: 'coordinate',
};
export const BUILTIN_CALLOUT_CONTENT_TYPES = {
  ENVIRO: 1,
  GATHER: 2,
};
