import { getUid } from 'ol/util';
import useShapeProperties from '../../../composables/useShapeProperties';
import { EditableText } from '../overlays';

export default function createTextEdit(map, forNewFeature) {
  const { getShapeProperty, setShapeProperty } = useShapeProperties();
  const editableText = new EditableText(
    map,
    /* A placeholder. */ {
      layerUid: null,
      getText() {
        return '';
      },
      setText(text) {},
      getTextAngle() {
        return 0;
      },
      setTextAngle(textAngle) {},
      getPosition() {
        return map.getCoordinateFromPixel([0, 0]);
      },
      setPosition(position) {},
    }
  );
  const onResolutionChange = () => {
    editableText.update();
  };
  let feature_;

  return {
    selectFeature(feature) {
      const layer = map.layerManager.findLayerByFeature(feature);
      const layerUid = getUid(layer);
      editableText.managedText = {
        layerUid,
        getText() {
          return getShapeProperty('text', '');
        },
        setText(text) {
          setShapeProperty('text', text);

          const layer = map.layerManager.findLayerByFeature(feature);
          layer.applyOptions({
            ...layer.options,
            text,
          });
        },
        getTextAngle() {
          return getShapeProperty('textAngle', 0);
        },
        setTextAngle(textAngle) {
          setShapeProperty('textAngle', textAngle);

          const layer = map.layerManager.findLayerByFeature(feature);
          layer.applyOptions({
            ...layer.options,
            textAngle,
          });
        },
        getPosition() {
          const layer = map.layerManager.findLayerByFeature(feature);
          return layer.getPosition();
        },
        setPosition(position) {
          const layer = map.layerManager.findLayerByFeature(feature);
          layer.setPosition(position);
        },
      };
      editableText.update();
      if (!forNewFeature) {
        editableText.switchToTransformer();
      }

      feature_ = feature;
    },
    getFeature() {
      return feature_;
    },
    activate() {
      map.getView().on('change:resolution', onResolutionChange);
      editableText.switchToInput();
    },
    destroy() {
      map.getView().un('change:resolution', onResolutionChange);
      editableText.destroy();
    },
  };
}
