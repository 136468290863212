import posthog from 'posthog-js';
import { auth } from '../auth';
import useIsGather from '../composables/useIsGather';

export const EVENTS = {
  maps: 'maps',
  autoDocs: 'auto-docs',
  enviro: 'enviro',
  gather: 'gather',
  aiExplorer: 'ai-explorer',
  project: 'project',
  aiReview: 'ai-review',
  csm: 'csm',
  leaderboard: 'leaderboard',
  leagues: 'leagues',
  tabLogs: 'tablogs',
  assistant: 'assistant',
  spatialInsight: 'spatial-insight',
};

export interface AnalyticsEvent {
  event: string;
  properties: Record<string, any>;
}

export interface AnalyticsIdentify {
  user_name: string;
  email: string;
  enad_ref: string;
  customer_ref: string;
  company_name?: string;
}

const POSTHOG_TOKEN = import.meta?.env?.VITE_POSTHOG_KEY || null;

const getPosthogBaseUrl = () => {
  if (import.meta?.env?.VITE_POSTHOG_HOST) {
    return import.meta.env.VITE_POSTHOG_HOST;
  }
  return 'https://e.datanest.earth';
};

const shouldCollectAnalytics =
  POSTHOG_TOKEN !== undefined &&
  POSTHOG_TOKEN !== '' &&
  (typeof window !== 'undefined'
    ? !(window.location?.host || '').includes('127.0.0.1') &&
    !(window.location?.host || '').includes('localhost')
    : null);

export const initializeAnalytics = () => {
  if (shouldCollectAnalytics) {
    posthog.init(POSTHOG_TOKEN, {
      api_host: getPosthogBaseUrl(),
      disable_session_recording: true,
      autocapture: false,
    });
    const impersonating = auth.impersonating();
    const sessionIdObject = getLongSessionId();
    const registerObject = {
      ...sessionIdObject,
      impersonating: impersonating ?? false,
    };

    posthog.register(registerObject);
  }
};

const getLongSessionId = () => {
  const user = auth.user();
  if (!user) {
    return {};
  }
  const enadRef = user.enad_ref;
  if (!enadRef) {
    console.error('No enad_ref found in user object');
    return {};
  }
  if (useIsGather()) {
    const gatherUserKey = 'gather-user-' + enadRef;
    const gatherLongSessionId = localStorage.getItem(gatherUserKey);
    if (gatherLongSessionId) {
      return {
        gather_long_session_id: gatherLongSessionId,
      };
    }
  } else {
    const hubUserKey = 'hub-user-' + enadRef;
    const hubLongSessionId = localStorage.getItem(hubUserKey);
    if (hubLongSessionId) {
      return {
        hub_long_session_id: hubLongSessionId,
      };
    }
  }

  return {};
};

export const postAnalyticsEvent = (event: AnalyticsEvent) => {
  if (shouldCollectAnalytics) {
    posthog.capture(event.event, event.properties);
  }
};

export const postAnalyticsUserIdentificationEvent = (
  identifier: AnalyticsIdentify
) => {
  if (shouldCollectAnalytics) {
    posthog.identify(identifier.email, identifier);

    posthog.group('company', identifier.customer_ref, {
      name: identifier.company_name,
    });
  }
};
