import { BasemapId } from '../../../../../lib/olbm/layer/basemap/types';

export default {
  index: BasemapId.AU_SA_AERIAL,
  title: 'South Australia - Aerial',
  subtitle: '',
  type: 'satellite',
  options: {
    attributions: 'Aerial imagery: Government for South Australia',
    version: '1.0.0',
    id: 'SAGovMosaic',
    format: 'image/png',
    url: 'https://imagemap.geohub.sa.gov.au/mapproxy/wmts/SAGovMosaic/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
    matrixSet: 'webmercator_22',
    matrixIds: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
    ],
    resolutions: [
      156543.03392812004, 78271.51696392002, 39135.75848196001,
      19567.879241008006, 9783.939620504003, 4891.9698102520015,
      2445.9849051260007, 1222.9924525616002, 611.4962262808001,
      305.74811314040005, 152.87405657048004, 76.43702828524002,
      38.218514142480004, 19.109257071296003, 9.554628535648002,
      4.777314267824001, 2.3886571339120004, 1.1943285669560002,
      0.5971642834780001, 0.29858214173760006, 0.14929107086936003,
      0.07464553543468001,
    ],
    origins: [
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
      [-20037508.3428, 20037508.3428],
    ],
    sizes: [
      [1, 1],
      [2, 2],
      [4, 4],
      [8, 8],
      [16, 16],
      [32, 32],
      [64, 64],
      [128, 128],
      [256, 256],
      [512, 512],
      [1024, 1024],
      [2048, 2048],
      [4096, 4096],
      [8192, 8192],
      [16384, 16384],
      [32768, 32768],
      [65536, 65536],
      [131072, 131072],
      [262144, 262144],
      [524288, 524288],
      [1048576, 1048576],
      [2097152, 2097152],
    ],
    tileSizes: [
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
      [256, 256],
    ],
    style: 'default',
    requestEncoding: 'REST',
    projection: 'EPSG:3857',
  },
};
