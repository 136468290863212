import axios from 'axios';
import { AXIS_ORIENTATION_ENU } from '../business-logic/projection';
import { ROOT_PATH } from './common';

class Api {
  /**
   * Layers
   */
  static getListOfLayers() {
    return axios.get(`${ROOT_PATH}/layer/list`);
  }

  static modifyLayerOrdering(data) {
    return axios.post(`${ROOT_PATH}/layer/modify-ordering`, data);
  }

  static modifyLayer(data) {
    return axios.post(`${ROOT_PATH}/layer/modify`, data);
  }

  static modifyMultipleLayers(data) {
    return axios.post(`${ROOT_PATH}/layer/modify-multiple`, data);
  }

  static deleteLayer(data) {
    return axios.delete(`${ROOT_PATH}/layer/delete`, {
      params: data,
    });
  }

  static deleteFeature({ id, layerId }) {
    return axios.delete(`${ROOT_PATH}/layers/${layerId}/features/${id}`);
  }

  static getSampleRestrictions(sampleId) {
    return axios.get(`${ROOT_PATH}/sample/${sampleId}/restrictions`);
  }

  static createSample(data) {
    return axios.post(`${ROOT_PATH}/sample/create`, data);
  }

  static modifySample(data) {
    return axios.post(`${ROOT_PATH}/sample/modify`, data);
  }

  static deleteSampleById({ id, forced = false }) {
    return axios.delete(`${ROOT_PATH}/sample/delete/${id}?forced=${forced}`);
  }

  static importBulkSamples(data) {
    return axios.post(`${ROOT_PATH}/sample/bulk-import`, data);
  }

  /**
   * Figures
   */
  /**
   * @deprecated Use `useFigureStore.fetchProjectFigures` instead.
   */
  static getListOfFigures(data) {
    return axios.get(`${ROOT_PATH}/figure/list`, {
      params: data,
    });
  }

  static modifyFigure(data) {
    return axios.post(`${ROOT_PATH}/figure/modify`, data);
  }

  static modifyFigureColumn(data) {
    return axios.post(`${ROOT_PATH}/figure/modify-column`, data);
  }

  static modifyFigureBasemapImage({ data, progressUpdater }) {
    return axios.post(`${ROOT_PATH}/figure/modify-basemap-image`, data, {
      onUploadProgress: (progressEvent) =>
        progressUpdater(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        ),
    });
  }

  static deleteFigure(figure_id) {
    return axios.delete(`${ROOT_PATH}/figure/delete/${figure_id}`);
  }

  // Doesn't edit the ProjectFigureLayer, instead edits the FigureLayer
  static modifyFigureLayer(data) {
    return axios.post(`${ROOT_PATH}/figure/layer/modify`, data);
  }

  /**
   * Additional Data
   */
  static getListOfChemicals() {
    return axios.get(`${ROOT_PATH}/data/chemicals`);
  }

  static getListOfChemicalResults(data) {
    return axios.get(`${ROOT_PATH}/data/chemical-results`, { params: data });
  }

  static getListOfScenarioStyles() {
    return axios.get(`/dashboard/scenario/get-styling`);
  }

  static getListOfExceedances() {
    return axios.get(`${ROOT_PATH}/data/exceedances`);
  }

  static getListOfRecommendedServices(data) {
    return axios.get(`${ROOT_PATH}/services/list`, {
      params: data,
    });
  }

  static searchRecommendedServices(data) {
    return axios.get(`${ROOT_PATH}/services/search`, {
      params: data,
    });
  }

  static saveRecommendedService(data) {
    let { should_use_cors_proxy, axis_orientation } = data;
    should_use_cors_proxy = should_use_cors_proxy ?? false;
    axis_orientation = axis_orientation ?? AXIS_ORIENTATION_ENU.value;
    return axios.post(`${ROOT_PATH}/services/modify`, {
      ...data,
      should_use_cors_proxy,
      axis_orientation,
    });
  }

  static deleteRecommendedService(id) {
    return axios.delete(`${ROOT_PATH}/services/delete/${id}`);
  }

  static async loadGatherApps() {
    const {
      data: { tabs: apps },
    } = await axios.get('/gather/tabs');
    const requests = apps.map((app) => {
      return axios.get(`/gather/template/${app.id}`, {
        params: {
          filters: {
            fieldFilterType: 'all-fields',
          },
        },
      });
    });
    const results = await Promise.allSettled(requests);
    const failureIndex = results.findIndex(
      (result) => result.status === 'rejected'
    );
    if (failureIndex !== -1) {
      throw `Failed to load gather app ${apps[failureIndex].id}.`;
    }
    return results.map(
      (result) => (result as PromiseFulfilledResult<any>).value.data.template
    );
  }

  static getLinkedApps(params) {
    return axios.get(`/gather/linked-apps`, {
      params,
    });
  }

  static createSpatialBookmark(value) {
    return axios.post(`${ROOT_PATH}/spatial-bookmark`, value);
  }

  static getSpatialBookmarks() {
    return axios.get(`${ROOT_PATH}/spatial-bookmarks`);
  }

  static updateSpatialBookmark(value) {
    const { id } = value;
    delete value.id;
    return axios.put(`${ROOT_PATH}/spatial-bookmarks/${id}`, value);
  }

  static deleteSpatialBookmark(id) {
    return axios.delete(`${ROOT_PATH}/spatial-bookmarks/${id}`);
  }
}

export default Api;

export * as layer from './layer';
export * as sample from './sample';
