import getFillPatternProperties from '@component-library/business-logic/mapping/helpers/getFillPatternProperties';
import { Map } from 'ol';
import { getStoreApi } from '../../common/store-api';
import type { FigureStylingRuleOnApp } from '../../figure/styling-rule';
import {
  checkIsFigureStylingRuleOnAppFollowed,
  getFigureStylingRulesOnLayer,
} from '../../figure/styling-rule';
import type { Figure } from '../../figure/types';
import { StylingPriority } from '../../style/types';
import { getDefaultStylingPriority } from '../../style/utils';
import { ShapeStyle, SingleShapeLayerModel } from './types';

export default function getShapeStyle(
  map: Map,
  figure: Figure,
  layerModel: SingleShapeLayerModel
): ShapeStyle {
  const storeApi = getStoreApi(map);
  const fsrsOnLayer = getFigureStylingRulesOnLayer(
    map,
    figure,
    layerModel.id
  ) as FigureStylingRuleOnApp[];
  const hasFsrsOnLayer = fsrsOnLayer.length > 0;

  let { stylingPriority } = layerModel.geojson.properties;
  if (!stylingPriority) {
    stylingPriority = getDefaultStylingPriority(hasFsrsOnLayer);
  }

  let result = { ...layerModel.geojson.properties, stylingPriority };

  const lopSample = storeApi.findLopSampleByLayerModelId(layerModel.id);
  if (stylingPriority === StylingPriority.Appbased) {
    const appId = lopSample?.template_tab_id;
    const app = appId ? storeApi.findAppById(appId) : undefined;
    if (app)
      result = {
        ...result,
        color: app.drawing_colour,
        fillStyle: app.drawing_properties?.fillStyle,
        outlineStyle: app.drawing_properties?.outlineStyle,
        weight: undefined,
        ...getFillPatternProperties(app.drawing_properties?.fillStyle),
      };
  } else if (stylingPriority === StylingPriority.Rulebased) {
    const inputValuesForStyling = lopSample?.input_values_for_styling ?? [];
    // The latter styling rule takes precedence.
    for (let i = fsrsOnLayer.length - 1; i >= 0; i--) {
      const fsrOnLayer = fsrsOnLayer[i];
      const field = storeApi.findFieldById(fsrOnLayer.condition.fieldId);

      if (!field) {
        continue;
      }

      if (
        checkIsFigureStylingRuleOnAppFollowed(
          fsrOnLayer,
          field,
          inputValuesForStyling
        )
      ) {
        result = {
          ...result,
          ...fsrOnLayer.style,
        };
        break;
      }
    }
  }

  return result;
}
