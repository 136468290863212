import { createStore } from 'vuex';
import { setContext } from '@component-library/sentry';
import dashboardStore from '@/js/modules/project/dashboard/store';
import gatherTableStore from '@/js/modules/project/gather-table-vertical/store';
/**
 *@todo this should only be registered when needed!
 */
import mapsStore from '@maps/store';
import visualReporterStore from '@visual-reporter/store';
import dataTableStore from '@/js/modules/project/data-table/store';
import { matrices } from '@component-library/enviro';

const store = createStore({
  state: {
    matrix: localStorage.matrix ? localStorage.matrix : 'soil',
    project: null,

    fullScreenLoader: {
      isVisible: false,
    },
  },
  mutations: {
    SET_MATRIX(state, matrix) {
      state.matrix = matrix;
    },
    UPDATE_PROJECT(state, project) {
      state.project = project;
      setContext('project', project);
    },
    RESET_PROJECT(state) {
      state.matrix = 'soil';
      state.project = null;
      setContext('project', null);
    },
    ENABLE_PROJECT_MATRIX(state, matrix) {
      state.project['has_' + matrix + '_upload'] = 1;
    },
    SET_FULL_SCREEN_LOADER(state, fullScreenLoader) {
      state.fullScreenLoader = fullScreenLoader;
    },
  },
  actions: {
    changeMatrix(context, matrix) {
      context.commit('SET_MATRIX', matrix || 'soil');

      localStorage.matrix = matrix;
    },
    enableMatrix(context, matrix) {
      context.commit('ENABLE_PROJECT_MATRIX', matrix);
    },
    changeMatrixBasedOnProject(context, project) {
      let specifiedProject = project || context.state.project;

      let selectedMatrix = 'soil';

      if (specifiedProject) {
        for (let matrix of matrices) {
          if (specifiedProject[`has_${matrix}_upload`] == true) {
            selectedMatrix = matrix;
            break;
          }
        }
      }

      context.commit('SET_MATRIX', selectedMatrix);
      localStorage.matrix = selectedMatrix;
    },
    updateProject(context, updatedProjectData) {
      if (!updatedProjectData) {
        context.dispatch('resetProject');
        return;
      }

      let project = { ...context.state.project, ...updatedProjectData };
      context.commit('UPDATE_PROJECT', project);
    },
    resetProject(context) {
      context.commit('RESET_PROJECT');

      localStorage.removeItem('matrix');
    },

    // Please call from the pinia project store instead.
    loadSpecificProject(context, { project, autoRedirect, temporaryRedirect }) {
      // if project is imported, send the user to finish creating project
      if (!project.is_confirmed) {
        context.dispatch('updateProject', project);
        Vue.router.push('/project-settings');
        return;
      }

      // project is laboratory request and not configured
      if (project.lab_access_token) {
        Vue.router.push({
          name: 'project_request',
          params: {
            token: project.lab_access_token,
          },
        });

        context.dispatch('resetProject');
        return;
      }

      context.dispatch('updateProject', project);
      context.dispatch('changeMatrixBasedOnProject');

      if (!autoRedirect) {
        return;
      }

      if (temporaryRedirect) {
        if (
          Vue.router.currentRoute.path === temporaryRedirect ||
          Vue.router.currentRoute.fullPath === temporaryRedirect
        ) {
          window.location.reload();
          return;
        }
        Vue.router.push(temporaryRedirect);
        return;
      }
    },
    showFullScreenLoader(context, message) {
      context.commit('SET_FULL_SCREEN_LOADER', {
        isVisible: true,
        message,
      });
    },
    hideFullScreenLoader(context) {
      context.commit('SET_FULL_SCREEN_LOADER', {
        isVisible: false,
        message: undefined,
      });
    },
  },
  getters: {
    get_project_field_by_key:
      (state) =>
      (key, multiKey = null) => {
        if (!state.project) {
          return null;
        }

        if (multiKey) {
          let first = state.project[key];
          return first ? first[multiKey] : null;
        }

        return state.project[key] || null;
      },
    get_project_country: (state) => {
      if (!state.project || !state.project.location) {
        return 'NZ';
      }
      return state.project.location.country;
    },
    get_project_timezone: (state) => {
      return state.project?.timezone || null;
    },
    get_date_format_by_country: (state) => {
      if (!state.project || !state.project.location) {
        return 'DD-MM-YYYY';
      }

      const country = state.project.location.country;

      if (country == 'US' || country == 'CA') {
        return 'YYYY-MM-DD';
      }

      return 'DD-MM-YYYY';
    },
    project_has_matrix_upload: (state) => {
      if (!state.project) {
        return false;
      }

      return state.project['has_' + state.matrix + '_upload'];
    },
    is_figure_only_type: (state) => {
      if (!state.project) {
        return false;
      }

      return state.project.project_type == 1;
    },
    is_project_processed: (state) => {
      if (!state.project) {
        return false;
      }

      return state.project.processed_at != null;
    },
    project_requires_qa_qc: (state) => {
      const project = state.project;

      return (
        project &&
        (project.has_chemical_misalignment ||
          project.has_sample_merging_misalignment ||
          project.has_matrice_misalignment ||
          project.has_unit_misalignment)
      );
    },
    // USE PINIA STORE
    get_project_base_route: (state) => () => {
      if (!state.project) {
        return '/projects';
      }

      return '/data-manager';
    },
    is_enviro_project: (state) => {
      return state.project && state.project.project_type == 0;
    },
  },
  modules: {
    dashboard: dashboardStore,
    maps: { namespaced: true, ...mapsStore },
    reporter: visualReporterStore,
    'data-table': dataTableStore,
    gather: gatherTableStore,
  },
});

/** @deprecated */
export default store;
/** @deprecated */
export const useStore = () => store;
