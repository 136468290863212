import { captureException } from '@component-library/sentry';
import arcgisPbfDecode from 'arcgis-pbf-parser';
import _cloneDeep from 'lodash/cloneDeep';
import { createStyleFunctionFromUrl } from 'ol-esri-style';
import { stylefunction as _styleVectorTileLayer } from 'ol-mapbox-style';
import { getHeight, getIntersection, getWidth, isEmpty } from 'ol/extent';
import { EsriJSON, GeoJSON, MVT } from 'ol/format';
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
  VectorTile as VectorTileLayer,
} from 'ol/layer';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { transformExtent } from 'ol/proj';
import {
  ImageArcGISRest,
  TileArcGISRest,
  Vector as VectorSource,
  VectorTile as VectorTileSource,
  XYZ,
} from 'ol/source';
import { defaultImageLoadFunction } from 'ol/source/Image';
import { createForExtent, createXYZ } from 'ol/tilegrid';
import TileGrid from 'ol/tilegrid/TileGrid';
import { Dpi } from '../../../business-logic/measure';
import { assignIdToLayer } from '../layers/LayerManager';
import * as utils from '../utils';
import { extentToBounds, transformEsriServiceLayerExtent } from '../utils';
import * as network from '../utils/network';
import enableLoadingEvents from './enableLoadingEvents';

const WKID = 4326;

const glStyleByUrl = {};

function styleVectorTileLayer(map, layer, glStyle) {
  const glStyleCopy = _cloneDeep(glStyle);
  const { sources } = glStyleCopy;
  const source = Object.keys(sources).find(
    (key) => sources[key].type === 'vector'
  );
  const { zoom } = map.getViewer().figureLayout;
  try {
    glStyleCopy.layers = glStyleCopy.layers.map((glStyleLayer) => {
      let result = glStyleLayer;

      if (result.type === 'line') {
        let lineDasharray = result.paint['line-dasharray'];
        if (Array.isArray(lineDasharray)) {
          lineDasharray = lineDasharray.map((value) => value * zoom);
        }

        let lineWidth = result.paint['line-width'];
        if (typeof lineWidth === 'number') {
          lineWidth *= zoom;
        } else if (
          lineWidth &&
          typeof lineWidth === 'object' &&
          'stops' in lineWidth
        ) {
          lineWidth = {
            ...lineWidth,
            stops: lineWidth.stops.map((stop) => [stop[0], stop[1] * zoom]),
          };
        }

        result = {
          ...result,
          paint: {
            ...result.paint,
            'line-dasharray': lineDasharray,
            'line-width': lineWidth,
          },
        };
      } else if (result.type === 'symbol') {
        let textSize = result.layout['text-size'];
        if (typeof textSize === 'number') {
          textSize *= zoom;
        }

        let textLetterSpacing = result.layout['text-letter-spacing'];
        if (typeof textLetterSpacing === 'number') {
          textLetterSpacing *= zoom;
        }

        let textHaloWidth = result.paint['text-halo-width'];
        if (typeof textHaloWidth === 'number') {
          textHaloWidth *= zoom;
        }

        result = {
          ...result,
          layout: {
            ...result.layout,
            'text-size': textSize,
            'text-letter-spacing': textLetterSpacing,
          },
          paint: {
            ...result.paint,
            'text-halo-width': textHaloWidth,
          },
        };
      }

      if (layer.options.shouldIgnoreLayerStyleRestrictions ?? true) {
        delete result.minzoom;
        delete result.maxzoom;
      }

      return result;
    });
    _styleVectorTileLayer(layer, glStyleCopy, source);
  } catch (e) {
    captureException(e);
  }
}

function calculateMaxAllowableOffset(extent, size) {
  const xResolution = getWidth(extent) / size;
  const yResolution = getHeight(extent) / size;
  return Math.max(xResolution, yResolution);
}

function createImageryLayer(map, options) {
  const {
    attributions,
    mapTileConfig,
    projection = 'EPSG:3857',
    shouldUseCorsProxy,
  } = options;
  let { url } = options;
  const visibleExtent = transformExtent(
    options.visibleExtent,
    'EPSG:4326',
    map.getView().getProjection()
  );

  // Use the Map Tile API to get images of the layer.
  if (mapTileConfig) {
    const { tileInfo, maxLOD } = mapTileConfig;
    let { resolutions } = tileInfo;
    if (typeof maxLOD === 'number') {
      resolutions = resolutions.filter((r, index) => index <= maxLOD);
    }

    const tileGrid = new TileGrid({
      extent: transformExtent(
        options.visibleExtent,
        'EPSG:4326',
        tileInfo.projection
      ),
      origin: tileInfo.origin,
      tileSize: tileInfo.tileSize,
      resolutions,
    });
    map.patchTileGrid(tileGrid, tileInfo.projection);

    if (shouldUseCorsProxy) {
      url = utils.network.proxify(url);
    }
    const layerSource = new XYZ({
      crossOrigin: 'anonymous',
      attributions,
      projection: tileInfo.projection,
      url: `${url}/tile/{z}/{y}/{x}`,
      tileGrid,
    });
    const layer = new TileLayer({
      source: layerSource,
      extent: visibleExtent,
    });
    assignIdToLayer(layer);
    layer.options = options;
    layer.hasFeature = function (feature) {
      return false;
    };

    enableLoadingEvents(layer);
    return layer;
  }

  // Use the Export API to get images of the layer.
  const { token, layers } = options;
  let params = {
    DPI: Dpi.Screen,
  };

  if (token) {
    params = {
      ...params,
      token,
    };
  }

  if (layers) {
    params = {
      ...params,
      layers: `show:${layers.join(',')}`,
    };
  }

  const { isTileServiceForced, tileSize } =
    map.layerManager.getEsriImageryLayerInfo(options);
  let layer;
  if (!isTileServiceForced) {
    const layerSource = new ImageArcGISRest({
      crossOrigin: 'anonymous',
      attributions,
      url,
      params,
      projection,
      imageLoadFunction: (image, src) => {
        // Fix to the bug of OL that causes wrong DPI applied.
        const { zoom } = map.getViewer().figureLayout;
        const dpi = params.DPI;
        let newSrc = src.replace(/DPI=(\d+)/, `DPI=${Math.round(dpi * zoom)}`);
        if (shouldUseCorsProxy) {
          newSrc = utils.network.proxify(newSrc);
        }
        defaultImageLoadFunction(image, newSrc);
      },
    });
    layer = new ImageLayer({
      source: layerSource,
      extent: visibleExtent,
    });
  } else {
    const maxZoom = map.getView().getMaxZoom();
    const tileGrid = createForExtent(
      transformExtent(options.visibleExtent, 'EPSG:4326', projection),
      maxZoom,
      tileSize
    );
    const layerSource = new TileArcGISRest({
      crossOrigin: 'anonymous',
      attributions,
      url,
      params,
      tileGrid,
      projection,
    });
    map.patchTileGrid(tileGrid, projection);

    const getRequestUrl_ = layerSource.getRequestUrl_;
    layerSource.getRequestUrl_ = function (...args) {
      let requestUrl = getRequestUrl_.call(this, ...args);
      if (shouldUseCorsProxy) {
        requestUrl = utils.network.proxify(requestUrl);
      }
      return requestUrl;
    };

    layer = new TileLayer({
      source: layerSource,
      extent: visibleExtent,
    });
  }

  assignIdToLayer(layer);
  layer.options = options;
  layer.hasFeature = function (feature) {
    return false;
  };

  enableLoadingEvents(layer);

  return layer;
}

const esri = {
  featureLayer: function (map, options) {
    const {
      attributions,
      token,
      minScale,
      maxScale,
      pbfSupported,
      shouldUseCorsProxy,
    } = options;
    let { url } = options;
    if (shouldUseCorsProxy) {
      url = utils.network.proxify(url);
    }
    const visibleExtent = transformExtent(
      options.visibleExtent,
      'EPSG:4326',
      map.getView().getProjection()
    );
    const tileSize = 512;
    const layerSource = new VectorSource({
      attributions,
      loader: function (extent, resolution, projection, onLoad, onError) {
        const intersection = getIntersection(extent, visibleExtent);
        if (isEmpty(intersection)) {
          onLoad([]);
          return;
        }

        const { _southWest, _northEast } = extentToBounds(
          intersection,
          projection
        );
        const extentParam = {
          spatialReference: { wkid: WKID },
          xmin: _southWest.lng,
          ymin: _southWest.lat,
          xmax: _northEast.lng,
          ymax: _northEast.lat,
        };
        let params = {
          f: pbfSupported ? 'pbf' : 'json',
          spatialRel: 'esriSpatialRelIntersects',
          geometry: JSON.stringify(extentParam),
          geometryType: 'esriGeometryEnvelope',
          inSR: WKID,
          outFields: '*',
          outSR: WKID,
          resultType: 'tile',
        };
        if (token) {
          params = {
            ...params,
            token,
          };
        }

        if (pbfSupported) {
          const maxAllowableOffset = calculateMaxAllowableOffset(
            [
              extentParam.xmin,
              extentParam.ymin,
              extentParam.xmax,
              extentParam.ymax,
            ],
            tileSize
          );
          params = {
            ...params,
            maxAllowableOffset,
            quantizationParameters: JSON.stringify({
              extent: extentParam,
              mode: 'view',
              origionPosition: 'upperLeft',
            }),
          };
        }

        const urlWithParams =
          url + '/query/?' + new URLSearchParams(params).toString();
        axios
          .get(urlWithParams, {
            ...network.createRequestOptions(),
            responseType: pbfSupported ? 'arraybuffer' : 'json',
          })
          .then(({ data }) => {
            let features = [];
            if (pbfSupported) {
              const { featureCollection } = arcgisPbfDecode(data);
              const featureFormat = new GeoJSON({
                featureProjection: projection,
              });

              features = featureFormat.readFeatures(featureCollection);
            } else {
              const featureFormat = new EsriJSON();
              features = featureFormat.readFeatures(data, {
                featureProjection: projection,
              });
            }
            for (const feature of features) {
              if (!layerSource.hasFeature(feature)) {
                layerSource.addFeature(feature);
              }
            }
            onLoad(features);
          })
          .catch((e) => {
            console.error(e);
            captureException(e);
            onError();
          });
      },
      strategy: tileStrategy(
        createXYZ({
          extent: visibleExtent,
          tileSize,
          maxResolution: map.getView().getMaxResolution(),
          maxZoom: map.getView().getMaxZoom(),
        })
      ),
    });

    const viewer = map.getViewer();
    const projection = map.getView().getProjection();
    const center = map.getView().getCenter();
    const minResolution =
      maxScale > 0
        ? map.scaleToResolution(projection, maxScale, center)
        : undefined;
    const maxResolution =
      minScale > 0
        ? map.scaleToResolution(projection, minScale, center)
        : undefined;
    const layer = new VectorLayer({
      source: layerSource,
      extent: visibleExtent,
      minResolution,
      maxResolution,
    });
    assignIdToLayer(layer);
    layer.options = {
      ...options,
      isFeatureLayer: true,
    };
    layer.hasFeature = function (feature) {
      return layerSource.hasFeature(feature);
    };

    enableLoadingEvents(layer);

    createStyleFunctionFromUrl(
      url,
      map.getView().getProjection(),
      token,
      () => viewer.figureLayout.zoom
    ).then((styleFunction) => {
      layer.setStyle(styleFunction);
    });

    return layer;
  },
  dynamicMapLayer: createImageryLayer,
  vectorTileLayer: function (map, options) {
    const {
      attributions,
      styleUrl,
      projection,
      origin,
      tileSize,
      maxLOD,
      shouldUseCorsProxy,
    } = options;
    let { url } = options;
    if (shouldUseCorsProxy) {
      url = utils.network.proxify(url);
    }
    const visibleExtent = transformExtent(
      options.visibleExtent,
      'EPSG:4326',
      map.getView().getProjection()
    );

    let { resolutions } = options;
    if (typeof maxLOD === 'number') {
      resolutions = resolutions.filter((r, index) => index <= maxLOD);
    }

    const tileGrid = new TileGrid({
      extent: transformExtent(options.visibleExtent, 'EPSG:4326', projection),
      origin,
      tileSize,
      resolutions,
    });
    map.patchTileGrid(tileGrid, projection);

    const layerSource = new VectorTileSource({
      attributions,
      format: new MVT(),
      url,
      projection,
      extent: visibleExtent,
      tileGrid,
    });
    const layer = new VectorTileLayer({
      source: layerSource,
      extent: visibleExtent,
    });
    assignIdToLayer(layer);
    layer.options = options;
    layer.hasFeature = function (feature) {
      const extent = map.getView().calculateExtent();
      return this.getFeaturesInExtent(extent).indexOf(feature) !== -1;
    };
    layer.on('refresh', () => {
      const glStyle = glStyleByUrl[styleUrl];
      if (glStyle) {
        styleVectorTileLayer(map, layer, glStyle);
      }
    });

    enableLoadingEvents(layer);

    const glStyle = glStyleByUrl[styleUrl];
    if (glStyle) {
      styleVectorTileLayer(map, layer, glStyle);
    } else {
      fetch(styleUrl)
        .then(function (response) {
          return response.json();
        })
        .then(function (glStyle) {
          glStyleByUrl[styleUrl] = glStyle;
          styleVectorTileLayer(map, layer, glStyle);
        });
    }

    return layer;
  },
  imageMapLayer: createImageryLayer,
};

export default esri;

function requestEsriServiceLayerExtent(url, token) {
  return axios
    .get(
      url + '?f=pjson' + (token ? `&token=${token}` : ''),
      network.createRequestOptions()
    )
    .then(({ data }) => {
      return transformEsriServiceLayerExtent(data.fullExtent);
    });
}

export async function checkAndUpdateEsriServiceLayers(layers) {
  const esriServiceLayers = layers.filter(
    (layer) => layer.data.properties.esri_type
  );

  const failedLayerTitles = [];

  return await Promise.all(
    esriServiceLayers.map((layer) => {
      const { token, extent, shouldUseCorsProxy } = layer.data.properties;
      let { url } = layer.data.properties;
      if (shouldUseCorsProxy) {
        url = utils.network.proxify(url);
      }
      if (!extent) {
        return requestEsriServiceLayerExtent(url, token)
          .then((esriServiceLayerExtent) => {
            layer.data.properties.extent = esriServiceLayerExtent;
          })
          .catch(() => {
            failedLayerTitles.push(layer.text);
          });
      }
    })
  ).then(() => failedLayerTitles);
}
