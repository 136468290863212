import { Map } from 'ol';
import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer';
import { transformExtent } from 'ol/proj';
import { ImageArcGISRest, TileArcGISRest, XYZ } from 'ol/source';
import { defaultImageLoadFunction } from 'ol/source/Image';
import { createForExtent } from 'ol/tilegrid';
import TileGrid from 'ol/tilegrid/TileGrid';
import { Code, throwError } from '../../../common/error';
import { proxify } from '../../../common/network';
import { getLayoutZoom } from '../../../measurement/layout';
import { patchTileGrid } from '../../../measurement/scale';
import { Dpi } from '../../../measurement/types';
import type { LayerUsage } from '../../constants';
import { LayerType } from '../../types';
import { createLayerProperties, getVisibleExtent } from '../../utils';
import enableLoadingEvents from '../enableLoadingEvents';
import {
  ImageServerFolderLayerModel,
  MapImageServerLayer,
  MapServerFolderLayerModel,
} from './types';
import {
  checkIsMapServerFolderLayerModel,
  getMapImageServerLayerInfo,
  getVisibleMapServerItemLayerModels,
} from './utils';

export default function createMapImageServerLayer(
  map: Map,
  folderModel: MapServerFolderLayerModel | ImageServerFolderLayerModel,
  layerType: LayerType = LayerType.BASEMAP_SERVICE,
  layerUsage?: LayerUsage
): MapImageServerLayer {
  const {
    url: originalUrl,
    attributions,
    mapTileConfig,
    projection = 'EPSG:3857',
    shouldUseCorsProxy,
  } = folderModel.geojson.properties;

  const url = shouldUseCorsProxy ? proxify(originalUrl) : originalUrl;

  const visibleExtent = getVisibleExtent(
    folderModel,
    map.getView().getProjection()
  );

  // Use the Map Tile API to get images of the layer.
  if (mapTileConfig) {
    const { tileInfo, maxLOD } = mapTileConfig;
    let { resolutions } = tileInfo;
    if (typeof maxLOD === 'number') {
      resolutions = resolutions.filter((r, index) => index <= maxLOD);
    }

    const tileGrid = new TileGrid({
      extent: transformExtent(
        folderModel.geojson.properties.visibleExtent,
        'EPSG:4326',
        tileInfo.projection
      ),
      origin: tileInfo.origin,
      tileSize: tileInfo.tileSize,
      resolutions,
    });
    patchTileGrid(map, tileGrid, tileInfo.projection);

    const layerSource = new XYZ({
      crossOrigin: 'anonymous',
      attributions,
      projection: tileInfo.projection,
      url: `${url}/tile/{z}/{y}/{x}`,
      tileGrid,
    });
    const layer = new TileLayer({
      source: layerSource,
      extent: visibleExtent,
      properties: createLayerProperties(folderModel.id, layerType, layerUsage),
    }) as MapImageServerLayer;

    addLayerMixin(layer);
    enableLoadingEvents(layer);

    return layer;
  }

  // Use the Export API to get images of the layer.
  const { token } = folderModel.geojson.properties;
  let params: Record<string, any> = {
    DPI: Dpi.Screen,
  };

  if (token) {
    params = {
      ...params,
      token,
    };
  }

  if (checkIsMapServerFolderLayerModel(folderModel)) {
    const visibleItemModels = getVisibleMapServerItemLayerModels(folderModel);
    if (visibleItemModels.length > 0) {
      params = {
        ...params,
        layers: `show:${visibleItemModels
          .map((vim) => vim.geojson.properties.index)
          .join(',')}`,
      };
    }
  }

  const { isTileServiceForced, tileSize } = getMapImageServerLayerInfo(
    map,
    folderModel
  );
  let layer;
  if (!isTileServiceForced) {
    const layerSource = new ImageArcGISRest({
      crossOrigin: 'anonymous',
      attributions,
      url,
      params,
      projection,
      imageLoadFunction: (image, src) => {
        const layoutZoom = getLayoutZoom(map);
        const dpi = params.DPI;
        let newSrc = src.replace(
          /DPI=(\d+)/,
          `DPI=${Math.round(dpi * layoutZoom)}`
        );
        if (shouldUseCorsProxy) {
          newSrc = proxify(newSrc);
        }
        defaultImageLoadFunction(image, newSrc);
      },
    });

    layer = new ImageLayer({
      source: layerSource,
      extent: visibleExtent,
      properties: createLayerProperties(folderModel.id, layerType, layerUsage),
    });
  } else {
    const maxZoom = map.getView().getMaxZoom();
    const tileGrid = createForExtent(
      transformExtent(
        folderModel.geojson.properties.visibleExtent,
        'EPSG:4326',
        projection
      ),
      maxZoom,
      tileSize
    );
    const layerSource = new TileArcGISRest({
      crossOrigin: 'anonymous',
      attributions,
      url,
      params,
      tileGrid,
      projection,
    });
    patchTileGrid(map, tileGrid, projection);

    // @ts-expect-error - The getRequestUrl_ method is not exposed.
    const getRequestUrl_ = layerSource.getRequestUrl_;
    // @ts-expect-error - The getRequestUrl_ method is not exposed.
    layerSource.getRequestUrl_ = function (...args) {
      let requestUrl = getRequestUrl_.call(this, ...args);
      if (shouldUseCorsProxy) {
        requestUrl = proxify(requestUrl);
      }
      return requestUrl;
    };

    layer = new TileLayer({
      source: layerSource,
      extent: visibleExtent,
      properties: createLayerProperties(folderModel.id, layerType, layerUsage),
    });
  }

  addLayerMixin(layer);
  enableLoadingEvents(layer);

  return layer;
}

function addLayerMixin(layer: MapImageServerLayer) {
  layer.getFirstFeature = function () {
    throwError(Code.MethodNotImplemented, 'getFirstFeature');
  };
  layer.checkHasFeature = function () {
    throwError(Code.MethodNotImplemented, 'checkHasFeature');
  };
  layer.toGeoJSON = function () {
    throwError(Code.MethodNotImplemented, 'toGeoJSON');
  };
  layer.getBounds = function () {
    throwError(Code.MethodNotImplemented, 'getBounds');
  };
  layer.refresh = function () {};
}
