import { View } from 'ol';
import { defaults as olControlDefaults } from 'ol/control';
import { normalizeJsonData } from '../../../api/layer/utils';
import { LAYER_TYPES } from '../../../business-logic/layer';
import { getStoreApi, setStoreApi } from '../../../lib/olbm/common/store-api';
import { MapType } from '../../../lib/olbm/common/types';
import { BasemapId } from '../../../lib/olbm/layer/basemap/types';
import TileSet from '../../TileSet';
import { disableInteractions, enableInteractions } from '../interactions';
import { createSampleLayer } from '../layers';
import { latLngToCoordinate, scaleToResolution } from '../utils';
import DatanestMap from './DatanestMap';

export class CalloutMap extends DatanestMap {
  private _container: CalloutMapContainer;
  tileSet: TileSet;
  constructor(options, viewer, basemapId: BasemapId) {
    super(options, viewer, MapType.CALL_OUT);
    this._container = new CalloutMapContainer(this);
    this.tileSet = new TileSet(this, basemapId);
  }

  getContainer() {
    return this._container;
  }

  enableInteractions() {
    enableInteractions(this);
    disableInteractions(this.getViewer().map);
  }

  disableInteractions() {
    disableInteractions(this);
    enableInteractions(this.getViewer().map);
  }

  refreshSampleLayers() {
    this.layerManager.getSampleLayers().forEach((sl) => sl.refresh());
  }
}

class CalloutMapContainer {
  private map: CalloutMap;
  private _loadingLayerIds: number[] = [];
  constructor(map: CalloutMap) {
    this.map = map;
  }
  startLoadingLayer(id) {
    if (this._loadingLayerIds.length === 0) {
      this.map.controlManager.showLoadingSpinner();
    }

    if (this._loadingLayerIds.indexOf(id) === -1) {
      this._loadingLayerIds.push(id);
    }
  }
  endLoadingLayer(id) {
    const index = this._loadingLayerIds.indexOf(id);
    if (index !== -1) {
      this._loadingLayerIds.splice(index, 1);
    }

    if (this._loadingLayerIds.length === 0) {
      this.map.controlManager.hideLoadingSpinner();
    }
  }
}

export default function createCalloutMap(
  target: HTMLDivElement,
  viewer,
  mapData
) {
  const { basemapApiIndex: basemapId, centerLatLng, scale } = mapData;
  // The layerId is used here for backward-compatibility.
  const layerIds = (mapData.layerIds ?? [mapData.layerId]).map((layerId) =>
    parseInt(layerId, 10)
  );

  const { project } = viewer;
  const projectLocation = {
    lat: project.latitude,
    lng: project.longitude,
  };
  const center = latLngToCoordinate(
    centerLatLng ?? projectLocation,
    'EPSG:3857'
  );

  const map = new CalloutMap(
    {
      target,
      controls: olControlDefaults({
        attribution: false,
        rotate: false,
        zoom: false,
      }),
      view: new View({
        center,
        zoom: 15,
      }),
    },
    viewer,
    basemapId
  );

  const superStoreApi = getStoreApi(map);
  const storeApi = {
    ...superStoreApi,
    getLayerModels() {
      // Needs to add shape layer models to the store because
      // the store API from the DatanestMap has only sample
      // group layer models.
      const superLayerModels = superStoreApi.getLayerModels();
      const layerModels = layerIds
        .map((layerId) => {
          return viewer.getLayerById(layerId);
        })
        .filter((l) => l.data.properties.type !== LAYER_TYPES.SAMPLE_GROUP)
        .map((l) => {
          const { id, geojson } = l;
          return {
            id,
            geojson: normalizeJsonData(geojson),
            visible: true,
            children: [],
          };
        });
      return [...superLayerModels, ...layerModels];
    },
  };
  setStoreApi(map, storeApi);

  // Add the basemap layer
  map.tileSet.renderBasemap();

  //Add the layers
  const addLayerPromises: Promise<undefined>[] = [];
  for (let layerId of layerIds) {
    const layer = viewer.getLayerById(layerId);
    if (layer.data.properties.type === LAYER_TYPES.SAMPLE_GROUP) {
      const sampleGroup = viewer.getLayerById(layerId);
      const sampleLayer = createSampleLayer(map, sampleGroup) as any;
      map.layerManager.addLayer(sampleLayer);
    } else {
      const layerModel = storeApi.findLayerModelById(layerId)!;
      const addLayerPromise = map.olbmLayerManager
        .createLayers(layerModel)
        .then((mapLayers) => {
          mapLayers.forEach((mapLayer) => {
            map.olbmLayerManager.addLayer(mapLayer);
          });
        });
      addLayerPromises.push(addLayerPromise);
    }
  }

  // Sort the layers
  Promise.all(addLayerPromises).then(() => {
    const zIndexByLayerId = viewer.getZIndexByLayerIdForMapCallout(layerIds);
    Object.keys(zIndexByLayerId).forEach((key) => {
      const layerId = parseInt(key, 10);
      const layer =
        map.layerManager.findLayerByDatabaseLayerId(layerId) ??
        map.olbmLayerManager.findLayerByModelId(layerId);
      layer.setZIndex(zIndexByLayerId[key]);
    });
  });

  if (scale) {
    // Set resolution
    const view = map.getView();
    const projection = view.getProjection();
    const resolution = scaleToResolution(
      projection,
      scale / viewer.figureLayout.zoom,
      center
    );
    view.setResolution(resolution);
  }

  map.disableInteractions();

  return map;
}
