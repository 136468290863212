<template>
  <div v-click-outside="clickOutsideInput" class="position-relative">
    <div class="d-flex align-items-center">
      <div class="w-100">
        <div
          class="input-group"
          :class="{
            'dropdown-toggle': clickToToggle && !disableSearch,
          }"
          @submit.prevent
          @click="onClickToToggle"
        >
          <span v-if="prepend" class="input-group-text">
            <i class="fas fa-search"></i>
          </span>
          <div class="form-floating">
            <input
              id="autocomplete"
              v-model.trim="query"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': error != null,
              }"
              :disabled="disableSearch || disabled"
              autocomplete="off"
              placeholder="a"
              @keyup="keyMonitor($event)"
            />
            <label for="autocomplete">{{ placeholder }}</label>
          </div>
        </div>
        <div v-if="error != null" class="invalid-feedback">
          {{ error[0] }}
        </div>
      </div>

      <button
        v-if="disableSearch && !isLoading"
        class="btn btn-outline-danger ms-2"
        type="button"
        @click="removeSelection"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <template v-if="getResults?.length || isLoading">
      <div class="dropdown-menu show w-100 mt-1">
        <template v-if="isLoading">
          <div class="dropdown-item text-center">
            <Spinner small />
          </div>
        </template>
        <template v-else>
          <div
            v-for="(result, rIndex) in getResults"
            :key="rIndex"
            class="dropdown-item"
            :class="{
              'bg-danger text-white': result['style'] == 'danger',
            }"
            @click="selectResult(result, true)"
          >
            <span v-html="getDisplayValue(result, displayIndex, true)" />
            <template v-if="subIndex">
              <br />
              <small
                :class="{
                  'text-white': result['style'] == 'danger',
                  'text-secondary': !result['style'],
                }"
              >
                <span v-html="getDisplayValue(result, displayIndex, true)" />
              </small>
            </template>
          </div>
        </template>
      </div>
    </template>
    <template
      v-else-if="!isLoading && dataLoaded && query && getResults?.length == 0"
    >
      <div class="dropdown-menu show w-100">
        <div class="dropdown-item">No results found.</div>
      </div>
    </template>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { usePublicApi } from '../api';
import Spinner from './Spinner.vue';

export default {
  name: 'Autocomplete',
  components: { Spinner },
  props: [
    'queryUrl',
    'placeholder',
    'previousValue',
    'disabled',
    'container',
    'containerFilter',
    'displayIndex',
    'subIndex',
    'error',
    'dontDisable',
    'prepend',
    'shortenByChar',
    'clearOnClick',
    'selectOnExit',
    'byProject',
    'formatText',
    'clickToToggle',
    'required',
  ],
  data: () => ({
    query: null,
    disableSearch: false,
    isLoading: false,
    results: [],
    dataLoaded: false,
  }),
  watch: {
    previousValue(newVal) {
      if (newVal != null && !this.dontDisable) {
        this.disableSearch = true;
      }

      if (!newVal && !this.dontDisable) {
        this.disableSearch = false;
      }

      this.query = newVal;

      this.$emit('changeValue', {
        value: newVal,
      });
    },
  },
  computed: {
    getResults() {
      return this.results;
    },
  },
  methods: {
    hideResults() {
      this.dataLoaded = false;

      if (this.results?.length == 0) {
        return;
      }

      this.results = [];
    },
    clickOutsideInput() {
      if (this.selectOnExit && this.results?.length > 0) {
        this.selectResult(this.results[0], false);
      }

      this.hideResults();
    },
    getDisplayValue(item, index, highlight = false) {
      const iQuery = new RegExp(this.query, 'ig');

      let value;
      if (this.formatText) {
        value = this.formatText(item);
      } else {
        // Add the ability to support multiple indices which are separated by a comma.
        const indices = index.split(',');
        value = indices
          .map((item2) => item[item2] ?? '')
          .filter((item) => !!item)
          .join(', ')
          .replace(/<\/?[^>]+>/gi, ' ')
          .toString();
      }

      if (!highlight) {
        return value;
      }

      return value.replace(iQuery, (matchedTxt) => {
        return `<span class="fw-bold fw-bold">${matchedTxt}</span>`;
      });
    },
    autoComplete: _debounce(function () {
      this.$emit('changeValue', {
        value: this.query,
      });

      if (this.query != null && this.query?.length > 1) {
        this.search();
        return;
      }

      this.results = [];
      this.dataLoaded = false;
      this.isLoading = false;
    }, 800),
    async search() {
      this.isLoading = true;
      const api = usePublicApi();

      try {
        let { data } = await api.get(this.queryUrl, {
          params: {
            q: this.query,
            in_project: this.byProject,
          },
        });

        if (this.container != null) {
          data = data[this.container];
        }

        if (typeof this.containerFilter === 'function') {
          data = this.containerFilter(data);
        }

        this.results = data;
        this.dataLoaded = true;
      } catch (e) {
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
    selectResult(result, hideResults) {
      if (!this.dontDisable) {
        this.disableSearch = true;
      }

      this.$emit('changeValue', {
        value: this.getDisplayValue(result, this.displayIndex),
        data: result,
      });

      this.query = this.getDisplayValue(result, this.displayIndex);
      if (this.shortenByChar) {
        this.query = this.query.split(this.shortenByChar)[0];
      }

      if (this.clearOnClick) this.query = '';

      if (hideResults) {
        this.hideResults();
      }
    },
    removeSelection() {
      this.disableSearch = false;

      this.$emit('changeValue', {
        value: null,
      });

      this.query = null;
    },
    keyMonitor(event) {
      this.dataLoaded = false;

      if (event.key === 'Enter' && this.results?.length > 0) {
        this.selectResult(this.results[0], true);
      } else {
        this.isLoading = true;

        this.autoComplete(event);
      }
    },
    onClickToToggle() {
      if (!this.clickToToggle || this.disableSearch) {
        return;
      }
      this.search();
    },
  },
  mounted() {
    if (!this.previousValue) {
      return;
    }

    this.query = this.previousValue;
    if (this.shortenByChar) {
      this.query = this.query.split(this.shortenByChar)[0];
    }

    this.$emit('changeValue', {
      value: this.previousValue,
    });

    this.disableSearch = true;
  },
};
</script>
<style scoped>
.auto-complete-loader {
  position: absolute;
  right: 10px;
  top: 0px;
  bottom: 0px;
  z-index: 9999;
}

.dropdown-menu {
  min-width: auto;
  padding: 8px;
  overflow-y: auto;
  max-height: 160px;
}

.dropdown-item {
  margin-bottom: 6px;
  padding: 8px;
  border-radius: 6px;
  white-space: normal;
  transition: 0.3s all ease;
}

.dropdown-item:hover {
  background: #f4f4f4;
}

.dropdown-item:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.dropdown-item.bg-danger:hover {
  background-color: #bb2d3b !important;
}

.dropdown-toggle::after {
  position: absolute;
  right: 16px;
  top: calc(50% - 2px);
  font-size: 14px;
  z-index: 9999;
}
</style>
