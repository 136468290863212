import _trimEnd from 'lodash/trimEnd';

export function normalizeLongitude(value) {
  return parseFloat(value.toFixed(7));
}

export function normalizeLatitude(value) {
  return parseFloat(value.toFixed(7));
}

export function normalizeElevation(value) {
  return parseFloat(value.toFixed(3));
}

export function normalizeCorrectedElevation(value) {
  return parseFloat(value.toFixed(3));
}

export function normalizeDepth<T extends string | number>(value: T): T {
  return typeof value === 'string' && value.includes('.')
    ? _trimEnd(Number(value).toFixed(4), '0')
    : value;
}
