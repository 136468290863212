<template>
  <a
    v-if="value.value"
    :href="src"
    :target="mimeType === 'pdf' ? '_blank' : '_self'"
    :download="mimeType !== 'pdf' ? value.value2 : null"
  >
    <p class="mb-0">{{ value.value2 }}</p>
  </a>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  data: () => ({
    mimeType: null,
  }),
  mounted() {
    this.mimeType = this.getMimeType(this.value.value);
  },
  methods: {
    getMimeType(value) {
      return value.split('.').at(-1).toLowerCase();
    },
  },
  computed: {
    src() {
      return `/api/images/value/${this.value.project_id}/${this.value.value}`;
    },
  },
};
</script>
