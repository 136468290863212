import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { isProd } from '../utils/is-prod';
import { useProjectStore } from './project';

export interface Reward {
  percentage: number;
  text: string;
}

export type SidebarItem = {
  title: string;
  link?: string;
  icon?: string;
  items?: SidebarItem[];
  click?: (redirect?: string) => void;
  beta?: boolean;
  new?: boolean;
};

export const useNavigationStore = defineStore('navigation', () => {
  const forceOpenHelpDropdown = ref(false);
  const showFeedbackModal = ref(false);
  const showNotificationDropdown = ref(false);
  const showImpersonationModal = ref(false);
  const showMobileNavigation = ref(false);
  const showGatherUploadModal = ref(false);
  const impersonationSearchQuery = ref<string>();
  const demoModeEnabled = ref(localStorage.getItem('demoModeEnabled') === '1');
  const sidebarCollapsed = ref(
    localStorage.sidebarCollapsed
      ? JSON.parse(localStorage.sidebarCollapsed)
      : window.screen.width < 1600
      ? true
      : false
  );

  watch(sidebarCollapsed, () => {
    localStorage.setItem('sidebarCollapsed', sidebarCollapsed.value.toString());
  });

  const loadingLocalState = ref(false);
  const loadingClosestReward = ref(false);
  const closestReward = ref<Reward | null>(null);

  function toggleFeedbackModal(show: boolean) {
    showFeedbackModal.value = show;
  }

  function toggleHelpDropdown() {
    forceOpenHelpDropdown.value = true;

    setTimeout(() => {
      forceOpenHelpDropdown.value = false;
    }, 1000);
  }

  function enableDemoMode() {
    demoModeEnabled.value = true;
    localStorage.setItem('demoModeEnabled', '1');
  }

  function disableDemoMode() {
    demoModeEnabled.value = false;
    localStorage.removeItem('demoModeEnabled');
  }

  function toggleDemoMode() {
    demoModeEnabled.value = !demoModeEnabled.value;
    localStorage.setItem('demoModeEnabled', demoModeEnabled.value ? '1' : '0');
  }

  const isProduction = computed(() => {
    return isProd;
  });

  return {
    forceOpenHelpDropdown,
    sidebarCollapsed,
    loadingLocalState,
    getSidebarContainerClass: (force = false) => {
      if (!force && !useProjectStore().project) {
        return;
      }

      if (sidebarCollapsed.value) {
        return 'collapsed-container';
      }

      return 'full-container';
    },
    showFeedbackModal,
    showNotificationDropdown,
    showMobileNavigation,
    showGatherUploadModal,
    showImpersonationModal,
    impersonationSearchQuery,
    loadingClosestReward,
    closestReward,

    isProduction,

    demoModeEnabled: computed(() => demoModeEnabled.value),
    toggleDemoMode,
    enableDemoMode,
    disableDemoMode,

    toggleFeedbackModal,
    toggleHelpDropdown,
    toggleNotificationDropdown(state?: boolean) {
      showNotificationDropdown.value = state || !showNotificationDropdown.value;
    },
    openNotificationDropdown() {
      showNotificationDropdown.value = true;
    },
    setLoadingLocalState(value) {
      loadingLocalState.value = value;
    },
  };
});
