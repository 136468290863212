import _uniqBy from 'lodash/uniqBy';
import { FieldTypeIds } from '../fields';
import type {
  App,
  AppLinkConfig,
  CompoundApp,
  DrawingType,
  GatherField,
  ProjectPhase,
  Section,
  AppShareable,
  ShareGroup,
} from '../gather';

export function findAppById(apps: App[], id: number): App | undefined {
  return apps.find((app) => app.id === id);
}

export function findAppByTitle(apps: App[], title: string): App | undefined {
  return apps.find((app) => app.title === title);
}

export function findAppByFieldId(
  apps: App[],
  fieldId: number
): App | undefined {
  for (let i = 0; i < apps.length; i++) {
    const { sections = [] } = apps[i];
    for (let j = 0; j < sections.length; j++) {
      const { template_fields: fields = [] } = sections[j];
      for (let k = 0; k < fields.length; k++) {
        const field = fields[k];
        if (field.id === fieldId) {
          return apps[i];
        }
      }
    }
  }

  return undefined;
}

export function findSectionByIdFromApps(
  apps: App[],
  id: number
): Section | undefined {
  for (let i = 0; i < apps.length; i++) {
    const { sections = [] } = apps[i];
    for (let j = 0; j < sections.length; j++) {
      const section = sections[j];

      if (section.id === id) {
        return section;
      }
    }
  }

  return undefined;
}

export function findSectionByIdFromApp(
  app: App,
  id: number
): Section | undefined {
  return findSectionByIdFromApps([app], id);
}

export function findFieldByIdFromApps(
  apps: App[],
  id: number
): GatherField | undefined {
  for (let i = 0; i < apps.length; i++) {
    const { sections = [] } = apps[i];
    for (let j = 0; j < sections.length; j++) {
      const { template_fields: fields = [] } = sections[j];
      for (let k = 0; k < fields.length; k++) {
        const field = fields[k];
        if (field.id === id) {
          return field;
        }
      }
    }
  }

  return undefined;
}

export function findFieldByIdFromApp(
  app: App,
  id: number
): GatherField | undefined {
  return findFieldByIdFromApps([app], id);
}

export function getFields(templateTab) {
  return templateTab.sections.reduce((accu, item) => {
    return [...accu, ...item.template_fields];
  }, []);
}

export function getField(templateTab, fieldId) {
  return getFields(templateTab).find((field) => field.id === fieldId);
}

const _getShareable = (app: App) => {
  const shareables = app.app_shareables;
  if (!!shareables?.length) {
    const found = shareables.find(
      (aShareable) => !!aShareable?.share_group?.group_title
    );
    if (found) {
      return found;
    }
  }

  const parentShareable = app.parent_shareables;
  if (!!parentShareable?.length) {
    const found = parentShareable.find(
      (aShareable) => !!aShareable.share_group?.group_title
    );
    if (found) {
      return found;
    }
  }
};

export const getAppShareGroupTitle = (
  shareGroup: App | ShareGroup
): string | undefined => {
  if ('group_title' in shareGroup) {
    return (shareGroup as ShareGroup).group_title;
  }
  if ('title' in shareGroup) {
    return shareGroup.title ?? undefined;
  }
  return undefined;
};

export const getAppShareGroupDescription = (
  app: App | AppShareable | ShareGroup
): string | undefined => {
  if ('group_description' in app) {
    return app.group_description;
  }
  if ('shared_from_shareables' in app) {
    if (app.share_group) {
      return app.share_group?.share_group?.group_description;
    }
    const shareable = _getShareable(app);
    return shareable?.share_group?.group_description;
  }
  return undefined;
};

export const getAppCreator = (app: App | ShareGroup) => {
  if ('creator_name' in app) {
    return app.creator_name;
  }
  if ('share_group' in app && app.share_group) {
    return app.creator_name;
  }
  const shareable = _getShareable(app as App);
  return shareable?.share_group?.creator_name || undefined;
};

export const getAppIcon = (app: App | AppShareable | ShareGroup) => {
  if ('icon_url' in app && app.icon_url) {
    return app.icon_url;
  }
  if ('app' in app && app.app) {
    return getAppIcon(app.app);
  }
  if ('share_group' in app) {
    return (app as AppShareable).share_group?.icon_url;
  }
  const shareable = _getShareable(app as App);
  return shareable?.share_group?.icon_url || undefined;
};

export function getAppIconClass(drawing_type: DrawingType) {
  return (
    {
      point: 'fa-map-marker',
      polygon: 'fa-hexagon',
      polyline: 'fa-wave-triangle',
      rectangle: 'fa-rectangle-wide',
      circle: 'fa-circle',
      arrow: 'fa-arrow-right',
    }[drawing_type] ?? 'fa-table'
  );
}

export function parseIdAsArray(id: number | string): number[] {
  if (typeof id === 'number') {
    return [id];
  } else if (typeof id === 'string' && !id.includes('_')) {
    const parsed = parseInt(id, 10);
    if (!isNaN(parsed)) {
      return [parsed];
    }
  } else if (/^\d+(_\d+)+$/.test(id)) {
    // The id represents the id of a compound app, section or field.
    return id.split('_').map(($item) => parseInt($item, 10));
  }

  throw `Unsupported id: ${id}`;
}

export const checkIsCompoundApp = (
  app: App | CompoundApp
): app is CompoundApp => {
  return typeof app.id === 'string';
};

export const findPoiOwnerApps = (apps: App[], poiAppId: number): App[] => {
  return apps.filter(
    (app) => app.allow_collection_on_poi && app.poi_app_id === poiAppId
  );
};

export const checkIsPoiApp = (apps: App[], poiAppId: number): boolean => {
  return findPoiOwnerApps(apps, poiAppId).length > 0;
};

export const checkIfAppReachedLimit = (app: App): boolean => {
  return !!(
    app.item_limit &&
    app.samples_count &&
    app.samples_count >= app.item_limit
  );
};

export function getLinkFields(
  app: App,
  isUnique: boolean = true
): GatherField[] {
  const linkFields = (getFields(app) as GatherField[]).filter(
    (f) => f.field_type_id === FieldTypeIds.REFERENCE
  );
  return isUnique
    ? _uniqBy(linkFields, (lf: GatherField) => lf.options?.template_tab_title)
    : linkFields;
}

export function getLinkConfigs(app: App): AppLinkConfig[] {
  return (app.link_configs ?? []).filter((lc) => lc.isEmbedded);
}

export function getRenderableAppLinkConfig(app: App, allApps: App[]) {
  if (!['any', 'non-spatial'].includes(app.drawing_type)) {
    return null;
  }

  const linkedApps = allApps.filter(
    (_app) =>
      ['any', 'point'].includes(_app.drawing_type) &&
      _app.id !== app.id &&
      !!_app.link_configs
  );

  for (const linkedApp of linkedApps) {
    for (const linkConfig of linkedApp.link_configs!) {
      const { icon, color, linkFieldId } = linkConfig;
      const isContained = !!findFieldByIdFromApp(app, linkFieldId);
      if (isContained) {
        return {
          icon,
          color,
          linkFieldId,
        };
      }
    }
  }

  return null;
}

export function checkIsEmbeddedApp(app: App, allApps: App[]): boolean {
  return (
    ['any', 'non-spatial'].includes(app.drawing_type) &&
    allApps.some(
      (_app) =>
        _app.id !== app.id &&
        (_app.link_configs ?? []).some(
          (lc) =>
            findAppByFieldId(allApps, lc.linkFieldId)?.id === app.id &&
            lc.isEmbedded
        )
    )
  );
}

export function checkIsPhaseVisible(
  app: App,
  allPhases: ProjectPhase[]
): boolean {
  return app.app_phase_group_id !== null
    ? allPhases.find((p) => p.id === app.app_phase_group_id)?.is_visible ||
        false
    : true;
}
