import {
  Vector as VectorSource,
  TileArcGISRest,
  VectorTile as VectorTileSource,
  ImageArcGISRest,
  ImageWMS,
  WMTS,
  XYZ,
} from 'ol/source';

export default function enableLoadingEvents(layer) {
  let loadingCounter = 0;
  let startEventName: string;
  let endEventName: string;
  let errorEventName: string;

  const source = layer.getSource();
  if (source instanceof VectorSource) {
    startEventName = 'featuresloadstart';
    endEventName = 'featuresloadend';
    errorEventName = 'featuresloaderror';
  } else if (
    source instanceof TileArcGISRest ||
    source instanceof VectorTileSource ||
    source instanceof WMTS ||
    source instanceof XYZ
  ) {
    startEventName = 'tileloadstart';
    endEventName = 'tileloadend';
    errorEventName = 'tileloaderror';
  } else if (source instanceof ImageArcGISRest || source instanceof ImageWMS) {
    startEventName = 'imageloadstart';
    endEventName = 'imageloadend';
    errorEventName = 'imageloaderror';
  } else {
    return;
  }

  source.on(startEventName as any, () => {
    if (loadingCounter === 0) {
      layer.dispatchEvent('loadstart');
    }

    loadingCounter++;
  });

  // @ts-expect-error @Matt
  source.on([endEventName, errorEventName], (event) => {
    const { type } = event;
    loadingCounter--;

    if (type === errorEventName) {
      layer.dispatchEvent('loaderror');
    }

    if (loadingCounter === 0) {
      layer.dispatchEvent('loadend');
    }
  });
}
