import type { Ref } from 'vue';
import type { StylingRuleOnGatherApp as StylingRule } from './business-logic/mapping/styling-rule';
import { Default } from './business-model/auto-assign';
import { ExpressionValueObject } from './business-model/expression';
import { getSystemReference } from './business-logic/documents/document-snippets';
import { FieldTypeId } from './fields';
import { getLayerIconByType } from './layers';
import { Address, Country2CharCode } from './project';
import moment from 'moment';
import { Figure } from './maps';
import SectionClass from './classes/Section';
import { DateType } from './business-model/date';

export type FieldId = number;

export type GatherField<T = GatherFieldOptions> = {
  id: FieldId;
  template_section_id: number;
  /**
   * @deprecated Use app() instead, you may have to refactor API upstream.
   */
  template_tab?: App | null;
  app?: App | null;
  section?: Section;
  field_type_id: FieldTypeId;
  is_required?: boolean;
  label: string;
  system_reference?: null | string;
  order: number;
  options?: T;

  updated_at?: string | null;
  created_at?: string | null;
}

export type ItemDetailed = {
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
  app_id: number;
  latitude: string | null;
  longitude: string | null;
} & Record<string, Record<string, string> | Record<string, string>[]>;

export enum DrawingType {
  Any = 'any',
  Point = 'point',
  Polygon = 'polygon',
  Polyline = 'polyline',
  Arrow = 'arrow',
  Rectangle = 'rectangle',
  Circle = 'circle',
  NonSpatial = 'non-spatial',
  Hedge = 'hedge',
}
export enum OutlineStyle {
  Solid = 0,
  Dashed = 1,
  Dotted = 2,
}

export enum FillStyle {
  Solid = 0,
  Transparent = 1,
  HorizontalStripe = 2,
  VerticalStripe = 3,
  Dot = 4,
  Circle = 5,
  Cross45Deg = 6,
  Stripe45Deg = 7,
}

// Also known as TemplateTab
export interface App {
  id: number;
  custom_template_id: null | number;
  project_id: number;
  cloned_from_id: null | number;
  title: null | string;
  system_reference: null | string;
  is_locked: boolean | 1 | 0;
  public_redirect: null | string;
  public_link: null | string;
  prefix: null | string;
  has_read_only_item_titles: boolean;
  drawing_type: DrawingType;

  point_icon?: number;
  drawing_colour?: string;
  drawing_properties?: { fillStyle: FillStyle; outlineStyle: OutlineStyle };
  shared_from_group_id?: null | number;
  share_group?: AppShareable;
  creator_name?: string;
  icon_url?: string;

  // TODO: Not sure of these types
  // drawing_type: null | string;
  // point_icon: null | string;
  // drawing_properties: null | string;
  sub_folders?: any[];
  // hs_categories: null | string;

  custom_template?: App;
  template_tab?: App;

  shared_from_shareables?: AppShareable[];
  app_shareables?: AppShareable[];
  parent_shareables?: AppShareable[];
  used_template_tab?: App;

  sections?: Section[];
  styling_rules?: StylingRule[];

  is_read_only?: boolean;

  created_at: string;
  updated_at: string;
  deleted_at: null | string;

  allow_collection_on_poi: boolean;
  poi_app_id?: number | null;
  item_limit?: number | null;

  samples_count?: number;
  link_configs?: AppLinkConfig[] | null;

  filters: any;

  app_phase_group_id: number | null;
  order: number;
  default_figure_ids: number[];

  // Gather related
  previous_app_requirement?: any; // ?

  start_node_field?: null; // appears to be null through the whole database?
  end_node_field?: null; // appears to be null through the whole database?
}

// A compound app is formed by combining two or more apps which have sections and fields in common.
// Two sections which are considered to be common sections among apps must have the same label. Or
// their label are the same after the leading App title is removed.
// Tow fields which are considered to be common among sections must have the same label.
// It is not persistent in the database.
// The id of a compound object, e.g. app, section, field is a string by joining its sources' id with
// a underscore symbol. For example, if App1's id is 1234 and App2's id is 5678 then the id of the
// compound app is 1234_5678.
export interface CompoundApp {
  id: string;
  sections: CompoundAppSection[];
  sources: App[];
  title: string;
}

export interface CompoundAppSection {
  id: string;
  label: string;
  max_section_index: number;
  template_fields: CompoundAppField[];
}

export interface CompoundAppField {
  id: string;
  label: string;
}

export interface ShareGroup {
  id: number;
  project_id: number;
  shared_by_id?: number;
  shareable_type: string;
  shareable_id: number;
  group_title: string;
  group_description?: string;
  group_icon_filename?: string;
  creator_name?: string | null;
  icon_url?: string | null;
  updated_at: string;
  created_at: string;

  app_shareables?: AppShareable[];
  apps?: App[];
  document_links?: ShareableDocument[];
  linked_data_events?: ShareableDataEvent[];
  linked_data_insights?: ShareableDataInsightDashboard[];
}

export interface AppShareable {
  id: number;
  template_tab_id: number;
  share_group_id: number;
  share_group?: ShareGroup;
  app?: App;
}

export type ShareableDocument = {
  id: number;
  share_group_id: number;
  document_id: number;
};

export type ShareableDataEvent = {
  id: number;
  share_group_id: number;
  data_event_id: number;
};

export type ShareableDataInsightDashboard = {
  id: number;
  share_group_id: number;
  dashboard_id: number;
};

export type Condition = {
  field_id: number | null;
  operator: string;
  value: string | null;

  default?: string; // double check this
  field_label?: string; // double check this
};

export type GroupConditionOperator = '=' | '!=' | '>' | '>=' | '<' | '<=';

export type GatherFieldOptions = GatherFieldLegacyOptions &
  GatherAIFieldOptions &
  UserFieldOptions &
  GatherDateOptions &
  GatherDropdownOptions &
  GatherMediaOptions;

export type GatherFieldLegacyOptions = {
  is_always_hidden?: boolean;
  is_add_enabled?: boolean;
  is_public_form?: boolean;
  display_as_input?: boolean;
  should_clear_selection_on_invisible?: boolean;
  conditions?: Condition[];
  options?: any[];
  groups?: { items: string[]; default: null; title?: string }[];
  groupConditions?: {
    field_id: number;
    operator: GroupConditionOperator;
    value: string;
    /** Group index */
    default: number;
  }[];
  defaults?: Default[];
  default?: string;
  all_conditions_match?: boolean | 1 | 0 | null;
  is_readonly?: boolean | 1 | 0;
  validation?: 'email' | null;
  restricted_country?: Country2CharCode | null;
  content?: any;
  status?: string;
  option_type?: number;
  optionExtensions?: any;
  type?: string;
  is_range?: boolean;
  is_richtext?: boolean;
  prefix?: string;
  unit?: string;
  maxDecimals?: number;
  track_direction?: string | null;
  template_tab_title?: string;
  expression?: ExpressionValueObject;
  placeholder?: string;
  allow_renaming?: boolean;
  has_auto_assign_editor?: boolean;
  has_auto_assign_creator?: boolean;
  isAutoAssignActive?: boolean;
  selected_lab_title_format_id?: string | null;
  use_preset_format?: boolean;
  rpd_allow_custom_title?: boolean;
  isInvolvedInDataCopy?: boolean;
  node_icons?: Record<string, any>;
  disabled?: boolean;
  default_value?: string;
  increment?: string;
  should_keep_continuity?: boolean;
  enforce_integer?: boolean;
};

export type GatherDateOptions = {
  type?: DateType;
  format?: 'DD-MM-YYYY' | 'MM-DD-YYYY' | 'YYYY-MM-DD' | 'MM-YYYY' | 'YYYY' | 'HH:mm';
  /** @deprecated this name is too long and wasteful in json. It should be snake case */
  current?: boolean;
};

export type GatherMediaOptions = {
  type?: 'image' | 'video' | 'audio';
} | GatherMediaImageOptions;

export type GatherMediaImageOptions = {
  type: 'image';
  has_multiple?: boolean;
  track_direction?: boolean;
  allow_renaming?: boolean;
};

export type UserFieldOptions = {
  user_filter?: 'external' | 'company_external' | 'company_team' | 'company';
  /** Undefined = name */
  user_property?: 'email' | undefined | 'uuid';
  is_readonly?: boolean;
};

export enum DropdownOptionType {
  Text = 1,
  Number = 2,
}

export const OPTION_TYPE_OPTIONS = {
  [DropdownOptionType.Text]: {
    text: 'Text',
  },
  [DropdownOptionType.Number]: {
    text: 'Number',
  },
};

export type GatherDropdownOptions = {
  option_type?: DropdownOptionType;
  defaults?: Default[];
  options?: any[]; // TODO make this more specific
  has_multiple?: boolean;
  is_add_enabled?: boolean;
  is_readonly?: boolean;
  should_clear_selection_on_invisible?: boolean;
  display_as_input?: boolean;
  node_icons?: any; // what is this?
};

export type GatherAIFieldOptions = {
  system_prompt?: string;
  max_tokens?: number;
  temperature?: number;
  inputs?: [
    FieldId, RepeatModeOrAll
  ][];
};
export type GatherAIFieldValue = string | null;

/** TODO: use for data insights. */
export enum RepeatModeAggregate {
  /** Only the current value of the repeating section */
  Current = 1,
  /** Only the first value of the repeating section */
  First = 2,
  /** Only the last value of the repeating section */
  Last = 3,
  Average = 4,
  Sum = 5,
  Count = 6,
}

export enum RepeatModeOrAll {
  /** All values from the repeating section */
  All = 0,
  /** Only the current value of the repeating section */
  Current = 1,
  /** Only the first value of the repeating section */
  First = 2,
  /** Only the last value of the repeating section */
  Last = 3,
}

export function formatRepeatMode(mode: RepeatModeOrAll | RepeatModeAggregate) {
  switch (mode) {
    case RepeatModeOrAll.All:
      return 'All';
    case RepeatModeOrAll.Current:
      return 'Current';
    case RepeatModeOrAll.First:
      return 'First';
    case RepeatModeOrAll.Last:
      return 'Last';
    case RepeatModeAggregate.Average:
      return 'Average';
    case RepeatModeAggregate.Sum:
      return 'Sum';
    case RepeatModeAggregate.Count:
      return 'Count';
  }
}

export type InputValueValue =
  | string
  | null
  | boolean
  | number
  | string[]
  | Address
  | object;
export type ValuePair = {
  value: InputValueValue;
  value2: InputValueValue;
};

export interface InputValue<T = InputValueValue, T2 = InputValueValue> {
  id: number;
  project_id: number;
  sample_id: number;
  template_tab_id: number;
  template_field_id: number;
  template_section_id: number;
  template_section_index: number;
  value: T;
  value2: T2;
  options: null | any;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
}

export type InputValuePlaceholder = Omit<
  InputValue,
  | 'id'
  | 'project_id'
  | 'template_tab_id'
  | 'created_at'
  | 'updated_at'
  | 'deleted_at'
  | 'sample_id'
>;

export interface Section {
  id: number;
  label: string;
  system_reference: null | string;
  template_tab_id: number;
  app?: App | null;
  template_fields?: GatherField[];
  fields?: GatherField[];

  is_public_form?: boolean | 1 | 0;
  is_shown_on_new_page?: boolean | 1 | 0;
  is_repeatable?: boolean | 1 | 0;
  is_permanent?: boolean | 1 | 0;
  primary_field_id?: null | number;
  secondary_field_id?: null | number;
  is_lab_sample?: boolean;
  is_health_safety?: boolean;
  is_soil_log?: boolean;
  is_site_visit?: boolean;
  is_photolog?: boolean;
  is_gps_point_metadata?: boolean;
  is_number_used_as_title?: boolean;
  validated?: boolean;
  collapsed?: boolean;
}

export type SectionWithLoading = Section & {
  toggled: boolean;
  max_section_index: number;
  loading: boolean;
};

export interface Item {
  id: number;
  offline_user_id?: number;
  custom_title: string;
  lab_title: string | null;
  template_tab_id: number;
  latitude: number;
  longitude: number;

  start_depth?: number | null;
  end_depth?: number | null;

  sub_folders: null | any[];
  sub_folder?: null | any;
  is_sub_folder_applicable?: boolean;
  template_to_select?: null | number;
  area_figure_layer?: null | AreaFigureLayer;
  layer: any; // maps layer?
  geojson: any;

  // Front end only
  latlng?: any;
}

export type ProjectPhase = {
  id: number;
  title: string;
  order: number;
  is_visible: boolean;
};

export interface AreaFigureLayer {
  geojson: {
    properties: any;
  };
}

export function isItemNonSpatial(item: Item) {
  return (
    !item.geojson &&
    !item.area_figure_layer &&
    !item.layer &&
    !item.latlng &&
    !item.longitude &&
    !item.latitude
  );
}

function isValidReference(systemReference: string) {
  return (
    !systemReference.match(/^\d/) && !systemReference.match(/[^a-zA-Z0-9_]/)
  );
}

export function getAppReferenceError(app: App): string | false {
  const ref = getSystemReference(app);

  if (ref?.match(/^\d/)) {
    return 'Should not start with a number';
  }

  if (ref === 'photos') {
    return 'This name may clash with our system, please change the App title unless this is a pre-built Datanest app';
  }
  if (app.system_reference && !isValidReference(app.system_reference)) {
    return 'System reference should only contain letters, numbers and underscores';
  }

  return false;
}

export function getSectionReferenceError(
  section: Section | SectionClass,
  app: App
): string | false {
  const ref = getSystemReference(section);

  if (ref?.match(/^\d/)) {
    return 'Should not start with a number';
  }

  if (section.system_reference && !isValidReference(section.system_reference)) {
    return 'System reference should only contain letters, numbers and underscores';
  }

  if (ref === 'photos') {
    return 'This label may clash with our system, please change the section title unless this is a pre-built Datanest app';
  }
  if (ref === '_' || ref === '') {
    return 'Section label should not be empty or just underscores';
  }
  if (ref === getSystemReference(app)) {
    return 'Label should not be the same as the App';
  }

  const sections = app?.sections || app?.template_tab?.sections;
  if (sections) {
    const duplicate = sections.find(
      (s) => s.id !== section.id && getSystemReference(s) === ref
    );
    if (duplicate) {
      return 'There is another section with this name in this app.';
    }
  }

  return false;
}

export function getFieldReferenceError(
  field: GatherField,
  section: Section
): string | false {
  const ref = getSystemReference(field);

  if (ref?.match(/^\d/)) {
    return 'Should not start with a number';
  }

  if (field.system_reference && !isValidReference(field.system_reference)) {
    return 'System reference should only contain letters, numbers and underscores';
  }
  if (ref === '_' || ref === '') {
    return 'Label should not be empty or just underscores';
  }

  const fields = section.fields || section.template_fields;
  if (fields) {
    const duplicate = fields.find(
      (f) => f.id !== field.id && getSystemReference(f) === ref
    );
    if (duplicate) {
      return 'There is another field with this name in this section.';
    }
  }

  return false;
}

export function getItemSpatialLayerIcon(item: Item) {
  if (item.area_figure_layer) {
    return getLayerIconByType(item.area_figure_layer.geojson.properties.type);
  }
  if (isItemNonSpatial(item)) {
    return 'fa-table';
  }

  return 'fa-map-marker';
}

export function findTemplateField(
  templateTab,
  templateSectionId,
  templateFieldId
) {
  const section = templateTab.sections.find(
    (item) => item.id === templateSectionId
  );
  return section.template_fields.find((item) => item.id === templateFieldId);
}

export enum Operator {
  EqualTo = '==',
  NotEqualTo = '!=',
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
  LessThan = '<',
  LessThanOrEqualTo = '<=',
}

export const OperatorLabelMap: Record<Operator, string> = {
  [Operator.EqualTo]: 'Equal to',
  [Operator.NotEqualTo]: 'Not equal to',
  [Operator.GreaterThan]: 'Greater than',
  [Operator.GreaterThanOrEqualTo]: 'Greater than or equal to',
  [Operator.LessThan]: 'Less than',
  [Operator.LessThanOrEqualTo]: 'Less than or equal to',
};

export type AppLinkConfig = {
  linkFieldId: number;
  isEmbedded: boolean;
  maxNumberOfItems: number;
  icon: number;
  color: string;
};

export type AppLinkConfigContext = {
  app: App;
  item: Item;
  hasChangedInputValues: Ref<boolean>;
  iconButtonContainer?: string;
};

export type RenderableNonSpatialSampleGroup = {
  layerModelId: number;
  unrenderableAppLinkConfigs: { linkFieldId: number }[];
  filteredUnrenderableItemIds: number[];
};

export const ALL_ITEMS_OPTION_VALUE = 0;

export function checkIsNewItem(item: Item): boolean {
  return !item.id || (!item.template_tab_id && !item.template_to_select);
}

export function normalizeDate(value: string, field?: GatherField<GatherDateOptions>) {
  const parts = value.split('-');
  // Convert from format to ISO
  if (parts.length >= 3 && parts[0].length !== 4) {
    const format = field?.options?.format || 'DD-MM-YYYY';
    return moment(value, format).format('YYYY-MM-DD');
  }
  return value;
}

export function checkIsAppEnabledForGather(
  app: App,
  selectedFigure?: Figure,
  isNonSpatialView = false
) {
  if (isNonSpatialView) {
    return true;
  }

  if (!selectedFigure) {
    return false;
  } else if (selectedFigure.gather_access) {
    return true;
  }

  if (!app) {
    return false;
  }
  const defaultFigureIds = app.default_figure_ids || [];
  return (
    defaultFigureIds.includes(0) || defaultFigureIds.includes(selectedFigure.id)
  );
}
