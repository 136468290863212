import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { CompanyData } from '../types/company-data';
import { isCompanyManager } from '@component-library/company';
import { User } from '@component-library/user';
import { useProjectStore } from '@component-library/store/project';
import axios from 'axios';
import useIsGather from './useIsGather';

export default function useCompanyManagement(
  user: User,
  clearCurrentProject: boolean = true
) {
  const isLoading = ref(true);
  const isUpdating = ref(false);
  const companyData = ref<CompanyData | null>(null);
  const isGather = useIsGather();
  const canModifyCompany = computed(() => {
    return isCompanyManager(user);
  });

  async function loadCompany() {
    try {
      isLoading.value = true;
      const url = (isGather ? 'api' : '') + '/company/statistics';
      const { data } = await axios.get(url);
      companyData.value = data;
      companyData.value!.users =
        data.users.map((user: User) => {
          return {
            ...user,
            backup_name: user.name,
          };
        }) ?? [];
    } catch (e) {
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  onBeforeMount(() => {
    if (clearCurrentProject) {
      const projectStore = useProjectStore();
      if (projectStore.currentProject) {
        // Clear current project to update feature flags
        // Ensure external users do not have extra permissions
        // from the project they were last in.
        projectStore.updateProject(null);
      }
    }
  });

  onMounted(loadCompany);

  return {
    isLoading,
    isUpdating,
    companyData,
    canModifyCompany,
    loadCompany,
  };
}
