import store from '@/js/store';
import * as color from '../business-logic/color';
import { getSampleIcon } from '../lib/olbm/layer/sample/utils';
import { NAMESPACE } from '../store';

class MarkerHandler {
  static getMarkerImageIcon(icon, color, markerSize = '22px') {
    const { src } = getSampleIcon(icon, color);
    return `<div class="marker-image" style="width: ${markerSize}; height: ${markerSize};">
      <img src=${src} />
    </div>`;
  }

  static getMarkerPropertiesBySample(sample) {
    if (!sample.sample_group) {
      return this.getMarkerPropertiesByIdentifier(
        NAMESPACE.getGetter(store, 'getSampleGroupByIdentifier')().data,
        sample.id
      );
    }

    return this.getMarkerPropertiesByIdentifier(
      sample.sample_group.data,
      sample.id
    );
  }

  static getMarkerPropertiesByIdentifier(sampleGroup, sampleId = null) {
    let identifierProperties = sampleGroup.marker_identifier.split('_');
    const icon = parseInt(identifierProperties[0], 10);
    const groupProperties = sampleGroup.properties;
    let iconSize = groupProperties.iconSize;
    let iconRotation = groupProperties.iconRotation;
    let iconOpacity = groupProperties.iconOpacity;
    let hideMarkerLabel = groupProperties.hideMarkerLabel;
    const hideDuplicateLabel = groupProperties.hideDuplicateLabel;
    let markerLabelColor = groupProperties.markerLabelColor;
    let markerLabelShadowColor = groupProperties.markerLabelShadowColor;
    let labelSize = groupProperties.labelSize;
    let markerLabelUnderlined = groupProperties.markerLabelUnderlined;
    let markerLabelAsteriskAppended =
      groupProperties.markerLabelAsteriskAppended;
    let markerLabelPosition = null;
    let stylingPriority = groupProperties.stylingPriority;

    if (sampleId) {
      const sample = NAMESPACE.getGetter(store, 'getSampleById')(sampleId);
      iconSize = sample.icon_size ?? iconSize;
      iconRotation = sample.icon_rotation ?? iconRotation;
      iconOpacity = sample.icon_opacity ?? iconOpacity;
      hideMarkerLabel = hideMarkerLabel || sample.is_label_hidden;
      markerLabelColor = sample.label_color ?? markerLabelColor;
      markerLabelShadowColor =
        sample.label_shadow_color ?? markerLabelShadowColor;
      labelSize = sample.label_size ?? labelSize;
      markerLabelUnderlined =
        sample.is_label_underlined ?? markerLabelUnderlined;
      markerLabelAsteriskAppended =
        sample.is_label_asterisk_appended ?? markerLabelAsteriskAppended;
      markerLabelPosition = sample.label_position;
      stylingPriority = sample.styling_priority ?? stylingPriority;
    }

    return {
      icon,
      color: identifierProperties[1],
      iconSize: iconSize ?? 22,
      iconRotation: iconRotation ?? 0,
      iconOpacity: iconOpacity ?? 1,
      isDefault: groupProperties.default ?? false,
      labelPosition: markerLabelPosition,
      isLabelHidden: hideMarkerLabel ?? false,
      isDuplicateLabelHidden: hideDuplicateLabel ?? false,
      labelColor: (markerLabelColor || undefined) ?? color.BLACK,
      labelShadowColor: markerLabelShadowColor ?? color.WHITE,
      labelSize: labelSize ?? 16,
      isLabelUnderlined: markerLabelUnderlined ?? false,
      isLabelAsteriskAppended: markerLabelAsteriskAppended ?? false,
      stylingPriority,
    };
  }
}

export default MarkerHandler;
