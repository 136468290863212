import { Code, throwError } from '../common/error';

export enum Color {
  PrimaryColor = '#3366a2',
  ExtentEditorCornerColor = '#ff0000',

  // 16 main colours.
  Aqua = '#00FFFF',
  Black = '#000000',
  Blue = '#0000FF',
  Fuchsia = '#CA2C92',
  Gray = '#808080',
  Green = '#00FF00',
  Lime = '#BFFF00',
  Maroon = '#800000',
  Navy = '#000080',
  Olive = '#808000',
  Purple = '#A020F0',
  Red = '#FF0000',
  Silver = '#C0C0C0',
  Teal = '#008080',
  White = '#FFFFFF',
  Yellow = '#FFFF00',

  // Datanest colors
  Border = '#dbdbdb',

  // Feature colors
  Feature = '#2980b9',
  SiteBoundary = '#e84b3c',

  Parcel = '#3388ff',
}

export const SWATCHES = Object.freeze([
  Object.freeze(['#1FBC9C', '#1CA085', '#2ECC70', '#27AF60', '#3398DB']),
  Object.freeze(['#2980B9', '#A463BF', '#8E43AD', '#3D556E', Color.Black]),
  Object.freeze(['#F2C511', '#F39C19', '#E84B3C', '#C0382B', '#DDE6E8']),
]);

export function getSwatchTriggerStyle(color) {
  return color.toLowerCase() === Color.White.toLowerCase()
    ? { border: `1px solid ${Color.Border}` }
    : null;
}

export function normalizeHex(hex: string): string {
  if (hex.length === 9) {
    return hex;
  } else if (hex.length === 7) {
    return `${hex}ff`;
  }

  throwError(Code.InvalidArgument, hex);
}

// Function to convert a hex color to HSL
function hexToHSL(hex: string) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;

  if (max != min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h * 360, s: s * 100, l: l * 100 };
}

// Function to convert HSL back to hex
function hslToHex(h: number, s: number, l: number) {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  const toHex = (n: number) => {
    const hex = n.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

// Function to generate a contrastive color by rotating the hue
export function getContrastColor(hexColor: string): string {
  if (hexColor.length === 9) {
    hexColor = hexColor.substring(0, 7);
  }

  const hsl = hexToHSL(hexColor);
  // Rotate hue by 180 degrees for contrast
  const contrastHue = (hsl.h + 180) % 360;
  // Return the new hex color with the same saturation and lightness but different hue
  return hslToHex(contrastHue, hsl.s, hsl.l);
}

export function getRandomHexColor() {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;
}
