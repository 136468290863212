export function createRequestOptions(timeout = 120) {
  return {
    transformRequest: (data, headers) => {
      delete headers.common['Authorization'];
      delete headers.common['X-CSRF-TOKEN'];
      delete headers.common['X-Requested-With'];
    },
    timeout: 1000 * timeout,
  };
}

export function proxify(url) {
  return `${import.meta.env.VITE_CORS_PROXY_URL}/?${url}`;
}

export function encodeRequestParams(params: Record<string, string>) {
  const result: string[] = [];

  for (let key in params) {
    result.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    );
  }

  return result.join('&');
}

export function forceHttps(url) {
  return url.replace(/^http:\/\/(.+)$/i, `https://$1`);
}

export function replaceQueryParam(
  url: string,
  paramName: string,
  paramValue: string
) {
  const regex = new RegExp(`([?&])${paramName}=.*?(&|$)`, 'i');
  const separator = url.indexOf('?') !== -1 ? '&' : '?';

  return url.match(regex)
    ? url.replace(regex, `$1${paramName}=${paramValue}$2`)
    : `${url}${separator}${paramName}=${paramValue}`;
}
