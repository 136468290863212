<script lang="ts" setup>
import InputTag from '../../components/InputTag.vue';
import EventBus from '../../EventBus';
import {
  checkIsOptionDuplicate,
  getOptionIconSrc,
} from '../../business-logic/dropdown';
import { GatherField, InputValue } from '../../gather';
import { computed, inject, nextTick, ref, watch } from 'vue';
import { useIsPublicForm } from '../../composables/useIsGather';

const props = withDefaults(
  defineProps<{
    field: GatherField;
    inputValue: InputValue;
    defaultValue?: any;
    groupKey?: false | number;
    isAutoAssignEnabled: boolean;
    isAutoAssignActive: boolean;
    isDisabled: boolean;
  }>(),
  {
    groupKey: false,
  }
);
type FormContext = {
  updateField: (fieldId: number, key: string, value: any) => Promise<void>;
};
const formContext = inject<FormContext>('formContext');
const search = ref<string | null>(null),
  isMenuVisible = ref(false),
  isDefaultInputValue = ref(false);

const dropdownInput = ref<HTMLInputElement>();
const searchInput = ref<HTMLInputElement>();

const searchInputPlaceholder = computed(() => {
  return !props.field.options?.is_add_enabled
    ? 'Search options...'
    : 'Search options or add a new option...';
});
const isSearchable = computed(() => {
  // The search input is also used to add new options on the fly.
  return options.value.length > 5 || props.field.options?.is_add_enabled;
});
const isAddButtonVisible = computed(() => {
  return (
    props.field.options?.is_add_enabled &&
    search.value &&
    !checkIsOptionDuplicate(options.value, search.value)
  );
});
const value = computed({
  get() {
    const value = props.inputValue.value;
    if (props.field.options?.has_multiple) {
      if (Array.isArray(value)) {
        return value;
      } else if (value) {
        return [value];
      } else {
        return [];
      }
    } else {
      return Array.isArray(value) ? value[0] : value;
    }
  },
  set(updated) {
    const _isDefaultInputValue = isDefaultInputValue.value;
    if (_isDefaultInputValue) {
      isDefaultInputValue.value = false;
    }
    EventBus.$emit('updateInputValue', {
      inputValue: { ...props.inputValue, value: updated },
      field: props.inputValue.template_field_id,
      sectionIndex: props.inputValue.template_section_index,
      templateTabId: props.inputValue.template_tab_id,
      isDefaultInputValue: _isDefaultInputValue,
    });
  },
});

const options = computed(() => {
  let result: any[] = [];
  const { options = [], groups = [] } = props.field.options ?? {};
  if (
    props.groupKey !== false &&
    Array.isArray(groups[props.groupKey]?.items) &&
    groups[props.groupKey].items.length
  ) {
    result = groups[props.groupKey].items;
  } else if (Array.isArray(options)) {
    result = options;
  }

  // handle case where offline or public form updated dropdown options but request wasn't saved in DB.
  const value = props.inputValue.value;
  if (value !== null) {
    const has_multiple = props.field.options?.has_multiple ?? false;
    let selectedOptions: any[] | any = [];
    if (has_multiple) {
      if (Array.isArray(value)) {
        selectedOptions = value;
      } else {
        selectedOptions = [value];
      }
    } else {
      selectedOptions = [Array.isArray(value) ? value[0] : value];
    }
    for (const so of selectedOptions) {
      if (!result.includes(so)) {
        result.push(so);
      }
    }
  }

  return result;
});

const searchResult = computed(() => {
  return search.value
    ? options.value.filter((o) =>
        o.toLowerCase().includes(search.value?.toLowerCase())
      )
    : options.value;
});

watch(
  () => props.defaultValue,
  (newValue) => {
    if (
      !value.value ||
      (props.isAutoAssignEnabled && props.isAutoAssignActive)
    ) {
      isDefaultInputValue.value = true;
      setValue(newValue);
    }
  },
  {
    immediate: true,
  }
);
watch(
  () => props.isAutoAssignActive,
  (newValue) => {
    if (newValue) {
      setValue(props.defaultValue);
    }
  }
);

watch(
  () => props.groupKey,
  () => {
    setValue(props.defaultValue);
  }
);

function removeFocus() {
  dropdownInput.value?.blur();
}
async function toggleMenu() {
  isMenuVisible.value = !isMenuVisible.value;
  if (isMenuVisible.value) {
    await nextTick();
    searchInput.value?.focus();
  }
}

function hideMenu() {
  isMenuVisible.value = false;
}

function isSelected(v1, v2) {
  if (Array.isArray(v1)) {
    return v1.includes(v2);
  }
  if (v1 && typeof v1 === 'object') {
    console.error('v1 is an object?', v1);
    return false;
  }
  if (typeof v1 === 'string') {
    v1 = v1.toLowerCase().trim();
  }
  if (typeof v2 === 'string') {
    v2 = v2.toLowerCase().trim();
  }
  if (v1 === v2) {
    return true;
  }
  return v1 === v2;
}

function setValue(newValue) {
  value.value = newValue;
  hideMenu();
  search.value = null;
}

function selectOption(option) {
  if (props.isDisabled) {
    return;
  }
  if (props.field.options?.has_multiple) {
    if (!value.value.includes(option)) {
      value.value = [...value.value, option];
    } else {
      value.value = value.value.filter((item) => item !== option);
    }
  } else {
    value.value = option;
    toggleMenu();
  }
}

async function handleOptionAdd() {
  const newOption = search.value;
  search.value = null;
  if (newOption === null || newOption.trim() === '') {
    return;
  }

  const groups = props.field.options?.groups ?? [];
  let nextOptions = [...(props.field.options?.options ?? [])];
  let nextGroups = [...groups];

  // An option which doesn't exist in the group may already exist.
  if (!checkIsOptionDuplicate(nextOptions, newOption)) {
    nextOptions.push(newOption);
  }

  let group;
  if (
    props.groupKey !== false &&
    ((group = groups[props.groupKey]), group?.items.length) &&
    !checkIsOptionDuplicate(group.items, newOption)
  ) {
    const nextItems = [...group.items, newOption];
    const nextGroup = { ...group, items: nextItems };
    nextGroups.splice(props.groupKey, 1, nextGroup);
  }

  if (useIsPublicForm()) {
    props.field.options ||= {};
    props.field.options.options = nextOptions;
    props.field.options.groups = nextGroups;
    props.field.options = { ...props.field.options };
  } else {
    await formContext!.updateField(props.field.id, 'options', {
      ...props.field.options,
      options: nextOptions,
      groups: nextGroups,
    });
  }

  selectOption(newOption);
}

function getOptionIcon(option) {
  return getOptionIconSrc(option, props.field);
}
</script>
<template>
  <div v-click-outside="hideMenu" class="form-group">
    <label class="form-label" for="dropdown">
      {{ field.label }}
      <sup v-if="!!field.is_required" class="text-danger">*</sup>
      <a
        v-if="
          !isDisabled &&
          value &&
          !field.options?.display_as_input &&
          !field.options?.has_multiple
        "
        href="#"
        @click.prevent="value = null"
      >
        Clear selection
      </a>
    </label>

    <template v-if="field.options?.display_as_input">
      <div
        class="input-group flex-nowrap"
        :class="{
          'mb-2': !isMenuVisible,
        }"
      >
        <input
          v-if="!field.options.has_multiple"
          ref="dropdownInput"
          type="text"
          class="form-control cursor-pointer"
          :class="{
            'border-danger': !!field.is_required && !value,
          }"
          name="dropdown-replace"
          :value="value"
          :required="!!field.is_required"
          :disabled="isDisabled"
          autocomplete="off"
          @focus="removeFocus"
          @click.prevent="toggleMenu"
        />
        <InputTag
          v-else
          class="flex-grow-1"
          :modelValue="value"
          :readOnly="true"
          :isDisabled="isDisabled"
          @click="toggleMenu"
        />

        <button
          v-if="isAddButtonVisible && !field.options?.is_readonly"
          class="btn btn-primary"
          type="button"
          @click.prevent="handleOptionAdd"
        >
          <i class="fal fa-plus"></i>
        </button>
        <button
          class="btn btn-danger"
          type="button"
          :disabled="isDisabled"
          @click.prevent="setValue(null)"
        >
          <i class="fal fa-times"></i>
        </button>
      </div>

      <div v-if="isMenuVisible" class="dropdown-content">
        <input
          v-if="isSearchable"
          ref="searchInput"
          v-model.trim="search"
          type="text"
          class="form-control form-control-sm border-0"
          style="border-radius: 0px"
          :placeholder="searchInputPlaceholder"
        />

        <div
          v-for="(option, optionIndex) in searchResult"
          :key="optionIndex"
          class="d-flex align-items-center dropdown-option clickable"
          @click.stop="selectOption(option)"
        >
          <input
            v-if="field.options?.has_multiple"
            v-model="value"
            type="checkbox"
            class="form-check-input me-2"
            :disabled="isDisabled"
            :value="option"
          />
          <a class="flex-grow-1">{{ option }}</a>
        </div>
        <a v-if="searchResult && !searchResult.length" class="text-muted">
          No options for "{{ search }}"
        </a>

        <button
          class="btn btn-light w-100 position-sticky bottom-0"
          style="border-radius: 0px"
          @click="hideMenu"
        >
          Close
        </button>
      </div>
    </template>
    <template v-else>
      <div class="dropdown-container">
        <div
          v-for="(option, optionIndex) in options"
          :key="optionIndex"
          class="d-flex form-check"
          @click.stop="selectOption(option)"
        >
          <input
            class="form-check-input"
            :type="field.options?.has_multiple ? 'checkbox' : 'radio'"
            :value="option"
            :disabled="isDisabled"
            :checked="isSelected(value, option)"
          />

          <label class="form-check-label ms-2">
            <template v-if="getOptionIcon(option)">
              <img :src="getOptionIcon(option)" width="16px" height="16px" />
            </template>
            {{ option }}
          </label>
        </div>
      </div>

      <small v-if="!!field.is_required && !value" class="d-block text-danger">
        You must select at least one option.
      </small>

      <div
        v-if="field.options?.is_add_enabled && !field.options.is_readonly"
        class="input-group input-group-sm mt-2"
      >
        <input
          v-model="search"
          type="text"
          class="form-control"
          placeholder="Add custom value..."
        />
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="handleOptionAdd"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </template>
  </div>
</template>

<style scoped>
/* Dropdown Content*/
.dropdown-content {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 25rem;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.dropdown-content .dropdown-option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content .dropdown-option:hover {
  background-color: #f4f4f4;
}

.dropdown-container {
  max-height: 150px;
  overflow-y: auto;
}
</style>
