<template>
  <div>
    <slot
      :isLoading="isLoading"
      :hasSimpleLoader="props.hasSimpleLoader"
      :errorLoadingTable="errorLoadingTable"
      :editable="props.editable"
      :hasNoSamples="hasNoSamples"
      :samples="samples"
      :tabId="props.tabId"
      :getFilters="getFilters"
      :loadingSamples="loadingSamples"
      :loadingTemplate="loadingTemplate"
      :loadSamples="loadSamples"
      :loadSamplesFromFilters="loadSamplesFromFilters"
      :setSearchQuery="setSearchQuery"
      :inputValues="inputValues"
      :sections="sections"
      :editingField="editingField"
      :editingSample="editingSample"
      :selectField="selectField"
      :toggleSection="toggleSection"
      :setImageUrl="setImageUrl"
      :viewMedia="viewMedia"
      :templateTab="templateTab"
      :subFolders="subFolders"
      :updateSample="updateSample"
      :deleteSample="deleteSample"
      :onSubFolderChanged="onSubFolderChanged"
      :imageUrl="imageUrl"
      :getImageURL="getImageURL"
      :mediaType="mediaType"
      :mediaSrc="mediaSrc"
      :closeMediaModal="closeMediaModal"
      :updateField="updateField"
      :tableSkeletonConfig="props.tableSkeletonConfig"
      :isUpdatingComputations="isUpdatingComputations"
    >
      <TableView
        v-if="templateTab"
        :isLoading="isLoading"
        :hasSimpleLoader="props.hasSimpleLoader"
        :errorLoadingTable="errorLoadingTable"
        :editable="props.editable"
        :hasNoSamples="hasNoSamples"
        :samples="samples"
        :tabId="props.tabId"
        :getFilters="getFilters"
        :loadingSamples="loadingSamples"
        :loadingTemplate="loadingTemplate"
        :loadSamples="loadSamples"
        :loadSamplesFromFilters="loadSamplesFromFilters"
        :setSearchQuery="setSearchQuery"
        :inputValues="inputValues"
        :sections="sections"
        :editingField="editingField"
        :editingSample="editingSample"
        :selectField="selectField"
        :toggleSection="toggleSection"
        :setImageUrl="setImageUrl"
        :viewMedia="viewMedia"
        :templateTab="templateTab"
        :subFolders="subFolders"
        :updateSample="updateSample"
        :deleteSample="deleteSample"
        :onSubFolderChanged="onSubFolderChanged"
        :imageUrl="imageUrl"
        :getImageURL="getImageURL"
        :mediaType="mediaType"
        :mediaSrc="mediaSrc"
        :closeMediaModal="closeMediaModal"
        :updateField="updateField"
        :tableSkeletonConfig="props.tableSkeletonConfig"
        :loadEverything="reloadTable"
        :isUpdatingComputations="isUpdatingComputations"
      />
    </slot>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, provide } from 'vue';
import { useStore } from '@/js/store';
import _debounce from 'lodash/debounce';
import { parseIdAsArray } from '@component-library/business-logic/app';
import { useTaskStore } from '@component-library/store/tasks';
import usePopoverHandler from '../gather-table-editor/composables/usePopoverHandler';
import api from './api';
import TableView from './components/TableView.vue';
import { getDefaultFilters } from './store';
import EventBus from '@component-library/EventBus';
import { FORM_CONTEXT_TYPE_DATA_MANAGER } from '@component-library/business-model/common';
import { createFormContext } from '@component-library/utils';
import axios from 'axios';

const props = defineProps({
  tabId: [Number, String],
  editable: { type: Boolean, default: true },
  filter: Object,
  hasSimpleLoader: Boolean,
  tableSkeletonConfig: Object,
});

const store = useStore();
const project = computed(() => store.state.project);
const filters = computed(() => store.state.gather.filters);
const taskStore = useTaskStore();

const { isReadOnly } = usePopoverHandler();

const loadingSamples = ref(true);
const samples = ref({});
const editingSample = ref(null);
const editingField = ref(null);
const searchQuery = ref(null);
const hasSearched = ref(false);
const loadingTemplate = ref(false);
const templateTab = ref(null);
const subFolders = ref([]);
const sections = ref([]);
const inputValues = ref([]);
const errorLoadingTable = ref(false);
const imageUrl = ref(null);
const mediaSrc = ref(null);
const mediaType = ref(null);
const isUpdatingComputations = ref(false);

const getImageURL = computed(() => {
  if (!imageUrl.value) return;
  if (typeof imageUrl.value === 'string' && imageUrl.value.includes('base64')) {
    return imageUrl.value;
  }
  if (Array.isArray(imageUrl.value)) return imageUrl.value;
  return `/api/images/value/${project.value.project_id}/${imageUrl.value}`;
});

const hasNoSamples = computed(() => {
  return (
    samples.value.data &&
    samples.value.data.length === 0 &&
    !searchQuery.value &&
    !hasSearched.value
  );
});

const isLoading = computed(() => {
  return (
    (loadingSamples.value && !samples.value.data) ||
    loadingTemplate.value ||
    (sections.value.filter((s) => s.loading).length > 0 &&
      sections.value.filter((s) => s.loaded).length === 0)
  );
});

const getFilters = computed(() => {
  return props.filter
    ? { ...getDefaultFilters(), ...props.filter }
    : filters.value;
});

const isCompoundApp = computed(() => {
  return parseIdAsArray(props.tabId).length > 1;
});

const loadSamples = async () => {
  editingSample.value = null;
  loadingSamples.value = true;
  try {
    const { data } = await api.getSamples({
      tab_id: props.tabId,
      page: samples.value ? samples.value.current_page : 1,
      search: searchQuery.value,
      filters: getFilters.value,
    });
    samples.value = data.samples;
    if (
      sections.value.length > 0 &&
      sections.value.filter((s) => s.toggled).length === 0
    ) {
      toggleSection(sections.value[0]);
    } else {
      sections.value
        .filter((s) => s.toggled)
        .forEach((section) => {
          getInputValuesBySection(section);
        });
    }
    loadingSamples.value = false;
    try {
      taskStore.completeTask('collect-gather-data');
    } catch (e) {}
  } catch (e) {
    loadingSamples.value = false;
    errorLoadingTable.value = true;
    throw e;
  }
};

const loadSamplesFromFilters = () => {
  hasSearched.value = true;
  loadSamples();
};

const setFilters = (filtersData) => {
  store.dispatch('gather/setFilters', filtersData);
};

const loadFullTemplate = async () => {
  editingSample.value = null;
  loadingTemplate.value = true;
  try {
    if (!isCompoundApp.value) {
      const { data } = await api.getTemplate({
        tab_id: props.tabId,
        filters: props.filter ? getFilters.value : null,
      });
      const { template: tab } = data;
      templateTab.value = tab;
      subFolders.value = tab.sub_folders;
      sections.value = tab.sections.map((s) => ({
        ...s,
        max_section_index: parseInt(s.max_section_index),
        toggled: false,
        loading: false,
        loaded: false,
      }));
      setFilters(tab.filters);
    } else {
      const appIds = parseIdAsArray(props.tabId);
      templateTab.value = await api.getCompoundApp(appIds);
      subFolders.value = [];
      sections.value = templateTab.value.sections.map((section) => ({
        ...section,
        toggled: false,
        loading: false,
        loaded: false,
      }));
    }
  } catch (e) {
    errorLoadingTable.value = true;
    throw e;
  } finally {
    loadingTemplate.value = false;
  }
};

const selectField = ({ sample, field_id, section_index }) => {
  editingSample.value = sample;
  editingField.value = { field_id, section_index };
};

const updateSample = ({ sample, inputValues: newInputValues }) => {
  const sampleToUpdate = samples.value.data.find((s) => s.id === sample.id);
  if (sampleToUpdate) {
    sampleToUpdate.custom_title = sample.custom_title;
  }
  inputValues.value = Object.freeze([
    ...inputValues.value.filter((s) => s.sample_id !== sample.id),
    ...newInputValues.filter((s) => s.sample_id === sample.id),
  ]);
};

const deleteSample = (id) => {
  const sampleIndex = samples.value.data.findIndex((s) => s.id === id);
  if (sampleIndex !== -1) {
    samples.value.data.splice(sampleIndex, 1);
    inputValues.value = Object.freeze(
      inputValues.value.filter((iV) => iV.sample_id !== id)
    );
  }
};

const onSubFolderChanged = (value) => {
  if (editingSample.value) {
    editingSample.value.sub_folder = value;
  }
};

const reloadTable = async () => {
  errorLoadingTable.value = false;
  subFolders.value = [];
  sections.value = [];
  samples.value = {};
  await loadFullTemplate();
  await loadSamples();
};

const getInputValuesBySection = async (section) => {
  const sampleIds = samples.value.data
    ? samples.value.data.map((s) => s.id)
    : [];
  if (sampleIds.length === 0) return;
  section.loading = true;
  try {
    const inputVals = await api.getInputValuesBySection({
      tab_id: props.tabId,
      section_id: section.id,
      sample_ids: sampleIds,
    });
    inputValues.value = Object.freeze([
      ...inputValues.value.filter(
        (iV) => iV.template_section_id !== section.id
      ),
      ...inputVals,
    ]);
    section.loaded = true;
  } catch (e) {
    errorLoadingTable.value = true;
    throw e;
  } finally {
    section.loading = false;
  }
};

const toggleSection = (section) => {
  section.toggled = !section.toggled;
  if (section.toggled) {
    getInputValuesBySection(section);
  } else {
    inputValues.value = Object.freeze(
      inputValues.value.filter((iV) => iV.template_section_id !== section.id)
    );
  }
};

const setSearchQuery = _debounce((value) => {
  hasSearched.value = true;
  searchQuery.value = value;
  loadSamples();
}, 300);

const closeMediaModal = () => {
  mediaSrc.value = null;
  mediaType.value = null;
};

const viewMedia = ({ src, type }) => {
  if (!isReadOnly(props.tabId)) return;
  if (src instanceof Blob) {
    mediaSrc.value = URL.createObjectURL(src);
  } else {
    mediaSrc.value = `/api/images/value/${project.value.project_id}/${src}`;
  }
  mediaType.value = type;
};

const setImageUrl = (value) => {
  if (!isReadOnly(props.tabId) && props.editable) return;
  imageUrl.value = value;
};

const updateField = async (fieldId, prop, value) => {
  for (let i = 0; i < templateTab.value.sections.length; i++) {
    const s = templateTab.value.sections[i];
    for (let j = 0; j < s.template_fields.length; j++) {
      const f = s.template_fields[j];
      if (f.id === fieldId) {
        const nextField = { ...f, [prop]: value };
        const { data } = await axios.put(`gather/template/field/${fieldId}`, {
          template_section_id: nextField.template_section_id,
          field_type_id: nextField.field_type_id,
          system_reference: nextField.system_reference,
          label: nextField.label,
          is_required: nextField.is_required,
          is_permanent: nextField.is_permanent,
          options: nextField.options,
          order: nextField.order,
          c_template_field_id: nextField.c_template_field_id,
          c_input_value: nextField.c_input_value,
        });
        nextField.created_at = data.result.created_at;
        nextField.updated_at = data.result.updated_at;
        s.template_fields.splice(j, 1, nextField);
      }
    }
  }
};

const formContext = createFormContext(
  FORM_CONTEXT_TYPE_DATA_MANAGER,
  updateField,
  () => project.value,
  undefined,
  (isBusy) => {
    isUpdatingComputations.value = isBusy;
  }
);
provide('formContext', formContext);

watch(
  () => props.tabId,
  async () => {
    await reloadTable();
  }
);

watch(
  () => props.filter,
  () => {
    if (isLoading.value) return;
    reloadTable();
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  reloadTable();
  EventBus.$on('resetTable', reloadTable);
});

onBeforeUnmount(() => {
  EventBus.$off('resetTable', reloadTable);
});
</script>
