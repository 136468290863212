import striptags from 'striptags';
import { FieldTypeIds } from '../fields';
import { GatherField, InputValue } from '../gather';
import { useLinkedFieldsStore } from '../store/linked-fields';
import { formatDateInputValue } from '../utils/date';
import { normalizeDepth } from './number';

export function getNumberTitle(index: number) {
  return `#${index}`;
}

export function getPrimaryFieldTitle(
  primaryField: GatherField | null,
  inputValue: InputValue | null,
  index: number,
  loadedAfterCallback?: (value: any) => void
) {
  const defaultTitle = getNumberTitle(index);

  if (!primaryField || !inputValue) {
    return defaultTitle;
  }

  const { value, value2 } = inputValue;

  // The value of a newly created Drawing field is a File instance.
  if (
    primaryField.field_type_id === FieldTypeIds.DRAWING &&
    value instanceof File
  ) {
    return defaultTitle;
  }

  if (primaryField.field_type_id === FieldTypeIds.REFERENCE) {
    if (!value) {
      return defaultTitle;
    }
    const linkedFieldsStore = useLinkedFieldsStore();
    const id = parseInt(String(value), 10);
    if (isNaN(id)) {
      return defaultTitle;
    }

    const linkedAppTitle = linkedFieldsStore.getLinkedAppTitle(
      id,
      loadedAfterCallback
    );

    return linkedAppTitle || defaultTitle;
  }

  if (primaryField.field_type_id === FieldTypeIds.DATE) {
    return formatDateInputValue(inputValue, primaryField);
  }

  if (primaryField.field_type_id !== FieldTypeIds.DEPTH) {
    const selectedValue =
      typeof value === 'string' || Number.isInteger(value) ? value : value2;
    const title = selectedValue ? striptags(String(selectedValue)) : null;

    return title || defaultTitle;
  }

  const is_range = primaryField.options?.is_range;
  const startDepth = normalizeDepth((value ?? '') as number | string);
  const endDepth = normalizeDepth((value2 ?? '') as number | string);
  const depths = [startDepth, endDepth].filter((item) => !!item);
  if (!depths.length) {
    return defaultTitle;
  } else if (depths.length === 1 || !is_range) {
    return depths[0];
  } else {
    return `${startDepth} - ${endDepth}`;
  }
}

export function checkIsInPublicForm(section) {
  if (section.is_public_form) {
    return true;
  }

  return !!section.template_fields.find((item) => item.options.is_public_form);
}
