import UnknownField from './Unknown.vue';
import CheckboxField from './Checkbox.vue';
import TextField from './Text.vue';
import NumberField from './Number.vue';
import DateField from './Date.vue';
import DropdownField from './Dropdown.vue';
import DepthField from './Depth.vue';
import ImageField from './Image.vue';
import SignatureField from './Signature.vue';
import ExpressionField from './Expression.vue';
import ReferenceField from './Reference.vue';
import DocumentField from './Document.vue';
import DuplicateField from './Duplicate.vue';
import TriplicateField from './Triplicate.vue';
import DrawingField from './Drawing.vue';
import LithologyField from './Lithology.vue';
import AddressField from './Address.vue';
import UserField from './User.vue';
import LabIdField from './LabId.vue';
import _upperFirst from 'lodash/upperFirst.js';
import { FieldTypeId, FieldTypeIds } from '../../fields';

const types = [
  'checkbox',
  'text',
  'number',
  'date',
  'dropdown',
  'depth',
  'reference',
  'image',
  'signature',
  'expression',
  'document',
  'duplicate',
  'triplicate',
  'drawing',
  'lithology',
  'address',
  'user',
  'lab-id',
  'expression',
] as const;

function getComponentName(type: (typeof types)[number] | null) {
  if (type && types.includes(type)) {
    return type + '-field';
  }

  return 'unknown-field';
}

function getFieldById(
  field_type_id: FieldTypeId
): (typeof types)[number] | null {
  let type: (typeof types)[number] | null = null;

  switch (field_type_id) {
    case FieldTypeIds.TEXT:
    case FieldTypeIds.AI_PROMPT:
      type = 'text';
      break;
    case FieldTypeIds.NUMBER:
      type = 'number';
      break;
    case FieldTypeIds.EXPRESSION:
      type = 'expression';
      break;
    case FieldTypeIds.DATE:
      type = 'date';
      break;
    case FieldTypeIds.DROPDOWN:
      type = 'dropdown';
      break;
    case FieldTypeIds.CHECKBOX:
      type = 'checkbox';
      break;
    case FieldTypeIds.MEDIA:
      type = 'image';
      break;
    case FieldTypeIds.DEPTH:
      type = 'depth';
      break;
    case FieldTypeIds.REFERENCE:
      type = 'reference';
      break;
    case FieldTypeIds.SIGNATURE:
      type = 'signature';
      break;
    case FieldTypeIds.CAPTION:
      type = 'expression';
      break;
    case FieldTypeIds.DOCUMENT:
      type = 'document';
      break;
    case FieldTypeIds.DUPLICATE:
      type = 'duplicate';
      break;
    case FieldTypeIds.TRIPLICATE:
      type = 'triplicate';
      break;
    case FieldTypeIds.DRAWING:
      type = 'drawing';
      break;
    case FieldTypeIds.LITHOLOGY:
      type = 'lithology';
      break;
    case FieldTypeIds.ADDRESS:
      type = 'address';
      break;
    case FieldTypeIds.USER:
      type = 'user';
      break;
    case FieldTypeIds.LAB_ID:
      type = 'lab-id';
      break;
  }

  return type;
}

function getComponentNameFromId(field_type_id) {
  const type = getFieldById(field_type_id);
  return getComponentName(type);
}

function getUpperCaseComponentNameFromId(field_type_id) {
  const fieldName = getComponentNameFromId(field_type_id);
  return fieldName
    .split('-')
    .map((word) => _upperFirst(word))
    .join('');
}

export default {
  components: {
    UnknownField,
    CheckboxField,
    TextField,
    NumberField,
    DateField,
    DropdownField,
    DepthField,
    ReferenceField,
    ImageField,
    SignatureField,
    ExpressionField,
    DocumentField,
    DuplicateField,
    TriplicateField,
    DrawingField,
    LithologyField,
    AddressField,
    UserField,
    LabIdField,
  },
  types,
  getComponentName,
  getComponentNameFromId,
  getUpperCaseComponentNameFromId,
};
