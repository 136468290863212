import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import { Polygon } from 'ol/geom';
import {
  fromLonLat,
  legacyLatLngToLonLat,
  lonLatToLegacyLatLng,
  toLonLat,
} from '../../common/coordinate';
import type { LayerModel } from '../types';
import { LayerType } from '../types';
import type { ImageCorners, ImageLayerModel } from './types';

export function checkIsImageLayerModel(
  model: LayerModel
): model is ImageLayerModel {
  const { type } = model.geojson.properties;
  return type === LayerType.IMAGE;
}

/**
 * The coordinates of the rectangle:
 * [bottomLeft, bottomRight, topRight, topLeft, bottomLeft]
 * The corners of the image:
 * [topLeft, topRight, bottomLeft, bottomRight]
 * @param {ol/geom/Polygon-Rectangle} rectangle
 * @returns
 */
export function getImageCorners(map: Map, rectangle: Polygon): ImageCorners {
  const projection = map.getView().getProjection();
  const coordinates = rectangle.getLinearRing(0)!.getCoordinates();

  return [
    lonLatToLegacyLatLng(toLonLat(coordinates[3], projection)),
    lonLatToLegacyLatLng(toLonLat(coordinates[2], projection)),
    lonLatToLegacyLatLng(toLonLat(coordinates[0], projection)),
    lonLatToLegacyLatLng(toLonLat(coordinates[1], projection)),
  ];
}

export function createRectangleFromImageCorners(
  map: Map,
  corners: ImageCorners
): Polygon {
  const projection = map.getView().getProjection();
  const [topLeftLatLng, topRightLatLng, bottomLeftLatLng, bottomRightLatLng] =
    corners;
  const bottomLeft = fromLonLat(
    legacyLatLngToLonLat(bottomLeftLatLng),
    projection
  );
  const bottomRight = fromLonLat(
    legacyLatLngToLonLat(bottomRightLatLng),
    projection
  );
  const topRight = fromLonLat(legacyLatLngToLonLat(topRightLatLng), projection);
  const topLeft = fromLonLat(legacyLatLngToLonLat(topLeftLatLng), projection);

  return new Polygon([
    [bottomLeft, bottomRight, topRight, topLeft, bottomLeft],
  ]);
}

export function getImageCenter(map: Map, corners?: ImageCorners): Coordinate {
  if (!corners) {
    return map.getView().getCenter() as Coordinate;
  }

  const rectangle = createRectangleFromImageCorners(map, corners);
  return getCenter(rectangle.getExtent());
}
