import BigNumber from 'bignumber.js';

export type UnitConversion = {
  input_unit: string;
  output_unit: string;
  conversion_factor: number;
  matrices: string[];
  is_default?: boolean;
};

export function safeMultiply(
  a: number,
  b: number,
  maxDecimals: number = 8
): number {
  const scaleFactor = new BigNumber(10).pow(maxDecimals);

  const scaledA = new BigNumber(a)
    .multipliedBy(scaleFactor)
    .integerValue(BigNumber.ROUND_HALF_UP);
  const scaledB = new BigNumber(b)
    .multipliedBy(scaleFactor)
    .integerValue(BigNumber.ROUND_HALF_UP);

  const scaledResult = scaledA.multipliedBy(scaledB);

  const result = scaledResult.dividedBy(scaleFactor.pow(2));

  return result.toNumber();
}

const unitKeyMap = {
  'ug/l': 'µg/L', // Micrograms per liter
  'ug/kg': 'µg/kg', // Micrograms per kilogram
  'ug/m3': 'µg/m3',
  'mg/l': 'mg/L', // Milligrams per liter
  'ng/ml': 'ng/mL', // Nanograms per milliliter
  'pg/ml': 'pg/mL', // Picograms per milliliter
  m: 'M', // Molar (moles per liter)
  mm: 'mM', // Millimolar
  um: 'µM', // Micromolar
  nm: 'nM', // Nanomolar
  'iu/l': 'IU/L', // International Units per Liter
  'cfu/ml': 'CFU/mL', // Colony Forming Units per Milliliter
};

export const getUnitByKey = (key: string) => {
  return unitKeyMap[key] || key;
};
