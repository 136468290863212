<script lang="ts" setup>
import Draggable from 'vuedraggable';
import { checkIsOptionDuplicate } from '../business-logic/dropdown';
import { computed, nextTick, ref } from 'vue';
import { useToastStore } from '../store/toasts';

const toastStore = useToastStore();
const value = defineModel<string[]>({ default: () => [] });
const inputTag = ref<HTMLInputElement>();

const props = withDefaults(
  defineProps<{
    checkIsTagRemovable?: (index: number) => boolean;
    readOnly?: boolean;
    isDisabled?: boolean;
    hasInlineDeletion?: boolean;
    hasCopy?: boolean;
    hasCut?: boolean;
    hasPaste?: boolean;
    hasImport?: boolean;
    selectedItem?: string;
  }>(),
  {
    checkIsTagRemovable: (_) => true,
    readOnly: false,
    hasInlineDeletion: true,
    hasCopy: true,
    hasCut: true,
    hasPaste: true,
    hasImport: false,
  }
);
const emit = defineEmits<{
  (event: 'clickOption', option: string): void;
  (event: 'click'): void;
  (event: 'importClick'): void;
  (event: 'update:modelValue', value: string[]): void;
}>();

const newItem = ref('');

const items = computed<string[]>({
  get() {
    return value.value;
  },
  set(updated) {
    if (
      !updated ||
      !Array.isArray(updated) ||
      JSON.stringify(updated) === JSON.stringify(value.value)
    ) {
      return;
    }
    value.value = updated;
  },
});

function handleClick() {
  if (!props.readOnly) {
    inputTag.value?.focus();
  }

  if (props.isDisabled) {
    return;
  }

  emit('click');
}

async function removeItem(index) {
  if (await props.checkIsTagRemovable(index)) {
    items.value = items.value.filter((v, i) => i !== index);
  }
}

function createItem() {
  if (
    newItem.value === '' ||
    checkIsOptionDuplicate(items.value, newItem.value)
  ) {
    newItem.value = '';
    return;
  }
  items.value = [...items.value, newItem.value];
  newItem.value = '';
  nextTick(handleClick);
}

function sort() {
  let isNumber = true;
  items.value = items.value.sort((a, b) => {
    const na = Number(a),
      nb = Number(b);
    isNumber = Number.isInteger(na) && Number.isInteger(nb);
    if (isNumber) {
      return na - nb;
    }
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a > b ? 1 : b > a ? -1 : 0;
  });

  emit('update:modelValue', items.value);
}

function sortDesc() {
  let isNumber = true;
  items.value = items.value.sort((a, b) => {
    const na = Number(a),
      nb = Number(b);
    isNumber = Number.isInteger(na) && Number.isInteger(nb);
    if (isNumber) {
      return nb - na;
    }
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? 1 : b < a ? -1 : 0;
  });
  emit('update:modelValue', items.value);
}

async function handleCopyClick() {
  if (!items.value.length) {
    return;
  }

  const text = items.value.join('\n');
  try {
    await navigator.clipboard.writeText(text);
    toastStore.success('Copied!');
  } catch (e) {
    toastStore.unexpected(e, 'Failed to copy!.');
  }
}
async function handleCutClick() {
  if (!items.value.length) {
    return;
  }

  const text = items.value.join('\n');
  try {
    await navigator.clipboard.writeText(text);
    items.value = [];
    toastStore.success('Cut!');
  } catch (e) {
    toastStore.unexpected(e, 'Failed to cut!.');
  }
}
async function handlePasteClick(evt) {
  try {
    const text = await navigator.clipboard.readText();
    const newItems = text
      .split('\n')
      .map((item) => item.trim())
      .filter((item) => !!item && !checkIsOptionDuplicate(items.value, item));
    items.value = [...items.value, ...newItems];
    toastStore.success('Pasted!');
  } catch (e) {
    toastStore.unexpected(e, 'Failed to paste!.');
  }
}
function handleImportClick() {
  emit('importClick');
}
</script>

<template>
  <div class="d-flex">
    <div
      v-if="items"
      class="wrapper w-100 flex-col"
      @click.prevent="handleClick"
    >
      <template v-if="items.length > 0">
        <Draggable
          v-model="items"
          draggable=".input-tag"
          :disabled="readOnly"
          :itemKey="(item) => item"
        >
          <template #item="{ element, index }">
            <span
              :class="[
                'input-tag border-4 newline',
                { 'bg-dark': selectedItem === element },
              ]"
            >
              <span @click.prevent.stop.self="emit('clickOption', element)">{{
                element
              }}</span>
              <a
                v-if="hasInlineDeletion && !readOnly"
                class="remove"
                @click.prevent.stop.self="removeItem(index)"
              >
                x
              </a>
            </span>
          </template>
        </Draggable>
      </template>
      <input
        v-if="!readOnly"
        ref="inputTag"
        v-model.trim="newItem"
        type="text"
        class="new-tag"
        @keyup.enter="createItem"
      />
    </div>

    <slot name="extensionContainer" />

    <div v-if="!readOnly" class="d-flex flex-column button-container">
      <button
        v-if="hasCopy"
        type="button"
        class="btn btn-outline-primary"
        :disabled="!items.length"
        title="Copy"
        @click.stop="handleCopyClick"
      >
        <i class="fas fa-copy"></i>
      </button>

      <button
        v-if="hasCut"
        type="button"
        class="btn btn-outline-primary"
        :disabled="!items.length"
        title="Cut"
        @click.stop="handleCutClick"
      >
        <i class="fas fa-cut"></i>
      </button>

      <button
        v-if="hasPaste"
        type="button"
        class="btn btn-outline-primary"
        title="Paste"
        @click.stop="handlePasteClick($event)"
      >
        <i class="fas fa-paste"></i>
      </button>

      <button
        v-if="hasImport"
        type="button"
        class="btn btn-outline-primary"
        title="Import"
        @click.stop="handleImportClick"
      >
        <i class="fas fa-file-import"></i>
      </button>

      <button
        type="button"
        class="btn btn-outline-primary"
        title="Sort in alphabetical order "
        @click.stop="sort"
      >
        <i class="fas fa-sort-alpha-up"></i>
      </button>

      <button
        type="button"
        class="btn btn-outline-primary"
        title="Sort in reverse alphabetical order "
        @click.stop="sortDesc"
      >
        <i class="fas fa-sort-alpha-down"></i>
      </button>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-right: 0px;
  overflow: hidden;
  padding-left: 4px;
  padding-top: 4px;
  cursor: text;
  text-align: left;
  -webkit-appearance: textfield;
  appearance: textfield;
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.button-container button {
  border-radius: 0px;
  border-bottom-width: 0px;
}

.button-container button:last-child {
  border-bottom-width: 1px !important;
}

.wrapper > div {
  min-height: 32px;
}

.input-tag {
  border-radius: 2px;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 3px;
  cursor: pointer;
  border-radius: 0.375rem;
}

.input-tab-clickable {
  cursor: pointer;
}

.remove {
  font-weight: 700;
  text-decoration: none;
  color: #fff !important;
}

.new-tag {
  background: transparent;
  border: 0;
  color: #777;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 4px;
  flex-grow: 1;
}

.sort-btn {
  position: relative;
  margin-top: 2em;
  float: right;
}

.sort-desc-btn {
  position: absolute;
  margin-top: 4em;
  float: right;
}
</style>
