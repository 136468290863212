import { getPointResolution, get as getProjection } from 'ol/proj';
import { Dpi } from './types';
import TileGrid from 'ol/tilegrid/TileGrid';
import { Map } from 'ol';
import { ProjectionLike } from 'ol/proj';
import { transformCoordinate } from '../common/coordinate';
import { getDpi } from './medium';
import { Coordinate } from 'ol/coordinate';
import { getLayoutZoom } from './layout';

const INCHES_PER_METER = 1 / 0.0254;

/**
 * Convert scale to point resolution.
 * @param {Number} scale
 * @param {ProjectionLike} projection
 * @param {Number} dpi
 * @returns
 */
export function scaleToResolution(
  projection: ProjectionLike,
  scale: number,
  center: Coordinate,
  dpi = Dpi.Screen
) {
  const proj = getProjection(projection);
  if (!proj) {
    console.error('Invalid projection', projection);
    throw new Error('Invalid projection');
  }

  let mpu = proj.getMetersPerUnit();
  if (mpu === undefined) {
    console.warn('Invalid meters per unit', mpu, projection, proj);
    mpu = 1;
  }
  const pointResolution =
    getPointResolution(projection, 1 / mpu, center, 'm') * mpu;

  return scale / (pointResolution * INCHES_PER_METER * dpi);
}

/**
 * Convert point resolution to scale.
 * @param {Number} resolution
 * @param {ProjectionLike} projection
 * @param {Number} dpi
 * @returns
 */
export function resolutionToScale(
  projection: ProjectionLike,
  resolution: number,
  center: Coordinate,
  dpi = Dpi.Screen
) {
  const pointResolution = getPointResolution(
    projection,
    resolution,
    center,
    'm'
  );
  return pointResolution * INCHES_PER_METER * dpi;
}

export function getScale(map: Map) {
  const view = map.getView();
  const projection = view.getProjection();
  const resolution = view.getResolution()!;
  const center = view.getCenter()!;
  return resolutionToScale(projection, resolution, center, getDpi(map));
}

export function setScale(map: Map, value: number) {
  const view = map.getView();
  const projection = view.getProjection();
  const center = view.getCenter()!;
  const resolution = scaleToResolution(projection, value, center, getDpi(map));
  view.setResolution(resolution);
}

export function patchTileGrid(
  map: Map,
  tileGrid: TileGrid,
  projection?: ProjectionLike,
  targetDpi: Dpi = Dpi.Screen
) {
  const view = map.getView();
  const viewProjection = view.getProjection();
  projection = projection ?? viewProjection;
  const getZForResolutionNative = tileGrid.getZForResolution;
  tileGrid.getZForResolution = function (resolution, opt_direction) {
    const center = transformCoordinate(
      view.getCenter()!,
      viewProjection,
      projection
    );
    resolution = scaleToResolution(
      projection,
      getScale(map) * getLayoutZoom(map),
      center,
      targetDpi
    );
    return getZForResolutionNative.call(this, resolution, opt_direction);
  };
}
