import { Map } from 'ol';
import { LineString } from 'ol/geom';
import { Style, Text } from 'ol/style';
import { StyleLike } from 'ol/style/Style';
import { getLayoutZoom } from '../measurement/layout';
import { formatLength, getLength } from '../measurement/length';
import { getProjectUnit } from '../measurement/utils';
import { getMeasurementStyleOptions } from './text';

export default function createOutlineStyle(map: Map, feature, isTemporary) {
  const outlineStyle: StyleLike = [];
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();
  let coordinates = [];
  if (['Polygon', 'Circle'].indexOf(geometryType) !== -1) {
    coordinates = geometry.getLinearRing(0).getCoordinates();
  } else if (geometryType === 'LineString') {
    coordinates = geometry.getCoordinates();
  }

  for (let i = 0; i < coordinates.length - 1; i++) {
    const segmentCoordinates = [coordinates[i], coordinates[i + 1]];
    const segment = new LineString(segmentCoordinates);
    const segmentStyle = new Style({
      geometry: segment,
      text: new Text({
        ...getMeasurementStyleOptions(getLayoutZoom(map), isTemporary),
        text: formatLength(getLength(map, segment), getProjectUnit(map)),
        placement: 'line',
        textBaseline: 'bottom',
        overflow: false,
      }),
      zIndex: 1,
    });
    outlineStyle.push(segmentStyle);
  }

  return outlineStyle;
}
