<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { GatherField, InputValue, Item } from '../../gather';
import { FieldTypeIds } from '../../fields';
import useAuth from '../../composables/useAuth';
import {
  structureToPreview,
  getDefaultStructures,
  checkRegexPattern,
} from '../../upload-structure';
import { CompanyUploadTitleStructure } from '../../company';
import EventBus from '../../EventBus';

const auth = useAuth();

const props = defineProps<{
  field: GatherField;
  inputValue: InputValue;
  item: Item;
  allFields: GatherField[];
  inputValues: InputValue[];
  sampleIdentifier?: string | null;
  isVisible: boolean;
}>();

watch(
  () => props.isVisible,
  () => {
    updateFormat();
  }
);

const hideLabFormat = ref<boolean>(false);
const selectedFormatId = ref<string | null>(null);
const customItemTitle = ref<string | null>(null);

const getFormattedLabId = (
  companyStructure: CompanyUploadTitleStructure | null
) => {
  if (!companyStructure || !props.sampleIdentifier) {
    return null;
  }

  const { structure } = companyStructure;

  const title = customItemTitle.value || props.sampleIdentifier;
  const startDepth = depthValue.value?.value ?? null;
  const endDepth = depthValue.value?.value2 ?? null;

  return structureToPreview(
    structure,
    title,
    startDepth as string,
    endDepth as string,
    formatDate(dateValue.value?.value as string, 'DDMMYY'),
    formatDate(dateValue.value?.value as string, 'YYMMDD')
  );
};

const formats = computed<CompanyUploadTitleStructure[]>(() => {
  const companyStructures = auth.user()?.company?.upload_title_structures;

  let allStructures =
    companyStructures && companyStructures.length > 0
      ? companyStructures
      : (getDefaultStructures() as any);

  allStructures = allStructures.filter((s) => {
    const value = getFormattedLabId(s);
    return value
      ? !checkRegexPattern(value, s.structure).some(
          (result) => result.extracted_value === null
        )
      : false;
  });

  return allStructures;
});

const depthField = computed(() => {
  return props.allFields.find(
    (f) =>
      f.template_section_id === props.inputValue.template_section_id &&
      f.field_type_id === FieldTypeIds.DEPTH
  );
});

const depthValue = computed(() => {
  if (!depthField.value) {
    return null;
  }

  return props.inputValues.find(
    (v) =>
      v.template_field_id === depthField.value!.id &&
      v.template_section_id === props.inputValue.template_section_id &&
      v.template_section_index === props.inputValue.template_section_index
  );
});

const dateField = computed(() => {
  return props.allFields.find(
    (f) =>
      f.template_section_id === props.inputValue.template_section_id &&
      f.field_type_id === FieldTypeIds.DATE
  );
});

const dateValue = computed(() => {
  if (!dateField.value) {
    return null;
  }

  return props.inputValues.find(
    (v) =>
      v.template_field_id === dateField.value!.id &&
      v.template_section_id === props.inputValue.template_section_id &&
      v.template_section_index === props.inputValue.template_section_index
  );
});

const formatDate = (dateString: string, formatType: 'DDMMYY' | 'YYMMDD') => {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2);

  let formattedDate = '';

  if (formatType === 'DDMMYY') {
    formattedDate = `${day}${month}${year}`;
  } else if (formatType === 'YYMMDD') {
    formattedDate = `${year}${month}${day}`;
  } else {
    throw new Error('Invalid format type specified. Use "DDMMYY" or "YYMMDD".');
  }

  return formattedDate;
};

const formattedLabId = computed(() => {
  if (!selectedFormatId.value) {
    return null;
  }

  const selectedFormat = formats.value.find(
    (s) => s.id === selectedFormatId.value
  );
  if (!selectedFormat) {
    return null;
  }

  return getFormattedLabId(selectedFormat);
});

const usePresetFormat = computed(() => {
  return props.field.options?.use_preset_format || false;
});

const allowInputItemTitle = computed(() => {
  return (
    props.field.options?.rpd_allow_custom_title ||
    formats.value.length === 0 ||
    customItemTitle.value
  );
});

const updateFormat = () => {
  if (!props.isVisible) {
    return;
  }

  let newInputValue;

  if (usePresetFormat.value) {
    newInputValue = {
      ...props.inputValue,
      value: formattedLabId.value,
      value2: allowInputItemTitle.value
        ? customItemTitle.value || props.sampleIdentifier
        : null,
      options: {
        selectedFormatId: selectedFormatId.value,
      },
    };

    if (hideLabFormat.value) {
      newInputValue.value = null;
      newInputValue.value2 = customItemTitle.value;
    }
  } else {
    newInputValue = {
      ...props.inputValue,
      value: customItemTitle.value,
    };
  }

  EventBus.$emit('updateInputValue', {
    inputValue: newInputValue,
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
  });
};

const setSelectedFormat = () => {
  if (selectedFormatId.value) {
    return;
  }

  if (props.inputValue.options?.selectedFormatId) {
    selectedFormatId.value = props.inputValue.options.selectedFormatId;
    return;
  }

  if (props.field?.options?.selected_lab_title_format_id) {
    selectedFormatId.value = props.field.options.selected_lab_title_format_id;
    return;
  }

  if (formats.value.length > 0) {
    selectedFormatId.value = formats.value[0].id;
  }
};

watch(formats, () => {
  if (!usePresetFormat.value) {
    return;
  }

  setSelectedFormat();
});

watch(
  () => formattedLabId.value,
  () => {
    if (!usePresetFormat.value) {
      return;
    }

    updateFormat();
  }
);

onMounted(() => {
  if (!usePresetFormat.value) {
    customItemTitle.value = props.inputValue.value as string;
    return;
  }

  if (props.inputValue.value === null && props.inputValue.value2 !== null) {
    hideLabFormat.value = true;
  }

  // if user swaps from no format to format, need to pull across the value
  if (
    !props.inputValue.options?.selectedFormatId &&
    props.inputValue.value &&
    !props.inputValue.value2
  ) {
    customItemTitle.value = props.inputValue.value as string;
  } else {
    customItemTitle.value = (props.inputValue.value2 as string) || null;
  }

  setSelectedFormat();
});
</script>
<template>
  <div class="form-group mb-2">
    <label class="form-label">
      {{ field.label }}
      <a
        v-if="usePresetFormat && allowInputItemTitle && formats.length > 0"
        href="#"
        @click.prevent="hideLabFormat = !hideLabFormat"
      >
        {{ hideLabFormat ? 'Show' : 'Hide' }} Lab Format
      </a>
    </label>

    <template v-if="usePresetFormat">
      <div class="input-group">
        <input
          v-if="allowInputItemTitle"
          v-model="customItemTitle"
          type="text"
          class="form-control"
        />
        <select
          v-if="(!hideLabFormat || !allowInputItemTitle) && formats.length > 0"
          v-model="selectedFormatId"
          class="form-select"
        >
          <option v-for="format in formats" :value="format.id">
            {{ getFormattedLabId(format) }}
          </option>
        </select>
      </div>

      <small v-if="formats.length === 0" class="text-danger d-block mt-1">
        Your fields do not contain all of the required fields to generate a lab
        id, fill those in to select a format or enter a custom value.
      </small>
    </template>
    <template v-else>
      <div class="input-group">
        <input
          v-model="customItemTitle"
          type="text"
          class="form-control"
          @change="updateFormat"
        />
      </div>
    </template>
  </div>
</template>
