<script lang="ts" setup>
import _debounce from 'lodash/debounce';
import striptags from 'striptags';
import { computed, inject, ref, watch } from 'vue';
import InputText from '../../components/InputText.vue';
import InputTextarea from '../../components/InputTextarea.vue';
import { GatherField, InputValue, InputValueValue } from '../../gather';
import EventBus from '../../EventBus';
import VueEditor from '../../components/VueEditor.vue';

const props = defineProps<{
  field: GatherField;
  inputValue: InputValue;
  defaultValue?: InputValueValue;
  isAutoAssignEnabled: boolean;
  isAutoAssignActive: boolean;
  isDisabled: boolean;
}>();

const formContext = inject<any>('formContext');

const customToolbar = ref([
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link'],
]);

const isWaiting = ref(false);
const isDefaultInputValue = ref(false);

const placeholder = computed(() => {
  return props.field.options?.placeholder || '';
});

const setValue = (value) => {
  const _isDefaultInputValue = isDefaultInputValue.value;
  if (_isDefaultInputValue) {
    isDefaultInputValue.value = false;
  }
  EventBus.$emit('updateInputValue', {
    inputValue: { ...props.inputValue, value },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
    isDefaultInputValue: _isDefaultInputValue,
  });
};
const setValueDebounced = _debounce((value) => {
  setValue(value);
  if (isWaiting.value) {
    isWaiting.value = false;
  }
}, 1000);

const value = computed<string | null>({
  get() {
    if (!props.inputValue?.value) {
      return '';
    }
    if (!props.field.options || !props.field?.options?.is_richtext) {
      return striptags(String(props.inputValue.value));
    }
    return props.inputValue.value as string;
  },
  set(value) {
    if (!isWaiting.value) {
      isWaiting.value = true;
    }
    setValueDebounced(value);
  },
});

const isRequired = computed(() => {
  return !!props.field.is_required;
});

const isEmail = computed(() => {
  return props.field.options?.validation === 'email';
});

watch(
  () => props.defaultValue,
  (newValue) => {
    if (
      !value.value ||
      (props.isAutoAssignEnabled && props.isAutoAssignActive)
    ) {
      isDefaultInputValue.value = true;
      setValue(newValue ? String(newValue) : null);
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.isAutoAssignActive,
  (newValue) => {
    if (newValue) {
      setValue(props.defaultValue ? String(props.defaultValue) : null);
    }
  }
);

watch(isWaiting, (newValue) => {
  formContext.setIsBusy(newValue);
});
</script>

<template>
  <div class="form-group">
    <template v-if="isEmail">
      <InputText
        v-model="value"
        type="email"
        :label="field.label"
        :isDisabled="isDisabled"
        :required="isRequired"
        :invalid="isRequired && (!value || value == '')"
        :placeholder="placeholder"
      />
    </template>

    <template v-else-if="field.options && field.options.is_richtext">
      <label class="form-label">
        {{ field.label }}
        <sup v-if="isRequired" class="text-danger">*</sup>
      </label>

      <VueEditor
        v-model="value"
        :editorToolbar="customToolbar"
        :class="{
          'border-danger': isRequired && (!value || value == ''),
        }"
        :required="isRequired"
        :disabled="isDisabled"
      />
    </template>

    <InputTextarea
      v-else
      v-model="value"
      type="text"
      :label="field.label"
      :name="field.label"
      :invalid="isRequired && (!value || value == '')"
      :placeholder="placeholder"
      :required="isRequired"
      :isDisabled="isDisabled"
      :autoResize="true"
    />
  </div>
</template>

<style lang="scss">
@import 'quill/dist/quill.snow.css';

.quillWrapper.border-danger {
  border: 1px solid red;
}
</style>
