import useIsGather from "./composables/useIsGather";

export const DATANEST_URL =
  import.meta.env?.VITE_DATANEST_URL ||
  import.meta.env?.VITE_APP_URL ||
  (typeof process !== 'undefined' ? process.env.VITE_DATANEST_URL : '') ||
  (typeof process !== 'undefined' ? process.env.VITE_APP_URL : '') ||
  '';

if (DATANEST_URL.includes('gather')) {
  throw new Error('The Datanest URL is not configured correctly.');
}

export const DATANEST_API_URI = useIsGather() ? '/api/hub' : '/api';
