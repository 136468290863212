<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch, inject } from 'vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import useUpdateGatherInputValue from '@component-library/composables/useUpdateGatherInputValue';
import FormSection from '@component-library/form/FormSection.vue';
import { InputValue, App } from '@component-library/gather';
import { Project } from '@component-library/project';
import { useToastStore } from '@component-library/store/toasts';
import { PopoverData } from '../gather-table-horizontal/types/popover-data';
import { getAssociatedFields } from './api';
import {
  AssociatedData,
  InputValueWithOriginal,
} from './types/associated-data';
import Spinner from '@component-library/components/Spinner.vue';
import { useProjectStore } from '@component-library/store/project';
import EventBus from '@component-library/EventBus';
import { processInputValuesForManiplation } from '@component-library/business-logic/input-value';

const toastStore = useToastStore();
const projectStore = useProjectStore();

const props = defineProps<{
  app: App;
  popoverData: PopoverData;
  isUpdatingComputations: boolean;
}>();

watch(
  () => props.popoverData,
  () => {
    loadAssociatedFields();
  }
);

const emit = defineEmits(['close', 'updateInputValues']);

const formContext = inject('formContext') as any;
const { uploadAssets, updateInputValue } = useUpdateGatherInputValue();
const isLoading = ref(true);
const isUpdating = ref(false);
const associatedData = ref<AssociatedData | null>(null);

const sections = computed(() => {
  return associatedData.value!.sections;
});

const fields = computed(() => {
  return associatedData.value!.fields;
});

const inputValues = computed(() => {
  return associatedData.value!.values;
});

const handleUpdateInputValue = ({
  inputValue,
  field,
  sectionIndex,
  templateTabId,
}) => {
  if (!associatedData.value) {
    return;
  }

  let inputValues = associatedData.value.values;

  let existingIV = inputValues.find(
    (iv) =>
      iv.template_field_id == (field?.id || field) &&
      iv.template_section_index == sectionIndex &&
      iv.template_section_id == inputValue.template_section_id
  );

  if (!existingIV) {
    const newIV = {
      ...inputValue,
      template_tab_id: templateTabId,
    };
    inputValues.push(newIV);
    formContext.changedInputValueQueue.push(newIV);
    return;
  }

  Object.keys(inputValue).forEach((key) => {
    existingIV![key] = inputValue[key];
  });
  formContext.changedInputValueQueue.push(existingIV);
};

const submitUpdateInputValue = async () => {
  document.getElementById('field-pane-save-button')?.focus();
  const updatedValues = associatedData.value!.values.filter(
    (iv) =>
      iv.original_value &&
      (iv.original_value.value != iv.value ||
        iv.original_value.value2 != iv.value2)
  );

  if (updatedValues.length === 0) {
    emit('close');
    return;
  }

  isUpdating.value = true;

  try {
    for (let inputValue of updatedValues) {
      if (!inputValue.project_id) {
        inputValue.project_id = (projectStore.project as Project).project_id;
      }
      const convertedAssets = await uploadAssets([inputValue]);

      for (let convertedAsset of convertedAssets) {
        if (!convertedAsset.index && convertedAsset.index != 0) {
          inputValue.value = convertedAsset.src;
          continue;
        }
        inputValue.value![convertedAsset.index].src = convertedAsset.src;
      }

      const updatedValue = await updateInputValue(inputValue);
      emit(
        'updateInputValues',
        processInputValuesForManiplation(
          [updatedValue],
          (projectStore.project as Project).project_id
        )
      );
    }

    emit('close');
  } catch (e) {
    isUpdating.value = false;
    toastStore.error('Failed to update values, try again.');
    throw e;
  } finally {
    isUpdating.value = false;
  }
};

const loadAssociatedFields = async () => {
  isLoading.value = true;

  const { data } = await getAssociatedFields(props.popoverData.field.id, {
    sample_id: props.popoverData.sample.id,
  });

  associatedData.value = data as AssociatedData;

  const castInputValue = (inputValue: InputValue): InputValueWithOriginal => {
    return {
      ...inputValue,
      original_value: {
        value: inputValue.value,
        value2: inputValue.value2,
      },
    };
  };

  if (!props.popoverData.inputValue.id) {
    associatedData.value.values.push(
      castInputValue(props.popoverData.inputValue)
    );
  }

  associatedData.value!.values = processInputValuesForManiplation(
    associatedData.value!.values,
    (projectStore.project as Project).project_id
  ).map((iv) => {
    return castInputValue(iv);
  });

  isLoading.value = false;
};

onMounted(() => {
  loadAssociatedFields();

  // to work with vue2 event bus
  EventBus.$on('updateInputValue', handleUpdateInputValue);
});

onUnmounted(() => {
  EventBus.$off('updateInputValue', handleUpdateInputValue);
});
</script>

<template>
  <div>
    <div v-if="isLoading" class="text-center py-4">
      <Spinner large />
    </div>
    <template v-else>
      <div
        class="bg-dark text-white d-flex justify-content-between align-items-center p-3"
        style="border-top-left-radius: 4px; border-top-right-radius: 4px"
      >
        <h6 class="mb-0">Editing Value</h6>
      </div>

      <div class="pt-1 px-1 bg-white" @mousedown.stop>
        <template v-for="section of sections" :key="`section-${section.id}`">
          <FormSection
            v-show="
              section.template_tab_id === popoverData.inputValue.template_tab_id
            "
            :templateTab="app"
            :section="section"
            :sample="popoverData.sample"
            :inputValues="inputValues"
            :fields="fields"
            :allFields="fields"
            :allSections="sections"
            :sampleIdentifier="popoverData.sample.custom_title"
            :collapse="true"
            :showAddSection="false"
            :keepAllOpen="true"
            :shouldEmit="false"
            :currentValue="popoverData.inputValue"
          />
        </template>
      </div>

      <div
        class="d-flex bg-light py-2 px-3"
        style="border-bottom-left-radius: 4px; border-bottom-right-radius: 4px"
      >
        <button class="btn btn-flat me-2" @click="emit('close')">Cancel</button>
        <ButtonSpinner
          id="field-pane-save-button"
          type="submit"
          class="btn btn-primary flex-fill"
          :isLoading="isUpdating"
          :disabled="isUpdatingComputations"
          @click.prevent="submitUpdateInputValue"
        >
          Save
        </ButtonSpinner>
      </div>
    </template>
  </div>
</template>
