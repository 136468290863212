const SET_PROCESSED_INFORMATION = (
  state,
  { processed_conditions, settings, scenarios, has_insights }
) => {
  state.processed_information = processed_conditions;
  state.processed_settings = settings;
  state.scenarios = scenarios;
  state.has_insights = has_insights;
};

const SET_PROCESSED_INFORMATION_FIELD = (state, { key, value }) => {
  state.processed_information[key] = value;
};

const SET_PROCESSED_SETTINGS_FIELD = (state, { key, value }) => {
  if (!state.processed_settings) {
    state.processed_settings = {
      [key]: value,
    };
    return;
  }

  state.processed_settings[key] = value;
};

const SET_PROCESSED_GROUP_FIELD = (state, { group_id, key, value }) => {
  const settings = state.processed_settings;

  const group = settings.scenario_groups.find((g) => g.id == group_id);
  group[key] = value;
};

const UPDATE_SCENARIO_GROUP = (state, data) => {
  const settings = state.processed_settings;
  const scenarioGroupIndex = settings.scenario_groups.findIndex(
    (g) => g.id == data.id
  );

  if (scenarioGroupIndex === -1) {
    return;
  }

  Object.keys(data).forEach((key) => {
    const value = data[key];
    settings.scenario_groups[scenarioGroupIndex][key] = value;
  });
};

const SET_ALL_OUTDATED_STATISTICS = (state) => {
  const settings = state.processed_settings;
  settings.scenario_groups.forEach((group) => {
    group.has_outdated_statistics = true;
  });
};

const SET_ALL_FIGURE_LAYERS_OUTDATED = (state) => {
  const settings = state.processed_settings;
  settings.scenario_groups.forEach((group) => {
    group.has_outdated_layer_filtering = true;
  });
};

const CREATE_SCENARIOS = (state, scenarios) => {
  state.scenarios = [...state.scenarios, ...scenarios];
};

const DELETE_SCENARIO = (state, id) => {
  state.scenarios.splice(
    state.scenarios.findIndex((s) => s.id == id),
    1
  );
};

const UPDATE_SCENARIO = (state, scenario) => {
  let settingScenario = state.scenarios.find((s) => s.id == scenario.id);

  if (!settingScenario) return;

  Object.keys(scenario).forEach((key) => {
    settingScenario[key] = scenario[key];
  });
};

const UPDATE_SCENARIO_ORDERING = (state, scenarios) => {
  const settings = state.processed_settings;
  const currentGroup = settings.scenario_groups.find(
    (g) => g.id == settings.group_id
  );

  for (let i = 0; i < scenarios.length; i++) {
    const scenarioToUpdate = currentGroup.group_items.find(
      (s) => s.scenario_id == scenarios[i].id
    );

    if (scenarioToUpdate) {
      scenarioToUpdate.order = i;
    }
  }
};

export default {
  SET_PROCESSED_INFORMATION,
  SET_PROCESSED_INFORMATION_FIELD,
  SET_PROCESSED_SETTINGS_FIELD,
  SET_PROCESSED_GROUP_FIELD,
  UPDATE_SCENARIO_GROUP,
  SET_ALL_OUTDATED_STATISTICS,
  SET_ALL_FIGURE_LAYERS_OUTDATED,
  DELETE_SCENARIO,
  CREATE_SCENARIOS,
  UPDATE_SCENARIO,
  UPDATE_SCENARIO_ORDERING,
};
