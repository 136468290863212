import mitt from 'mitt';

const EventBus: any = mitt();

// Backward compatible aliases
EventBus.$on = EventBus.on;
EventBus.$off = EventBus.off;
EventBus.$emit = EventBus.emit;
EventBus.$once = (type: string, handler: (...args: Array<any>) => void) => {
  const fn = (...args: Array<any>) => {
    EventBus.$off(type, fn);
    handler(args);
  };

  EventBus.$on(type, fn);
};

EventBus.emitSync = async (name: string, args?: object) => {
  let succeed, fail;
  const p = new Promise((resolve, reject) => {
    succeed = resolve;
    fail = reject;
  });

  EventBus.$emit(name, {
    ...args,
    succeed,
    fail,
  });

  await p;
};

export default EventBus;
