import { Feature, Map, getUid } from 'ol';
import { Point } from 'ol/geom';
import { Layer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Code, throwError } from '../../common/error';
import { toGeoJSON as _toGeoJSON, fromGeoJSON } from '../../common/geojson';
import { getLayoutZoom } from '../../measurement/layout';
import type { LayerUsage } from '../constants';
import { createLayerProperties } from '../utils';
import createTextStyle from './createTextStyle';
import { TextLayer, TextLayerModel } from './types';

export default function createTextLayer(
  map: Map,
  model: TextLayerModel,
  layerUsage?: LayerUsage
): TextLayer {
  const textEl = document.createElement('div');
  textEl.style.position = 'absolute';
  textEl.style.whiteSpace = 'pre-wrap';

  const [feature] = fromGeoJSON(map, model.geojson) as Feature<Point>[];
  const options = {
    source: new VectorSource<Feature<Point>>({
      features: [feature],
    }),
    properties: createLayerProperties(
      model.id,
      model.geojson.properties.type,
      layerUsage
    ),
    render: function () {
      const style = createTextStyle(
        model.geojson.properties,
        getLayoutZoom(map)
      );
      textEl.innerHTML = model.geojson.properties.text;
      for (const property in style) {
        textEl.style[property] = style[property];
      }

      const coord = (feature!.getGeometry() as Point).getCoordinates();
      const pixel = map.getPixelFromCoordinate(coord);
      textEl.style.left = `${pixel[0]}px`;
      textEl.style.top = `${pixel[1]}px`;

      return textEl;
    },
  };
  const layer = new Layer(options) as TextLayer;
  feature.set('layerUid', getUid(layer));

  layer.getFirstFeature = function () {
    return this.getSource()!.getFeatures()[0];
  };

  layer.checkHasFeature = function (feature: Feature<Point>) {
    if (feature.getGeometry()?.getType() !== 'Point') {
      return false;
    }

    return this.getSource()!.hasFeature(feature);
  };

  layer.toGeoJSON = function () {
    const feature = this.getFirstFeature()!.clone() as Feature<Point>;
    feature.setProperties({
      ...this.getProperties(),
    });
    return _toGeoJSON(map, feature);
  };

  layer.getBounds = function () {
    throwError(Code.MethodNotImplemented, 'getBounds');
  };

  layer.refresh = function () { };

  return layer;
}
