import {
  getInputType,
  INPUT_TYPES,
  getClipBoundaries as _getClipBoundaries,
} from '../../../business-logic/analysis/heatmap';
import { getEnviroSamples as _getEnviroSamples } from '../../../business-logic/sample';

export const checkIsEnviroInput = (state) => (datasource) =>
  getInputType(datasource) === INPUT_TYPES.ENVIRO;

export const checkIsGatherInput = (state) => (datasource) =>
  getInputType(datasource) === INPUT_TYPES.GATHER;

export const getEnviroSamples =
  (state) => (samples, chemicalResult, depthUnit) => {
    return _getEnviroSamples(samples, chemicalResult, depthUnit);
  };

export const getSelectedInput = (state) => () => {
  const { inputs, selectedIndex } = state;
  if (selectedIndex === -1) {
    return null;
  }

  return inputs[selectedIndex];
};

export const getClipBoundaries = (state, getters, rootState, rootGetters) => {
  const allLayers = rootGetters['maps/allLayers'];
  return _getClipBoundaries(allLayers);
};
