import { GatherField, GatherFieldLegacyOptions } from '../gather';

type Tag = {
  type: string;
};

export type ExpressionValueObject = {
  tags: Tag[];
  resultType: ResultType;
};
type ExpressionValue = string | null | ExpressionValueObject;

export type ExpressionOptions = GatherFieldLegacyOptions & {
  expression: ExpressionValue;
};

export type CustomFunction = {
  id: number;
  name: string;
  displayName: string;
  description: string;
  paramGroups: ParamGroup[];
  getDefaultArgGroup(paramGroupIndex: number): ArgGroup;
};

export type ParamGroup = {
  types: ParamType[];
  maxCount: number;
  tooltip: string;
};

export type FieldArg = {
  type: ParamType.FIELD;
  fieldId: number;
  action: string;
};

export type LiteralTextArg = {
  type: ParamType.LITERAL_TEXT;
  value: string;
};

export type Arg = FieldArg | LiteralTextArg;

export type ArgGroup = Arg[];

export type FieldOperand = GatherField & {
  action: string;
};

export type FormatField = (fieldId: number, action: string) => string;

export type Result = string | number | boolean | null;

export const OPERATORS = [
  '+',
  '-',
  '*',
  '/',
  '%',
  'Not',
  'And',
  'Or',
  'In',
  '>',
  '>=',
  '<',
  '<=',
  '==',
  '!=',
  '(',
  ')',
];

/** @deprecated should be enum */
export const ACTION_CURRENT = 'CURRENT';
/** @deprecated should be enum */
export const ACTION_FIRST = 'FIRST';
/** @deprecated should be enum */
export const ACTION_LAST = 'LAST';
/** @deprecated should be enum */
export const ACTION_NEXT = 'NEXT';
/** @deprecated should be enum */
export const ACTION_PREVIOUS = 'PREVIOUS';
/** @deprecated should be enum */
export const ACTION_MIN = 'MIN';
/** @deprecated should be enum */
export const ACTION_MAX = 'MAX';
/** @deprecated should be enum */
export const ACTION_AVG = 'AVG';
/** @deprecated should be enum */
export const ACTION_SUM = 'SUM';
/** @deprecated should be enum */
export const ACTION_COUNT = 'COUNT';

export const ACTIONS = {
  [ACTION_CURRENT]: {
    text: 'Current Value',
  },
  [ACTION_FIRST]: {
    text: 'First Value',
  },
  [ACTION_LAST]: {
    text: 'Last Value',
  },
  [ACTION_NEXT]: {
    text: 'Next Value',
  },
  [ACTION_PREVIOUS]: {
    text: 'Previous Value',
  },
  [ACTION_MIN]: {
    text: 'MIN',
  },
  [ACTION_MAX]: {
    text: 'MAX',
  },
  [ACTION_AVG]: {
    text: 'Average',
  },
  [ACTION_SUM]: {
    text: 'Sum / Total',
  },
  [ACTION_COUNT]: {
    text: 'Count',
  },
};

/** @deprecated should be enum */
export const INPUT_TYPE_FIELD = 1;
/** @deprecated should be enum */
export const INPUT_TYPE_OPERATOR = 2;
/** @deprecated should be enum */
export const INPUT_TYPE_VALUE = 3;
/** @deprecated should be enum */
export const INPUT_TYPE_CUSTOM_FUNCTION = 4;

/** @deprecated should be enum */
export const VALUE_TYPE_TEXT = 1;
/** @deprecated should be enum */
export const VALUE_TYPE_NUMBER = 2;
/** @deprecated should be enum */
export const VALUE_TYPE_DATE = 3;
/** @deprecated should be enum */
export const VALUE_TYPE_YEAR_MONTH = 4;
/** @deprecated should be enum */
export const VALUE_TYPE_YEAR = 5;
/** @deprecated should be enum */
export const VALUE_TYPE_DATE_TIME = 6;
/** @deprecated should be enum */
export const VALUE_TYPE_TIME = 7;
/** @deprecated should be enum */
export const VALUE_TYPE_YES_NO = 8;
/** @deprecated should be enum */
export const VALUE_TYPE_EMPTY_TEXT = 9;

export const VALUE_TYPE_OPTIONS = {
  [VALUE_TYPE_TEXT]: {
    text: 'Text',
  },
  [VALUE_TYPE_EMPTY_TEXT]: {
    text: 'Empty Text',
  },
  [VALUE_TYPE_NUMBER]: {
    text: 'Number',
  },
  [VALUE_TYPE_DATE]: {
    text: 'Date',
  },
  [VALUE_TYPE_YEAR_MONTH]: {
    text: 'Year Month',
  },
  [VALUE_TYPE_YEAR]: {
    text: 'Year',
  },
  [VALUE_TYPE_DATE_TIME]: {
    text: 'Date & Time',
  },
  [VALUE_TYPE_TIME]: {
    text: 'Time',
  },
  [VALUE_TYPE_YES_NO]: {
    text: 'Yes / No',
  },
};

/** @deprecated should be enum */
export const TAG_TYPE_FIELD = 'variable';
/** @deprecated should be enum */
export const TAG_TYPE_OPERATOR = 'operator';
/** @deprecated should be enum */
export const TAG_TYPE_TEXT = 'text';
/** @deprecated should be enum */
export const TAG_TYPE_NUMBER = 'number';
/** @deprecated should be enum */
export const TAG_TYPE_DATE = 'date';
/** @deprecated should be enum */
export const TAG_TYPE_YEAR_MONTH = 'year_month';
/** @deprecated should be enum */
export const TAG_TYPE_YEAR = 'year';
/** @deprecated should be enum */
export const TAG_TYPE_DATE_TIME = 'date_time';
/** @deprecated should be enum */
export const TAG_TYPE_TIME = 'time';
/** @deprecated should be enum */
export const TAG_TYPE_YES_NO = 'yes_no';
/** @deprecated should be enum */
export const TAG_TYPE_CUSTOM_FUNCTION = 'custom_function';

export function valueType2TagType(valueType) {
  switch (valueType) {
    case VALUE_TYPE_TEXT:
    case VALUE_TYPE_EMPTY_TEXT:
      return TAG_TYPE_TEXT;
    case VALUE_TYPE_NUMBER:
      return TAG_TYPE_NUMBER;
    case VALUE_TYPE_DATE:
      return TAG_TYPE_DATE;
    case VALUE_TYPE_YEAR_MONTH:
      return TAG_TYPE_YEAR_MONTH;
    case VALUE_TYPE_YEAR:
      return TAG_TYPE_YEAR;
    case VALUE_TYPE_DATE_TIME:
      return TAG_TYPE_DATE_TIME;
    case VALUE_TYPE_TIME:
      return TAG_TYPE_TIME;
    case VALUE_TYPE_YES_NO:
      return TAG_TYPE_YES_NO;
    default:
      throw `Unknown mapping for ${valueType}`;
  }
}

/** @deprecated use enum */
export const RESULT_TYPE_NUMBER = 1;
/** @deprecated use enum */
export const RESULT_TYPE_BOOLEAN = 2;
/** @deprecated use enum */
export const RESULT_TYPE_TEXT = 3;

export const FIELD_PATTERN = /^\[([^\]]+)\]$/;

export enum ParamType {
  FIELD = 1,
  LITERAL_TEXT = 2,
}

export const CUSTOM_FUNCTIONS: CustomFunction[] = [
  {
    id: 1,
    name: 'concat',
    displayName: 'concat',
    description: 'Concatenates a number of strings',
    paramGroups: [
      {
        types: [ParamType.FIELD, ParamType.LITERAL_TEXT],
        maxCount: 64,
        tooltip:
          'A number of fields or text values for which you want to do a concatenation. 64 items at maximum are allowed.',
      },
      {
        types: [ParamType.LITERAL_TEXT],
        maxCount: 1,
        tooltip: 'A text value used as the string separator.',
      },
    ],
    getDefaultArgGroup(paramGroupIndex: number): ArgGroup {
      if (paramGroupIndex === 1) {
        return [{ type: ParamType.LITERAL_TEXT, value: ',' }];
      }

      return [];
    },
  },
  {
    id: 2,
    name: 'cleanse',
    displayName: 'cleanse',
    description:
      'Delete special characters, e.g. spaces, measurement unit(m) etc, from a string.',
    paramGroups: [
      {
        types: [ParamType.FIELD],
        maxCount: 1,
        tooltip: 'A field for which you want to do a clean.',
      },
      {
        types: [ParamType.LITERAL_TEXT],
        maxCount: 64,
        tooltip:
          'A number of special characters used to do a clean. 64 items at maximum are allowed.',
      },
    ],
    getDefaultArgGroup(paramGroupIndex: number): ArgGroup {
      if (paramGroupIndex === 1) {
        return [{ type: ParamType.LITERAL_TEXT, value: ' ' }];
      }

      return [];
    },
  },
];

export enum ResultType {
  NUMBER = 1,
  BOOLEAN = 2,
  TEXT = 3,
}
