import * as cl_utils from '@component-library/utils';
import { WMSCapabilities } from 'ol/format';
import { Image as ImageLayer } from 'ol/layer';
import { transformExtent } from 'ol/proj';
import { ImageWMS } from 'ol/source';
import { defaultImageLoadFunction } from 'ol/source/Image';
import { AXIS_ORIENTATION_NEU } from '../../../business-logic/projection';
import { EPSG_3857 } from '../../../lib/olbm/projection/common';
import { assignIdToLayer } from '../layers';
import * as utils from '../utils';
import { mergeExtents } from '../utils';
import * as network from '../utils/network';
import enableLoadingEvents from './enableLoadingEvents';

function correctBBOX(src) {
  const url = new URL(src);
  const [minx, miny, maxx, maxy] = url.searchParams.get('BBOX').split(',');
  const bbox = [miny, minx, maxy, maxx];
  const bboxParam = encodeURIComponent(bbox.join(','));
  return cl_utils.replaceQueryParam(src, 'BBOX', bboxParam);
}

function correctSize(src, scale) {
  const url = new URL(src);
  const width = parseInt(url.searchParams.get('WIDTH'));
  const height = parseInt(url.searchParams.get('HEIGHT'));
  let newSrc = cl_utils.replaceQueryParam(
    src,
    'WIDTH',
    String(Math.round(width * scale))
  );
  newSrc = cl_utils.replaceQueryParam(
    newSrc,
    'HEIGHT',
    String(Math.round(height * scale))
  );
  return newSrc;
}

export async function getServiceData(serviceUrl, shouldUseCorsProxy) {
  const url = !shouldUseCorsProxy
    ? serviceUrl
    : utils.network.proxify(serviceUrl);
  const { data } = await axios.get(url, network.createRequestOptions());
  const parser = new WMSCapabilities();
  const wmsCapabilities = parser.read(data);

  const {
    version,
    Service: { Title: title, Abstract: description },
    Capability: {
      Request: {
        GetMap: {
          DCPType: [
            {
              HTTP: {
                Get: { OnlineResource: onlineResource },
              },
            },
          ],
        },
      },
      Layer: wmsLayerGroup,
    },
  } = wmsCapabilities;
  const { Layer: wmsLayers } = wmsLayerGroup;
  const layers = [];
  const subLayerIds = wmsLayers.map((wmsLayer) => wmsLayer.Name);
  layers.push({
    id: 1,
    name: wmsLayerGroup.Title,
    description: wmsLayerGroup.Abstract,
    extent: wmsLayerGroup.EX_GeographicBoundingBox,
    parentLayerId: -1,
    subLayerIds,
  });

  wmsLayers.forEach((wmsLayer) => {
    const { Name, Title, EX_GeographicBoundingBox, Style } = wmsLayer;

    const legendGraphics = [];
    if (Array.isArray(Style)) {
      Style.forEach((item) => {
        const { Title: title } = item;
        const [{ OnlineResource: url, size }] = item.LegendURL; // LegendURL is an array with only one item.
        legendGraphics.push({
          title,
          url: utils.network.forceHttps(url),
          size,
        });
      });
    }

    layers.push({
      url: utils.network.forceHttps(onlineResource),
      id: Name,
      name: Title,
      extent: EX_GeographicBoundingBox,
      parentLayerId: 1,
      subLayerIds: null,
      projection: wmsLayer.CRS?.[0] ?? EPSG_3857,
      legendGraphics,
    });
  });

  const extents = layers.map((item) => item.extent);
  const fullExtent = mergeExtents(...extents);

  return {
    // TODO Research whether we can get attributions from the capabilities data.
    attributions: '',
    version,
    title,
    description,
    layers,
    fullExtent,
  };
}

export function createLayer(map, options) {
  const {
    attributions,
    version,
    id,
    projection = EPSG_3857,
    shouldUseCorsProxy,
    axisOrientation,
  } = options;

  const visibleExtent = transformExtent(
    options.visibleExtent,
    'EPSG:4326',
    map.getView().getProjection()
  );

  const url = new URL(options.url);
  const layerSource = new ImageWMS({
    attributions,
    crossOrigin: 'anonymous',
    url: url.toString(),
    params: { VERSION: version, LAYERS: id },
    ratio: 1,
    projection,
    imageLoadFunction: (image, src) => {
      let newSrc = src;
      if (shouldUseCorsProxy) {
        newSrc = utils.network.proxify(newSrc);
      }
      if (axisOrientation === AXIS_ORIENTATION_NEU.value) {
        newSrc = correctBBOX(newSrc);
      }
      newSrc = correctSize(newSrc, 1 / map.getViewer().figureLayout.zoom);
      defaultImageLoadFunction(image, newSrc);
    },
  });

  const layer = new ImageLayer({
    source: layerSource,
    extent: visibleExtent,
  });
  assignIdToLayer(layer);
  layer.applyOptions = function (options) {
    this.options = options;
  };
  layer.hasFeature = function (feature) {
    return false;
  };

  enableLoadingEvents(layer);

  return layer;
}
