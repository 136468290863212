<script lang="ts" setup>
import { computed, ref } from 'vue';
import { DialogOptions } from '../store/dialog';
import Modal from './Modal.vue';
import InputTextFloating from './InputTextFloating.vue';
import { useToastStore } from '../store/toasts';

const props = defineProps<{
  title: string;
  message?: string;
  danger: boolean;
  options: DialogOptions;
  validationString?: string;
}>();
const toastStore = useToastStore();
const emit = defineEmits(['confirm', 'cancel']);

const acceptText = ref('');

const canAccept = computed(() => {
  return (
    acceptText.value &&
    acceptText.value?.toLowerCase() === props.validationString?.toLowerCase()
  );
});

const handleConfirm = () => {
  if (canAccept.value || !props.validationString) {
    emit('confirm');
  } else {
    toastStore.error('Invalid confirmation string');
  }
};

const canSubmit = computed(() => {
  if (props.validationString && !canAccept.value) {
    return false;
  }

  return true;
});
</script>

<template>
  <Modal class="dialog-modal" @close="$emit('cancel')">
    <template v-if="title" #header>
      {{ title }}
    </template>

    <template v-if="message || validationString" #default>
      <div v-if="message">
        {{ message }}
      </div>

      <InputTextFloating
        v-if="validationString"
        v-model="acceptText"
        data-cy="validation-input"
        :class="{
          'mt-3': message,
        }"
        name="acceptance"
        :label="`Type '${validationString}' to confirm`"
      />
    </template>
    <template #footer>
      <button
        class="btn w-100 mb-2"
        :class="danger ? 'btn-danger' : 'btn-primary'"
        data-cy="confirm-dialog-button"
        :disabled="!canSubmit"
        @click="handleConfirm"
      >
        {{ options?.confirmButtonText || 'Confirm' }}
      </button>
      <button class="btn btn-flat w-100" @click="$emit('cancel')">
        {{ options?.cancelButtonText || 'Cancel' }}
      </button>
    </template>
  </Modal>
</template>
