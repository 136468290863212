<script lang="ts" setup>
import { ErrorBag } from '../business-model/form';
import makeId from '../local-id.mjs';
import { onBeforeMount, ref, computed, onMounted } from 'vue';

const props = defineProps<{
  label?: string;
  placeholder?: string;
  modelValue?: string | null;
  required?: boolean;
  invalid?: boolean;
  autoResize?: boolean;
  /** @deprecated use browser convention: `disabled` */
  isDisabled?: boolean;
  disabled?: boolean;
  name?: string;
  errorBag?: ErrorBag;
  inputClass?: string;
}>();

const emit = defineEmits(['update:modelValue', 'enter']);

const id = ref(props.name + '-' + makeId());
const textareaElement = ref<HTMLTextAreaElement>();

onBeforeMount(updateId);
onMounted(autoResize);

const inputError = computed(() => {
  return props.name && props.errorBag && props.name in props.errorBag
    ? props.errorBag[props.name]
    : null;
});

const inputValue = computed({
  get: () => {
    return props.modelValue;
  },
  set: (value) => {
    autoResize();
    emit('update:modelValue', value);
  },
});

function updateId() {
  id.value = props.name + '-' + makeId();
}

function autoResize() {
  if (!props.autoResize) return;

  if (!textareaElement.value) {
    throw new Error('Failed to resize textarea');
  }

  textareaElement.value.style.height = 'auto'; // Reset the height
  textareaElement.value.style.height =
    Math.min(textareaElement.value.scrollHeight + 10, 1500) + 'px';
}
</script>

<template>
  <div>
    <label v-if="label" class="form-label" :for="id">
      {{ label }} <sup v-if="required" class="text-danger">*</sup>
    </label>
    <textarea
      :id="id"
      ref="textareaElement"
      :value="inputValue"
      :class="[
        'form-control',
        { 'is-invalid': invalid || inputError },
        inputClass,
      ]"
      :required="required"
      :disabled="disabled || isDisabled"
      :placeholder="placeholder"
      style="max-height: 40vh"
      @input="($event) => (inputValue = $event.target.value)"
      @keypress.ctrl.enter="() => emit('enter')"
    ></textarea>
    <div v-if="inputError" class="invalid-feedback">
      {{ inputError }}
    </div>
  </div>
</template>
