import { Parser } from 'expr-eval';
import _flattenDeep from 'lodash/flattenDeep';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import {
  isNoBooleanValue,
  isYesBooleanValue,
} from '../business-logic/input-value';
import {
  type Arg,
  type ArgGroup,
  type CustomFunction,
  type FieldOperand,
  type Result,
  type FormatField,
  ACTION_CURRENT,
  TAG_TYPE_FIELD,
  FIELD_PATTERN,
  ACTIONS,
  TAG_TYPE_NUMBER,
  TAG_TYPE_OPERATOR,
  OPERATORS,
  ACTION_FIRST,
  ACTION_LAST,
  ACTION_NEXT,
  ACTION_PREVIOUS,
  ACTION_MIN,
  ACTION_MAX,
  ACTION_AVG,
  ACTION_COUNT,
  ACTION_SUM,
  TAG_TYPE_TEXT,
  TAG_TYPE_DATE,
  TAG_TYPE_YEAR_MONTH,
  TAG_TYPE_YEAR,
  TAG_TYPE_DATE_TIME,
  TAG_TYPE_TIME,
  TAG_TYPE_YES_NO,
  TAG_TYPE_CUSTOM_FUNCTION,
  ParamType,
  CUSTOM_FUNCTIONS,
  ResultType,
} from '../business-model/expression';
import { FieldTypeIds } from '../fields';
import {
  DropdownOptionType,
  GatherField,
  InputValueValue,
  ValuePair,
} from '../gather';
import slugify from '../utils/slugify.js';

const ELIGIBLE_FIELD_TYPES = [
  FieldTypeIds.TEXT,
  FieldTypeIds.NUMBER,
  FieldTypeIds.DATE,
  FieldTypeIds.DROPDOWN,
  FieldTypeIds.LITHOLOGY,
  FieldTypeIds.CHECKBOX,
  FieldTypeIds.EXPRESSION,
  FieldTypeIds.DUPLICATE,
  FieldTypeIds.TRIPLICATE,
  FieldTypeIds.DEPTH,
  FieldTypeIds.LAB_ID,
];

function createRegExp(pattern: string, flags: string = 'gi'): RegExp {
  return new RegExp(pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), flags);
}

function createParser() {
  const parser = new Parser({
    operators: { logical: true, in: true },
  });

  parser.functions.date2Number = function (value) {
    return moment(value, 'YYYY-MM-DD').valueOf();
  };

  parser.functions.yearMonth2Number = function (value) {
    const year = parseInt(value.substring(0, 4), 10);
    const month = parseInt(value.substring(5, 7), 10);
    return year * 12 + month;
  };

  parser.functions.toYearMonth = function (value) {
    return `${value.substring(0, 4)}-${value.substring(5, 7)}`;
  };

  parser.functions.year2Number = function (value) {
    return parseInt(value.substring(0, 4), 10);
  };

  parser.functions.toYear = function (value) {
    return value.substring(0, 4);
  };

  parser.functions.dateTime2Number = function (value) {
    return moment(value, 'YYYY-MM-DD HH:mm').valueOf();
  };

  parser.functions.time2Number = function (value) {
    const hours = parseInt(value.substring(0, 2), 10);
    const minutes = parseInt(value.substring(3, 5), 10);
    return hours * 60 + minutes;
  };

  parser.functions.isEqual = function (value1, value2) {
    return _isEqual(value1, value2);
  };

  parser.functions.concat = function (
    argGroup1: string[],
    argGroup2: string[]
  ) {
    const [separator] = argGroup2;
    return _flattenDeep(argGroup1)
      .filter((arg) => String(arg).trim() !== '')
      .join(separator || '');
  };

  parser.functions.cleanse = function (
    argGroup1: string[],
    argGroup2: string[]
  ) {
    const [text] = argGroup1;
    return text
      ? argGroup2.reduce((accu, specialChars) => {
        const regexp = createRegExp(specialChars);
        return accu.replace(regexp, '');
      }, text)
      : text;
  };

  return parser;
}

export function getFields(sections) {
  return sections.reduce((accu, item) => {
    return [...accu, ...item.template_fields];
  }, []);
}

// Stop the circular references between two expressions.
function checkIsFieldUsed(fields, expressionField, field) {
  let { expression } = expressionField.options;
  if (!expression) {
    return false;
  }

  const { tags } =
    typeof expression === 'string'
      ? parseLegacyExpression(expression, fields)
      : expression;
  return !!tags.find((item) => item.field_id === field.id);
}

export function getEligibleFields(fields, expressionField) {
  return fields
    .filter(
      (item) =>
        ELIGIBLE_FIELD_TYPES.includes(item.field_type_id) &&
        item.id !== expressionField.id &&
        (item.field_type_id !== FieldTypeIds.EXPRESSION ||
          !checkIsFieldUsed(fields, item, expressionField))
    )
    .map((item) => {
      return {
        ...item,
        slug: item.system_reference || slugify(item.label),
        testValue: getDefaultValuePair(item),
        action: ACTION_CURRENT,
      };
    });
}

// The expression is changed from a string to a JSON object.
export function parseLegacyExpression(expression, fields) {
  let tags: any[] = [];

  if (!expression) {
    return { tags, resultType: ResultType.NUMBER };
  }

  const items = expression.replace(/[\s]+/, ' ').trim().split(' ');
  items.forEach((item) => {
    let type: any = null;

    if (FIELD_PATTERN.test(item)) {
      type = TAG_TYPE_FIELD;
      const action = Object.keys(ACTIONS).find((a) => item.includes(a + '|'));
      if (action) {
        item = item.replace(action + '|', '');
      }
      const field = fields.find((f) => {
        const slug = f.system_reference || slugify(f.label);
        return (
          (item === `[${f.id}]` || item === `[${slug}]`) &&
          ELIGIBLE_FIELD_TYPES.includes(f.field_type_id)
        );
      });

      tags.push({
        type,
        field_id: field?.id,
        action: action ?? ACTION_CURRENT,
      });
      return;
    }

    if (OPERATORS.some((item2) => item2.toLowerCase() === item.toLowerCase())) {
      type = TAG_TYPE_OPERATOR;
    }

    if (!isNaN(item)) {
      type = TAG_TYPE_NUMBER;
    }

    if (type) {
      tags.push({
        type,
        value: item,
      });
    }
  });

  return { tags, resultType: ResultType.NUMBER };
}

function transformDropdownValue(field, value) {
  if (value === null) {
    return null;
  }

  const {
    options: { has_multiple, option_type },
  } = field;
  if (!has_multiple) {
    return option_type === DropdownOptionType.Number
      ? parseFloat(value)
      : JSON.stringify(value);
  } else {
    if (option_type === DropdownOptionType.Number) {
      const options = (
        typeof value === 'string' ? JSON.parse(value) : value
      ).map((item) => parseFloat(item));
      return JSON.stringify(options);
    } else {
      return typeof value === 'string' ? value : JSON.stringify(value);
    }
  }
}

function transformDepthValue(field, value, value2) {
  if (value === null) {
    return null;
  }

  const {
    options: { is_range, unit },
  } = field;

  value = unit ? `${value}${unit}` : value;
  value2 = value2 !== null ? (unit ? `${value2}${unit}` : value2) : value2;

  return JSON.stringify(
    is_range && value2 != null ? `${value} - ${value2}` : value
  );
}

function transformDateValue(
  field,
  value: InputValueValue,
  value2: InputValueValue
): string | null {
  const {
    options: { type = 'datetime', format = 'YYYY-MM-DD' },
  } = field;

  if (type === 'date') {
    if (['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'].includes(format)) {
      return value !== null ? `date2Number("${value}")` : null;
    } else if (format === 'month') {
      return value !== null ? `yearMonth2Number("${value}")` : null;
    } else if (format === 'year') {
      return value !== null ? `year2Number("${value}")` : null;
    }
  } else if (type === 'datetime') {
    return value !== null && value2 !== null
      ? `dateTime2Number("${value} ${value2}")`
      : null;
  } else if (type === 'time') {
    return value2 !== null ? `time2Number("${value2}")` : null;
  }

  return null;
}

function convertValueToNumber(value: InputValueValue): number | null {
  if (value === null) {
    return null;
  }

  if (isYesBooleanValue(value)) return 1;
  if (isNoBooleanValue(value)) return 0;

  const number = parseFloat(String(value));
  return !Number.isNaN(number) ? number : null;
}

function convertValuesToNumbers(values: InputValueValue[]): number[] {
  return values
    .map((v) => convertValueToNumber(v))
    .filter((v) => v !== null) as number[];
}

// Each item in the returned array is the value used in the expression.
export function resolveFieldFromValuePairs(
  field,
  action,
  valuePairs: ValuePair[],
  sectionIndex = 0
): Result {
  let value: InputValueValue = null;
  let value2: InputValueValue = null;

  // The action is default to CURRENT.
  if (!action || action === ACTION_CURRENT) {
    const valuePair = valuePairs[sectionIndex];
    value = valuePair.value;
    value2 = valuePair.value2;
  } else if (action === ACTION_FIRST) {
    const valuePair = valuePairs[0];
    value = valuePair.value;
    value2 = valuePair.value2;
  } else if (action === ACTION_LAST) {
    const valuePair = valuePairs[valuePairs.length - 1];
    value = valuePair.value;
    value2 = valuePair.value2;
  } else if (action === ACTION_NEXT) {
    const valuePair =
      sectionIndex + 1 >= valuePairs.length
        ? { value: null, value2: null }
        : valuePairs[sectionIndex + 1];
    value = valuePair.value;
    value2 = valuePair.value2;
  } else if (action === ACTION_PREVIOUS) {
    const valuePair =
      sectionIndex - 1 < 0
        ? { value: null, value2: null }
        : valuePairs[sectionIndex - 1];
    value = valuePair.value;
    value2 = valuePair.value2;
  } else if (action === ACTION_MIN) {
    const numbers = convertValuesToNumbers(valuePairs.map((vp) => vp.value));
    value = numbers.length ? String(Math.min(...numbers)) : null;
  } else if (action === ACTION_MAX) {
    const numbers = convertValuesToNumbers(valuePairs.map((vp) => vp.value));
    value = numbers.length ? String(Math.max(...numbers)) : null;
  } else if (action === ACTION_AVG) {
    const numbers = convertValuesToNumbers(valuePairs.map((vp) => vp.value));
    value = numbers.length
      ? String(numbers.reduce((accu, n) => (accu += n), 0) / numbers.length)
      : null;
  } else if (action == ACTION_COUNT) {
    value = String(valuePairs.length);
  } else if (action === ACTION_SUM) {
    const numbers = convertValuesToNumbers(valuePairs.map((vp) => vp.value));
    value = numbers.length
      ? String(numbers.reduce((accu, n) => (accu += n), 0))
      : null;
  }

  if (action === ACTION_COUNT) {
    return value as number;
  }

  const { field_type_id } = field;
  switch (field_type_id) {
    case FieldTypeIds.TEXT:
    case FieldTypeIds.DUPLICATE:
    case FieldTypeIds.TRIPLICATE:
    case FieldTypeIds.LAB_ID:
      return value !== null ? JSON.stringify(value) : null;
    case FieldTypeIds.NUMBER:
      return value !== null && value !== ''
        ? convertValueToNumber(value)
        : null;
    case FieldTypeIds.DATE:
      return transformDateValue(field, value, value2);
    case FieldTypeIds.DROPDOWN:
    case FieldTypeIds.LITHOLOGY:
      return transformDropdownValue(field, value);
    case FieldTypeIds.CHECKBOX:
      return value != null ? JSON.stringify(value) : null;
    case FieldTypeIds.EXPRESSION:
      if (value === null) {
        return null;
      }
      const resultType =
        field.options.expression?.resultType ?? ResultType.NUMBER;
      switch (resultType) {
        case ResultType.NUMBER:
          return value as number;
        case ResultType.BOOLEAN:
          return value as boolean;
        case ResultType.TEXT:
          return JSON.stringify(value);
        default:
          throw `Unknown result type: ${resultType}.`;
      }
    case FieldTypeIds.DEPTH:
      return transformDepthValue(field, value, value2);
    default:
      throw `The ${field_type_id} is not supported.`;
  }
}

export function padValue(value, maxLength = 2) {
  return String(value).padStart(maxLength, '0');
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = padValue(date.getMonth() + 1);
  const day = padValue(date.getDate());

  return [year, month, day].join('-');
}

function formatTime(date) {
  const hours = padValue(date.getHours());
  const minutes = padValue(date.getMinutes());
  return [hours, minutes].join(':');
}

export const DROPDOWN_TESTING_OPTIONS = ['Please configure options'];

export function getDefaultValuePair(field, isForTesting = true) {
  const { field_type_id, options } = field;
  switch (field_type_id) {
    case FieldTypeIds.TEXT:
    case FieldTypeIds.DUPLICATE:
    case FieldTypeIds.TRIPLICATE:
      return {
        value: isForTesting ? 'Test' : null,
        value2: null,
      };
    case FieldTypeIds.LAB_ID:
      return {
        value: isForTesting ? 'HA1@0.3-0.4' : null,
        value2: null,
      };
    case FieldTypeIds.NUMBER:
      return {
        value: isForTesting ? '10' : null,
        value2: null,
      };
    case FieldTypeIds.DATE:
      const now = new Date();
      return {
        value: isForTesting ? formatDate(now) : null,
        value2: isForTesting ? formatTime(now) : null,
      };
    case FieldTypeIds.DROPDOWN:
    case FieldTypeIds.LITHOLOGY:
      const { has_multiple } = options;
      const [firstOption] = options.options ?? DROPDOWN_TESTING_OPTIONS;
      if (!has_multiple) {
        return {
          value: isForTesting ? firstOption : null,
          value2: null,
        };
      } else {
        return {
          value: isForTesting ? JSON.stringify([firstOption]) : null,
          value2: null,
        };
      }
    case FieldTypeIds.CHECKBOX:
      return { value: isForTesting ? 'no' : null, value2: null };
    case FieldTypeIds.EXPRESSION:
      const resultType = options.expression?.resultType ?? ResultType.NUMBER;
      switch (resultType) {
        case ResultType.NUMBER:
          return { value: isForTesting ? '10' : null, value2: null };
        case ResultType.BOOLEAN:
          return {
            value: isForTesting ? JSON.stringify(false) : null,
            value2: null,
          };
        case ResultType.TEXT:
          return {
            value: isForTesting ? 'Test' : null,
            value2: null,
          };
        default:
          throw `Unknown result type: ${resultType}.`;
      }
    case FieldTypeIds.DEPTH:
      return {
        value: isForTesting ? '1' : null,
        value2: null,
      };
    default:
      throw `The ${field_type_id} is not supported.`;
  }
}

// Each item in the returned array is the value used in the expression.
export function resolveFieldFromInputValues(
  field: FieldOperand,
  action,
  inputValues,
  sectionCount,
  sectionIndex
): Result {
  const valuePairs: ValuePair[] = [];
  for (let i = 0; i < sectionCount; i++) {
    const inputValue = inputValues.find(
      (iv) =>
        iv.template_field_id === field.id && iv.template_section_index === i
    );
    const valuePair = inputValue
      ? { value: inputValue.value, value2: inputValue.value2 }
      : getDefaultValuePair(field, false);
    valuePairs.push(valuePair);
  }
  if (!valuePairs.length || sectionIndex > valuePairs.length - 1) {
    // If current section is repeating (sectionIndex),
    // but field's section (valuePairs.length) is 1, not repeating
    // Use the field's first section
    if (valuePairs.length === 1) {
      return resolveFieldFromValuePairs(field, action, valuePairs);
    }
    const defaultValuePair = getDefaultValuePair(field, false);
    return resolveFieldFromValuePairs(field, action, [defaultValuePair]);
  } else {
    return resolveFieldFromValuePairs(field, action, valuePairs, sectionIndex);
  }
}

export function getValuesFromTagsWithFunction(tags, formatField: FormatField) {
  return tags.reduce((accu, t) => {
    const { type } = t;

    if (type === TAG_TYPE_FIELD) {
      const { field_id: fieldId, action } = t;
      accu = [...accu, formatField(fieldId, action)];
    } else if (type === TAG_TYPE_OPERATOR) {
      const { value } = t;
      accu = [...accu, ` ${value.toLowerCase()} `];
    } else if (type === TAG_TYPE_TEXT) {
      const { value } = t;
      accu = [...accu, JSON.stringify(value)];
    } else if (type === TAG_TYPE_NUMBER) {
      const { value } = t;
      accu = [...accu, value];
    } else if (type === TAG_TYPE_DATE) {
      const { value } = t;
      accu = [...accu, `date2Number("${value}")`];
    } else if (type === TAG_TYPE_YEAR_MONTH) {
      const { value } = t;
      accu = [...accu, `yearMonth2Number("${value}")`];
    } else if (type === TAG_TYPE_YEAR) {
      const { value } = t;
      accu = [...accu, `year2Number("${value}")`];
    } else if (type === TAG_TYPE_DATE_TIME) {
      const { value } = t;
      accu = [...accu, `dateTime2Number("${value}")`];
    } else if (type === TAG_TYPE_TIME) {
      const { value } = t;
      accu = [...accu, `time2Number("${value}")`];
    } else if (type === TAG_TYPE_YES_NO) {
      const { value } = t;
      accu = [...accu, JSON.stringify(value)];
    } else if (type === TAG_TYPE_CUSTOM_FUNCTION) {
      const { value } = t;
      const { id, argGroups } = value;
      const cfValues = parseCustomFunction(id, argGroups, formatField);
      accu = [...accu, ...cfValues];
    }
    return accu;
  }, []);
}

function parseCustomFunction(
  id: number,
  argGroups: ArgGroup[],
  formatField: FormatField
): string[] {
  const s = ', ';
  const customFunction = CUSTOM_FUNCTIONS.find((cf) => cf.id === id);
  if (!customFunction) {
    throw `The custom function with id ${id} is unknown.`;
  }
  const isConcat = customFunction.id === 1;

  const parts: string[] = [customFunction.name, '('];
  (argGroups as ArgGroup[]).forEach((ag, agIndex, agArray) => {
    let agParts: string[] = [];
    agParts.push('[');
    ag.forEach((arg, argIndex, argArray) => {
      if (arg.type === ParamType.FIELD) {
        const { fieldId, action } = arg as any; // types are messed up
        let agPart = formatField(fieldId, action);

        // Need to change conversions for date fields used in the concat func
        if (isConcat) {
          const pattern =
            /(date2Number|yearMonth2Number|year2Number|dateTime2Number|time2Number)\(([^)]+)\)/;
          const found = typeof agPart === 'string' && agPart.match(pattern);
          if (found) {
            const name = found[1];
            if (name === 'yearMonth2Number') {
              agPart = `toYearMonth(${found[2]})`;
            } else if (name === 'year2Number') {
              agPart = `toYear(${found[2]})`;
            } else {
              agPart = found[2];
            }
          }
        }

        agParts.push(agPart);
      } else if (arg.type === ParamType.LITERAL_TEXT) {
        agParts.push(JSON.stringify(decodeURIComponent(arg.value ?? '')));
      }

      if (argIndex < argArray.length - 1) {
        agParts.push(s);
      }
    });
    agParts.push(']');

    if (isConcat && agIndex === 0) {
      agParts = agParts.map((agPart) =>
        agPart === null ? JSON.stringify('') : agPart
      );
    }

    parts.push(...agParts);
    if (agIndex < agArray.length - 1) {
      parts.push(s);
    }
  });
  parts.push(')');

  return parts;
}

export function getFieldDisplayText(
  fieldId: number,
  action: string,
  fields: FieldOperand[]
): string {
  const field = fields.find((f) => f.id === fieldId);
  const slug = field
    ? field.system_reference || slugify(field.label)
    : String(fieldId);
  return action && action !== ACTION_CURRENT
    ? `[${action}|${slug}]`
    : `[${slug}]`;
}

export function getTagDisplayText(tag, fields: FieldOperand[]) {
  const { type } = tag;
  if (type === TAG_TYPE_FIELD) {
    const { field_id: fieldId, action } = tag;
    return getFieldDisplayText(fieldId, action, fields);
  } else if (tag.type === TAG_TYPE_OPERATOR) {
    const { value } = tag;
    return ` ${value} `;
  } else if (tag.type === TAG_TYPE_TEXT) {
    const { value } = tag;
    return JSON.stringify(value);
  } else if (tag.type === TAG_TYPE_CUSTOM_FUNCTION) {
    const {
      value: { id, argGroups },
    } = tag;
    const parts = parseCustomFunction(id, argGroups, (fieldId, action) => {
      return getFieldDisplayText(fieldId, action, fields);
    });
    return parts.join('');
  } else {
    const { value } = tag;
    return value;
  }
}

export function getArgDisplayText(arg: Arg, fields: FieldOperand[]): string {
  if (arg.type === ParamType.FIELD) {
    const { fieldId, action } = arg;
    return getFieldDisplayText(fieldId, action, fields);
  } else if (arg.type === ParamType.LITERAL_TEXT) {
    return JSON.stringify(decodeURIComponent(arg.value ?? ''));
  }

  return 'Unknown argument';
}

export function convertTagsToString(tags, fields: FieldOperand[]) {
  return tags.map((t) => getTagDisplayText(t, fields)).join('');
}

export function evaluateValues(values) {
  const expr = values.join('');
  const parser = createParser();
  const parsedExpr = parser.parse(expr);
  return parsedExpr.evaluate();
}

export function getResultType(field: GatherField): number | undefined {
  const { expression } = field.options ?? {};

  if (!expression) {
    return;
  }

  // The legacy expression is a string and only supports numerical results.
  return typeof expression === 'string'
    ? ResultType.NUMBER
    : expression.resultType;
}

export function getResultDisplayText(field, result: Result): string {
  if (typeof result === 'string') {
    const resultType = getResultType(field);
    if (resultType === ResultType.BOOLEAN) {
      result = result === '1';
    } else if (resultType === ResultType.NUMBER) {
      result = parseFloat(result);
    } else {
      return result;
    }
  }

  if (typeof result === 'boolean') {
    return result ? 'Yes' : 'No';
  }

  if (typeof result === 'number' && !Number.isNaN(result)) {
    const digits = Math.max(0, Math.min(9, field.options.maxDecimals ?? 0));
    return result.toFixed(digits);
  }

  return '';
}

export function getActions(field) {
  const {
    field_type_id,
    options: { has_multiple, option_type, expression },
  } = field;
  const resultType = expression?.resultType ?? ResultType.NUMBER;
  switch (field_type_id) {
    case FieldTypeIds.TEXT:
      return [
        ACTION_CURRENT,
        ACTION_FIRST,
        ACTION_LAST,
        ACTION_NEXT,
        ACTION_PREVIOUS,
        ACTION_COUNT,
      ];
    case FieldTypeIds.NUMBER:
      return [
        ACTION_CURRENT,
        ACTION_FIRST,
        ACTION_LAST,
        ACTION_NEXT,
        ACTION_PREVIOUS,
        ACTION_MIN,
        ACTION_MAX,
        ACTION_AVG,
        ACTION_SUM,
        ACTION_COUNT,
      ];
    case FieldTypeIds.DATE:
      return [
        ACTION_CURRENT,
        ACTION_FIRST,
        ACTION_LAST,
        ACTION_NEXT,
        ACTION_PREVIOUS,
        ACTION_COUNT,
      ];
    case FieldTypeIds.DROPDOWN:
    case FieldTypeIds.LITHOLOGY:
      if (!has_multiple) {
        return option_type === DropdownOptionType.Number
          ? [
            ACTION_CURRENT,
            ACTION_FIRST,
            ACTION_LAST,
            ACTION_NEXT,
            ACTION_PREVIOUS,
            ACTION_MIN,
            ACTION_MAX,
            ACTION_AVG,
            ACTION_SUM,
            ACTION_COUNT,
          ]
          : [
            ACTION_CURRENT,
            ACTION_FIRST,
            ACTION_LAST,
            ACTION_NEXT,
            ACTION_PREVIOUS,
            ACTION_COUNT,
          ];
      } else {
        return [
          ACTION_CURRENT,
          ACTION_FIRST,
          ACTION_LAST,
          ACTION_NEXT,
          ACTION_PREVIOUS,
          ACTION_COUNT,
        ];
      }
    case FieldTypeIds.CHECKBOX:
      return [
        ACTION_CURRENT,
        ACTION_FIRST,
        ACTION_LAST,
        ACTION_NEXT,
        ACTION_PREVIOUS,
        ACTION_SUM,
        ACTION_COUNT,
      ];
    case FieldTypeIds.EXPRESSION:
      if (resultType === ResultType.NUMBER) {
        return [
          ACTION_CURRENT,
          ACTION_FIRST,
          ACTION_LAST,
          ACTION_NEXT,
          ACTION_PREVIOUS,
          ACTION_MIN,
          ACTION_MAX,
          ACTION_AVG,
          ACTION_SUM,
          ACTION_COUNT,
        ];
      } else if (resultType === ResultType.BOOLEAN) {
        return [
          ACTION_CURRENT,
          ACTION_FIRST,
          ACTION_LAST,
          ACTION_NEXT,
          ACTION_PREVIOUS,
          ACTION_SUM,
          ACTION_COUNT,
        ];
      }
      return [
        ACTION_CURRENT,
        ACTION_FIRST,
        ACTION_LAST,
        ACTION_NEXT,
        ACTION_PREVIOUS,
        ACTION_COUNT,
      ];
    case FieldTypeIds.DEPTH:
      return [
        ACTION_CURRENT,
        ACTION_FIRST,
        ACTION_LAST,
        ACTION_NEXT,
        ACTION_PREVIOUS,
        ACTION_COUNT,
      ];
  }
}

export function checkIsExpressionReady(values: any[]): boolean {
  return !values.some((v) => [null, undefined, NaN].includes(v));
}

export function findCustomFunctionById(id: number): CustomFunction | undefined {
  return CUSTOM_FUNCTIONS.find((cf) => cf.id === id);
}

export function addArg(
  argGroup: ArgGroup,
  arg: Arg,
  maxCount: number
): ArgGroup {
  if (argGroup.length > maxCount) {
    throw `Invalid arg group: the number of args inside is greater than ${maxCount}.`;
  } else if (maxCount === 0) {
    return argGroup;
  } else if (maxCount === 1) {
    const result = [...argGroup];
    result.splice(0, 1, arg);
    return result;
  } else {
    const result = [...argGroup];
    result.push(arg);
    return result;
  }
}

export function stringifyResult(
  field: GatherField,
  result: string | null | number | boolean
): string | null {
  const resultType = getResultType(field);
  if (resultType === undefined) {
    console.warn(`The result type of the expression ${field.id} is unknown.`);
    return null;
  }

  if (resultType === ResultType.NUMBER) {
    const maxDecimals = field.options?.maxDecimals ?? 0;
    if (typeof result === 'number') {
      return result.toFixed(maxDecimals);
    } else if (typeof result === 'string') {
      return parseFloat(result).toFixed(maxDecimals);
    } else {
      return String(NaN);
    }
  } else if (resultType === ResultType.BOOLEAN) {
    if (typeof result === 'number') {
      throw `The boolean expression should not have a number value`;
    } else if (typeof result === 'string') {
      if (!['1', '0'].includes(result)) {
        throw `The boolean expression should not have a string value other than '1' and '0'`;
      }
      return result;
    } else if (typeof result === 'boolean') {
      return result ? '1' : '0';
    }
    return result;
  } else if (resultType === ResultType.TEXT) {
    if (typeof result === 'number' || typeof result === 'boolean') {
      console.warn(`The text expression should not have a number or boolean value`, result);
      return String(result);
    }
    return result;
  }

  throw `The result type ${resultType} is illegal.`;
}
