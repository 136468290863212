import { useStore } from '@/js/store';
import createPolylineArrowHeadStyle from '@maps/lib/olbm/style/createPolylineArrowHeadStyle';
import { ol_coordinate_offsetCoords } from 'ol-ext/geom/GeomUtils';
import FillPattern from 'ol-ext/style/FillPattern';
import { LineString } from 'ol/geom';
import { getPointResolution } from 'ol/proj';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { BLACK, RED, WHITE } from '../../../business-logic/color';
import { LAYER_TYPES } from '../../../business-logic/layer';
import { MapType } from '../../../lib/olbm/common/types';
import { getSampleIcon } from '../../../lib/olbm/layer/sample/utils';
import { checkIsContours } from '../../../lib/olbm/layer/shape/utils';
import { Color, getContrastColor } from '../../../lib/olbm/style/color';
import createHedgeRenderer from '../../../lib/olbm/style/createHedgeRenderer';
import { formatArea } from '../utils/measurement';
import addOpacity from './addOpacity';
import createAreaStyle from './createAreaStyle';
import createArrowStyle from './createArrowStyle';
import createOutlineStyle from './createOutlineStyle';
import { getLabelStyleConfig, getMeasurementStyleConfig } from './text';

// Use ol-ext's fill patterns
const FILL_PATTERN_OPTIONS = {
  // horizontal-stripe-pattern
  2: {
    pattern: 'hatch',
    angle: 90,
  },
  // vertical-stripe-pattern
  3: {
    pattern: 'hatch',
  },
  // dot-pattern
  4: {
    pattern: 'dot',
  },
  // circle-pattern
  5: {
    pattern: 'circle',
    size: 6,
    spacing: 10,
  },
  // cross-45-deg-pattern
  6: {
    pattern: 'cross',
    angle: 45,
    spacing: 10,
  },
  // stripe-45-deg-pattern
  7: {
    pattern: 'hatch',
    angle: 45,
    spacing: 10,
  },
};

const markerCache = {};

function isFillPatternUsed(fillStyle) {
  return Object.keys(FILL_PATTERN_OPTIONS).indexOf(String(fillStyle)) !== -1;
}

function isNoOverlayUsed(fillStyle) {
  return fillStyle === 1;
}

function checkShouldSwapColors(fillStyle) {
  return [5, 6, 7].includes(fillStyle);
}

function createLineDash(dashArray) {
  if (dashArray == null) {
    return null;
  }

  if (typeof dashArray === 'number') {
    return [dashArray];
  } else if (typeof dashArray === 'string') {
    return dashArray.split(',').map((item) => {
      const neatItem = item.trim();
      return parseFloat(neatItem);
    });
  }
}

function createChainageHashMarkStyle(
  color,
  offset,
  markWidth,
  unitPixels,
  weight,
  res
) {
  return new Style({
    stroke: new Stroke({
      color,
      width: offset > 0 ? 2 * offset : -2 * offset,
      lineDash: [markWidth, unitPixels - markWidth],
      lineCap: 'butt',
      lineJoin: 'bevel',
    }),
    // Offset geometry
    geometry: function (feature) {
      let coords = feature.getGeometry().getCoordinates();

      let size;

      // offset by border
      const halfBorderSize = (weight / 2) * res;
      size = offset > 0 ? halfBorderSize : -halfBorderSize;
      coords = ol_coordinate_offsetCoords(coords, size);

      // offset by hash mark
      size = offset * res;
      coords = ol_coordinate_offsetCoords(coords, size);

      return new LineString(coords);
    },
  });
}

function checkIsOriginalContour(feature, layer, selectedContourId) {
  const featureBeingEdited = layer
    .getSource()
    .getFeatureById(selectedContourId);
  return feature.getId() === featureBeingEdited.get('clonedFromId');
}

/**
 * Geometry layers include Square, Circle, Polygon, Line, Arrow and Boundary.
 * @param {ol/Map} map
 * @param {Object} options
 * @returns
 */
export default function createDrawingLayerStyle(
  map,
  {
    type,
    title,
    usage,
    color = BLACK,
    weight, // Stroke thickness
    opacity = 1, // Stroke opacity
    fillStyle,
    fillOpacity = 0.2,
    outlineStyle,
    dashArray = null,
    areaMeasurement,
    outlineMeasurement,
    multiDirectional,
    icon,
    iconSize,
    shouldShowLabel,
    shouldIncludeUnitInLabels,
    unit,
    isConnectorShownAsArrow,
    isConnectorHidden,
    interval,
    shouldReverseHashMarks,
    arrowHeads = [],
  }
) {
  const proj = map.getView().getProjection();
  const mpu = proj.getMetersPerUnit();
  const isContours = checkIsContours(type, usage);

  // TODO foregroundColor could be a gradient of the backgroundColor
  fillOpacity = !isNoOverlayUsed(fillStyle) ? fillOpacity : 0;
  const foregroundColor = !checkShouldSwapColors(fillStyle)
    ? addOpacity(WHITE, fillOpacity)
    : addOpacity(color, fillOpacity);
  const backgroundColor = !checkShouldSwapColors(fillStyle)
    ? addOpacity(color, fillOpacity)
    : addOpacity(WHITE, fillOpacity);

  return function (feature, res) {
    const store = useStore();
    const styles = [];
    const viewer = map.getViewer();
    const {
      currentDrawingLayer,
      selectedContourId,
      figureLayout: { zoom },
    } = viewer;
    const layer = map.layerManager.findLayerByFeature(feature);
    const isLayerSelected =
      map.layerManager.deproxyLayer(currentDrawingLayer) === layer;
    const isFeatureBeingEdited =
      map.interactionManager?.isFeatureBeingEdited(feature) ?? false;

    let fill;
    if (isFillPatternUsed(fillStyle)) {
      let fillPatternOptions = {
        opacity: 1,
        ratio: 1,
        color: foregroundColor, // foreground color
        offset: 0,
        scale: 1,
        fill: new Fill({ color: backgroundColor }), // background color
        size: 2,
        spacing: 4,
        angle: 0, // in degree
        ...FILL_PATTERN_OPTIONS[fillStyle],
      };
      fillPatternOptions = {
        ...fillPatternOptions,
        size: fillPatternOptions.size * zoom,
        spacing: fillPatternOptions.spacing * zoom,
      };
      fill = new FillPattern(fillPatternOptions);
    } else {
      fill = new Fill({
        color: backgroundColor,
      });
    }

    if (type === 'point') {
      // The way the scaling works is attributed to
      // https://stackoverflow.com/questions/57492200/scaling-the-icon-image-size-to-an-absolute-value
      const { src, size = [width, height] } = getSampleIcon(
        icon,
        color,
        iconSize * zoom,
        iconSize * zoom
      );
      let style = markerCache[src];
      if (!style) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(img, 0, 0, width, height);
          style = markerCache[src] = new Style({
            image: new Icon({
              src: canvas.toDataURL(),
            }),
          });

          styles.push(style);
          feature.changed();
        };
        img.src = src;
      } else {
        styles.push(style);
      }
    } else if (type !== LAYER_TYPES.HEDGE) {
      let _color = color;
      let _opacity = opacity;
      let _outlineStyle = outlineStyle;
      let _weight = weight;
      let _dashArray = dashArray;

      if (isContours) {
        if (isLayerSelected) {
          if (selectedContourId) {
            const isOriginalFeature = checkIsOriginalContour(
              feature,
              layer,
              selectedContourId
            );
            const shouldDim = !isFeatureBeingEdited && !isOriginalFeature;
            _color = shouldDim
              ? Color.Gray
              : isOriginalFeature
              ? getContrastColor(_color)
              : _color;
            _opacity = shouldDim
              ? 0.4
              : isOriginalFeature
              ? feature.get('opacity') ?? 1
              : _opacity;
            if (!isFeatureBeingEdited) {
              _outlineStyle = feature.get('outlineStyle') ?? 0;
              const featureWeight = feature.get('weight') ?? 3;
              const slps = viewer.getScaledLayerProperties({
                outlineStyle: _outlineStyle,
                weight: featureWeight,
              });
              _weight = slps.weight;
              _dashArray = slps.dashArray;
            }
          }
        } else {
          _color = feature.get('color') ?? _color;
          _opacity = feature.get('opacity') ?? _opacity;
          _outlineStyle = feature.get('outlineStyle') ?? _outlineStyle;
          const featureWeight = feature.get('weight');
          if (typeof featureWeight === 'number') {
            const slps = viewer.getScaledLayerProperties({
              outlineStyle: _outlineStyle,
              weight: featureWeight,
            });
            _weight = slps.weight;
            _dashArray = slps.dashArray;
          }
        }
      }

      styles.push(
        new Style({
          fill,
          stroke: new Stroke({
            color: addOpacity(_color, _opacity),
            lineCap: _outlineStyle === 2 ? 'round' : 'butt',
            lineDash: createLineDash(_dashArray, zoom),
            width: _weight,
          }),
        })
      );
    }

    if (map.getType() !== MapType.MAIN) {
      return styles;
    }

    if (isContours) {
      let _shouldShowLabel = shouldShowLabel;
      let _title = title;

      if (isLayerSelected) {
        if (selectedContourId) {
          if (!isFeatureBeingEdited) {
            _shouldShowLabel = feature.get('shouldShowLabel') ?? true;
            _title = String(feature.get('Value') ?? '');
          }
        } else {
          _title = String(feature.get('Value') ?? '');
        }
      } else {
        _shouldShowLabel = feature.get('shouldShowLabel') ?? _shouldShowLabel;
        _title = String(feature.get('Value') ?? '');
      }

      if (_shouldShowLabel) {
        styles.push(
          new Style({
            text: new Text({
              text: shouldIncludeUnitInLabels
                ? `${_title} ${unit}`.trim()
                : _title,
              ...getMeasurementStyleConfig(map),
            }),
          })
        );
      }
    }

    const hasArea = [
      LAYER_TYPES.RECTANGLE,
      LAYER_TYPES.CIRCLE,
      LAYER_TYPES.POLYGON,
      LAYER_TYPES.SITE_BOUNDARY,
      LAYER_TYPES.IMAGE_MATE,
      LAYER_TYPES.HEDGE,
    ].includes(type);
    const hasLength =
      [
        LAYER_TYPES.RECTANGLE,
        LAYER_TYPES.POLYGON,
        LAYER_TYPES.POLYLINE,
        LAYER_TYPES.ARROW,
        LAYER_TYPES.SITE_BOUNDARY,
        LAYER_TYPES.IMAGE_MATE,
        LAYER_TYPES.CHAINAGE,
        LAYER_TYPES.HEDGE,
      ].includes(type) || isContours;

    const shouldShowAreaMeasurement =
      (isFeatureBeingEdited && hasArea) || areaMeasurement;
    // If label is shown then the area measurement shows in the label.
    if (!shouldShowLabel && shouldShowAreaMeasurement) {
      styles.push(createAreaStyle(map, feature, !areaMeasurement));
    }

    let _outlineMeasurement = outlineMeasurement;
    if (isContours) {
      if (isLayerSelected) {
        if (selectedContourId) {
          if (!isFeatureBeingEdited) {
            _outlineMeasurement = feature.get('outlineMeasurement') ?? false;
          }
        }
      } else {
        _outlineMeasurement =
          feature.get('outlineMeasurement') ?? _outlineMeasurement;
      }
    }
    if ((isFeatureBeingEdited && hasLength) || _outlineMeasurement) {
      styles.push(...createOutlineStyle(map, feature, !_outlineMeasurement));
    }

    if (
      type === LAYER_TYPES.ARROW ||
      (type === LAYER_TYPES.CALL_OUT &&
        !isConnectorHidden &&
        isConnectorShownAsArrow)
    ) {
      const headStroke = new Stroke({
        color: addOpacity(color, opacity),
        width: weight,
      });
      styles.push(
        ...createArrowStyle(map, feature, headStroke, weight, multiDirectional)
      );
    }

    if (type === LAYER_TYPES.CHAINAGE) {
      const factor = shouldReverseHashMarks ? -1 : 1;
      const largeOffset = 20 * factor;
      const mediumOffset = 15 * factor;
      const smallOffset = 10 * factor;
      const unitPixels =
        interval /
        (mpu *
          getPointResolution(
            map.getView().getProjection(),
            res,
            map.getView().getCenter()
          ));

      styles.push(
        createChainageHashMarkStyle(
          BLACK,
          smallOffset,
          1,
          unitPixels / 10,
          weight,
          res
        ),
        createChainageHashMarkStyle(
          BLACK,
          mediumOffset,
          1,
          unitPixels / 2,
          weight,
          res
        ),
        createChainageHashMarkStyle(
          RED,
          largeOffset,
          1,
          unitPixels,
          weight,
          res
        )
      );
    }

    if (type === LAYER_TYPES.HEDGE) {
      const { hedgeImage: image } = map.getViewer();
      const isEditing = map.interactionManager.isEditing();
      styles.push(
        new Style({
          renderer: createHedgeRenderer(map, image, isEditing),
          zIndex: 0,
        })
      );
    }

    // Arrow heads on the polyline
    if (type === LAYER_TYPES.POLYLINE || isContours) {
      let _arrowHeads = arrowHeads;

      if (
        isContours &&
        (!isLayerSelected || (selectedContourId && !isFeatureBeingEdited))
      ) {
        _arrowHeads = feature.get('arrowHeads') ?? [];
      }

      const pahStyles = createPolylineArrowHeadStyle(
        map,
        feature,
        res,
        zoom,
        _arrowHeads
      );
      styles.push(...pahStyles);
    }

    if (
      [
        LAYER_TYPES.POLYGON,
        LAYER_TYPES.RECTANGLE,
        LAYER_TYPES.CIRCLE,
        LAYER_TYPES.POLYLINE,
        LAYER_TYPES.ARROW,
        LAYER_TYPES.SITE_BOUNDARY,
        LAYER_TYPES.HEDGE,
      ].includes(type) &&
      shouldShowLabel
    ) {
      const label = shouldShowAreaMeasurement
        ? [
            title,
            formatArea(
              map,
              feature.getGeometry(),
              store.state.project.measurement_type
            ),
          ].join('\n')
        : title;
      styles.push(
        new Style({
          text: new Text(getLabelStyleConfig(map, label, color)),
          zIndex: 1,
        })
      );
    }

    return styles;
  };
}
