import store from '@/js/store';
import EventBus from '@component-library/EventBus';
import { getUid } from 'ol/util';
import { LAYER_TYPES } from '../../../business-logic/layer';
import { NAMESPACE } from '../../../store';
import { callout } from '../layers';
import createBufferEdit from './createBufferEdit';
import createChainageEdit from './createChainageEdit';
import createCircleEdit from './createCircleEdit';
import createDraw from './createDraw';
import createFeatureCollectionEdit from './createFeatureCollectionEdit';
import createPolyEdit from './createPolyEdit';
import createSampleEdit from './createSampleEdit';
import createSquareEdit from './createSquareEdit';
import createTextEdit from './createTextEdit';
import * as image from './image';

export default class InteractionManager {
  constructor(map) {
    this.map = map;
  }
  /**
   * Begin an interaction session which is used to draw and edit features
   * on the map.
   */
  beginSession() {
    if (this.session) {
      throw new Error('An interaction session has already existed.');
    }

    this.session = {
      // Draw is used to draw a shape.
      draw: null,
      // Edit is used to modify a shape.
      editsByLayerUid: {},
    };
  }
  hasSession() {
    return !!this.session;
  }
  /**
   * End the interaction session.
   * @param {Boolean} cancelled True means that the interaction is cancelled.
   */
  endSession(cancelled) {
    if (!this.session) {
      throw new Error('There is no interaction session.');
    }

    this.endDraw();

    const { editsByLayerUid } = this.session;
    const keys = Object.keys(editsByLayerUid);
    if (keys.length > 0) {
      for (const key of keys) {
        this._removeEdits(key, cancelled);
      }
    }

    this.session = null;
  }
  requestDraw(options) {
    if (!this.session) {
      throw new Error(`An interaction session is required.`);
    }
    if (this.isDrawing()) {
      throw new Error(`A draw has already existed.`);
    }

    this.session.draw = createDraw(this.map, options);
    this.map.addInteraction(this.session.draw);
  }
  endDraw() {
    if (!this.session) {
      throw new Error('There is no interaction session.');
    }

    const { draw } = this.session;
    if (draw) {
      this.map.removeInteraction(draw);
    }
    this.session.draw = null;
  }
  getDraw() {
    return this.session.draw;
  }
  isDrawing() {
    return this.session?.draw?.getActive();
  }
  isEditing() {
    return Object.keys(this.session?.editsByLayerUid || {}).length > 0;
  }
  isLayerBeingEdited(layer) {
    const key = getUid(layer);
    return !!this.session?.editsByLayerUid[key];
  }
  _addEdit(layer, edit) {
    const key = getUid(layer);
    if (!this.session.editsByLayerUid[key]) {
      this.session.editsByLayerUid[key] = [];
    }
    this.session.editsByLayerUid[key].push(edit);
    edit.layer = layer;
    edit.activate(this.map);
    return edit;
  }
  _removeEdits(key, cancelled) {
    const edits = this.session.editsByLayerUid[key];
    delete this.session.editsByLayerUid[key];
    edits.forEach((item) => {
      const { layer, initialData } = item;
      item.destroy(this.map);
      EventBus.$emit('closeFeature', { layer, initialData, cancelled });
    });
  }
  /**
   * Request an edit.
   * @param {ol.layer.Layer} layer
   * @param {ol.Feature} feature The feature must be specified for sample layers.
   * @param {Boolean} forNewFeature A bool value used to represent whether the edit is used for
   *                                a newly created object or an existing object.
   * @returns
   */
  requestEdit(layer, feature, forNewFeature = true) {
    if (!this.session) {
      throw new Error(`An interaction session is required.`);
    }

    const {
      options: { type: layerType },
    } = layer;

    if (layerType !== LAYER_TYPES.SAMPLE && this.isLayerBeingEdited(layer)) {
      throw new Error(`The layer is being edited.`);
    }

    if (layerType === LAYER_TYPES.RECTANGLE) {
      return this._addEdit(layer, createSquareEdit(this.map));
    } else if (layerType === LAYER_TYPES.IMAGE) {
      const edit = image.createEdit(this.map);
      const imageEditMethodCode = NAMESPACE.getState(
        store,
        'imageEditMethodCode'
      );
      edit.useEditMethod(imageEditMethodCode);
      return this._addEdit(layer, edit);
    } else if (layerType === LAYER_TYPES.CIRCLE) {
      return this._addEdit(layer, createCircleEdit(this.map));
    } else if (
      [
        LAYER_TYPES.POLYGON,
        LAYER_TYPES.SITE_BOUNDARY,
        LAYER_TYPES.POLYLINE,
        LAYER_TYPES.ARROW,
      ].includes(layerType) ||
      this.map.layerManager.isContours(layer)
    ) {
      return this._addEdit(layer, createPolyEdit(this.map, layerType));
    } else if (layerType === LAYER_TYPES.CHAINAGE) {
      return this._addEdit(layer, createChainageEdit(this.map));
    } else if (layerType === LAYER_TYPES.TEXT) {
      return this._addEdit(layer, createTextEdit(this.map, forNewFeature));
    } else if (layerType === LAYER_TYPES.CALL_OUT) {
      return this._addEdit(layer, callout.createEdit(this.map));
    } else if (layerType === LAYER_TYPES.SAMPLE) {
      const viewer = this.map.getViewer();
      const sample = viewer.getSampleById(feature.getId());
      const edit = createSampleEdit(this.map, sample, forNewFeature);
      // The initial positions can be used to revert the position of a sample
      // when the edit of the sample's group is cancelled.
      edit.initialData = {
        sampleId: sample.id,
        positions: {
          position: layer.getPosition(sample),
          labelPosition: layer.getLabelPosition(sample),
        },
        iconSize: layer.getIconSize(sample),
        iconRotation: layer.getIconRotation(sample),
        iconOpacity: layer.getIconOpacity(sample),
        isLabelHidden: layer.getIsLabelHidden(sample),
        labelColor: layer.getLabelColor(sample),
        labelShadowColor: layer.getLabelShadowColor(sample),
        labelSize: layer.getLabelSize(sample),
        isLabelUnderlined: layer.getIsLabelUnderlined(sample),
        isLabelAsteriskAppended: layer.getIsLabelAsteriskAppended(sample),
        stylingPriority: layer.getStylingPriority(sample),
      };
      return this._addEdit(layer, edit);
    } else if (layerType === LAYER_TYPES.FEATURE_COLLECTION) {
      return this._addEdit(layer, createFeatureCollectionEdit());
    } else if (layerType === LAYER_TYPES.BUFFER) {
      return this._addEdit(layer, createBufferEdit(this.map));
    } else if (layerType === LAYER_TYPES.HEDGE) {
      return this._addEdit(layer, createSquareEdit(this.map));
    } else {
      throw new Error(`The layer type "${layerType}" is not supported`);
    }
  }
  // For layers which have only one feature, e.g. Square, Circle, use this method.
  getEdit(layer) {
    const edits = this.getEdits(layer);
    return edits ? edits[0] : null;
  }
  getEdits(layer) {
    if (!this.session) {
      throw new Error(`An interaction session is required.`);
    }

    const key = getUid(layer);
    return this.session.editsByLayerUid[key];
  }
  // For sample layers.
  findSampleEdit(layer, sampleId) {
    return this.getEdits(layer)?.find(
      (item) => item.getFeature().getId() === sampleId
    );
  }
  changeEditsKey(oldKey, newKey) {
    if (!this.session) {
      throw new Error(`An interaction session is required.`);
    }

    const edits = this.session.editsByLayerUid[oldKey];
    delete this.session.editsByLayerUid[oldKey];

    const newLayer = this.map.layerManager.findLayerByUid(newKey);
    for (const edit of edits) {
      const sampleId = edit.getFeature().getId();
      const newFeature = newLayer.findFeatureBySampleId(sampleId);
      edit.selectFeature(newFeature);
    }

    this.session.editsByLayerUid[newKey] = edits;
  }
  isFeatureBeingEdited(feature) {
    const { layerManager } = this.map;
    const layer = layerManager.findLayerByFeature(feature);
    return (
      layer &&
      this.isLayerBeingEdited(layer) &&
      (!layerManager.isContours(layer) ||
        feature.getId() === this.map.getViewer().selectedContourId)
    );
  }
  checkIsPolyEditWorking() {
    if (!this.isEditing()) {
      return false;
    }

    const { editsByLayerUid } = this.session;
    const keys = Object.keys(editsByLayerUid);
    if (keys.length !== 1) {
      return false;
    }

    const id = parseInt(keys[0], 10);
    const layer = this.map.layerManager.findLayerByUid(id);
    if (
      !layer ||
      ![
        LAYER_TYPES.POLYGON,
        LAYER_TYPES.RECTANGLE,
        LAYER_TYPES.CIRCLE,
        LAYER_TYPES.SITE_BOUNDARY,
        LAYER_TYPES.HEDGE,
        LAYER_TYPES.POLYLINE,
        LAYER_TYPES.ARROW,
        LAYER_TYPES.CALL_OUT,
      ].includes(layer.options.type)
    ) {
      return false;
    }

    const [edit] = editsByLayerUid[id];
    return edit.checkIsWorking();
  }
}
