import Popup from 'ol-ext/overlay/Popup';

const ANCHOR_SIZE = 11;

/**
 * Offset is required when the popup doesn't have an anchor.
 * @param {ol-ext/overlayer/Popup} popup
 */
export function offsetNoAnchor(popup: Popup) {
  const positioning = popup.getPositioning();
  let offset = [0, 0];
  if (positioning === 'top-center') {
    offset[1] = -ANCHOR_SIZE;
  } else if (positioning === 'center-right') {
    offset[0] = ANCHOR_SIZE;
  } else if (positioning === 'bottom-center') {
    offset[1] = ANCHOR_SIZE;
  } else if (positioning === 'center-left') {
    offset[0] = -ANCHOR_SIZE;
  }
  popup.setOffset(offset);
}
