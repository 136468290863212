import { createTraits } from '../utils/FigurePrintLayoutUtils';

export const LAYOUT_TYPES = {
  FIGURE: 'figure',
  FLOW_DIAGRAM: 'flow-diagram',
};

export const COMPONENT_TYPE_CODES = {
  MAIN_MAP: 'main-map',
  MINI_MAP: 'mini-map',
  FIGURE_LEGEND: 'figure-legend',
  NORTH_ARROW: 'north-arrow',
  SCALE_BAR: 'scale-bar',
  FIGURE_TABLE: 'figure-table',
  DIVIDER: 'divider',
  FIGURE_TEXT: 'figure-text',
  FIGURE_IMAGE: 'figure-image',
};

export const TRAIT_CODES = {
  PADDINGS: 'paddings',
  BORDERS: 'borders',
  FILL: 'fill',
  ALIGNMENTS: 'alignments',
  IS_MULTIPLE: 'is_multiple', // Multiple components can be created.
  DIRECTION: 'direction', // Horizontal or vertical,
  FONT: 'font',
  VALUE: 'value',
  RICH_TEXT: 'rich_text',
  IMAGE: 'image',
};

export const DIRECTIONS = {
  HORIZONTAL: 1,
  VERTICAL: 2,
};

export const VALUE_TYPES = {
  STATIC: 1,
  DYNAMIC: 2,
};

export const URL_TYPES = {
  COMPANY_LOGO: 1,
  OTHER_IMAGE: 2,
};

const COMMON_TRAIT_CODES = [
  TRAIT_CODES.PADDINGS,
  TRAIT_CODES.BORDERS,
  TRAIT_CODES.FILL,
];

export const COMPONENT_TYPES = [
  {
    code: COMPONENT_TYPE_CODES.MAIN_MAP,
    label: 'Viewer',
    traits: createTraits(COMMON_TRAIT_CODES),
  },
  {
    code: COMPONENT_TYPE_CODES.MINI_MAP,
    label: 'Mini-map',
    traits: createTraits(COMMON_TRAIT_CODES),
    type: LAYOUT_TYPES.FIGURE,
  },
  {
    code: COMPONENT_TYPE_CODES.FIGURE_LEGEND,
    label: 'Legend',
    traits: createTraits(COMMON_TRAIT_CODES),
    type: LAYOUT_TYPES.FIGURE,
  },
  {
    code: COMPONENT_TYPE_CODES.NORTH_ARROW,
    label: 'North Arrow',
    traits: createTraits([...COMMON_TRAIT_CODES, TRAIT_CODES.IMAGE]).map(
      (item) => {
        if ([TRAIT_CODES.IMAGE].includes(item.code)) {
          return {
            ...item,
            visible: false,
          };
        }
        return item;
      }
    ),
    type: LAYOUT_TYPES.FIGURE,
  },
  {
    code: COMPONENT_TYPE_CODES.SCALE_BAR,
    label: 'Scale Bar',
    traits: createTraits([...COMMON_TRAIT_CODES, TRAIT_CODES.ALIGNMENTS]),
    type: LAYOUT_TYPES.FIGURE,
  },
  {
    code: COMPONENT_TYPE_CODES.FIGURE_TABLE,
    label: 'Table',
    traits: createTraits([
      ...COMMON_TRAIT_CODES,
      TRAIT_CODES.IS_MULTIPLE,
      TRAIT_CODES.RICH_TEXT,
      TRAIT_CODES.FONT,
    ]),
  },
  {
    code: COMPONENT_TYPE_CODES.FIGURE_TEXT,
    label: 'Text',
    traits: createTraits([
      ...COMMON_TRAIT_CODES,
      TRAIT_CODES.IS_MULTIPLE,
      TRAIT_CODES.ALIGNMENTS,
      TRAIT_CODES.FONT,
      TRAIT_CODES.VALUE,
      TRAIT_CODES.DIRECTION,
    ]),
  },
  {
    code: COMPONENT_TYPE_CODES.FIGURE_IMAGE,
    label: 'Image',
    traits: createTraits([
      ...COMMON_TRAIT_CODES,
      TRAIT_CODES.IS_MULTIPLE,
      TRAIT_CODES.IMAGE,
    ]),
  },
  {
    code: COMPONENT_TYPE_CODES.DIVIDER,
    label: 'Divider',
    traits: createTraits([
      ...COMMON_TRAIT_CODES,
      TRAIT_CODES.IS_MULTIPLE,
      TRAIT_CODES.FILL,
    ]).map((item) => {
      if ([TRAIT_CODES.PADDINGS, TRAIT_CODES.BORDERS].includes(item.code)) {
        return {
          ...item,
          visible: false,
        };
      }
      return item;
    }),
  },
];
