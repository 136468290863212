import _omit from 'lodash/omit';
import { Feature, Map, getUid } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { extentToBounds } from '../../common/extent';
import { toGeoJSON as _toGeoJSON, fromGeoJSON } from '../../common/geojson';
import { getStoreApi } from '../../common/store-api';
import createShapeStyle from '../../style/createShapeStyle';
import { createLayerProperties } from '../utils';
import type { MultipleShapeLayerModel, Shape, ShapeLayer } from './types';

export default function createMultipleShapeLayer(
  map: Map,
  model: MultipleShapeLayerModel
): ShapeLayer {
  const storeApi = getStoreApi(map);
  const features = fromGeoJSON(map, _omit(model.geojson, ['properties'])) as Feature<Shape>[];
  const { type, usage } = model.geojson.properties;
  const options = {
    source: new VectorSource<Feature<Shape>>({
      features,
    }),
    properties: createLayerProperties(model.id, type, usage),
  };
  const layer = new VectorLayer(options) as ShapeLayer;
  features.forEach((feature) => feature.set('layerUid', getUid(layer)));
  layer.setStyle(
    createShapeStyle(map, () => {
      return model.geojson.properties;
    })
  );

  layer.getFirstFeature = function () {
    return this.getSource()!.getFeatures()[0];
  };

  layer.checkHasFeature = function (feature) {
    return this.getSource()!.hasFeature(feature as Feature<Shape>);
  };

  layer.toGeoJSON = function () {
    const layerModel = storeApi.findLayerModelById(
      model.id
    )! as MultipleShapeLayerModel;
    const features = this.getSource()!.getFeatures();
    const result = _toGeoJSON(map, features) as GeoJSON.FeatureCollection & {
      properties: object;
    };
    for (const gFeature of result.features) {
      delete gFeature.id;
      delete gFeature.properties!.layerUid;
    }
    result.properties = layerModel.geojson.properties;
    return result;
  };

  layer.getBounds = function (padding = 0) {
    const extent = this.getSource()!.getExtent();
    return extentToBounds(extent, map.getView().getProjection(), padding);
  };

  layer.refresh = function () { };

  return layer;
}
