<script setup>
import { ref, watch, onMounted } from 'vue';

const props = defineProps({
  toRight: Boolean,
  onTop: Boolean,
  onRight: Boolean,
  onLeft: Boolean,
  customWidth: {
    type: String,
    default: 'auto',
  },
  customStyle: {
    type: Object,
    default: () => ({}),
  },
  forceOpen: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: 'px-3',
  },
  autoDetermineDirectionV: {
    type: Boolean,
    default: false,
  },
  autoDetermineDirectionH: {
    type: Boolean,
    default: false,
  },
  blockClose: {
    type: Boolean,
    default: false,
  },
});

const dropdownMenuRef = ref();
const toggled = ref(false);
const showOnTop = ref(props.onTop);
const showToRight = ref(props.toRight);

const emit = defineEmits(['toggled']);

watch(
  () => props.forceOpen,
  (val) => {
    if (!val) return;

    toggled.value = true;
    emit('toggled', true);
  }
);

const toggle = () => {
  toggled.value = !toggled.value;
  emit('toggled', toggled.value);
};

const close = () => {
  if (!toggled.value || props.blockClose) {
    return;
  }

  toggle();
};

defineExpose({ toggle, toggled, close });

onMounted(() => {
  if (props.autoDetermineDirectionV) {
    watch(
      [toggled, () => window.innerHeight, () => window.innerWidth],
      () => {
        if (dropdownMenuRef.value) {
          const rect = dropdownMenuRef.value?.getBoundingClientRect();
          if (!rect) {
            throw new Error('Unable to autoDetermineDirectionV: No rect');
          }

          const exceedsBottom = rect.bottom > window.innerHeight;
          showOnTop.value = exceedsBottom;
        }
      },
      { immediate: true }
    );
  }

  if (props.autoDetermineDirectionH) {
    watch(
      [toggled, () => window.innerHeight, () => window.innerWidth],
      () => {
        if (dropdownMenuRef.value) {
          const rect = dropdownMenuRef.value?.getBoundingClientRect();
          if (!rect) {
            throw new Error('Unable to autoDetermineDirectionH: No rect');
          }

          const exceedsRight = rect.right > window.innerWidth;
          showToRight.value = exceedsRight;
        }
      },
      { immediate: true }
    );
  }
});
</script>

<template>
  <div v-click-outside="close" class="dropdown" :class="{ toggled: toggled }">
    <slot
      name="button"
      :toggle="() => toggle()"
      :toggled="toggled"
      :close="() => close()"
      :class="{ dropup: showOnTop }"
      @click="toggle"
    />

    <Transition name="dropdown">
      <div
        v-if="toggled"
        ref="dropdownMenuRef"
        class="dropdown-menu mt-1"
        :class="[
          {
            show: toggled,
            'management__dropdown dropdown-menu-end': showToRight,
            top: showOnTop,
            onRight: onRight,
            onLeft: onLeft,
          },
          padding,
        ]"
        :style="{ ...{ width: customWidth }, ...customStyle }"
      >
        <slot name="items" :toggled="toggled" :close="() => toggle()" />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.dropdown-menu {
  z-index: 20003;
}

@media only screen and (max-width: 576px) {
  .dropdown-menu {
    width: 100% !important;
    margin: 0px;
  }
}
</style>
