import { useToastStore } from '../toasts';

export default {
  install: function (app) {
    const toastStore = useToastStore();
    // Set as a global property
    /** @deprecated useToastStore instead of this.$toastStore */
    app.config.globalProperties.$toastStore = toastStore;
  },
};
