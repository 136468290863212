import { ref } from 'vue';

export default function useInfiniteScroll(buffer: number = 75) {
  const loadedAllPaginatedData = ref(false);
  const currentPage = ref<number>(1);

  const checkIfLoadMore = (ele: HTMLElement) => {
    return ele.scrollHeight - ele.scrollTop - buffer <= ele.clientHeight;
  };

  return {
    loadedAllPaginatedData,
    currentPage,
    checkIfLoadMore,
    onScroll(e: Event, loadData: () => void) {
      if (loadedAllPaginatedData.value) {
        return;
      }

      const ele = e.target as HTMLElement;
      if (checkIfLoadMore(ele)) {
        loadData();
      }
    },
  };
}
