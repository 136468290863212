import _omit from 'lodash/omit';
import { Feature, Map, getUid } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Style, Text } from 'ol/style';
import { checkIsValidLatLng, fromLonLat } from '../../common/coordinate';
import { extentToBounds } from '../../common/extent';
import { toGeoJSON as _toGeoJSON, fromGeoJSON } from '../../common/geojson';
import { getStoreApi } from '../../common/store-api';
import createShapeStyle from '../../style/createShapeStyle';
import { getFont } from '../../style/text';
import type { LayerUsage } from '../constants';
import { createLayerProperties, getLayerTitle } from '../utils';
import getShapeStyle from './getShapeStyle';
import type { Shape, ShapeLayer, SingleShapeLayerModel } from './types';

export default function createSingleShapeLayer(
  map: Map,
  model: SingleShapeLayerModel,
  layerUsage?: LayerUsage
): ShapeLayer {
  const storeApi = getStoreApi(map);
  const [feature] = fromGeoJSON(
    map,
    _omit(model.geojson, ['properties'])
  ) as Feature<Shape>[];
  const lopSample = storeApi.findLopSampleByLayerModelId(model.id);
  if (lopSample) {
    feature.setId(lopSample.id);
  }
  const options = {
    source: new VectorSource<Feature<Shape>>({
      features: [feature],
    }),
    properties: createLayerProperties(
      model.id,
      model.geojson.properties.type,
      layerUsage
    ),
  };
  const layer = new VectorLayer(options) as ShapeLayer;
  layer.setStyle((feature, res) => {
    const figure = storeApi.getSelectedFigure()!;
    const layerModel = storeApi.findLayerModelById(
      model.id
    )! as SingleShapeLayerModel;
    const _lopSample = storeApi.findLopSampleByLayerModelId(model.id);

    // Basic styles
    const shapeStyle = createShapeStyle(map, () => {
      let {
        geojson: { properties },
      } = layerModel;
      properties = {
        ...properties,
        ...getShapeStyle(map, figure, layerModel),
      };

      return {
        ...properties,
        title: getLayerTitle(layerModel),
        shouldShowLabel: properties.shouldShowLabel ?? !!_lopSample,
      };
    })(feature, res);

    const poisStyle: Style[] = (
      _lopSample?.points_of_interest?.map((poi, index) => {
        const { longitude, latitude } = poi;

        if (!checkIsValidLatLng({ lat: poi.latitude, lng: poi.longitude })) {
          return;
        }

        const hoveredPoi = map.get('hoveredPoi');
        const isHovered =
          !!hoveredPoi &&
          _lopSample.id === hoveredPoi.sampleId &&
          index === hoveredPoi.index;
        const staticBackgroundColor = poi.dataForm ? 'green' : 'orange';
        const backgroundColor = isHovered ? '#333' : staticBackgroundColor;
        const fontColor = isHovered ? staticBackgroundColor : '#333';

        const coordinate = fromLonLat(
          { longitude, latitude },
          map.getView().getProjection()
        );
        return new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: backgroundColor,
            }),
          }),
          text: new Text({
            text: String(index + 1),
            font: getFont(10),
            fill: new Fill({
              color: fontColor,
            }),
          }),
          geometry: new Point(coordinate),
        });
      }) ?? []
    ).filter((style) => !!style) as Style[];

    return [...shapeStyle, ...poisStyle];
  });
  feature.set('layerUid', getUid(layer));

  layer.getFirstFeature = function () {
    return this.getSource()!.getFeatures()[0];
  };

  layer.checkHasFeature = function (feature) {
    return this.getSource()!.hasFeature(feature as Feature<Shape>);
  };

  layer.toGeoJSON = function () {
    const layerModel = storeApi.findLayerModelById(
      model.id
    )! as SingleShapeLayerModel;
    const feature = this.getFirstFeature()!;
    feature.setProperties(layerModel.geojson.properties);
    const result = _toGeoJSON(map, feature) as GeoJSON.Feature;
    delete result.id;
    delete result.properties!.layerUid;
    return result;
  };

  layer.getBounds = function (padding = 0) {
    const extent = this.getSource()!.getExtent();
    return extentToBounds(extent, map.getView().getProjection(), padding);
  };
  layer.refresh = function () {
    layer.changed();
  };

  return layer;
}
