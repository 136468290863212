import { replaceQueryParam } from '@component-library/utils';
import _snakeCase from 'lodash/snakeCase';
import type {
  FigureSample,
  NormalizeSampleStreamable,
  OnSampleStreamableParsed,
  ResolveSampleGroup,
  Sample,
  SampleStreamable,
  StreamingParameters,
} from './definitions';
import { normalizeSampleLabelPosition, normalizeSampleLocation } from './utils';

const START = '__s__';
const END = '__e__';
const START_DELIMITER = `{"${START}":true`;
const END_DELIMITER = `"${END}":true}`;

async function streamSampleStreamable<S extends SampleStreamable>(
  baseUrl: string,
  parameters: StreamingParameters<S>,
  normalize: NormalizeSampleStreamable<S> = (s) => {
    return s as S;
  },
  onSampleStreamableParsed: OnSampleStreamableParsed<S> = () => {}
) {
  const url = Object.keys(parameters)
    .filter((name) => name !== 'token')
    .reduce((accu, name) => {
      const value = parameters[name];
      if (value !== null) {
        return replaceQueryParam(accu, _snakeCase(name), value);
      }
      return accu;
    }, baseUrl);
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${parameters.token}`,
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  if (!response.body) {
    throw new Error('Sample streaming is not supported in this environment.');
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let processedData = '';
  while (true) {
    const { done, value } = await reader.read();
    const data = decoder.decode(value, { stream: !done });

    if (done && !value) {
      break;
    }

    processedData += data;

    let startIndex;
    let endIndex;
    while (
      (startIndex = processedData.indexOf(START_DELIMITER)) !== -1 &&
      (endIndex = processedData.indexOf(END_DELIMITER)) !== -1
    ) {
      const jsonStartIndex = startIndex;
      const jsonEndIndex = endIndex + END_DELIMITER.length;
      const json = processedData.slice(jsonStartIndex, jsonEndIndex);
      let sampleStreamable = JSON.parse(json);
      delete sampleStreamable[START];
      delete sampleStreamable[END];
      const nSampleStreamable = normalize(sampleStreamable);
      onSampleStreamableParsed(nSampleStreamable);

      processedData =
        processedData.slice(0, jsonStartIndex) +
        processedData.slice(jsonEndIndex);
    }
  }
}

export async function streamSamples(
  parameters: StreamingParameters<Sample>,
  resolveSampleGroup: ResolveSampleGroup,
  onSampleParsed: OnSampleStreamableParsed<Sample>
) {
  await streamSampleStreamable(
    '/api/figure8/samples/stream',
    parameters,
    (_sample) => {
      let sample = normalizeSampleLocation(_sample);
      sample = normalizeSampleLabelPosition(sample);
      sample.icon_opacity = parseFloat(sample.icon_opacity);
      sample.is_label_hidden = !!sample.is_label_hidden;
      sample.sample_group = resolveSampleGroup(sample.project_figure_layer_id);
      return sample;
    },
    onSampleParsed
  );
}

export async function streamFigureSamples(
  parameters: StreamingParameters<FigureSample>,
  onFigureSampleParsed: OnSampleStreamableParsed<FigureSample>
) {
  await streamSampleStreamable(
    '/api/figure8/figure-samples/stream',
    parameters,
    undefined,
    onFigureSampleParsed
  );
}
