
export function usePublicFormToken(): string | undefined {
    const pathParts = window.location.pathname.split('/');
    if (pathParts.length >= 3) {

        if (pathParts[1] === 'form') {
            return pathParts[2];
        }
    }

    return undefined;
}
